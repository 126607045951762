import { Stub, Config, upgrade } from '../../../..';
import { v4 as uuidv4 } from 'uuid';

export default (stub: Stub, config: Config): Stub => {
    stub.version = 7;

    if (!stub.id) {
        stub.id = uuidv4();
    }

    if (config.recursive) {
        const keys = Object.keys(stub.buttonType);

        for (const key of keys) {
            if (typeof stub.buttonType[key] === 'object') {
                stub.buttonType[key] = upgrade(stub.buttonType[key], config);
            } else {
                delete stub.buttonType[key];
            }
        }
    }

    return stub;
};
