import * as Sentry from '@sentry/react'
import { makeAutoObservable } from 'mobx'
import { AxiosRequestConfig } from 'axios'
import { isHydrated, makePersistable } from 'mobx-persist-store'

import { RootStore } from './RootStore'
import { APIMembership } from 'src/model/api/user/APIMembership'
import { GenericUserResponse } from 'src/model/auth/GenericUserResponse'
import { CompanyModel } from 'src/model/company'
import { Company } from 'src/model/company/Company'
import { Membership } from 'src/model/user/Membership'
import { Profile } from 'src/model/user/Profile'
import { UserModel } from 'src/model/user/UserModel'
import { UUID } from 'src/util/types'
import { UserUtil } from 'src/util/UserUtil'
import { BaseStore } from './BaseStore'

export class UserStore implements BaseStore {
  static USER_STORE_STORAGE_KEY = 'TeamsUser'
  isAuthenticated = false
  isConsoleAuthenticated = false // Non persisted, used for console login
  isAuthMethodSSO = false
  didFailLogin = false
  didLogout = false
  accessToken?: string
  refreshToken?: string
  authCheckTimestamp: number
  authZeroCheckTimestamp: number
  authDestructiveActionTimestamp: number
  rootStore: RootStore
  platform?: string
  loginCompanyUUID?: UUID
  profile?: Profile
  memberships: Membership[]

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.isAuthenticated = false
    this.isConsoleAuthenticated = false
    this.isAuthMethodSSO = false
    this.didFailLogin = false
    this.didLogout = false
    this.accessToken = undefined
    this.refreshToken = undefined
    this.platform = undefined
    this.loginCompanyUUID = undefined
    this.authCheckTimestamp = 0
    this.authZeroCheckTimestamp = 0
    this.authDestructiveActionTimestamp = 0
    this.memberships = []
    makeAutoObservable(this, { rootStore: false })
    makePersistable(this, {
      name: UserStore.USER_STORE_STORAGE_KEY,
      properties: [
        'isAuthenticated',
        'isAuthMethodSSO',
        'accessToken',
        'refreshToken',
        'authCheckTimestamp',
        'authZeroCheckTimestamp',
        'authDestructiveActionTimestamp',
        'profile',
        'platform',
        'loginCompanyUUID',
        'memberships',
      ],
      storage: window.localStorage,
    }).then((r) => {
      console.debug('Persisting data for UserStore')
    })
  }

  /**
   * Used to validate the failover condition
   */
  async isInFailover() {
    try {
      const res = await this.rootStore.getAPIClient().userAPI.getHealth()
      if (res) {
        return res.headers.hasOwnProperty('out-of-coffee')
      }
      return true
    } catch (e) {
      console.error(e)
    }
  }

  async getMetadata(config?: AxiosRequestConfig) {
    const response = await this.rootStore
      .getAPIClient()
      .userAPI.getMetadata(config)
    return UserModel.userMetadataFromAPIModel(response.data)
  }

  /**
   * Logs user out from the system
   */
  async logout() {
    if (this.accessToken) {
      try {
        await this.rootStore.getAPIClient().userAPI.logout(this.accessToken)
      } catch (e) {
        console.warn(e)
      }
    }
    await this.rootStore.clearData()
    Sentry.configureScope((scope) => scope.setUser(null))
    this.setDidLogout(true)
  }

  async login(email: string, password: string): Promise<GenericUserResponse> {
    this.setDidFailLogin(false)
    try {
      const res = await this.rootStore
        .getAPIClient()
        .userAPI.login(email, password)
      if (res.data) {
        this.setAccessToken(res.data.access_token)
        this.setRefreshToken(res.data.refresh_token)
        this.setAuthCheckTimestamp(UserUtil.getDefaultAuthCheckTimestamp())
        this.setAuthDestructiveActionTimestamp(
          UserUtil.getDefaultAuthDestructiveActionTimestamp(),
        )
        this.setLoginPlatform('browser')
        const profile = UserModel.profileFromAPIModel(res.data.user, email)
        this.setProfile(profile)
        this.setIsAuthenticated(true)
        Sentry.setUser({ id: profile.id })
        return {
          wasSuccessful: true,
          message: 'Login successful',
        }
      }
    } catch (e) {
      this.setDidFailLogin(true)
      console.error(e)
    }
    return {
      wasSuccessful: false,
      message: 'Login failed',
    }
  }

  async postLogin(email: string, token: string) {
    this.setDidFailLogin(false)
    try {
      const res = await this.rootStore
        .getAPIClient()
        .userAPI.postLogin(email, token)
      if (res.data) {
        this.setAccessToken(res.data.access_token)
        this.setRefreshToken(res.data.refresh_token)
        this.setAuthCheckTimestamp(UserUtil.getDefaultAuthCheckTimestamp())
        this.setAuthDestructiveActionTimestamp(
          UserUtil.getDefaultAuthDestructiveActionTimestamp(),
        )
        this.setLoginPlatform('browser')
        const profile = UserModel.profileFromAPIModel(res.data.user, email)
        this.setProfile(profile)
        this.setIsAuthenticated(true)
        this.setIsAuthMethodSSO(true)
        Sentry.setUser({ id: profile.id })

        return res.data
      }
    } catch (e) {
      this.setDidFailLogin(true)
      console.error(e)
    }
  }

  async getMemberships() {
    try {
      const res = await this.rootStore.getAPIClient().userAPI.getMemberships()
      if (res) {
        const memberships = res.map((apiMembership: APIMembership) =>
          UserModel.membershipFromAPIModel(apiMembership),
        )
        this.setMemberships(memberships)
      }
    } catch (e) {
      console.error(e)
    }
  }

  async getEula(company?: string) {
    try {
      const res = await this.rootStore.getAPIClient().userAPI.getEula(company)
      if (res.data) {
        return res.data
      }
    } catch (e) {
      console.error(e)
    }
  }

  /**
   * Update Ingage User eula_agree response
   * We don't have an Ingage User update endpoint in Teams so this will feel like a one-off solution
   * until we really need to update the Ingage User for other reasons
   *
   * @param userUUID
   * @param eulaResponse
   */
  async updateUserEulaResponse(userUUID: UUID, eulaResponse: Date) {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .userAPI.updateUserEulaResponse(userUUID, eulaResponse)
      if (res.status < 400) {
        this.updateProfile(eulaResponse)
        return true
      }
    } catch (e) {
      console.error(e)
    }
  }

  async searchForUserInCompany(searchStr: string, companyUUID: UUID) {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .userAPI.searchForUserInCompany(searchStr, companyUUID)
      if (res.data && res.data.length > 0) {
        return CompanyModel.companyUserFromAPIModel(res.data[0])
      }
    } catch (e) {
      console.error(`Unable to find user with search string: ${searchStr}`, e)
    }
  }

  async emailTemporaryPasskey(email: string = '') {
    const response: GenericUserResponse = {
      wasSuccessful: false,
      message: '',
    }
    try {
      const res = await this.rootStore
        .getAPIClient()
        .userAPI.emailTemporaryPasskey(email.trim().toLowerCase())
      if (res.status === 200) {
        response.wasSuccessful = true
        response.message = res.data.detail
      } else if (res.status === 400) {
        response.wasSuccessful = false
        response.message = res.data.detail
      }
    } catch (e) {
      console.error('Unable to send password reset passkey to user', e)
      response.message = 'An error has occurred. Please try again later.'
    }
    return response
  }

  async resetPassword(
    email: string = '',
    passkey: string = '',
    newPassword: string,
  ) {
    const response: GenericUserResponse = {
      wasSuccessful: false,
      message: '',
    }
    try {
      const res = await this.rootStore
        .getAPIClient()
        .userAPI.resetPassword(email.trim().toLowerCase(), passkey, newPassword)
      if (res.status === 200) {
        response.wasSuccessful = true
        response.message = res.data.detail
      } else if (res.status === 400) {
        response.wasSuccessful = false
        response.message = res.data.detail
      }
    } catch (e) {
      console.error('Unable to reset user password', e)
      response.message = 'An error has occurred. Please try again later.'
    }
    return response
  }

  /**
   * Activates a new user.
   *
   * @param userUUID
   * @param password
   * @param confirmPassword
   */
  async activateUser(
    userUUID: UUID,
    password: string,
    firstName?: string,
    lastName?: string,
    authenticate?: boolean,
  ) {
    const response: GenericUserResponse = {
      wasSuccessful: false,
      message: '',
    }
    try {
      const res = await this.rootStore
        .getAPIClient()
        .userAPI.activateUser(
          userUUID,
          password,
          firstName,
          lastName,
          authenticate,
        )
      if (res.status === 201) {
        response.wasSuccessful = true

        if (authenticate) {
          this.setAccessToken(res.data.access_token)
          this.setRefreshToken(res.data.refresh_token)
          this.setAuthCheckTimestamp(UserUtil.getDefaultAuthCheckTimestamp())
          this.setAuthDestructiveActionTimestamp(
            UserUtil.getDefaultAuthDestructiveActionTimestamp(),
          )
          this.setLoginPlatform('browser')
          const profile = UserModel.profileFromAPIModel(
            res.data.user,
            res.data.user.email,
          )
          this.setProfile(profile)
          this.setIsAuthenticated(true)
          Sentry.setUser({ id: profile.id })
        }
      } else {
        response.wasSuccessful = false
      }
    } catch (e) {
      response.wasSuccessful = false
      response.message = `${e}`
    }

    return response
  }

  async getUserByUUID(userUUID: UUID) {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .userAPI.getUserByUUID(userUUID)
      if (res.data) {
        return UserModel.userFromAPIModel(res.data)
      }
    } catch (e) {
      console.error(`Unable to fetch user with ID ${userUUID}`, e)
    }
  }

  async clearData() {
    this.setAccessToken(undefined)
    this.setRefreshToken(undefined)
    this.setAuthCheckTimestamp(0)
    this.setAuthZeroCheckTimestamp(0)
    this.setAuthDestructiveActionTimestamp(0)
    this.setProfile(undefined)
    this.setIsAuthenticated(false)
    this.setIsConsoleAuthenticated(false)
    this.setIsAuthMethodSSO(false)
    this.setLoginPlatform(undefined)
    this.setLoginCompanyUUID(undefined)
    this.setMemberships([])
  }

  get isHydrated() {
    return isHydrated(this)
  }

  getValidMemberships(productID?: string) {
    if (this.memberships.length > 0) {
      if (productID) {
        return this.memberships.filter(
          (m) =>
            m.status === 'active' &&
            new Date(m.endAt) > new Date() &&
            m.productID === productID,
        )
      } else {
        return this.memberships.filter(
          (m) => m.status === 'active' && new Date(m.endAt) > new Date(),
        )
      }
    }
    return []
  }

  getValidCompanyMemberships(company: Company, productID?: string) {
    const membershipsWithACompany = this.getValidMemberships(productID).filter(
      (m) => m.company,
    )
    if (membershipsWithACompany.length > 0) {
      return membershipsWithACompany.filter((m) => {
        return (
          m.company!.includes(`company/${company.uuid}/`) ||
          m.company!.includes(`company/${company.id}/`)
        )
      })
    }
    return []
  }

  setIsAuthenticated(isAuthenticated: boolean) {
    this.isAuthenticated = isAuthenticated
  }

  setIsConsoleAuthenticated(isConsoleAuthenticated: boolean) {
    this.isConsoleAuthenticated = isConsoleAuthenticated
  }

  setIsAuthMethodSSO(isAuthMethodSSO: boolean) {
    this.isAuthMethodSSO = isAuthMethodSSO
  }

  setDidFailLogin(didFailLogin: boolean) {
    this.didFailLogin = didFailLogin
  }

  setLoginPlatform(platform: string | undefined) {
    this.platform = platform?.toLowerCase() ?? ``
  }

  setLoginCompanyUUID(companyUUID: UUID | undefined) {
    this.loginCompanyUUID = companyUUID
  }

  setProfile(profile: Profile | undefined) {
    this.profile = profile
  }

  updateProfile(eulaAgreedAt: Date) {
    if (this.profile) {
      this.profile = {
        ...this.profile,
        eulaAgreedAt: eulaAgreedAt,
      }
    }
  }

  setAccessToken(token: string | undefined) {
    this.accessToken = token
  }

  setRefreshToken(token: string | undefined) {
    this.refreshToken = token
  }

  setAuthCheckTimestamp(timestamp: number) {
    this.authCheckTimestamp = timestamp
  }

  setAuthZeroCheckTimestamp(timestamp: number) {
    this.authZeroCheckTimestamp = timestamp
  }

  setAuthDestructiveActionTimestamp(timestamp: number) {
    this.authDestructiveActionTimestamp = timestamp
  }

  setMemberships(memberships: Membership[]) {
    this.memberships = memberships
  }

  setDidLogout(didLogout: boolean) {
    this.didLogout = didLogout
  }
}
