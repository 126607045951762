import { Stub, Config, upgrade } from '../../../../';

export default (stub: Stub, config: Config): Stub => {
    stub.version = 3;

    delete stub.foregroundBlurStyle;
    delete stub.backgroundType.type;
    delete stub.backgroundType.version;
    delete stub.backgroundType.blurStyle;

    return stub;
};
