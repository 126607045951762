import { AxiosRequestConfig } from 'axios'

import { Story } from 'stubs/src/story'

import { UUID } from 'src/util/types'
import { RootStore } from 'src/store/RootStore'
import { BaseStore } from 'src/store/BaseStore'
import { PresentationsModel } from 'src/model/presentation/PresentationsModel'

export class AssetStore implements BaseStore {
  rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  /**
   * Find all assets in a version that match the given path
   *
   * @param versionUUID parent version UUID
   * @param path asset path
   * @param config axios request config
   * @returns version asset list
   */
  async findAssetsByPathFromVersion(
    versionUUID: UUID,
    path: string,
    config?: AxiosRequestConfig,
  ) {
    const apiAssets = await this.rootStore
      .getAPIClient()
      .assetAPI.findAssetsByPathFromVersion(versionUUID, path, config)

    return apiAssets.map((apiAsset) =>
      PresentationsModel.presentationAssetFromAPIModel(apiAsset),
    )
  }

  /**
   * Returns the assets belonging to a presentation version using an API URL provided by the API.
   *
   * @param assetsURL Provided by the API in a previous response for getting a presentation version.
   * @param config axios request configuration
   * @returns version asset list
   */
  async fetchAssetsFromAssetsURL(
    assetsURL: string,
    config?: AxiosRequestConfig,
  ) {
    try {
      const apiAssets = await this.rootStore
        .getAPIClient()
        .assetAPI.fetchAssetsFromAssetsURL(assetsURL, config)

      return apiAssets.map((apiAsset) =>
        PresentationsModel.presentationAssetFromAPIModel(apiAsset),
      )
    } catch (e) {
      console.error('Unable to fetch presentation assets', e)
    }
    return []
  }

  /**
   * Fetch all assets for a given version
   *
   * @param versionUUID parent version UUID
   * @param config axios request configuration
   * @returns version asset list
   */
  async fetchAssetsForVersion(versionUUID: UUID, config?: AxiosRequestConfig) {
    const apiAssets = await this.rootStore
      .getAPIClient()
      .assetAPI.fetchAssetsForVersion(versionUUID, config)

    return apiAssets.map((apiAsset) =>
      PresentationsModel.presentationAssetFromAPIModel(apiAsset),
    )
  }

  /**
   * Fetch all assets for a given version, but with CloudFront URLs
   *
   * @param versionUUID parent version UUID
   * @param config axios request configuration
   * @returns version asset list
   */
  async fetchAssetsForVersionRefreshingDirectURLs(
    versionUUID: UUID,
    config?: AxiosRequestConfig,
  ) {
    const apiAssetsWithDirectURL = await this.rootStore
      .getAPIClient()
      .assetAPI.fetchAssetsForVersionRequestingDirectURL(versionUUID, config)

    return apiAssetsWithDirectURL.map((apiAsset) =>
      PresentationsModel.presentationAssetFromAPIModel(apiAsset),
    )
  }

  /**
   * Get the list of video frame URLs, assuming asset is a scrollmotion and frames have been generated
   *
   * @param assetURL asset url found in the asset model
   * @param config axios request configuration
   * @returns list of video frame URLs
   */
  async fetchFramesForVideoAssetURL(
    assetURL: string,
    config?: AxiosRequestConfig,
  ) {
    return this.rootStore
      .getAPIClient()
      .assetAPI.fetchFramesForVideoAssetURL(assetURL, config)
  }

  /**
   * Force the generation of frames for a scrollmotion video asset
   *
   * @param assetURL asset url found in the asset model
   * @param config axios request configuration
   * @returns list of video frame URLs
   */
  async forceFrameGenerationForVideoAssetURL(
    assetURL: string,
    config?: AxiosRequestConfig,
  ) {
    return this.rootStore
      .getAPIClient()
      .assetAPI.forceFrameGenerationForVideoAssetURL(assetURL, config)
  }

  /**
   * Look for the stubs.json asset in the given version
   *
   * If found, download it as array buffer, then decode and parse it
   *
   * @param versionUUID parent version UUID
   * @param config axios request configuration
   * @returns version stub
   */
  async downloadStubForVersion(versionUUID: UUID, config?: AxiosRequestConfig) {
    const assets = await this.findAssetsByPathFromVersion(
      versionUUID,
      'stubs.json',
      config,
    )

    if (assets && assets.length > 0) {
      return this.downloadStubFromURL(assets[0].download)
    }

    return null
  }

  /**
   * Download stub from given URL as array buffer, then decode and parse it
   *
   * @param downloadURL stubs.json asset download URL
   * @param config axios request configuration
   * @returns version stub
   */
  async downloadStubFromURL(downloadURL: string, config?: AxiosRequestConfig) {
    return this.rootStore
      .getAPIClient()
      .assetAPI.downloadStubFromURL(downloadURL, config)
  }

  /**
   * Convert a version's content_spec.json to stubs.json
   *
   * @param versionUUID parent version UUID
   * @param config axios request configuration
   * @returns version stub
   */
  async convertContentSpecToStubsForVersion(
    versionUUID: UUID,
    config?: AxiosRequestConfig,
  ) {
    return this.rootStore
      .getAPIClient()
      .assetAPI.convertContentSpecToStubsForVersion(versionUUID, config)
  }

  /**
   * Encode and upload updated stubs.json file to given version
   *
   * @param stub updated stub JSON
   * @param versionUUID version UUID
   */
  async updateStubForVersion(stub: Story, versionUUID: UUID) {
    try {
      const bytes = new TextEncoder().encode(JSON.stringify(stub))
      const blob = new Blob([bytes], { type: 'application/json;charset=utf-8' })
      const preambleResponse = await this.rootStore
        .getAPIClient()
        .assetAPI.uploadPreamble('stubs.json', versionUUID)
      await this.rootStore
        .getAPIClient()
        .assetAPI.uploadToS3(preambleResponse, blob)
    } catch (e) {
      console.error(`Unable to update stub for version: ${versionUUID}`)
    }
  }
}
