import dayjs from 'dayjs'
import { startOfDay } from 'date-fns'

import { SavedReport } from 'src/model/analytics/SavedReport'
import { APISavedReport } from 'src/model/api/analytics/APISavedReport'
import { SavedReportParams } from 'src/model/analytics/SavedReportParams'
import { SavedReportOptions } from 'src/model/analytics/SavedReportOptions'
import { SavedReportWithLabels } from 'src/model/analytics/SavedReportWithLabels'
import { APISavedReportParams } from 'src/model/api/analytics/APISavedReportParams'
import { APISavedReportOptions } from 'src/model/api/analytics/APISavedReportOptions'
import { DateRangePreset } from 'src/views/company/analytics/utils/AnalyticsDateRange'
import { APISavedReportWithLabels } from 'src/model/api/analytics/APISavedReportWithLabels'
import { SavedReportDateRangePreset } from 'src/model/analytics/SavedReportDateRangePreset'
import { SavedReportParamsWithLabels } from 'src/model/analytics/SavedReportParamsWithLabels'
import { APISavedReportDateRangePreset } from 'src/model/api/analytics/APISavedReportDateRangePreset'
import { APISavedReportParamsWithLabels } from 'src/model/api/analytics/APISavedReportParamsWithLabels'
import {
  APIAnalyticsReport,
  APIAnalyticsEvent,
  APIAnalyticsOptions,
  APIAnalyticsSavedReport,
} from 'src/model/api/analytics/APIAnalytics'
import {
  AnalyticsReport,
  AnalyticsEvent,
  AnalyticsOptions,
  AnalyticsSavedReport,
} from 'src/model/analytics/Analytics'

export namespace AnalyticsModel {
  export const analyticsReportFromAPIModel = (
    apiAnalyticsReport: APIAnalyticsReport,
  ) => {
    const report: AnalyticsReport = {
      id: apiAnalyticsReport.report_id,
      name: apiAnalyticsReport.report_name,
    }
    return report
  }

  export const analyticsEventFromAPIModel = (
    apiAnalyticsEvent: APIAnalyticsEvent,
  ) => {
    const event: AnalyticsEvent = apiAnalyticsEvent
    return event
  }

  export const analyticsOptionsFromAPIModel = (
    apiAnalyticsOptions: APIAnalyticsOptions,
  ) => {
    const analyticsOptions: AnalyticsOptions = {
      dateFormat: apiAnalyticsOptions.date_format,
      reportType: apiAnalyticsOptions.report_type,
      fields: apiAnalyticsOptions.fields,
      presentations: apiAnalyticsOptions.presentations,
      teams: apiAnalyticsOptions.teams,
      users: apiAnalyticsOptions.users,
      sessionID: apiAnalyticsOptions.session_id,
      userID: apiAnalyticsOptions.user_id,
    }
    return analyticsOptions
  }

  export const analyticsSavedReportFromAPIModel = (
    apiAnalyticsSavedReport: APIAnalyticsSavedReport,
  ) => {
    const savedReport: AnalyticsSavedReport = {
      id: apiAnalyticsSavedReport.id,
      companyUUID: apiAnalyticsSavedReport.company_uuid,
      name: apiAnalyticsSavedReport.name,
      description: apiAnalyticsSavedReport.description,
      urlParams: apiAnalyticsSavedReport.url_params,
      params: analyticsOptionsFromAPIModel(apiAnalyticsSavedReport.params!),
      isExtract: apiAnalyticsSavedReport.is_extract!,
      exportFrequency: apiAnalyticsSavedReport.export_frequency,
      exportRecipients: apiAnalyticsSavedReport.export_recipients,
      updatedAt: apiAnalyticsSavedReport.updated_at,
      createdAt: apiAnalyticsSavedReport.created_at,
      companyID: apiAnalyticsSavedReport.company,
      updatedBy: apiAnalyticsSavedReport.updated_by,
      createdBy: apiAnalyticsSavedReport.created_by,
    }
    return savedReport
  }

  export const dateRangePresetFromAPIModel = (
    apiDateRangePreset: APISavedReportDateRangePreset,
  ): SavedReportDateRangePreset => {
    switch (apiDateRangePreset.preset) {
      default:
      case 'this_week':
        return {
          preset: DateRangePreset.THIS_WEEK,
        }
      case 'last_week':
        return {
          preset: DateRangePreset.LAST_WEEK,
        }
      case 'this_month':
        return {
          preset: DateRangePreset.THIS_MONTH,
        }
      case 'last_month':
        return {
          preset: DateRangePreset.LAST_MONTH,
        }
      case 'custom_range':
        return {
          preset: DateRangePreset.CUSTOM_RANGE,
          startDate: dayjs(
            apiDateRangePreset.start_date ?? startOfDay(new Date()),
          ).toDate(),
          endDate: dayjs(
            apiDateRangePreset.end_date ?? startOfDay(new Date()),
          ).toDate(),
        }
    }
  }

  export const savedReportParamsFromAPIModel = (
    apiSavedReportParams: APISavedReportParams,
  ): SavedReportParams => {
    return {
      dateFormat: apiSavedReportParams.date_format,
      fields: apiSavedReportParams.fields,
      presentations: apiSavedReportParams.presentations,
      reportType: apiSavedReportParams.report_type,
      sessionId: apiSavedReportParams.session_id,
      teams: apiSavedReportParams.teams,
      userId: apiSavedReportParams.user_id,
      users: apiSavedReportParams.users,
      tags: apiSavedReportParams.tags,
    }
  }

  export const savedReportParamsWithLabelsFromAPIModel = (
    apiSavedReportParams: APISavedReportParamsWithLabels,
  ): SavedReportParamsWithLabels => {
    return {
      dateFormat: apiSavedReportParams.date_format,
      fields: apiSavedReportParams.fields,
      presentations: apiSavedReportParams.presentations,
      reportType: apiSavedReportParams.report_type,
      sessionId: apiSavedReportParams.session_id,
      teams: apiSavedReportParams.teams,
      userId: apiSavedReportParams.user_id,
      users: apiSavedReportParams.users,
      tags: apiSavedReportParams.tags,
    }
  }

  export const savedReportFromAPIModel = (
    apiSavedReport: APISavedReport,
  ): SavedReport => {
    return {
      id: apiSavedReport.id,
      name: apiSavedReport.name,
      reportType: apiSavedReport.report_type,
      description: apiSavedReport.description,
      params: savedReportParamsFromAPIModel(apiSavedReport.params),
      dateRangePreset: dateRangePresetFromAPIModel(
        apiSavedReport.date_range_preset,
      ),
      isExtract: apiSavedReport.is_extract,
      exportFrequency: apiSavedReport.export_frequency,
      exportRecipients: apiSavedReport.export_recipients,
      updatedAt: apiSavedReport.updated_at,
      createdAt: apiSavedReport.created_at,
      updatedBy: apiSavedReport.updated_by,
      createdBy: apiSavedReport.created_by,
    }
  }

  export const savedReportWithLabelsFromAPIModel = (
    apiSavedReport: APISavedReportWithLabels,
  ): SavedReportWithLabels => {
    return {
      id: apiSavedReport.id,
      name: apiSavedReport.name,
      reportType: apiSavedReport.report_type,
      description: apiSavedReport.description,
      params: savedReportParamsWithLabelsFromAPIModel(apiSavedReport.params),
      dateRangePreset: dateRangePresetFromAPIModel(
        apiSavedReport.date_range_preset,
      ),
      isExtract: apiSavedReport.is_extract,
      exportFrequency: apiSavedReport.export_frequency,
      exportRecipients: apiSavedReport.export_recipients,
      updatedAt: apiSavedReport.updated_at,
      createdAt: apiSavedReport.created_at,
      updatedBy: apiSavedReport.updated_by,
      createdBy: apiSavedReport.created_by,
    }
  }

  export const savedReportParamsFromSavedReportParamsWithLabels = (
    params: SavedReportParamsWithLabels,
  ): SavedReportParams => {
    return {
      ...params,
      presentations: params.presentations.map(
        (presentation) => presentation.value,
      ),
      teams: params.teams.map((presentation) => presentation.value),
      users: params.users.map((presentation) => presentation.value),
    }
  }

  export const savedReportFromSavedReportWithLabels = (
    report: SavedReportWithLabels,
  ): SavedReport => {
    return {
      ...report,
      params: savedReportParamsFromSavedReportParamsWithLabels(report.params),
    }
  }

  export const savedReportOptionsFromSavedReport = (
    report: SavedReport,
  ): SavedReportOptions => {
    return {
      name: report.name,
      reportType: report.reportType,
      params: report.params,
      dateRangePreset: report.dateRangePreset,
    }
  }

  export const APIModelFromSavedReportDateRangePreset = (
    dateRangePreset: SavedReportDateRangePreset,
  ): APISavedReportDateRangePreset => {
    if (dateRangePreset.preset === DateRangePreset.CUSTOM_RANGE) {
      return {
        preset: dateRangePreset.preset,
        start_date: dayjs(dateRangePreset.startDate).format('YYYY-MM-DD'),
        end_date: dayjs(dateRangePreset.endDate).format('YYYY-MM-DD'),
      }
    }

    return {
      preset: dateRangePreset.preset,
    }
  }

  export const APIModelFromSavedReportParams = (
    savedReportParams: SavedReportParams,
  ): APISavedReportParams => {
    return {
      date_format: savedReportParams.dateFormat,
      fields: savedReportParams.fields,
      presentations: savedReportParams.presentations,
      report_type: savedReportParams.reportType,
      session_id: savedReportParams.sessionId,
      teams: savedReportParams.teams,
      user_id: savedReportParams.userId,
      users: savedReportParams.users,
      tags: savedReportParams.tags,
    }
  }

  export const APISavedReportOptionsFromModel = (
    report: SavedReportOptions,
  ): APISavedReportOptions => {
    return {
      name: report.name,
      report_type: report.reportType,
      params: APIModelFromSavedReportParams(report.params),
      date_range_preset: APIModelFromSavedReportDateRangePreset(
        report.dateRangePreset,
      ),
    }
  }
}
