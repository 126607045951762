import { makeAutoObservable } from 'mobx'
import axios, { AxiosRequestConfig } from 'axios'
import { makePersistable } from 'mobx-persist-store'

import { Font } from 'ingage-fonts/types/Font'
import { FontUtils } from 'ingage-fonts/utils'
import { APIFont } from 'ingage-fonts/types/APIFont'
import { FontModel } from 'ingage-fonts/utils/FontModel'
import { FontHelper } from 'ingage-fonts/utils/FontHelper'

import { UUID } from 'src/util/types'
import { RootStore } from './RootStore'
import { BaseStore } from './BaseStore'

export class FontStore implements BaseStore {
  rootStore: RootStore
  companyFontsMap: {
    [companyUUID: UUID]: Font[]
  }

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.companyFontsMap = {}

    makeAutoObservable(this, { rootStore: false })
    makePersistable(this, {
      name: 'TeamsFonts',
      properties: ['companyFontsMap'],
      storage: window.localStorage,
    }).then((r) => {
      console.debug('Persisting data for FontStore')
    })
  }

  getFontFamilies() {
    return FontUtils.fontFamilies
  }

  getLegacyFontFamilies() {
    return FontUtils.legacyFontFamilies
  }

  getFontWeightsForFontFamily(fontFamily: string) {
    return FontUtils.familyToWeightsMap[fontFamily]
  }

  /**
   * Returns font rules for a legacy font
   */
  getLegacyFontRulesForFont(legacyFontName: string) {
    const isAvailable = Boolean(FontUtils.getLegacyFontRules(legacyFontName))
    if (isAvailable) {
      return FontUtils.getLegacyFontRules(legacyFontName)
    }
  }

  getFontWeightProps(fontFamily: string, fontWeight: string) {
    return FontUtils.getFontWeightProps(fontFamily, fontWeight)
  }

  /**
   * Fetch all custom fonts for the given company
   *
   * @param companyUUID current company UUID
   * @returns company font list
   */
  async fetchFontsForCompany(companyUUID: UUID, config?: AxiosRequestConfig) {
    try {
      const apiFonts = await this.rootStore
        .getAPIClient()
        .fontAPI.getAllFonts(companyUUID)

      return apiFonts.map((apiFont: APIFont) =>
        FontModel.fontFromAPIModel(apiFont),
      )
    } catch (error) {
      console.error(`Unable to fetch fonts for company: ${companyUUID}`, error)
    }
  }

  /**
   * Downloads all fonts
   * @param companyUUID
   * @param shouldForceCacheBust
   */
  async downloadAllFonts(companyUUID: UUID) {
    if (!this.companyFontsMap[companyUUID]) {
      console.info(`No fonts to download for company ${companyUUID}`)
      return
    }
    const downloadPromises = this.companyFontsMap[companyUUID].map(
      async (fontItem) => {
        try {
          const font = new FontFace(
            fontItem.familyName,
            `url(${fontItem.downloadURL})`,
            {
              weight: `${FontHelper.getNumericWeightForSubfamilyName(
                fontItem.subfamilyName,
              )}`,
            },
          )
          await font.load()
          document.fonts.add(font)
          // Download and add font blob to indexeddb
        } catch (err) {
          console.error(err)
        }
      },
    )

    return Promise.all(downloadPromises)
  }

  async getAllFonts(companyUUID: UUID, shouldForceCacheBust = false) {
    if (this.companyFontsMap[companyUUID] && !shouldForceCacheBust) {
      FontUtils.setCustomFontMaps(this.companyFontsMap[companyUUID])
      return this.companyFontsMap[companyUUID]
    }

    const fonts = await this.fetchFontsForCompany(companyUUID)

    if (fonts) {
      this.companyFontsMap[companyUUID] = fonts
      FontUtils.setCustomFontMaps(fonts)
      return fonts
    }

    return undefined
  }

  async importFontFile(companyUUID: UUID, fontFile: File) {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .fontAPI.importFontFile(companyUUID, fontFile)
      return res.status < 400
    } catch (e) {
      console.error(`Unable to import font file for company ${companyUUID}`, e)
      if (axios.isAxiosError(e)) {
        return e.response?.data
      }
    }
  }

  async deleteFont(companyUUID: UUID, fontUUID: UUID) {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .fontAPI.deleteFont(companyUUID, fontUUID)
      return res.status < 400
    } catch (e) {
      console.error(`Unable to delete font for company ${companyUUID}`, e)
    }
  }

  clearData() {
    this.companyFontsMap = {}

    FontUtils.clearCustomFontMaps()
    FontUtils.removeFontsFromFontFaceSet()
  }
}
