import { UUID } from 'src/util/types'
import { EnvConfig } from 'src/util/EnvConfig'
import { RequestOptions } from 'mixpanel-browser'
import { MixpanelClient } from 'src/util/analytics/MixpanelClient'
import { PresentationsAnalyticsEvent } from 'src/model/presentation-analytics/PresentationsAnalyticsEvent'
import { PresentationsAnalyticsPayload } from 'src/model/presentation-analytics/PresentationsAnalyticsPayload'

export namespace PresentationsAnalyticsUtil {
  const mixpanelClient = new MixpanelClient(
    EnvConfig.getPresentationsMixpanelToken(),
  )

  export const identifyUser = (userUUID: UUID) => {
    mixpanelClient.identify(userUUID)
  }

  export const timeEvent = (event: PresentationsAnalyticsEvent) => {
    mixpanelClient.timeEvent(event)
  }

  export const trackEvent = (
    event: PresentationsAnalyticsEvent,
    props?: PresentationsAnalyticsPayload,
    options?: RequestOptions,
  ) => {
    mixpanelClient.track(event, props, options)
  }

  export const registerSuperProperties = (
    props: PresentationsAnalyticsPayload,
  ) => {
    mixpanelClient.register(props)
  }
}
