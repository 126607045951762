import { Stub, Config, Context, upgrade } from '../../../../';

export default (
    stub: Stub,
    config: Config,
    context: Context = { width: 1024, height: 768 },
): Stub => {
    stub.version = 3;

    delete stub.transformation.version;
    delete stub.transformation.type;

    const scaleFactor: number = config.scaleFactor || 1;

    if (context && stub.transformation.x && stub.transformation.y && context.width && context.height) {
        stub.transformation.x /= context.width * scaleFactor;
        stub.transformation.y /= context.height * scaleFactor;
    }

    return stub;
};
