import { makeAutoObservable } from 'mobx'
import { makePersistable } from 'mobx-persist-store'
import { EnvConfig } from 'src/util/EnvConfig'
import { RootStore } from './RootStore'
import { BaseStore } from './BaseStore'

/**
 * Contains application configuration values for the site
 */
export class AppConfigStore implements BaseStore {
  theme: Theme
  appDownloadURL: string
  environment: Environment
  rootStore: RootStore

  static APP_CONFIG_STORE_STORAGE_KEY = 'TeamsAppConfig'

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.appDownloadURL = 'https://www.ingage.io/download'
    this.theme = getDefaultTheme()
    this.environment = EnvConfig.getDefaultEnvironment()

    makeAutoObservable(this, { rootStore: false })
    makePersistable(this, {
      name: AppConfigStore.APP_CONFIG_STORE_STORAGE_KEY,
      properties: ['theme', 'environment', 'appDownloadURL'],
      storage: window.localStorage,
    }).then((r) => {
      console.debug('Persisting data for AppConfigStore')
    })
  }

  setEnvironment(environment: Environment) {
    this.environment = environment
  }

  async clearData() {
    this.environment = EnvConfig.getDefaultEnvironment()
  }
}

const getDefaultTheme = () => {
  return {
    global: {
      colors: {
        success: '#00DBC1',
        danger: '#F57162',
      },
    },
  }
}

export type Theme = {
  global: {
    colors: {
      [key: string]: string
    }
  }
}

export enum Environment {
  LOCAL = 'local',
  DEVELOPMENT = 'development',
  QA = 'qa',
  PRODUCTION = 'production',
}
