import axios from 'axios'

import { UserUtil } from 'src/util/UserUtil'
import { EnvConfig } from 'src/util/EnvConfig'
import { UserStore } from 'src/store/UserStore'
import { LocalStorage } from 'src/store/LocalStorage'

export class TokenRefresher {
  static #instance: TokenRefresher

  private pendingRefesh: Promise<unknown> | null = null

  private constructor() {}

  public static get instance(): TokenRefresher {
    if (!TokenRefresher.#instance) {
      TokenRefresher.#instance = new TokenRefresher()
    }

    return TokenRefresher.#instance
  }

  private async refreshAccessToken(refreshToken: string): Promise<unknown> {
    try {
      const userStore = LocalStorage.getInstance().getUserStore()

      // generate form data for refresh request
      const refreshData = new FormData()
      const clientId = userStore.isAuthMethodSSO
        ? EnvConfig.getAuth0ClientID()
        : EnvConfig.getClientID()
      refreshData.append('client_id', clientId)
      refreshData.append('grant_type', 'refresh_token')
      refreshData.append('refresh_token', refreshToken)

      const res = await axios.post(
        `${EnvConfig.getBaseURL()}/oauth2/token/`,
        refreshData,
      )

      // update localStorage
      const updatedUserStore = {
        ...userStore,
        accessToken: res.data.access_token,
        refreshToken: res.data.refresh_token,
        isAuthenticated: true,
        authCheckTimestamp: UserUtil.getDefaultAuthCheckTimestamp(),
      }
      localStorage.setItem(
        UserStore.USER_STORE_STORAGE_KEY,
        JSON.stringify(updatedUserStore),
      )
      return res.data.access_token
    } catch (e) {
      console.error('Unable to refresh token', e)
      window.location.href = `${window.location.origin}/auth/logout`
    }
  }

  public async refresh(refreshToken: string): Promise<unknown> {
    if (!this.pendingRefesh) {
      this.pendingRefesh = this.refreshAccessToken(refreshToken).finally(() => {
        this.pendingRefesh = null
      })
    }

    return this.pendingRefesh
  }
}
