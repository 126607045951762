import { version } from 'punycode';
import { Stub, Config, upgrade } from '../../../../';

export default (stub: Stub, config: Config): Stub => {
    stub.version = 4;

    delete stub.foregroundBlurStyle;
    delete stub.backgroundType.blurStyle;
    delete stub.backgroundType.type;
    delete stub.backgroundType.version;

    return stub;
};
