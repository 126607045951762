import { Stub, Config, upgrade } from '../../../..';

export default (stub: Stub, config: Config): Stub => {
  stub.version = 2

  if (!stub.padding) {
    stub.padding = 0
  }
  return stub
}
