import { APIFont } from 'ingage-fonts/types/APIFont'
import { FontModel } from 'ingage-fonts/utils/FontModel'

import { APIMarketplaceContent } from 'src/model/api/marketplace/APIMarketplace'
import { APIMarketplaceCategory } from 'src/model/api/marketplace/APIMarketplaceCategory'
import { APIMarketplaceItem } from 'src/model/api/marketplace/APIMarketplaceItem'
import { APIMarketplaceItemContentObject } from 'src/model/api/marketplace/APIMarketplaceItemContentObject'
import { APIMarketplaceListing } from 'src/model/api/marketplace/APIMarketplaceListing'
import { APIMarketplaceListingAsset } from 'src/model/api/marketplace/APIMarketplaceListingAsset'
import { APIMarketplacePublisher } from 'src/model/api/marketplace/APIMarketplacePublisher'
import { APIPromoCode } from 'src/model/api/marketplace/APIPromoCode'
import { APIPresentation } from 'src/model/api/presentation/APIPresentation'
import { APITheme } from 'src/model/api/theme/APITheme'
import { IngageContentType } from 'src/model/ingage/IngageContentType'
import { MarketplaceContent } from 'src/model/marketplace/Marketplace'
import { MarketplaceCategory } from 'src/model/marketplace/MarketplaceCategory'
import { MarketplaceItem } from 'src/model/marketplace/MarketplaceItem'
import { MarketplaceItemContentObject } from 'src/model/marketplace/MarketplaceItemContentObject'
import { MarketplaceListing } from 'src/model/marketplace/MarketplaceListing'
import { MarketplaceListingAsset } from 'src/model/marketplace/MarketplaceListingAsset'
import { MarketplacePublisher } from 'src/model/marketplace/MarketplacePublisher'
import { PromoCode } from 'src/model/marketplace/PromoCode'
import { PresentationsModel } from 'src/model/presentation/PresentationsModel'
import { ThemeModel } from 'src/model/theme/ThemeModel'
import { APIMarketplaceDistributedItem } from '../api/marketplace/APIMarketplaceDistributedItem'
import { APIMarketplaceHatchSpeedToLeadEmailPayload } from '../api/marketplace/APIMarketplaceHatchSpeedToLeadEmailPayload'
import { MarketplaceDistributedItem } from './MarketplaceDistributedItem'
import { MarketplaceHatchSpeedToLeadEmailPayload } from './MarketplaceHatchSpeedToLeadEmailPayload'

export namespace MarketplaceModel {
  export const marketplaceContentFromAPIModel = (
    apiMarketplaceContent: APIMarketplaceContent,
  ) => {
    const mpContent: MarketplaceContent = {
      uuid: apiMarketplaceContent.uuid,
      price: apiMarketplaceContent.price,
      createdAt: apiMarketplaceContent.created_at,
      createdBy: apiMarketplaceContent.created_by,
      description: apiMarketplaceContent.description,
      isCloneable: apiMarketplaceContent.is_cloneable,
      isInternal: apiMarketplaceContent.is_internal,
      isReadOnly: apiMarketplaceContent.is_read_only,
      isVisible: apiMarketplaceContent.is_visible,
      moreInfoUrl: apiMarketplaceContent.more_info_url ?? '',
      name: apiMarketplaceContent.name ?? '',
      type: apiMarketplaceContent.type,
    }

    if (apiMarketplaceContent.content.hasOwnProperty('versions')) {
      mpContent.content = PresentationsModel.presentationFromAPIModel(
        apiMarketplaceContent.content,
      )
    }

    if (apiMarketplaceContent.current_version?.hasOwnProperty('id')) {
      mpContent.currentVersion =
        PresentationsModel.presentationVersionFromAPIModel(
          apiMarketplaceContent.current_version,
        )
    }

    if (apiMarketplaceContent.published_version?.hasOwnProperty('id')) {
      mpContent.publishedVersion =
        PresentationsModel.presentationVersionFromAPIModel(
          apiMarketplaceContent.published_version,
        )
    }

    return mpContent
  }

  export const marketplaceListingFromAPIModel = (
    apiMarketplaceListing: APIMarketplaceListing,
  ) => {
    const listing: MarketplaceListing = {
      createdAt: apiMarketplaceListing.created_at,
      createdBy: apiMarketplaceListing.created_by,
      description: apiMarketplaceListing.description,
      isListed: apiMarketplaceListing.is_listed,
      isActive: apiMarketplaceListing.is_active,
      isFeatured: apiMarketplaceListing.is_featured,
      isInternal: apiMarketplaceListing.is_internal,
      isPublished: apiMarketplaceListing.is_published,
      learnMoreUrl: apiMarketplaceListing.learn_more_url,
      name: apiMarketplaceListing.name,
      price: apiMarketplaceListing.price,
      promoCodeDescription: apiMarketplaceListing.promo_code_description,
      slug: apiMarketplaceListing.slug,
      updatedAt: apiMarketplaceListing.updated_at,
      updatedBy: apiMarketplaceListing.updated_by,
      uuid: apiMarketplaceListing.uuid,
      metaTitle: apiMarketplaceListing.meta_title,
      metaDescription: apiMarketplaceListing.meta_description,
      metaKeywords: apiMarketplaceListing.meta_keywords,
      assets: [],
      items: [],
      publisher: apiMarketplaceListing.publisher
        ? marketplacePublisherFromAPIModel(apiMarketplaceListing.publisher)
        : null,
      categories: apiMarketplaceListing.categories,
    }
    return listing
  }

  export const marketplaceItemFromAPIModel = (
    apiMarketplaceItem: APIMarketplaceItem,
  ) => {
    const item: MarketplaceItem = {
      uuid: apiMarketplaceItem.uuid,
      contentObject: contentObjectFromAPIContentObject(
        apiMarketplaceItem.content_type,
        apiMarketplaceItem.content_object,
      ),
      contentType: apiMarketplaceItem.content_type,
      createdAt: apiMarketplaceItem.created_at,
      createdBy: apiMarketplaceItem.created_by,
      listing: apiMarketplaceItem.listing,
      listingUUID: apiMarketplaceItem.listing_uuid,
      name: apiMarketplaceItem.name,
      objectUUID: apiMarketplaceItem.object_uuid,
      contentUUID: apiMarketplaceItem.content_uuid,
      distributeAsReadOnly: apiMarketplaceItem.distribute_as_readonly,
      subtype: apiMarketplaceItem.subtype,
      updatedAt: apiMarketplaceItem.updated_at,
      updatedBy: apiMarketplaceItem.updated_by,
    }
    return item
  }

  export const marketplaceListingAssetFromAPIModel = (
    apiMarketplaceListingAsset: APIMarketplaceListingAsset,
  ) => {
    const asset: MarketplaceListingAsset = {
      createdAt: apiMarketplaceListingAsset.created_at,
      createdBy: apiMarketplaceListingAsset.created_by,
      image: apiMarketplaceListingAsset.image,
      imageThumbnail: apiMarketplaceListingAsset.image_thumbnail,
      isPrimary: apiMarketplaceListingAsset.is_primary,
      listing: apiMarketplaceListingAsset.listing,
      listingUUID: apiMarketplaceListingAsset.listing_uuid,
      name: apiMarketplaceListingAsset.name,
      type: apiMarketplaceListingAsset.type,
      updatedAt: apiMarketplaceListingAsset.updated_at,
      updatedBy: apiMarketplaceListingAsset.updated_by,
      uuid: apiMarketplaceListingAsset.uuid,
      weight: apiMarketplaceListingAsset.weight,
    }
    return asset
  }

  export const marketplaceCategoryFromAPIModel = (
    apiMarketplaceCategory: APIMarketplaceCategory,
  ) => {
    const category: MarketplaceCategory = {
      uuid: apiMarketplaceCategory.uuid,
      createdAt: apiMarketplaceCategory.created_at,
      createdBy: apiMarketplaceCategory.created_by,
      description: apiMarketplaceCategory.description,
      image: apiMarketplaceCategory.image,
      imageThumbnail: apiMarketplaceCategory.image_thumbnail,
      isFeatured: apiMarketplaceCategory.is_featured,
      name: apiMarketplaceCategory.name,
      numListings: apiMarketplaceCategory.num_listings,
      parent: undefined, // Leave undefined for now until we need nested categories
      slug: apiMarketplaceCategory.slug,
      type: apiMarketplaceCategory.type,
      updatedAt: apiMarketplaceCategory.updated_at,
      updatedBy: apiMarketplaceCategory.updated_by,
      weight: apiMarketplaceCategory.weight,
    }
    return category
  }

  /**
   * Converts the API representation of content_object to a Teams model on our end.
   *
   * MarketplaceItems can represent a variety of offerings--a presentation, a theme, a font, or some other type of
   * content. Our Django backend handles this with a GenericForeignKey model field, which has the following two
   * properties that we need to handle: `content_type` (the Django content type) and `content_object` (the actual model
   * data for the content).
   *
   * @param contentType
   * @param contentObject
   */
  export const contentObjectFromAPIContentObject = (
    contentType: IngageContentType,
    contentObject?: APIMarketplaceItemContentObject,
  ): MarketplaceItemContentObject | undefined => {
    if (!contentObject) {
      return undefined
    }
    switch (contentType) {
      default:
        console.error(
          'Unable to map content_object for MarketplaceItem due to it having an unhandled content type',
        )
        return undefined

      // Presentations are called "Content" content type in the Django backend
      case IngageContentType.CONTENT:
        return PresentationsModel.presentationFromAPIModel(
          contentObject as APIPresentation,
        )
      case IngageContentType.THEME:
        return ThemeModel.themeFromAPIModel(contentObject as APITheme)
      case IngageContentType.FONT:
        return FontModel.fontFromAPIModel(contentObject as APIFont)
    }
  }

  export const promoCodeFromAPIModel = (apiPromoCode: APIPromoCode) => {
    const promoCode: PromoCode = {
      code: apiPromoCode.code,
      isSingleUse: apiPromoCode.is_single_use,
      redeemedAt: apiPromoCode.redeemed_at,
      redeemedBy: apiPromoCode.redeemed_by,
      createdAt: apiPromoCode.created_at,
      createdBy: apiPromoCode.created_by,
      expiresAt: apiPromoCode.expires_at,
      isActive: apiPromoCode.is_active,
      listingUUID: apiPromoCode.listing,
    }
    return promoCode
  }

  export const distributedItemFromAPIDistributedItem = ({
    type,
    uuid,
  }: APIMarketplaceDistributedItem): MarketplaceDistributedItem => {
    return {
      type: type === 'Content' ? IngageContentType.CONTENT : null,
      uuid,
    }
  }

  export const marketplacePublisherFromAPIModel = (
    apiMarketplacePublisher: APIMarketplacePublisher,
  ) => {
    const marketplacePublisher: MarketplacePublisher = {
      listings: apiMarketplacePublisher.listings,
      uuid: apiMarketplacePublisher.uuid,
      price: apiMarketplacePublisher.price,
      createdAt: apiMarketplacePublisher.created_at,
      createdBy: apiMarketplacePublisher.created_by,
      name: apiMarketplacePublisher.name,
      slug: apiMarketplacePublisher.slug,
      image: apiMarketplacePublisher.image,
      bio: apiMarketplacePublisher.bio,
      url: apiMarketplacePublisher.url,
      isActive: apiMarketplacePublisher.is_active,
    }
    return marketplacePublisher
  }

  export const apiCreateHatchEmailPayloadFromCreateHatchEmailPayload = (
    createHatchEmailPayload: MarketplaceHatchSpeedToLeadEmailPayload,
  ): APIMarketplaceHatchSpeedToLeadEmailPayload => {
    return {
      company: createHatchEmailPayload.company,
      listing_uuid: createHatchEmailPayload.listingUUID,
      variables: createHatchEmailPayload.variables,
      teams: createHatchEmailPayload.teams,
    }
  }
}
