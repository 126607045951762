import { Stub, Config, Context, upgrade } from '../../../../';

export default (
    stub: Stub,
    config: Config,
    context: Context = { textMode: 'body' },
): Stub => {
    stub.version = 2;
    const weight = isFontWeight(stub.fontStyle)
        ? stub.fontStyle
        : stub.fontWeight;

    if (context.textMode == 'header') {
        stub.fontStyle = 'header';
        stub.fontWeight = 'bold';
    } else {
        switch (weight) {
            case 'light':
                stub.fontStyle = 'header';
                stub.fontWeight = 'bold';
                break;
            case 'regular':
                stub.fontStyle = 'body';
                stub.fontWeight = 'regular';
                break;
            case 'medium':
                stub.fontStyle = 'title';
                stub.fontWeight = 'light';
                break;
            case 'bold':
            case 'semibold':
                stub.fontStyle = 'title';
                stub.fontWeight = 'bold';
                break;
        }
    }
    return stub;
};

const isFontWeight = (value: string): boolean => {
    switch (value) {
        case 'light':
        case 'regular':
        case 'medium':
        case 'semibold':
        case 'bold':
            return true;
        default:
            return false;
    }
};
