import React, { ReactNode } from 'react'

export type route = {
  component?: ReactNode
  name?: string
  path?: string
  routes?: route[]
  noAuth?: boolean
}

// examples
const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const Blank = React.lazy(() => import('./views/blank/Blank'))
const Login = React.lazy(() => import('./views/auth/Login'))
const PostLogin = React.lazy(() => import('./views/auth/PostLogin'))
const Logout = React.lazy(() => import('./views/auth/Logout'))
const ForgotPassword = React.lazy(() => import('./views/auth/ForgotPassword'))
const AccountSetup = React.lazy(() => import('./views/auth/AccountSetup'))
const DashboardHome = React.lazy(
  () => import('./views/company/dashboard/DashboardHome'),
)
const AnalyticsMainDashboard = React.lazy(
  () => import('./views/company/analytics/AnalyticsDashboard'),
)
const AnalyticsSessions = React.lazy(
  () => import('./views/company/analytics/AnalyticsSessions'),
)
const AnalyticsSessionPlayback = React.lazy(
  () => import('./views/company/analytics/AnalyticsSessionPlayback'),
)
const AnalyticsUsers = React.lazy(
  () => import('./views/company/analytics/AnalyticsUsers'),
)
const AnalyticsUserDetail = React.lazy(
  () => import('./views/company/analytics/AnalyticsUsersDetail'),
)
const AnalyticsUserCompare = React.lazy(
  () => import('./views/company/analytics/AnalyticsUserCompare'),
)
const AnalyticsUserCompareDetail = React.lazy(
  () => import('./views/company/analytics/AnalyticsUserCompareDetail'),
)
const AnalyticsContents = React.lazy(
  () => import('./views/company/analytics/AnalyticsContents'),
)
const AnalyticsContentsDetail = React.lazy(
  () => import('./views/company/analytics/AnalyticsContentsDetail'),
)
const AnalyticsSavedReports = React.lazy(
  () => import('./views/company/analytics/AnalyticsSavedReports'),
)
const AnalyticsHome = React.lazy(
  () => import('./views/company/analytics/AnalyticsHome'),
)
const AnalyticsSavedReportsDetails = React.lazy(
  () => import('./views/company/analytics/legacy/AnalyticsSavedReportsDetails'),
)
const ThemesHome = React.lazy(() => import('./views/company/themes/ThemesHome'))
const ThemeDetails = React.lazy(
  () => import('./views/company/themes/ThemeDetails'),
)
const TemplatesHome = React.lazy(
  () => import('./views/company/presentations/TemplatesHome'),
)
const TemplateDetailPage = React.lazy(
  () => import('./views/company/presentations/TemplateDetailPage'),
)
const FontsHome = React.lazy(() => import('./views/company/fonts/FontsHome'))
const AutomationTesterHome = React.lazy(
  () => import('src/views/company/integrations/AutomationTesterHome'),
)
const AutomationTesterDetail = React.lazy(
  () => import('src/views/company/integrations/AutomationTesterDetail'),
)
const PublicMarketplaceRouter = React.lazy(
  () => import('./views/company/marketplace/PublicMarketplaceRouter'),
)
const MarketplaceHome = React.lazy(
  () => import('./views/company/marketplace/MarketplaceHome'),
)
const MarketplaceCategory = React.lazy(
  () => import('./views/company/marketplace/MarketplaceCategory'),
)
const MarketplaceListingDetailsPage = React.lazy(
  () => import('./views/company/marketplace/MarketplaceListingDetailsPage'),
)
const PublisherDetailsPage = React.lazy(
  () => import('./views/company/marketplace/publisher/PublisherDetailsPage'),
)
const PresentationsHome = React.lazy(
  () => import('./views/company/presentations/PresentationsHome'),
)
const ArchivedPresentations = React.lazy(
  () => import('./views/company/presentations/ArchivedPresentations'),
)
const PresentationDetailPage = React.lazy(
  () => import('./views/company/presentations/PresentationDetailPage'),
)
const PresentHome = React.lazy(
  () => import('./views/company/present/PresentHome'),
)
const TeamsHome = React.lazy(() => import('./views/company/teams/TeamsHome'))
const TeamDetails = React.lazy(
  () => import('./views/company/teams/TeamDetails'),
)
const CompanyHome = React.lazy(
  () => import('./views/company/company/CompanyHome'),
)
const UsersHome = React.lazy(() => import('./views/company/users/UsersHome'))
const UserSignupPage = React.lazy(
  () => import('./views/company/users/UserSignupPage'),
)
const UserDetails = React.lazy(
  () => import('./views/company/users/UserDetails'),
)
const DynamicContentHome = React.lazy(
  () => import('./views/company/dynamic-content/DynamicContentHome'),
)
const CustomFieldsDetail = React.lazy(
  () => import('./views/company/dynamic-content/CustomFieldsDetail'),
)
const DynamicKeysDetail = React.lazy(
  () => import('./views/company/dynamic-content/DynamicKeysDetail'),
)
const SettingsHome = React.lazy(() => import('./views/settings/SettingsHome'))
const MarketplacePromoCodeRedemptionPage = React.lazy(
  () =>
    import('./views/company/marketplace/MarketplacePromoCodeRedemptionPage'),
)
const LandingPage = React.lazy(() => import('./views/landing/LandingPage'))
const MarketplaceAdminDashboard = React.lazy(
  () => import('./views/settings/marketplace/MarketplaceAdminDashboard'),
)
const InternalCompaniesAdminDashboard = React.lazy(
  () => import('src/views/settings/companies/InternalCompaniesAdminDashboard'),
)
const CreateCompany = React.lazy(
  () => import('./views/settings/companies/CreateCompany'),
)
const AddMarketplaceListing = React.lazy(
  () => import('./views/settings/marketplace/AddMarketplaceListing'),
)
const EditMarketplaceListing = React.lazy(
  () => import('./views/settings/marketplace/EditMarketplaceListing'),
)
const AddPromoCodes = React.lazy(
  () => import('./views/settings/marketplace/AddPromoCodes'),
)
const MarketplaceCategories = React.lazy(
  () => import('./views/settings/marketplace/MarketplaceCategories'),
)
const AddCategory = React.lazy(
  () => import('./views/settings/marketplace/AddCategory'),
)
const EditCategory = React.lazy(
  () => import('./views/settings/marketplace/EditCategory'),
)
const Download = React.lazy(() => import('./views/download/Download'))
const Support = React.lazy(() => import('./views/support/Support'))
const SupportSearch = React.lazy(() => import('./views/support/SupportSearch'))
const SearchPlaylist = React.lazy(
  () => import('./views/company/presentations/playlisting/SearchPlaylist'),
)
const Playlist = React.lazy(
  () => import('src/views/company/presentations/playlisting/Playlist'),
)

/**
 * See {@link https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config GitHub}.
 */
export const defaultRoutes = [
  { path: '/', name: 'Home' },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/blank', name: 'Blank', component: Blank },
]

export const companyRoutes = [
  {
    path: '/company/:companyUUID/dashboard',
    name: 'Dashboard',
    component: DashboardHome,
  },
  {
    path: '/company/:companyUUID/analytics/dashboard',
    name: 'Analytics Dashboard',
    component: AnalyticsMainDashboard,
  },
  {
    path: '/company/:companyUUID/analytics/sessions',
    name: 'Analytics Sessions',
    component: AnalyticsSessions,
  },
  {
    path: '/company/:companyUUID/analytics/sessions/:sessionUUID/playback',
    name: 'Analytics Session Playback',
    component: AnalyticsSessionPlayback,
  },
  {
    path: '/company/:companyUUID/analytics/users',
    name: 'Analytics Users',
    component: AnalyticsUsers,
  },
  {
    path: '/company/:companyUUID/analytics/users/:userUUID',
    name: 'Analytics User Detail',
    component: AnalyticsUserDetail,
  },
  {
    path: '/company/:companyUUID/analytics/compare',
    name: 'Analytics Compare',
    component: AnalyticsUserCompare,
  },
  {
    path: '/company/:companyUUID/analytics/compare/:presentationUUID',
    name: 'Analytics Compare Detail',
    component: AnalyticsUserCompareDetail,
  },
  {
    path: '/company/:companyUUID/analytics/content',
    name: 'Analytics Content',
    component: AnalyticsContents,
  },
  {
    path: '/company/:companyUUID/analytics/content/:presentationUUID',
    name: 'Analytics Content Detail',
    component: AnalyticsContentsDetail,
  },
  {
    path: '/company/:companyUUID/analytics/reports',
    name: 'Analytics Saved Reports',
    component: AnalyticsSavedReports,
  },
  {
    path: '/company/:companyUUID/analytics/reports/:reportUUID/sessions',
    name: 'Sessions Saved Report',
    component: AnalyticsSessions,
  },
  {
    path: '/company/:companyUUID/analytics/reports/:reportUUID/sessions/:sessionUUID/playback',
    name: 'Session Playback Saved Report',
    component: AnalyticsSessionPlayback,
  },
  {
    path: '/company/:companyUUID/analytics/reports/:reportUUID/users',
    name: 'Users Saved Report',
    component: AnalyticsUsers,
  },
  {
    path: '/company/:companyUUID/analytics/reports/:reportUUID/users/:userUUID',
    name: 'User Detail Saved Report',
    component: AnalyticsUserDetail,
  },
  {
    path: '/company/:companyUUID/analytics/reports/:reportUUID/compare',
    name: 'Compare Saved Report',
    component: AnalyticsUserCompare,
  },
  {
    path: '/company/:companyUUID/analytics/reports/:reportUUID/compare/:presentationUUID',
    name: 'Compare Detail Report',
    component: AnalyticsUserCompareDetail,
  },
  {
    path: '/company/:companyUUID/analytics/reports/:reportUUID/content',
    name: 'Content Saved Report',
    component: AnalyticsContents,
  },
  {
    path: '/company/:companyUUID/analytics/reports/:reportUUID/content/:presentationUUID',
    name: 'Content Detail Saved Report',
    component: AnalyticsContentsDetail,
  },
  {
    path: '/company/:companyUUID/analytics',
    name: 'Analytics',
    component: AnalyticsHome,
  },
  {
    path: '/company/:companyUUID/analytics/:reportUUID',
    name: 'Analytics Saved Reports Details',
    component: AnalyticsSavedReportsDetails,
  },
  {
    path: '/company/:companyUUID/themes',
    name: 'Themes',
    component: ThemesHome,
  },
  {
    path: '/company/:companyUUID/themes/:themeUUID',
    name: 'Themes',
    component: ThemeDetails,
  },
  {
    path: '/company/:companyUUID/templates',
    name: 'Templates',
    component: TemplatesHome,
  },
  { path: '/company/:companyUUID/fonts', name: 'Fonts', component: FontsHome },
  {
    path: '/company/:companyUUID/integrations/automation-tester/:apiKeyUUID',
    name: 'Automation Tester',
    component: AutomationTesterHome,
  },
  {
    path: '/company/:companyUUID/integrations/automation-tester/:apiKeyUUID/:shareAlias',
    name: 'Automation Tester',
    component: AutomationTesterDetail,
  },
  {
    path: '/company/:companyUUID/presentations',
    name: 'Company Presentations',
    component: PresentationsHome,
  },
  {
    path: '/company/:companyUUID/team/:teamUUID/presentations',
    name: 'Team Presentations',
    component: PresentationsHome,
  },
  {
    path: '/company/:companyUUID/archived/presentations',
    name: 'Archived Presentations',
    component: ArchivedPresentations,
  },
  {
    path: '/company/:companyUUID/templates/:presentationUUID',
    name: 'Presentation Details',
    component: TemplateDetailPage,
  },
  {
    path: '/company/:companyUUID/presentations/:presentationUUID',
    name: 'Presentation Details',
    component: PresentationDetailPage,
  },
  {
    path: '/company/:companyUUID/team/:teamUUID/presentations/:presentationUUID',
    name: 'Presentation Details',
    component: PresentationDetailPage,
  },
  {
    path: '/company/:companyUUID/archived/presentations/:presentationUUID',
    name: 'Presentation Details',
    component: PresentationDetailPage,
  },
  {
    path: '/company/:companyUUID/presentations/search-playlist',
    name: 'Default Playlist',
    component: SearchPlaylist,
  },
  {
    path: '/company/:companyUUID/presentations/:presentationUUID/playlist',
    name: 'Presentation Playlist',
    component: Playlist,
  },
  {
    path: '/company/:companyUUID/archived/presentations/:presentationUUID/playlist',
    name: 'Presentation Playlist',
    component: Playlist,
  },
  {
    path: '/company/:companyUUID/team/:teamUUID/presentations/:presentationUUID/playlist',
    name: 'Presentation Playlist',
    component: Playlist,
  },
  { path: '/company/:companyUUID/teams', name: 'Teams', component: TeamsHome },
  {
    path: '/company/:companyUUID/teams/:teamUUID',
    name: 'Team Details',
    component: TeamDetails,
  },
  {
    path: '/company/:companyUUID/company',
    name: 'Company Home',
    component: CompanyHome,
  },
  { path: '/company/:companyUUID/users', name: 'Users', component: UsersHome },
  {
    path: '/company/:companyUUID/users/signup',
    name: 'User Signup',
    component: UserSignupPage,
  },
  {
    path: '/company/:companyUUID/users/:userUUID',
    name: 'User Details',
    component: UserDetails,
  },
  {
    path: '/company/:companyUUID/dynamic-content/',
    name: 'Dynamic Content Home',
    component: DynamicContentHome,
  },
  {
    path: '/company/:companyUUID/dynamic-content/custom-fields/:slug',
    name: 'Custom Fields Detail',
    component: CustomFieldsDetail,
  },
  {
    path: '/company/:companyUUID/dynamic-content/dynamic-keys',
    name: 'Dynamic Keys Detail',
    component: DynamicKeysDetail,
  },
]

export const internalRoutes = [
  { path: '/settings', name: 'Settings', component: SettingsHome },
  {
    path: '/settings/companies',
    name: 'Internal Company Dashboard',
    component: InternalCompaniesAdminDashboard,
  },
  {
    path: '/settings/companies/create',
    name: 'Create Company',
    component: CreateCompany,
  },
]

export const marketplaceAdminRoutes = [
  {
    path: '/settings/marketplace',
    name: 'Marketplace Admin',
    component: MarketplaceAdminDashboard,
  },
  {
    path: '/settings/marketplace/listings',
    name: 'Marketplace Admin',
    component: MarketplaceAdminDashboard,
  },
  {
    path: '/settings/marketplace/listings/create',
    name: 'Add Marketplace Listing',
    component: AddMarketplaceListing,
  },
  {
    path: '/settings/marketplace/listings/edit/:listingUUID',
    name: 'Edit Marketplace Listing',
    component: EditMarketplaceListing,
  },
  {
    path: '/settings/marketplace/listings/edit/:listingUUID/codes/add',
    name: 'Add Promo Codes',
    component: AddPromoCodes,
  },
  {
    path: '/settings/marketplace/categories',
    name: 'Categories Admin',
    component: MarketplaceCategories,
  },
  {
    path: '/settings/marketplace/categories/create',
    name: 'Add Category',
    component: AddCategory,
  },
  {
    path: '/settings/marketplace/categories/edit/:categoryUUID',
    name: 'Edit Category',
    component: EditCategory,
  },
]

export const marketplaceRoutes = [
  {
    path: '/company/:companyUUID/marketplace',
    name: 'Marketplace Home',
    component: MarketplaceHome,
  },
  {
    path: '/company/:companyUUID/marketplace/categories/:categorySlug',
    name: 'Marketplace Category',
    component: MarketplaceCategory,
  },
  {
    path: '/company/:companyUUID/marketplace/listing/:listingSlug',
    name: 'Marketplace Details',
    component: MarketplaceListingDetailsPage,
  },
  {
    path: '/company/:companyUUID/marketplace/publisher/:publisherSlug',
    name: 'Publisher Details',
    component: PublisherDetailsPage,
  },
  {
    path: '/company/:companyUUID/marketplace/redeem',
    name: 'Redeem Item Code',
    component: MarketplacePromoCodeRedemptionPage,
  },
  {
    path: '/company/:companyUUID/marketplace/redeem/:itemCode',
    name: 'Redeem Item Code',
    component: MarketplacePromoCodeRedemptionPage,
  },
  {
    path: '/marketplace/redeem',
    name: 'Redeem Item Code',
    component: MarketplacePromoCodeRedemptionPage,
  },
  {
    path: '/marketplace/redeem/:itemCode',
    name: 'Redeem Item Code',
    component: MarketplacePromoCodeRedemptionPage,
  },
]

export const presentRoutes = [
  {
    path: '/company/:companyUUID/presentations/:presentationUUID/present',
    name: 'Present Home',
    component: PresentHome,
  },
  {
    path: '/company/:companyUUID/team/:teamUUID/presentations/:presentationUUID/present',
    name: 'Present Home',
    component: PresentHome,
  },
  {
    path: '/company/:companyUUID/presentations/:presentationUUID/versions/:versionUUID/present',
    name: 'Present Version',
    component: PresentHome,
  },
]

export const noAuthRoutes = [
  { path: '/auth', name: 'Login', component: Login, noAuth: true },
  { path: '/auth/sso', name: 'Post Login', component: PostLogin, noAuth: true },
  { path: '/auth/logout', name: 'Logout', component: Logout },
  { path: '/auth/forgot', name: 'ForgotPassword', component: ForgotPassword },
  { path: '/account_setup', name: 'AccountSetup', component: AccountSetup },
  { path: '/signup/:entity', name: 'Landing', component: LandingPage },
  { path: '/download', name: 'Download', component: Download },
  { path: '/support', name: 'Support', component: Support },
  { path: '/support-search', name: 'Support Search', component: SupportSearch },
  // Handling redirects from Public Marketplace
  {
    path: '/marketplace',
    name: 'Marketplace Home',
    component: PublicMarketplaceRouter,
  },
  {
    path: '/marketplace/categories/:categorySlug',
    name: 'Marketplace Category',
    component: PublicMarketplaceRouter,
  },
  {
    path: '/marketplace/listing/:listingSlug',
    name: 'Marketplace Details',
    component: PublicMarketplaceRouter,
  },
]
