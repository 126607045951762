import { Filter } from 'src/util/filter/Filter'

interface ConstructorParams {
  search?: string
  isFeatured?: boolean
  showInternal?: boolean
  showActive?: boolean
  includeInactive?: boolean
  showPublished?: boolean
  showListed?: boolean
  categories?: string[]
  orderBy?: string[]
}

export class ListingListFilter extends Filter {
  search?: string
  isFeatured?: boolean
  showInternal?: boolean
  showActive?: boolean // Only show inactive
  includeInactive?: boolean // Show inactive along with active
  showPublished?: boolean
  showListed?: boolean
  categories?: string[]
  orderBy?: string[]

  private constructor({
    search,
    isFeatured,
    showInternal,
    showActive,
    includeInactive,
    showPublished,
    showListed,
    categories,
    orderBy,
  }: ConstructorParams) {
    super()
    this.search = search
    this.isFeatured = isFeatured
    this.showInternal = showInternal
    this.showActive = showActive
    this.includeInactive = includeInactive
    this.showPublished = showPublished
    this.showListed = showListed
    this.categories = categories
    this.orderBy = orderBy
  }

  static create(constructorParams: ConstructorParams): ListingListFilter {
    const filter = new ListingListFilter(constructorParams)

    if (filter.isFeatured !== undefined) {
      filter.addParam('is_featured', filter.isFeatured)
    }

    if (filter.search !== undefined) {
      filter.addParam('search', filter.search)
    }

    if (filter.showInternal !== undefined) {
      filter.addParam('is_internal', filter.showInternal)
    }

    if (filter.showActive !== undefined) {
      filter.addParam('is_active', filter.showActive)
    }

    if (filter.showPublished !== undefined) {
      filter.addParam('is_published', filter.showPublished)
    }

    if (filter.showListed !== undefined) {
      filter.addParam('is_listed', filter.showListed)
    }

    if (filter.includeInactive !== undefined) {
      filter.addParam('include_inactive', filter.includeInactive)
    }

    if (filter.orderBy !== undefined && filter.orderBy.length > 0) {
      filter.addParam('order_by', filter.orderBy.join(','))
    }

    if (filter.categories !== undefined && filter.categories.length > 0) {
      filter.addParam('categories', filter.categories.join(','))
    }

    return filter
  }
}
