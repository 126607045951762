import { APILoginUser } from 'src/model/api/auth/APILoginUser'
import { APINewUser } from 'src/model/api/company/APICompanyUser'
import { APIMembership, APITier } from 'src/model/api/user/APIMembership'
import { APIUser } from 'src/model/api/user/APIUser'
import { User } from 'src/model/user/User'
import { Profile } from './Profile'
import { Membership, Tier } from './Membership'
import { NewUser } from './NewUser'
import { APIUserMetadata } from '../api/user/APIUserMetadata'
import { UserMetadata } from './UserMetadata'
import { CompanyModel } from '../company'

/**
 * Provides methods to ingest client-side data models from API data for Users.
 */
export namespace UserModel {
  export const profileFromAPIModel = (apiUser: APILoginUser, email: string) => {
    const profile: Profile = {
      email: email,
      firstName: apiUser.first_name,
      lastName: apiUser.last_name,
      fullName: apiUser.full_name,
      id: apiUser.id,
      isCompanyAdmin: apiUser.is_company_admin,
      isCompanyAnalyst: apiUser.is_company_analyst,
      isInternal: apiUser.internal,
      isPaid: apiUser.paid,
      isTeamAdmin: apiUser.is_team_admin,
      url: apiUser.url,
      eulaAgreedAt: apiUser.eula_agreed_at,
      features: apiUser.features,
    }
    return profile
  }

  export const newUserToAPIModel = (newUser: NewUser) => {
    const apiNewUser: APINewUser = {
      company: newUser.company,
      email: newUser.email,
      first_name: newUser.firstName,
      last_name: newUser.lastName,
      role: newUser.role,
      is_paid: newUser.isPaid,
      user: {
        first_name: newUser.user.firstName,
        last_name: newUser.user.lastName,
        phone: newUser.user.phone,
      },
    }
    return apiNewUser
  }

  export const userFromAPIModel = (apiUser: APIUser) => {
    const user: User = {
      businesses: apiUser.businesses,
      firstName: apiUser.first_name ?? '',
      id: apiUser.id,
      isActive: apiUser.is_active,
      lastName: apiUser.last_name ?? '',
      isPaid: apiUser.paid,
      photo: apiUser.photo,
      updatedAt: apiUser.updated_at,
      url: apiUser.url,
      isInternal: apiUser.internal ?? false,
      email: apiUser.email ?? '',
      eulaAgreedAt: apiUser.eula_agreed_at,
      features: apiUser.features,
    }
    return user
  }

  export const userMetadataFromAPIModel = (
    apiUserMetadata: APIUserMetadata,
  ) => {
    const userMetadata: UserMetadata = {
      companies: apiUserMetadata.companies.map((company) =>
        CompanyModel.companyFromAPIModel(company),
      ),
      companyUsers: apiUserMetadata.company_users.map((companyUser) =>
        CompanyModel.companyUserFromAPIModel(companyUser),
      ),
      email: apiUserMetadata.email,
      eulaAgreedAt: apiUserMetadata.eula_agreed_at,
      features: apiUserMetadata.features,
      firstName: apiUserMetadata.first_name,
      fullName: apiUserMetadata.full_name,
      id: apiUserMetadata.id,
      internal: apiUserMetadata.internal,
      isActive: apiUserMetadata.is_active,
      isQA: apiUserMetadata.is_qa,
      lastName: apiUserMetadata.last_name,
      paid: apiUserMetadata.paid,
      photo: apiUserMetadata.photo,
      profile: apiUserMetadata.profile,
      updatedAt: apiUserMetadata.updated_at,
      url: apiUserMetadata.url,
      username: apiUserMetadata.username,
    }
    return userMetadata
  }

  export const membershipFromAPIModel = (apiMembership: APIMembership) => {
    const membership: Membership = {
      url: apiMembership.url,
      id: apiMembership.id,
      productID: apiMembership.product_id,
      transactionID: apiMembership.transaction_id,
      startAt: apiMembership.start_at,
      endAt: apiMembership.end_at,
      user: apiMembership.user,
      company: apiMembership.company,
      tier: tierFromAPIModel(apiMembership.tier),
      createdBy: apiMembership.created_by,
      createdAt: apiMembership.created_at,
      updatedBy: apiMembership.updated_by,
      updatedAt: apiMembership.updated_at,
      cancelledAt: apiMembership.cancelled_at,
      status: apiMembership.status,
      platform: apiMembership.platform,
      membershipType: apiMembership.membership_type,
      futureCancel: apiMembership.future_cancel,
    }
    return membership
  }

  export const tierFromAPIModel = (apiTier: APITier) => {
    const tier: Tier = {
      name: apiTier.name,
      slug: apiTier.slug,
      description: apiTier.description,
      settings: apiTier.settings,
      tierType: apiTier.tier_type,
      uuid: apiTier.uuid,
    }
    return tier
  }
}
