import { AppConfigStore, Environment } from './AppConfigStore'
import { UserStore } from './UserStore'

/**
 * Light wrapper class around `window.localStorage` for storing items that don't belong in the MobX store because they
 * require more effort to avoid breaking the Rules of React Hooks {@link https://reactjs.org/docs/hooks-rules.html}.
 * Candidates for this are: 1) Data that has low rates of writing (ideally once per session), so that components don't
 * need to react to them; 2) Data that is written to from only one place within the application, like the environment
 * switcher, to prevent mutation race conditions.
 *
 * A long explanation of why this is ideal, and why the alternative is more exhausting:
 * ------------------------------------------------------------------------------------
 * A prime candidate for this would be tracking which server environment a user has chosen. The server environment
 * dictates which .env vars we use for things like API URL, client ID, etc. If we were to add `severEnvironment` to
 * the `AppConfigStore` MobX store, it would be reactive and theoretically give us whichever .env vars are appropriate
 * whenever we switch server environments; however, in order to leverage MobX (or any other storage mechanism that uses
 * Hooks) we'd need to obey the laws of hooks.
 *
 * For example, we could not simply evoke `useAppConfigStore()` inside our
 * `EnvConfig.ts` util file because that utility script is _not_ a React component. Rather, we'd need to write it so
 * that each utility function gets `appConfigStore` _injected_ into it as a parameter, and that injected value would
 * need to be instantiated at the top level of _each_ component that uses one of those EnvConfig functions. I.e.
 * <CLabel>{EnvConfig.getVersion(appConfigStore)</CLabel>.
 *
 * To bypass this problem, one could convert `EnvConfig.ts` to a class with a singleton instance, where `appConfigStore`
 * is injected via the constructor... but this means for proper use you would need to inject the instance of EnvConfig
 * at the React Context.Provider level to ensure it's the source of truth. While this isn't so bad on its own, having
 * _multiple_ non-React utility scripts referencing parts of MobX store (or any other store using hooks) involves having
 * to inject multiple singletons at the React.Provider level. And the final straw of impracticability is what happens if
 * those non-React utility scripts also depend on each other--like an API.ts script that depends on using EnvConfig.ts.
 * This means you'd have to inject instances between the two, and risk massive code entanglement.
 *
 * Sometimes, it's easier to just color outside of the lines if it means not having to entangle dependencies and not
 * require reactive store values to be used inside utility classes. If we take something like the `serverEnvironment`
 * variable and keep it in localStorage, we can just poll directly from it to get the value without React throwing
 * errors that we're violating its laws of Hooks. We just pay the penalty of those values not being reactive, which is
 * fine for something like `serverEnvironment` because it's only controlled via the `EnvSwitcher` component at the
 * Login page, and (as of this comment) does not change after the user has logged in.
 *
 */
export class LocalStorage {
  private static instance: LocalStorage

  static getInstance() {
    if (!LocalStorage.instance) {
      LocalStorage.instance = new LocalStorage()
    }
    return LocalStorage.instance
  }

  /**
   * Make private to encourage using the singleton getInstance()
   * @private
   */
  private constructor() {}

  getAppConfigStore() {
    return JSON.parse(
      localStorage.getItem(AppConfigStore.APP_CONFIG_STORE_STORAGE_KEY) ?? '{}',
    ) as AppConfigStore
  }

  getUserStore() {
    return JSON.parse(
      localStorage.getItem(UserStore.USER_STORE_STORAGE_KEY) ?? '{}',
    ) as UserStore
  }

  getServerEnvironment() {
    const appConfigStore = this.getAppConfigStore()
    // Filter and default to DEVELOPMENT if no valid value is found
    switch (appConfigStore.environment) {
      default:
      case Environment.DEVELOPMENT:
        return Environment.DEVELOPMENT
      case Environment.LOCAL:
        return Environment.LOCAL
      case Environment.QA:
        return Environment.QA
      case Environment.PRODUCTION:
        return Environment.PRODUCTION
    }
  }

  getIsAuthenticated() {
    const userStore = this.getUserStore()
    return userStore.isAuthenticated
  }

  getAccessToken() {
    const userStore = this.getUserStore()
    return userStore.accessToken
  }

  getRefreshToken() {
    const userStore = this.getUserStore()
    return userStore.refreshToken
  }

  getAuthCheckTimestamp() {
    const userStore = this.getUserStore()
    return userStore.authCheckTimestamp
  }

  /**
   * This should be the only direct write operation to local storage, and it is used to force a user to logout after
   * their token is no longer valid.
   */
  purgeUserStore() {
    localStorage.removeItem(UserStore.USER_STORE_STORAGE_KEY)
  }
}
