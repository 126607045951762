import { Stub, Config } from '../../../../';

export default (stub: Stub, config: Config): Stub => {
    stub.version = 3;

    if (stub.textX == null) {
        stub.textX = 0.5;
    }

    if (stub.textY == null) {
        stub.textY = 0.5;
    }

    return stub;
};
