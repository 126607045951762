import { Stub, Config, upgrade } from '../../../../';
import getDefaults from '../defaults/v2'

export default (stub: Stub, config: Config): Stub => {
    // Upgrades from 1 -> 2
    stub.version = 2;
    const defaults = getDefaults()

    if (!stub.impactBody) {
      stub.impactBody = defaults.impactBody
    }

    if (!stub.modalTitle) {
      stub.modalTitle = defaults.modalTitle
    }

    if (!stub.modalBody) {
      stub.modalBody = defaults.modalBody
    }

    if (!stub.modalBackgroundColor) {
      stub.modalBackgroundColor = defaults.modalBackgroundColor
    }

    if (config.recursive) {
      const recurisveUpgradeKeys = ["subheading", "title", "body", "impactHeading", "heading", "impactBody", "modalTitle", "modalBody"]

      for (const key of recurisveUpgradeKeys) {
        stub[key] = upgrade(stub[key], config)
      }
    }

    return stub;
};
