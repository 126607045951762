import { Stub, Config } from '../../../..';

export default (stub: Stub, config: Config): Stub => {
  stub.version = 2;

  stub.scale = 1;
  stub.offsetX = 0;
  stub.offsetY = 0;

  return stub;
};