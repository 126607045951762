import { Stub, Config, upgrade } from '../../../../';

export default (stub: Stub, config: Config): Stub => {
    for (const item of stub.items) {
        if (!('type' in item)) {
            item.type = 'button-component';
        }

        if (!('version' in item)) {
            item.version = 1;
        }
    }

    if (config.recursive) {
        for (let i = 0; i < stub.items.length; i++) {
            stub.items[i] = upgrade(stub.items[i], config);
        }
    }
    return stub;
};
