export function defaultThemeColors() {
  return [
    {
      color: "33A9E4",
      identifier: "named-color-1",
      userFriendlyName: "Primary Color"
    },
    {
      color: "FFFFFF",
      identifier: "named-color-2",
      userFriendlyName: "Primary Pairing"
    },
    {
      color: "1F1F1F",
      identifier: "named-color-3",
      userFriendlyName: "Accent Color"
    },
    {
      color: "FFFFFF",
      identifier: "named-color-4",
      userFriendlyName: "Accent Pairing"
    }
  ]
}