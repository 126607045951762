const gafGettingStartedGuide = {
  gettingStarted: {
    video: {
      default:
        'https://s3.amazonaws.com/teams-assets.ingage.io/gaf/onboarding-videos/gaf-onboarding-getting-started.mp4',
      admin:
        'https://s3.amazonaws.com/teams-assets.ingage.io/gaf/onboarding-videos/gaf-onboarding-getting-started-admin.mp4',
    },
  },
  viewAPresentation: {
    video:
      'https://s3.amazonaws.com/teams-assets.ingage.io/gaf/onboarding-videos/gaf-onboarding-view-presentation.mp4',
    copy: 'Content in GAF Present is interactive and easy to navigate. Watch this video to get the most out of your presentations.',
  },
  addUsers: {
    video:
      'https://s3.amazonaws.com/teams-assets.ingage.io/gaf/onboarding-videos/gaf-onboarding-add-users.mp4',
    copy: 'Invite the rest of your team to GAF Present so they can join you in closing more deals fast.',
  },
  companyProfile: {
    tooltipCopy:
      'GAF will verify your certification status and your President’s Club Level will be set within 1-3 business days. You will be notified via email when your club level has been added.',
    video:
      'https://s3.amazonaws.com/teams-assets.ingage.io/gaf/onboarding-videos/gaf-onboarding-customize-profile.mp4',
    copy: 'Update your company name, add your logo, and share a short company story with potential customers.',
  },
}

export default gafGettingStartedGuide
