import axios, { AxiosRequestConfig } from 'axios'
import { makeAutoObservable } from 'mobx'
import { makePersistable } from 'mobx-persist-store'
import { APIAutomationItem } from 'src/model/api/automation/automate/APIAutomationItem'
import { APIMarketplaceCategory } from 'src/model/api/marketplace/APIMarketplaceCategory'
import { APIMarketplaceItem } from 'src/model/api/marketplace/APIMarketplaceItem'
import { APIMarketplaceListing } from 'src/model/api/marketplace/APIMarketplaceListing'
import { APIMarketplaceListingAsset } from 'src/model/api/marketplace/APIMarketplaceListingAsset'
import { APIMarketplaceListingPurchaseStatus } from 'src/model/api/marketplace/APIMarketplaceListingPurchaseStatus'
import { APIMarketplaceS3UploadPresignedUrl } from 'src/model/api/marketplace/APIMarketplaceS3UploadPresignedUrl'
import { MarketplaceCategory } from 'src/model/marketplace/MarketplaceCategory'
import { MarketplaceDistributeResult } from 'src/model/marketplace/MarketplaceDistributeResult'
import { MarketplaceHatchSpeedToLeadEmailPayload } from 'src/model/marketplace/MarketplaceHatchSpeedToLeadEmailPayload'
import { MarketplaceItem } from 'src/model/marketplace/MarketplaceItem'
import { MarketplaceListing } from 'src/model/marketplace/MarketplaceListing'
import { MarketplaceListingAsset } from 'src/model/marketplace/MarketplaceListingAsset'
import { MarketplaceListingAssetType } from 'src/model/marketplace/MarketplaceListingAssetType'
import { MarketplaceModel } from 'src/model/marketplace/MarketplaceModel'
import { PromoCode } from 'src/model/marketplace/PromoCode'
import { PromoCodeCreateResult } from 'src/model/marketplace/PromoCodeCreateResult'
import { PromoCodeRedeemResult } from 'src/model/marketplace/PromoCodeRedeemResult'
import { RootStore } from 'src/store/RootStore'
import { CategoryListFilter } from 'src/util/filter/marketplace/CategoryListFilter'
import { ListingAssetListFilter } from 'src/util/filter/marketplace/ListingAssetListFilter'
import { ListingItemListFilter } from 'src/util/filter/marketplace/ListingItemListFilter'
import { ListingListFilter } from 'src/util/filter/marketplace/ListingListFilter'
import {
  ItemCodeRedemption,
  ItemCodeStatus,
} from 'src/util/marketplace/ItemCodeRedemption'
import { Nullable, UUID } from 'src/util/types'
import { BaseStore } from './BaseStore'

export class MarketplaceStore implements BaseStore {
  static MARKETPLACE_STORE_STORAGE_KEY = 'TeamsMarketplace'
  rootStore: RootStore
  activeItemCode: string = ''
  promoCodeMap: Record<string, ItemCodeRedemption> = {}

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this, { rootStore: false })
    makePersistable(this, {
      name: MarketplaceStore.MARKETPLACE_STORE_STORAGE_KEY,
      properties: [],
      storage: window.localStorage,
    }).then((r) => {
      console.debug(
        `Persisting data for ${MarketplaceStore.MARKETPLACE_STORE_STORAGE_KEY}`,
      )
    })
  }

  async getFirstMarketplaceListingHeroImageAsset(
    listingUUID: UUID,
    config?: AxiosRequestConfig,
  ): Promise<Nullable<MarketplaceListingAsset>> {
    try {
      const filter = ListingAssetListFilter.create({
        type: MarketplaceListingAssetType.HERO_IMAGE,
      })
      const res = await this.rootStore
        .getAPIClient()
        .marketplaceAPI.getMarketplaceListingAssetList(listingUUID, {
          ...config,
          params: filter.getParams(),
        })
      if (res && res.length > 0) {
        return MarketplaceModel.marketplaceListingAssetFromAPIModel(res[0])
      }
    } catch (e) {
      if (!config?.signal?.aborted) {
        console.error(
          `Unable to fetch a hero image asset for listing: ${listingUUID}`,
        )
      }
    }
    return null
  }

  async getMarketplaceCategory(
    categoryUUID: UUID,
    config?: AxiosRequestConfig,
  ): Promise<Nullable<MarketplaceCategory>> {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .marketplaceAPI.getMarketplaceCategory(categoryUUID, config)
      if (res.data) {
        return MarketplaceModel.marketplaceCategoryFromAPIModel(res.data)
      }
    } catch (e) {
      console.error(`Unable to fetch Marketplace Category: ${categoryUUID}`, e)
    }
    return null
  }

  async getMarketplaceCategoryBySlug(
    slug: string,
    config?: AxiosRequestConfig,
  ): Promise<Nullable<MarketplaceCategory>> {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .marketplaceAPI.getMarketplaceCategoryBySlug(slug, config)
      if (res.data) {
        return MarketplaceModel.marketplaceCategoryFromAPIModel(res.data)
      }
    } catch (e) {
      if (!config?.signal?.aborted) {
        console.error(
          `Unable to fetch Marketplace Category via its slug: ${slug}`,
          e,
        )
      }
    }
    return null
  }

  async getMarketplaceCategoryList(
    config?: AxiosRequestConfig,
  ): Promise<MarketplaceCategory[]> {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .marketplaceAPI.getMarketplaceCategoryList(config)
      if (res) {
        return res.map((apiMarketplaceCategory: APIMarketplaceCategory) =>
          MarketplaceModel.marketplaceCategoryFromAPIModel(
            apiMarketplaceCategory,
          ),
        )
      }
    } catch (e) {
      if (!config?.signal?.aborted) {
        console.error('Unable to fetch Marketplace Categories', e)
      }
    }
    return []
  }

  async getMarketplaceFeaturedListings(): Promise<MarketplaceListing[]> {
    const filter = ListingListFilter.create({
      isFeatured: true,
    })
    try {
      const res = await this.rootStore
        .getAPIClient()
        .marketplaceAPI.getAllMarketplaceListings(filter)
      if (res) {
        return res.map((apiMarketplaceListing: APIMarketplaceListing) =>
          MarketplaceModel.marketplaceListingFromAPIModel(
            apiMarketplaceListing,
          ),
        )
      }
    } catch (e) {
      console.error('Unable to fetch Marketplace Listings', e)
    }
    return []
  }

  async getMarketplaceListing(
    listingUUID: UUID,
  ): Promise<Nullable<MarketplaceListing>> {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .marketplaceAPI.getMarketplaceListing(listingUUID)
      if (res.data) {
        return MarketplaceModel.marketplaceListingFromAPIModel(res.data)
      }
    } catch (e) {
      console.error(`Unable to fetch Marketplace Listing: ${listingUUID}`)
    }
    return null
  }

  async getMarketplaceListingAssetList(
    listingUUID: UUID,
    config?: AxiosRequestConfig,
  ): Promise<MarketplaceListingAsset[]> {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .marketplaceAPI.getMarketplaceListingAssetList(listingUUID, config)
      if (res) {
        return res.map(
          (apiMarketplaceListingAsset: APIMarketplaceListingAsset) =>
            MarketplaceModel.marketplaceListingAssetFromAPIModel(
              apiMarketplaceListingAsset,
            ),
        )
      }
    } catch (e) {
      if (!config?.signal?.aborted) {
        console.error(
          `Unable to fetch Marketplace Assets for listing: ${listingUUID}`,
        )
      }
    }
    return []
  }

  async getMarketplaceListingBySlug(
    slug: string,
  ): Promise<Nullable<MarketplaceListing>> {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .marketplaceAPI.getMarketplaceListingBySlug(slug)
      if (res.data) {
        return MarketplaceModel.marketplaceListingFromAPIModel(res.data)
      }
    } catch (e) {
      console.error(`Unable to fetch Marketplace Listing via its slug: ${slug}`)
    }
    return null
  }

  async getMarketplaceListingCategoryList(
    listingUUID: UUID,
  ): Promise<MarketplaceCategory[]> {
    return Promise.resolve([])
  }

  async getMarketplaceListingItemList(
    listingUUID: UUID,
    filter?: ListingItemListFilter,
  ): Promise<MarketplaceItem[]> {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .marketplaceAPI.getMarketplaceListingItemList(listingUUID, filter)
      if (res) {
        return res.map((apiMarketplaceItem: APIMarketplaceItem) =>
          MarketplaceModel.marketplaceItemFromAPIModel(apiMarketplaceItem),
        )
      }
    } catch (e) {
      console.error(
        `Unable to fetch Marketplace Items for listing: ${listingUUID}`,
      )
    }
    return []
  }

  async getAllMarketplaceListings(
    filter?: ListingListFilter,
  ): Promise<MarketplaceListing[]> {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .marketplaceAPI.getAllMarketplaceListings(filter)
      if (res) {
        return res.map((apiMarketplaceListing: APIMarketplaceListing) =>
          MarketplaceModel.marketplaceListingFromAPIModel(
            apiMarketplaceListing,
          ),
        )
      }
    } catch (e) {
      console.error('Unable to fetch Marketplace Listings', e)
    }
    return []
  }

  async getMarketplaceListings(
    page: number,
    pageSize: number,
    filter?: ListingListFilter,
    config?: AxiosRequestConfig | undefined,
  ): Promise<{ totalCount: number; listings: MarketplaceListing[] }> {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .marketplaceAPI.getMarketplaceListings(page, pageSize, filter, config)
      if (res) {
        return {
          totalCount: res.headers['total-count']
            ? parseInt(res.headers['total-count'])
            : 0,
          listings: res.data.map(
            (apiMarketplaceListing: APIMarketplaceListing) =>
              MarketplaceModel.marketplaceListingFromAPIModel(
                apiMarketplaceListing,
              ),
          ),
        }
      }
    } catch (e) {
      console.error('Unable to fetch Marketplace Listings', e)
    }

    return {
      totalCount: 0,
      listings: [],
    }
  }

  async getAllMarketplaceListingsUnderCategory(
    categoryUUID: UUID,
    filter?: CategoryListFilter,
  ): Promise<MarketplaceListing[]> {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .marketplaceAPI.getAllMarketplaceListingsUnderCategory(
          categoryUUID,
          filter,
        )
      if (res) {
        return res.map((apiMarketplaceListing: APIMarketplaceListing) =>
          MarketplaceModel.marketplaceListingFromAPIModel(
            apiMarketplaceListing,
          ),
        )
      }
    } catch (e) {
      console.error(
        `Unable to fetch Marketplace Listings under Category: ${categoryUUID}`,
        e,
      )
    }
    return []
  }

  async getMarketplaceListingsUnderCategory(
    categoryUUID: UUID,
    page: number,
    pageCount: number,
    config?: AxiosRequestConfig,
  ): Promise<{ totalCount: number; listings: MarketplaceListing[] }> {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .marketplaceAPI.getMarketplaceListingsUnderCategory(
          categoryUUID,
          page,
          pageCount,
          config,
        )
      if (res) {
        return {
          totalCount: res.headers['total-count']
            ? parseInt(res.headers['total-count'])
            : 0,
          listings: res.data.map(
            (apiMarketplaceListing: APIMarketplaceListing) =>
              MarketplaceModel.marketplaceListingFromAPIModel(
                apiMarketplaceListing,
              ),
          ),
        }
      }
    } catch (e) {
      console.error(
        `Unable to fetch Marketplace Listings under Category: ${categoryUUID}`,
        e,
      )
    }

    return {
      totalCount: 0,
      listings: [],
    }
  }

  async distributeListing(
    companyUUID: UUID,
    listingUUID: UUID,
    teamUUIDs: UUID[] = [],
    makeItemsUnavailable: UUID[] = [],
  ): Promise<MarketplaceDistributeResult> {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .marketplaceAPI.distributeListing(
          companyUUID,
          listingUUID,
          teamUUIDs,
          makeItemsUnavailable,
        )
      if (res && res.status === 201) {
        return {
          wasSuccessful: true,
          message: 'Installation of Marketplace listing succeeded.',
          items: res.data.map((apiDistributedItem) =>
            MarketplaceModel.distributedItemFromAPIDistributedItem(
              apiDistributedItem,
            ),
          ),
        }
      }
    } catch (e) {
      console.error(`Unable to distribute Marketplace listing`)

      if (axios.isAxiosError(e)) {
        if (e.response?.data.detail) {
          return {
            wasSuccessful: false,
            message: e.response?.data.detail,
            items: [],
          }
        }
      }
    }
    return {
      wasSuccessful: false,
      message: 'An unknown error occurred during installation.',
      items: [],
    }
  }

  async createPromoCodeBatch(
    listingUUID: UUID,
    numCodes: number = 1,
    isSingleUse = false,
    expiry: Nullable<Date> = null,
  ): Promise<PromoCodeCreateResult> {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .marketplaceAPI.createPromoCodeBatch(
          listingUUID,
          numCodes,
          isSingleUse,
          expiry,
        )
      if (res && res.status === 201) {
        return {
          wasSuccessful: true,
          message: '',
          codes: res.data.map((apiPromoCode) =>
            MarketplaceModel.promoCodeFromAPIModel(apiPromoCode),
          ),
        }
      }
    } catch (e) {
      console.log('Unable to batch create promo codes', e)
    }

    return {
      wasSuccessful: false,
      message: 'An error occurred while generating promo code batch',
      codes: [],
    }
  }

  async getPromoCodeDetail(
    code: string,
  ): Promise<Nullable<Pick<PromoCode, 'listingUUID'>>> {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .marketplaceAPI.getPromoCodeDetail(code)
      if (res && res.status === 200) {
        return { listingUUID: res.data.listing }
      }
    } catch (e) {
      console.warn(`Unable fetch promo code detail: ${code}`, e)
    }
    return null
  }

  async getListingPurchaseStatus(
    listingUUID: UUID,
  ): Promise<APIMarketplaceListingPurchaseStatus> {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .marketplaceAPI.getListingPurchaseStatus(listingUUID)
      if (res && res.status === 200) {
        return res.data
      }
    } catch (e) {
      console.warn(
        `Unable to fetch purchase status for listing ${listingUUID}`,
        e,
      )
    }
    return {
      purchased: false,
    }
  }

  async redeemPromoCode(code: string): Promise<PromoCodeRedeemResult> {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .marketplaceAPI.redeemPromoCode(code)
      if (res) {
        if (res.status === 200) {
          return {
            wasSuccessful: true,
            message: res.data.message,
            listingUUID: res.data.listing_uuid,
          }
        }
      }
    } catch (e) {
      if (axios.isAxiosError(e) && e.response) {
        // Does not exist
        if (e.response.status === 404) {
          return {
            wasSuccessful: false,
            message: 'Invalid item code',
          }
        }
        // Already redeemed by another person
        else if (e.response.status === 406) {
          return {
            wasSuccessful: false,
            message: 'Item code has already been redeemed by another user',
          }
        }
        // Otherwise, go with the message from the server. Likely it will be "item code has expired"
        else if (e.response.status === 400 && e.response.data.message) {
          return {
            wasSuccessful: false,
            message: e.response.data.message,
          }
        }
      }
      console.warn(`Unable to redeem item code: ${code}`, e)
    }
    return {
      wasSuccessful: false,
      message: 'An unknown error occurred while redeeming item code',
    }
  }

  resetItemCodeRedeemProcess(): void {
    this.activeItemCode = ''
  }

  setActiveItemCode(code: string): void {
    this.activeItemCode = code ?? ''
    this.updateActiveItemCodeRedemption({
      status: ItemCodeStatus.NEEDS_REDEEMING,
    })
  }

  updateActiveItemCodeRedemption(
    itemCodeRedemption: Partial<ItemCodeRedemption>,
  ) {
    if (this.activeItemCode.length > 0) {
      this.promoCodeMap[this.activeItemCode] = {
        ...this.promoCodeMap[this.activeItemCode],
        ...itemCodeRedemption,
      }
    }
  }

  getActiveItemCodeRedemption(): ItemCodeRedemption | undefined {
    return this.promoCodeMap[this.activeItemCode]
  }

  async uploadMarketplaceAsset(
    file: File,
    upload: APIMarketplaceS3UploadPresignedUrl,
  ) {
    return await this.rootStore
      .getAPIClient()
      .marketplaceAPI.uploadMarketplaceAsset(file, upload)
  }

  async performAutomation(
    shareAlias: string,
    payload: APIAutomationItem[],
    clone: boolean = false,
    companyUUID?: UUID,
    listingUUID?: UUID,
  ) {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .marketplaceAPI.performAutomation(
          shareAlias,
          payload,
          clone,
          companyUUID,
        )
      if (res.data) {
        return res.data
      }
    } catch (e) {
      console.error(
        `Unable to perform automation for the given share alias: ${shareAlias}`,
        e,
      )
    }
    return null
  }

  async sendHatchEmail(
    sendEmailPayload: MarketplaceHatchSpeedToLeadEmailPayload,
  ) {
    const apiHatchEmailPayload =
      MarketplaceModel.apiCreateHatchEmailPayloadFromCreateHatchEmailPayload(
        sendEmailPayload,
      )
    try {
      const res = await this.rootStore
        .getAPIClient()
        .marketplaceAPI.sendHatchSpeedToLeadEmail(apiHatchEmailPayload)
      if (res.data) {
        return res.data
      }
    } catch (e) {
      console.error(
        `Unable to send email for listing ${sendEmailPayload.listingUUID}`,
        e,
      )
    }
    return null
  }
}
