import axios, { AxiosRequestConfig } from 'axios'
import { makeAutoObservable } from 'mobx'
import { makePersistable } from 'mobx-persist-store'
import { GetCompanyUserOption, GetCompanyUsersOptions } from 'src/api/TeamsAPI'
import { APIActivityLogItem } from 'src/model/api/company/APIActivityLogItem'
import { APICompany } from 'src/model/api/company/APICompany'
import { APICompanyRole } from 'src/model/api/company/APICompanyRole'
import { APICompanyUser } from 'src/model/api/company/APICompanyUser'
import { APIMessage } from 'src/model/api/company/APIMessage'
import { APITeam } from 'src/model/api/company/APITeam'
import { APITeamMember } from 'src/model/api/company/APITeamMember'
import { APIUserDetailAnalyticsEvent } from 'src/model/api/company/APIUserDetailAnalyticsEvent'
import { APIPresentationVersion } from 'src/model/api/presentation/APIPresentationVersion'
import { CompanyModel } from 'src/model/company'
import { ActivityLogItem } from 'src/model/company/ActivityLogItem'
import { Company } from 'src/model/company/Company'
import { CompanyRole } from 'src/model/company/CompanyRole'
import { CompanyRolesMap } from 'src/model/company/CompanyRolesMap'
import { CompanyTeamsMap } from 'src/model/company/CompanyTeamsMap'
import { CompanyUser } from 'src/model/company/CompanyUser'
import { CompanyUsersForceResendMap } from 'src/model/company/CompanyUsersForceResend'
import { CompanyUsersMap } from 'src/model/company/CompanyUsersMap'
import { NewTeam } from 'src/model/company/NewTeam'
import { Team } from 'src/model/company/Team'
import { UpdateCompanyUserOptions } from 'src/model/company/UpdateCompanyUserOptions'
import { UpdateTeamUserOptions } from 'src/model/company/UpdateTeamUserOptions'
import { UserAnalyticsOptions } from 'src/model/company/UserAnalyticsOptions'
import { PresentationsModel } from 'src/model/presentation/PresentationsModel'
import { NewUser } from 'src/model/user/NewUser'
import { RootStore } from 'src/store/RootStore'
import { TeamsUtil } from 'src/util/TeamsUtil'
import {
  AddTeamMemberOptions,
  AnalyticsReportID,
  CSVImportOptions,
  UUID,
} from 'src/util/types'
import { ImportResult } from '../model/company/ImportResult'
import { LicenseQuote } from '../model/company/LicenseQuote'
import { EnvConfig } from '../util/EnvConfig'
import { BaseStore } from './BaseStore'

export class TeamsStore implements BaseStore {
  static TEAMS_STORE_STORAGE_KEY = 'TeamsTeams' // Yup, that's right! We cornered ourselves into this one!
  rootStore: RootStore
  companies: Company[]
  users: CompanyUser[]
  allTeams: Record<UUID, Team>
  allTeamsExpires: number
  activityLog: ActivityLogItem[]
  companyTeamsMap: CompanyTeamsMap
  companyUsersMap: CompanyUsersMap
  companyRolesMap: CompanyRolesMap
  dismissedMessages: number[]
  hiddenCompanies: UUID[]
  companyUsersForceResendMap: CompanyUsersForceResendMap
  selectedCompanyUsersUUIDs: Record<UUID, boolean>

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.companies = []
    this.activityLog = []
    this.users = []
    this.allTeams = {}
    this.allTeamsExpires = 0
    this.companyTeamsMap = {}
    this.companyUsersMap = {}
    this.companyRolesMap = {}
    this.dismissedMessages = []
    this.hiddenCompanies = []
    this.companyUsersForceResendMap = {}
    this.selectedCompanyUsersUUIDs = {}

    makeAutoObservable(this, { rootStore: false })
    makePersistable(this, {
      name: TeamsStore.TEAMS_STORE_STORAGE_KEY,
      properties: [
        'companies',
        'allTeams',
        'allTeamsExpires',
        'companyUsersMap',
        'companyRolesMap',
        'activityLog',
        'dismissedMessages',
        'hiddenCompanies',
        'companyUsersForceResendMap',
        'selectedCompanyUsersUUIDs',
      ],
      storage: window.localStorage,
    }).then((r) => {
      console.debug(`Persisting data for ${TeamsStore.TEAMS_STORE_STORAGE_KEY}`)
    })
  }

  async getMessages() {
    try {
      const res = await this.rootStore.getAPIClient().teamsAPI.getMessages()
      if (res.data) {
        return res.data.map((apiMessage: APIMessage) =>
          CompanyModel.messageFromAPIMessage(apiMessage),
        )
      }
    } catch (e) {
      console.error('Unable to fetch messages', e)
    }
  }

  async getCompanies() {
    try {
      const res = await this.rootStore.getAPIClient().teamsAPI.getCompanies()
      if (res) {
        const companies = res.map((apiCompany: APICompany) =>
          CompanyModel.companyFromAPIModel(apiCompany),
        )
        this.setCompanies(companies)
        return companies
      }
    } catch (e) {
      console.error('Unable to fetch companies', e)
      this.setCompanies([])
    }
    return []
  }

  async convertCompany(companyUUID: UUID) {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .teamsAPI.convertCompany(companyUUID)
      if (res.data) {
        const company = CompanyModel.companyFromAPIModel(res.data)
        return company
      }
    } catch (e) {
      console.error(`Unable to convert company ${companyUUID}`, e)
    }
  }

  async getCompanyActivityLog(
    companyUUID: UUID,
    startDate?: string,
    endDate?: string,
    search?: string,
    pageSize: number = 100,
  ) {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .teamsAPI.getCompanyActivityLog(
          companyUUID,
          startDate,
          endDate,
          search,
          pageSize,
        )
      if (res.data) {
        this.setActivityLog(
          res.data.map((apiLogItem: APIActivityLogItem) =>
            CompanyModel.activityLogItemFromAPIModel(apiLogItem),
          ),
        )
      }
    } catch (e) {
      this.setActivityLog([])
      console.error(
        `Unable to fetch activity log for company: ${companyUUID}`,
        e,
      )
    }
  }

  async getCompanyActivityLogPage(
    companyUUID: UUID,
    page: number,
    pageSize: number,
    config?: AxiosRequestConfig | undefined,
  ): Promise<{ totalCount: number; results: ActivityLogItem[] }> {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .teamsAPI.getCompanyActivityLogPage(companyUUID, page, pageSize, config)
      if (res) {
        return {
          totalCount: res.headers['total-count']
            ? parseInt(res.headers['total-count'])
            : 0,
          results: res.data.map((apiLogItem: APIActivityLogItem) =>
            CompanyModel.activityLogItemFromAPIModel(apiLogItem),
          ),
        }
      }
    } catch (e) {
      console.error(
        `Unable to fetch activity log for company: ${companyUUID}`,
        e,
      )
    }

    return {
      totalCount: 0,
      results: [],
    }
  }

  async getCompanyUsers(
    companyUUID: UUID,
    options: GetCompanyUsersOptions,
    config?: AxiosRequestConfig,
  ) {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .teamsAPI.getCompanyUsers(companyUUID, options, config)
      if (res) {
        const users = res.map((apiUser: APICompanyUser) =>
          CompanyModel.companyUserFromAPIModel(apiUser),
        )
        this.setCompanyUsers(
          companyUUID,
          res.map((apiUser: APICompanyUser) =>
            CompanyModel.companyUserFromAPIModel(apiUser),
          ),
        )
        return users
      }
    } catch (e) {
      if (!config?.signal?.aborted) {
        this.setCompanyUsers(companyUUID, [])
        console.error(`Unable to fetch users for company: ${companyUUID}`, e)
      }
    }
    return null
  }

  async getCompanyUser(
    companyUUID: UUID,
    userUUID: UUID,
    options?: GetCompanyUserOption,
    config?: AxiosRequestConfig,
  ) {
    const reponse = await this.rootStore
      .getAPIClient()
      .teamsAPI.getCompanyUser(companyUUID, userUUID, options, config)
    return CompanyModel.companyUserFromAPIModel(reponse.data)
  }

  async getCompanyUsersPage(
    companyUUID: UUID,
    options: GetCompanyUsersOptions,
    config?: AxiosRequestConfig | undefined,
  ): Promise<{ totalCount: number; users: CompanyUser[] }> {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .teamsAPI.getCompanyUsersPage(companyUUID, options, config)
      if (res) {
        return {
          totalCount: res.headers['total-count']
            ? parseInt(res.headers['total-count'])
            : 0,
          users: res.data.map((apiUser: APICompanyUser) => {
            const companyUser = CompanyModel.companyUserFromAPIModel(apiUser)

            if (companyUser.teams) {
              companyUser.teams = companyUser.teams.filter(
                TeamsUtil.filterHiddenTeams(this.rootStore.userStore.profile),
              )
            }

            return companyUser
          }),
        }
      }
    } catch (e) {
      console.error('Unable to fetch Users', e)
    }

    return {
      totalCount: 0,
      users: [],
    }
  }

  async getCompanyUserTeams(companyUUID: UUID, userUUID: UUID) {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .teamsAPI.getCompanyUserTeams(companyUUID, userUUID)
      if (res) {
        return res
          .map((apiTeam: APITeam) => CompanyModel.teamFromAPIModel(apiTeam))
          .filter(TeamsUtil.filterHiddenTeams(this.rootStore.userStore.profile))
      }
    } catch (e) {
      console.error(
        `Unable to fetch teams for user ${userUUID} in company ${companyUUID}`,
      )
    }
    return []
  }

  async getCompanyRoles(companyUUID: UUID, config?: AxiosRequestConfig) {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .teamsAPI.getCompanyRoles(companyUUID, config)
      if (res.data) {
        const roles = res.data.map((apiRole: APICompanyRole) =>
          CompanyModel.companyRoleFromAPIModel(apiRole),
        )
        this.setCompanyRoles(companyUUID, roles)
        return roles
      }
    } catch (e) {
      if (!config?.signal?.aborted) {
        this.setCompanyRoles(companyUUID, [])
        console.error(`Unable to fetch roles for company: ${companyUUID}`, e)
      }
    }
    return []
  }

  /**
   * @param force (optional): if true, bust local cache
   */
  async getAllTeams(force?: boolean) {
    if (
      Object.keys(this.allTeams).length > 0 &&
      this.allTeamsExpires > Date.now() &&
      !force
    ) {
      console.debug('Fetching all teams from cache...')
      return Promise.resolve(Object.values(this.allTeams))
    }
    const teamsMap: Record<UUID, Team> = {}
    try {
      for (const company of this.companies) {
        const res = await this.rootStore
          .getAPIClient()
          .teamsAPI.getCompanyTeams(company.uuid)
        if (res) {
          for (const apiTeam of res) {
            teamsMap[apiTeam.uuid] = CompanyModel.teamFromAPIModel(apiTeam)
          }
        }
      }
      this.setAllTeams(teamsMap)
      return Object.values(teamsMap)
    } catch (e) {
      console.error(`Unable to fetch all teams for user`, e)
    }
    return []
  }

  async getCompanyTeams(companyUUID: UUID) {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .teamsAPI.getCompanyTeams(companyUUID)
      if (res) {
        return res
          .map((apiTeam) => CompanyModel.teamFromAPIModel(apiTeam))
          .filter(TeamsUtil.filterHiddenTeams(this.rootStore.userStore.profile))
          .sort((a, b) => a.name.localeCompare(b.name))
      }
    } catch (e) {
      console.error(`Unable to fetch teams for company: ${companyUUID}`, e)
    }
    return []
  }

  async getCompanyAllTeams(companyUUID: UUID, config?: AxiosRequestConfig) {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .teamsAPI.getCompanyAllTeams(companyUUID, config)
      if (res) {
        const teams = res.map((apiTeam: APITeam) =>
          CompanyModel.teamFromAPIModel(apiTeam),
        )
        this.setCompanyTeams(companyUUID, teams)
        return teams
      }
    } catch (e) {
      if (!config?.signal?.aborted) {
        console.error(
          `Unable to fetch all teams for company: ${companyUUID}`,
          e,
        )
      }
    }
    return []
  }

  async getCompanyTeamsPage(
    companyUUID: UUID,
    page: number,
    pageSize: number,
    config?: AxiosRequestConfig | undefined,
  ): Promise<{ totalCount: number; teams: Team[] }> {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .teamsAPI.getCompanyTeamsPage(companyUUID, page, pageSize, config)
      if (res) {
        return {
          totalCount: res.headers['total-count']
            ? parseInt(res.headers['total-count'])
            : 0,
          teams: res.data
            .map((apiTeam: APITeam) => CompanyModel.teamFromAPIModel(apiTeam))
            .filter(
              TeamsUtil.filterHiddenTeams(this.rootStore.userStore.profile),
            ),
        }
      }
    } catch (e) {
      console.error('Unable to fetch Teams', e)
    }

    return {
      totalCount: 0,
      teams: [],
    }
  }

  async getCompanyTeam(
    companyUUID: UUID,
    teamUUID: UUID,
    config?: AxiosRequestConfig,
  ) {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .teamsAPI.getCompanyTeam(companyUUID, teamUUID, config)
      if (res.data) {
        return CompanyModel.teamFromAPIModel(res.data)
      }
    } catch (e) {
      if (!config?.signal?.aborted) {
        console.error(
          `Unable to fetch team ${teamUUID} for company: ${companyUUID}`,
          e,
        )
      }
    }
    return undefined
  }

  async findCompanyUsersBySearchQuery(companyUUID: UUID, query: string) {
    try {
      const options: GetCompanyUsersOptions = { query }
      const res = await this.rootStore
        .getAPIClient()
        .teamsAPI.getCompanyUsers(companyUUID, options)
      if (res) {
        return res.map((apiUser: APICompanyUser) =>
          CompanyModel.companyUserFromAPIModel(apiUser),
        )
      }
    } catch (e) {
      console.error(
        `Unable to search users for company: ${companyUUID} with query: ${query}`,
        e,
      )
      return []
    }
  }

  async findCompanyUsersByEmail(companyUUID: UUID, email: string) {
    try {
      const options: GetCompanyUsersOptions = { email }
      const res = await this.rootStore
        .getAPIClient()
        .teamsAPI.getCompanyUsers(companyUUID, options)
      if (res) {
        return res.map((apiUser: APICompanyUser) =>
          CompanyModel.companyUserFromAPIModel(apiUser),
        )
      }
    } catch (e) {
      console.error(
        `Unable to search users for company: ${companyUUID} with email: ${email}`,
        e,
      )
      return []
    }
  }

  async saveNewUser(
    companyUUID: UUID,
    newUser: NewUser,
    shouldSendEmail: boolean = false,
  ) {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .teamsAPI.saveNewUser(companyUUID, newUser, shouldSendEmail)
      if (res.data) {
        return CompanyModel.newUserResponseFromAPIModel(res.data)
      }
    } catch (e) {
      console.error(
        `Unable to create new user ${newUser.email} for company: ${companyUUID}`,
        e,
      )
      if (axios.isAxiosError(e)) {
        return e.response?.data
      }
    }
  }

  async updateExistingUser(
    companyUUID: UUID,
    userUUID: UUID,
    updateCompanyUserOptions: UpdateCompanyUserOptions,
  ) {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .teamsAPI.updateCompanyUser(
          companyUUID,
          userUUID,
          updateCompanyUserOptions,
        )
      if (res.status > 200 && res.status < 400) {
        return true
      }
    } catch (e) {
      console.error(
        `Unable to update user ${updateCompanyUserOptions.email} for company: ${companyUUID}`,
        e,
      )
      return false
    }
  }

  async deleteUser(companyUUID: UUID, userUUID: UUID) {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .teamsAPI.deleteCompanyUser(companyUUID, userUUID)
      if (res.status < 400) {
        return true
      }
    } catch (e) {
      console.error(
        `Unable to delete user ${userUUID} from company: ${companyUUID}`,
        e,
      )
      return false
    }
    return false
  }

  async getUserAnalytics(
    companyUUID: UUID,
    userUUID: UUID,
    reportID: AnalyticsReportID,
    options: UserAnalyticsOptions,
  ) {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .teamsAPI.getUserAnalytics(companyUUID, userUUID, reportID, options)
      if (res.data) {
        return res.data.map((item: APIUserDetailAnalyticsEvent) =>
          CompanyModel.userDetailAnalyticsEventFromAPIModel(item),
        )
      }
    } catch (e) {
      console.error(
        `Unable to fetch analytics for user ${userUUID} in company ${companyUUID}.`,
        e,
      )
    }
    return []
  }

  async importUsers(
    companyUUID: UUID,
    csvFile: File,
    roleUUID: UUID,
    shouldSendInviteEmails: boolean,
    team?: Team,
  ) {
    try {
      const options: CSVImportOptions = {
        roleUUID,
        shouldSendInviteEmails: shouldSendInviteEmails,
      }
      if (team) {
        options.team = team
      }
      const res = await this.rootStore
        .getAPIClient()
        .teamsAPI.importUsersFromCSV(companyUUID, csvFile, options)
      const result: ImportResult = {
        wasSuccessful: true,
        detail: 'Successfully imported users from CSV.',
      }
      // If not all users were imported, update the messaging
      if (res.data && res.data.failed) {
        result.detail = res.data.msg as string
        result.failed = res.data.failed
      }
      return result
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const result: ImportResult = {
          wasSuccessful: false,
        }
        // HTTP 402 === Payment Required, i.e. needs to purchase more Ingage licenses
        if (error.response?.status === 402) {
          result.quote = error.response.data.quote as LicenseQuote
          result.detail = error.response.data.detail as string
          return result
        }
        result.detail = error.response?.data.msg as string
        return result
      }
      console.error(
        `Unable to import CSV users for company ${companyUUID}`,
        error,
      )
    }
    const result: ImportResult = {
      wasSuccessful: false,
      detail:
        'Unable to import CSV users for company. Please try again later or contact support.',
    }
    return result
  }

  findCompanyUserCountInForceResendMap(companyUUID: string, userUUID: string) {
    if (!(companyUUID in this.companyUsersForceResendMap)) {
      return 0
    }
    return this.companyUsersForceResendMap[companyUUID][userUUID] ?? 0
  }

  findCompanyUsersNotOverLimitInForceResendMap(
    companyUUID: string,
    userUUIDs: string[] = [],
  ) {
    const results: string[] = []
    if (userUUIDs.length > 0) {
      for (const userUUID of userUUIDs) {
        const count = this.findCompanyUserCountInForceResendMap(
          companyUUID,
          userUUID,
        )
        if (count === undefined || count < EnvConfig.forceResendInviteLimit()) {
          results.push(userUUID)
        }
      }
    }
    return results
  }

  updateCompanyUserInForceResendMap(
    companyUUID: string,
    userUUID: string,
    count: number,
  ) {
    if (!(companyUUID in this.companyUsersForceResendMap)) {
      this.companyUsersForceResendMap[companyUUID] = {
        ...this.companyUsersForceResendMap[companyUUID],
        [userUUID]: count,
      }
    }
    return (this.companyUsersForceResendMap[companyUUID][userUUID] = count)
  }

  async saveNewTeam(companyUUID: UUID, newTeam: NewTeam) {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .teamsAPI.saveNewTeam(companyUUID, newTeam)
      if (res.data) {
        return CompanyModel.teamFromAPIModel(res.data)
      }
    } catch (e) {
      console.error(
        `Unable to create new team ${newTeam.name} for company: ${companyUUID}`,
        e,
      )
      return undefined
    }
  }

  async updateTeam(companyUUID: UUID, team: Team) {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .teamsAPI.updateTeam(companyUUID, team)
      if (res.status === 200) {
        const teamsUpdated = this.companyTeamsMap[companyUUID].map((t, idx) => {
          if (t.uuid === team.uuid) {
            return team
          } else {
            return t
          }
        })
        this.setCompanyTeams(companyUUID, teamsUpdated)
        return res.data
      }
    } catch (e) {
      console.error(
        `Unable to update team ${team.name} for company: ${companyUUID}`,
        e,
      )
      return undefined
    }
  }

  async deleteTeam(companyUUID: UUID, teamUUID: UUID) {
    try {
      await this.rootStore
        .getAPIClient()
        .teamsAPI.deleteTeam(companyUUID, teamUUID)
      const teamsFiltered = this.companyTeamsMap[companyUUID].filter(
        (t) => t.uuid !== teamUUID,
      )
      this.setCompanyTeams(companyUUID, teamsFiltered)
    } catch (e) {
      console.error('Unable to delete team', e)
    }
  }

  async getTeamMembers(
    companyUUID: UUID,
    teamUUID: UUID,
    config?: AxiosRequestConfig,
  ) {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .teamsAPI.getTeamMembers(companyUUID, teamUUID, config)
      if (res) {
        return res.map((apiTeamMember: APITeamMember) =>
          CompanyModel.teamMemberFromAPIModel(apiTeamMember),
        )
      }
    } catch (e) {
      if (!config?.signal?.aborted) {
        console.error('Unable to get team members', e)
      }
    }
    return undefined
  }

  async addTeamMember(
    companyUUID: UUID,
    teamUUID: UUID,
    options: AddTeamMemberOptions,
  ) {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .teamsAPI.addTeamMember(companyUUID, teamUUID, options)
      if (res.data) {
        return res.data
      }
    } catch (e) {
      console.error(`Unable to add user to team for company: ${companyUUID}`, e)
    }
    return undefined
  }

  async updateTeamMember(
    companyUUID: UUID,
    teamUUID: UUID,
    memberUUID: UUID,
    options: UpdateTeamUserOptions,
  ) {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .teamsAPI.updateTeamMember(companyUUID, teamUUID, memberUUID, options)
      if (res.status === 200) {
        return true
      }
    } catch (e) {
      console.error(
        `Unable to update team member for company: ${companyUUID}`,
        e,
      )
      return false
    }
  }

  async removeTeamMember(companyUUID: UUID, teamUUID: UUID, memberUUID: UUID) {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .teamsAPI.removeTeamMember(companyUUID, teamUUID, memberUUID)
      if (res.status >= 200 && res.status <= 400) {
        return true
      }
    } catch (e) {
      console.error('Unable to remove team member', e)
    }
    return false
  }

  async getTeamPresentations(
    companyUUID: UUID,
    teamUUID: UUID,
    shouldShowArchived: boolean,
    config?: AxiosRequestConfig,
  ) {
    try {
      const apiPresentations = await this.rootStore
        .getAPIClient()
        .teamsAPI.getTeamPresentations(companyUUID, teamUUID, config)

      return Promise.all(
        apiPresentations.map(async (apiPresentation) => {
          const apiOptions = {
            is_archived: shouldShowArchived ? 'True' : undefined,
          }
          const presentation =
            PresentationsModel.presentationFromAPIModel(apiPresentation)
          if (!presentation.currentVersionURL) {
            console.warn(
              `Presentation ${presentation.id} does not have a valid current version URL: ${presentation.currentVersionURL}`,
            )
            return presentation
          }
          const versionRes = await this.rootStore
            .getAPIClient()
            .presentationsAPI.withAuth()
            .get<APIPresentationVersion>(presentation.currentVersionURL, {
              params: apiOptions,
            })
          if (versionRes && versionRes.data) {
            presentation.currentVersion =
              PresentationsModel.presentationVersionFromAPIModel(
                versionRes.data,
              )
          }
          return presentation
        }),
      )
    } catch (e) {
      if (!config?.signal?.aborted) {
        console.error('Unable to get team presentations', e)
      }
    }
  }

  // region Getters and setters
  setCompanies(companies: Company[]) {
    this.companies = companies
  }

  setAllTeams(teamsMap: Record<UUID, Team>) {
    this.allTeams = teamsMap
    this.allTeamsExpires = Date.now() + 600000
  }

  setActivityLog(items: ActivityLogItem[]) {
    this.activityLog = items
  }

  setCompanyTeams(companyUUID: UUID, teams: Team[]) {
    this.companyTeamsMap = {
      ...this.companyTeamsMap,
      [companyUUID]: teams,
    }
  }

  setCompanyUsers(companyUUID: UUID, users: CompanyUser[]) {
    this.companyUsersMap = {
      ...this.companyUsersMap,
      [companyUUID]: users,
    }
  }

  setCompanyRoles(companyUUID: UUID, roles: CompanyRole[]) {
    this.companyRolesMap = {
      ...this.companyRolesMap,
      [companyUUID]: roles,
    }
  }

  setMessagesDismissed(dismissedMessagedList: number[]) {
    this.dismissedMessages = dismissedMessagedList
  }

  setHiddenCompanies(hiddenCompaniesList: UUID[]) {
    this.hiddenCompanies = hiddenCompaniesList
  }

  setSelectedCompanyUsersUUIDs(userUUID: UUID, isSelected: boolean) {
    this.selectedCompanyUsersUUIDs = {
      ...this.selectedCompanyUsersUUIDs,
      [userUUID]: isSelected,
    }
  }

  resetSelectedCompanyUsersUUIDs() {
    this.selectedCompanyUsersUUIDs = {}
  }

  // endregion Getters and setters

  clearData() {
    this.companies = []
    this.allTeams = {}
    this.allTeamsExpires = 0
    this.users = []
    this.companyRolesMap = {}
    this.activityLog = []
    this.dismissedMessages = []
    this.hiddenCompanies = []
    this.selectedCompanyUsersUUIDs = {}
  }
}
