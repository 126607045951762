import uniq from 'lodash/uniq'

import type {
  WeightAndGlyphData,
  FamilyToWeightsMap,
  FontWeightToPropsMap,
  FamilyAndWeightToPropsMap,
  FamilyToWeightsAndGlyphDataMap
} from '../../rules/freestyle'
import {
  defaultFreestyleFontRules
} from '../../rules/freestyle'
import { FontHelper } from '../FontHelper'
import type { Font } from '../../types/Font'
import type { GlyphData } from '../../types/GlyphData'

/**
 * List of default font families
 */
export const defaultFontFamilies = defaultFreestyleFontRules.map(font => font.fontFamily)

/**
 * Maps a font family to its available font weights
 */
export const defaultFamilyToWeightsMap: FamilyToWeightsMap = defaultFreestyleFontRules.reduce((acc, curr) => {
  return {
    ...acc,
    [curr.fontFamily]: Object.keys(curr.fontWeights)
  }
}, {})

/**
 * Maps a font family and weight to its props
 */
export const defaultFamilyAndWeightToPropsMap: FamilyAndWeightToPropsMap = defaultFreestyleFontRules.reduce((acc, curr) => {
  return {
    ...acc,
    [curr.fontFamily]: curr.fontWeights
  }
}, {})

/**
 * Build a list of unique font families based on current Fonts.
 *
 * @param fonts Fonts array for current company
 */
export const buildCustomFontFamilies = (fonts: Font[]): string[] => {
  return uniq(fonts.map(fontItem => fontItem.familyName))
}

/**
 * Build a map that connects a font family to the list of available
 * font weights for said font family, based on current Fonts.
 *
 * @param fonts Font array for current company
 */
export const buildCustomFamilyToWeightsMap = (fonts: Font[]): FamilyToWeightsMap => {
  return fonts.reduce((familyToWeightsMap, { familyName, subfamilyName }) => {
    familyToWeightsMap[familyName] != null
      ? familyToWeightsMap[familyName].push(subfamilyName)
      : familyToWeightsMap[familyName] = [subfamilyName]
    return familyToWeightsMap
  }, {} as FamilyToWeightsMap)
}

/**
 * Build a map that connects a font family and font weight
 * to the props necessary to render said family and weight.
 *
 * @param fonts Fonts array for current company
 */
export const buildCustomFamilyAndWeightToPropsMap = (fonts: Font[]): FamilyAndWeightToPropsMap => {
  const familyToWeightAndGlyphDataMap = buildFamilyToWeightAndGlyphDataMap(fonts)
  return Object.entries(familyToWeightAndGlyphDataMap).reduce((familyAndWeightToPropsMap, [fontFamily, weightAndGlyphData]) => {
    familyAndWeightToPropsMap[fontFamily] = getFontWeightAndGlyphDataToPropsMap(weightAndGlyphData)
    return familyAndWeightToPropsMap
  }, {} as FamilyAndWeightToPropsMap)
}

/**
 * Build a map that connects a font family to the list of available font
 * weights and their corresponding glyph data, based on current Fonts.
 *
 * @param fonts Fonts array for current company
 */
const buildFamilyToWeightAndGlyphDataMap = (fonts: Font[]): FamilyToWeightsAndGlyphDataMap => {
  return fonts.reduce((familyToWeightsMap, { familyName, subfamilyName, glyphData }) => {
    const weightAndGlyphData = { fontWeight: subfamilyName, glyphData }
    familyToWeightsMap[familyName] != null
      ? familyToWeightsMap[familyName].push(weightAndGlyphData)
      : familyToWeightsMap[familyName] = [weightAndGlyphData]
    return familyToWeightsMap
  }, {} as FamilyToWeightsAndGlyphDataMap)
}

/**
 * Assign a range of arbitrary CSS numeric font weights for a
 * custom font family so we can map a font weight to its correct
 * font face declaration on render.
 *
 * CSS font weights do not have to match the font's stylistic
 * weight (i.e. Regular to 400, Bold to 700).
 *
 * Assign normal to all font styles, event italic ones. It's not
 * necessary as we don't intend this to be human readable.
 *
 * @param weightAndGlyphData list of font weights and glyph data in a font family
 */
const getFontWeightAndGlyphDataToPropsMap = (weightAndGlyphData: WeightAndGlyphData[]) => {
  return weightAndGlyphData.reduce((fontWeightToPropsMap, { fontWeight, glyphData }, index) => {
    fontWeightToPropsMap[fontWeight] = {
      ...FontHelper.guessFontPropsForSubfamilyName(fontWeight),
      lineHeight: calculateLineSpacingForGlyphData(glyphData)
    }
    return fontWeightToPropsMap
  }, {} as FontWeightToPropsMap)
}

/**
 * Calculate the base line height for a font based on glyph data.
 *
 * @param glyphData GlyphData for font family and weight
 */
const calculateLineSpacingForGlyphData = (glyphData?: GlyphData): number => {
  if (!glyphData?.head) {
    /**
     * default line height if glyph data is missing.
     *
     * line height of 1.2 will not fit all fonts, but
     * it's the closest general default line height as
     * most fonts fall within a 1.15 to 1.25 range.
     */
    return 1.2
  }

  const ascent = glyphData.hhea.ascent
  const descent = glyphData.hhea.descent
  const lineGap = glyphData.hhea.lineGap

  const unitsPerEm = glyphData.head.unitsPerEm

  return (ascent + -descent + lineGap) / unitsPerEm
}
