const ingageGettingStartedGuide = {
  gettingStarted: {
    video: {
      default: 'https://www.youtube.com/embed/GqBWUypxXww',
      admin: 'https://www.youtube.com/embed/GqBWUypxXww',
    },
  },
  viewAPresentation: {
    video:
      'https://s3.amazonaws.com/teams-assets.ingage.io/gaf/onboarding-videos/gaf-onboarding-view-presentation.mp4',
    copy: 'Content in Ingage is interactive and easy to navigate. Watch this video to get the most out of your presentations.',
  },
  addUsers: {
    video:
      'https://s3.amazonaws.com/teams-assets.ingage.io/gaf/onboarding-videos/gaf-onboarding-add-users.mp4',
    copy: 'Invite the rest of your team to Ingage so they can join you in closing more deals fast.',
  },
  companyProfile: {
    tooltipCopy: '',
    video:
      'https://s3.amazonaws.com/teams-assets.ingage.io/gaf/onboarding-videos/gaf-onboarding-customize-profile.mp4',
    copy: 'Update your company name, add your logo, and share a short company story with potential customers.',
  },
}

export default ingageGettingStartedGuide
