import { v4 as uuidv4 } from 'uuid';

import { Stub, Config, upgrade } from '../../../../';

export default (stub: Stub, config: Config): Stub => {
    stub.version = 5;

    if (stub.id == null) {
        stub.id = uuidv4();
    }

    if (config.recursive) {
        if (stub.layout == 'full') {
            if (stub.dynamicBox.x < 0.5) {
                stub.bodyText.textAlignment = 'left';
                stub.subheadingText.textAlignment = 'left';
            } else if (stub.dynamicBox.x > 0.5) {
                stub.bodyText.textAlignment = 'right';
                stub.subheadingText.textAlignment = 'right';
            }

            stub.dynamicBox.textX = 0.5;
            stub.dynamicBox.textY = stub.dynamicBox.y;
            stub.dynamicBox.x = 0.0;
            stub.dynamicBox.y = 0.0;
        } else if (stub.layout == 'fullXXL' || stub.layout == 'fullNoImage') {
            stub.dynamicBox.textX = stub.dynamicBox.x;
            stub.dynamicBox.textY = stub.dynamicBox.y;
            stub.dynamicBox.x = 0.0;
            stub.dynamicBox.y = 0.0;
        } else {
            stub.dynamicBox.textX = 0.5;
            stub.dynamicBox.textY = 0.5;
        }

        stub.subheadingText = upgrade(stub.subheadingText, config);
        stub.dynamicBox = upgrade(stub.dynamicBox, config);
        stub.backgroundMedia = upgrade(stub.backgroundMedia, config);
        stub.buttonOverlay = upgrade(stub.buttonOverlay, config);
        stub.bodyText = upgrade(stub.bodyText, config);
        stub.buttonTextOverlay = upgrade(stub.buttonTextOverlay, config);
    }
    return stub;
};
