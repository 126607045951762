import { Stub, Config, Context, upgrade } from '../../../../';

export default (stub: Stub, config: Config): Stub => {
    stub.version = 2;
    stub.theme = {
        mode: 'light',
        baseColor: '#FFFFFF',
    };

    if (stub.cosmeticCellColorA == '#000000') {
        stub.theme.mode = 'dark';
    }

    for (let i = 0; i < stub.partitions.length; i++) {
        const partition = stub.partitions[i];

        if (partition.image) {
            partition.media = {
                type: 'media-component',
                version: 1,
                mediaType: {
                    image: partition.image,
                },
            };
            delete partition.image;
        }

        if (!partition.buttonOverlay) {
            partition.buttonOverlay = {
                type: 'overlay-component',
                version: 1.0,
                items: [],
            };
        }
    }

    delete stub.cosmeticCellColorA;
    delete stub.cosmeticCellColorB;
    delete stub.cosmeticCellColorC;
    delete stub.cosmeticCellColorD;
    delete stub.cosmeticCellColorE;

    if (config.recursive) {
        const size = getSize(stub);

        for (let i = 0; i < stub.partitions.length; i++) {
            const partition = stub.partitions[i];

            partition.body = upgrade(partition.body, config);
            partition.media = upgrade(partition.media, config, size);
            partition.buttonOverlay = upgrade(partition.buttonOverlay, config);
            partition.subheading = upgrade(partition.subheading, config);
        }

        stub.title = upgrade(stub.title, config, { textMode: 'header' });
        stub.headline = upgrade(stub.headline, config);
    }

    return stub;
};

const getSize = (page: Stub): Context => {
    let mapTranslateX: { [key: number]: number };
    let mapTranslateY: { [key: number]: number };

    if (page.organization === 'columns') {
        mapTranslateX = {
            1: 384,
            2: 384,
            3: 277,
        };
        mapTranslateY = {
            1: 288,
            2: 288,
            3: 208,
        };
    } else {
        mapTranslateX = {
            1: 294,
            2: 294,
            3: 166,
        };
        mapTranslateY = {
            1: 220,
            2: 220,
            3: 125,
        };
    }

    return {
        width: mapTranslateX[page.partitionCount],
        height: mapTranslateY[page.partitionCount],
    };
};
