import { Stub, Config, upgrade, getUpgraderVersion } from '../../../../';

export default (stub: Stub, config: Config): Stub => {
    stub.version = 7;

    if (stub.template) {
        stub.template = upgrade(stub.template, config)
    }

    if (config.recursive) {
        if (stub.cover) {
            stub.cover = upgrade(stub.cover, config);
        }

        for (let i = 0; i < stub.sections.length; i++) {
            const section = stub.sections[i];

            for (let i = 0; i < section.pages.length; i++) {
                const page = section.pages[i];
                section.pages[i] = upgrade(page, config);
            }
            stub.sections[i] = section;
        }
    }

    stub.upgraderVersion = getUpgraderVersion()

    return stub;
};
