import dayjs from 'dayjs'
import { range } from 'lodash'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { addDays, getISOWeek, startOfDay, startOfWeek, subDays } from 'date-fns'

import { DateRangePreset } from 'src/views/company/analytics/utils/AnalyticsDateRange'

dayjs.extend(utc)
dayjs.extend(timezone)

export namespace DateUtil {
  export const dateToLocalTZ = (
    date: Date,
    dateFormat: string,
    eventType: string = '',
  ) => {
    const mpProjectTZ = 'America/Los_Angeles'
    const mpProjectOffset =
      dayjs.tz(dayjs(), mpProjectTZ).utcOffset() * 60 * 1000 * -1
    const localUserTZ = dayjs.tz.guess()

    // convert to Mixpanel timezone (Los Angeles)
    const calculatedDate =
      eventType === 'Mixpanel'
        ? new Date(date).getTime() + mpProjectOffset
        : new Date(date).getTime()
    const d = dayjs.tz(calculatedDate, mpProjectTZ)

    // convert to user's current timezone and format the result
    return d.tz(localUserTZ).format(dateFormat)
  }

  export const getWeekStartAndEndDatesByWeekNumber = (weekNumber: number) => {
    const currentYear = new Date().getFullYear()
    const initDate = new Date(currentYear, 0, 4)
    initDate.setDate(
      initDate.getDate() - (initDate.getDay() || 7) + 1 + 7 * (weekNumber - 1),
    )
    const startDate = subDays(initDate, 1)
    const endDate = addDays(startDate, 6)
    return { startDate, endDate }
  }

  export const isDateWithinDateRange = (
    startDate: Date,
    endDate: Date,
    compareDate: Date,
  ) => {
    return compareDate >= startDate && compareDate <= endDate
  }

  export const getDateRangesByNumberOfWeeks = (numberOfWeeks: number) => {
    const endWeekNumber = getISOWeek(new Date())
    const startWeekNumber = endWeekNumber - numberOfWeeks
    const rangeOfWeeks = range(startWeekNumber, endWeekNumber, 1)
    const dateRanges = rangeOfWeeks.map((weekNumber) => {
      return getWeekStartAndEndDatesByWeekNumber(weekNumber)
    })
    return dateRanges
  }

  export const customDatePickerRanges: {
    [key in DateRangePreset]: [Date, Date]
  } = {
    [DateRangePreset.THIS_WEEK]: [
      startOfWeek(new Date()),
      startOfDay(new Date()),
    ],
    [DateRangePreset.LAST_WEEK]: [
      subDays(startOfWeek(new Date()), 7),
      subDays(startOfWeek(new Date()), 1),
    ],
    [DateRangePreset.THIS_MONTH]: [
      new Date(new Date().setDate(1)),
      new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
    ],
    [DateRangePreset.LAST_MONTH]: [
      new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
      new Date(new Date().getFullYear(), new Date().getMonth(), 0),
    ],
    [DateRangePreset.CUSTOM_RANGE]: [
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
      ),
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
      ),
    ],
  }

  export const getInclusiveDaysBetweenDates = (
    startDate: Date,
    endDate: Date,
  ) => {
    return dayjs(endDate).diff(dayjs(startDate), 'day') + 1
  }
}
