import { AxiosRequestConfig } from 'axios'
import BaseAPI from 'src/api/BaseAPI'
import { APICompany } from 'src/model/api/company/APICompany'

export default class CompanyAPI extends BaseAPI {
  getCompany(companyUUID: string, options?: AxiosRequestConfig) {
    return this.V2()
      .withAuth()
      .get<APICompany>(`/company/${companyUUID}/`, options)
  }
}
