import BaseAPI from 'src/api/BaseAPI'
import { APITheme } from 'src/model/api/theme/APITheme'
import { Template } from 'src/model/theme/template/Template'
import { ThemeModel } from 'src/model/theme/ThemeModel'
import { UUID } from 'src/util/types'

export type ThemeDataPayload = {
  name: string
  description: string
  template: Template
}

/**
 * Client for themes-related calls to the API.
 */
export default class ThemeAPI extends BaseAPI {
  /**
   * Fetches and returns a list of themes for a specific company.
   * @param companyUUID
   */
  async getThemeList(companyUUID: UUID) {
    return this.withAuth().getAllPages<APITheme>(
      `/team/api/company/${companyUUID}/themes/`,
    )
  }

  /**
   * Fetches and returns a single theme for a specific company.
   *
   * @param companyUUID
   * @param themeUUID
   */
  async getTheme(companyUUID: UUID, themeUUID: UUID) {
    return this.withAuth().get<APITheme>(
      `/team/api/company/${companyUUID}/themes/${themeUUID}/`,
    )
  }

  /**
   * Creates a new theme under a specific company.
   *
   * @param companyUUID
   * @param newThemeData payload for the new theme, including company UUID, name, description, and theme template object data
   */
  async createTheme(companyUUID: UUID, newThemeData: ThemeDataPayload) {
    const themeDataPayload = {
      name: newThemeData.name,
      description: newThemeData.description,
      theme: ThemeModel.apiTemplateFromModel(newThemeData.template),
    }
    return this.withAuth().post<APITheme>(
      `/team/api/company/${companyUUID}/themes/`,
      themeDataPayload,
    )
  }

  /**
   * Updates an existing theme under a specific company.
   *
   * @param companyUUID
   * @param themeUUID
   * @param changes payload of the changes, including name, description, and theme template object data
   */
  async updateTheme(
    companyUUID: UUID,
    themeUUID: UUID,
    changes: ThemeDataPayload,
  ) {
    const apiChanges = {
      name: changes.name,
      description: changes.description,
      theme: ThemeModel.apiTemplateFromModel(changes.template),
    }
    return this.withAuth().patch<APITheme>(
      `/team/api/company/${companyUUID}/themes/${themeUUID}/`,
      apiChanges,
    )
  }

  /**
   * Removes an existing theme. This action permanently removes the theme itself, not just the relationship between
   * itself and the company.
   *
   * @param companyUUID
   * @param themeUUID
   */
  async removeTheme(companyUUID: UUID, themeUUID: UUID) {
    return this.withAuth().delete(
      `/team/api/company/${companyUUID}/themes/${themeUUID}/`,
    )
  }
}
