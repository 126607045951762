import uniqueId from 'lodash/uniqueId'
import mixpanel, {
  Mixpanel,
  Dict,
  RequestOptions,
  Callback,
} from 'mixpanel-browser'

/**
 * Wrapper client for interacting with the Mixpanel library.
 */
export class MixpanelClient {
  private instance: Mixpanel

  constructor(token: string) {
    this.instance = mixpanel.init(
      token,
      { api_host: 'https://analytics.ingage.io' },
      uniqueId(),
    )

    // We manually set super props on every session,
    // making it safer to reset them on every session
    this.instance.reset()
  }

  identify(id: string) {
    this.instance.identify(id)
  }

  timeEvent(event: string) {
    this.instance.time_event(event)
  }

  track(
    event: string,
    props?: Dict,
    optionsOrCallback?: RequestOptions | Callback,
    callback?: Callback,
  ) {
    this.instance.track(event, props, optionsOrCallback, callback)
  }

  register(props: Dict) {
    this.instance.register(props)
  }

  setPeople(props: Dict) {
    this.instance.people.set(props)
  }
}
