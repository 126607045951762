import BaseAPI from 'src/api/BaseAPI'
import { UUID } from 'src/util/types'

export default class IntegrationAPI extends BaseAPI {
  async getHubspotCompanyProperties(companyUUID: UUID) {
    const url = `/team/api/company/${companyUUID}/integrations/hubspot/get_company_properties/`
    return this.withAuth().get(url)
  }

  async getHubspotCompanies(companyUUID: UUID, properties: string) {
    const url = `/team/api/company/${companyUUID}/integrations/hubspot/get_companies/`
    const postBody = { properties: properties }
    return this.withAuth().post(url, postBody)
  }

  async getHubspotCompany(companyUUID: UUID, properties: string) {
    const url = `/team/api/company/${companyUUID}/integrations/hubspot/get_company/`
    const postBody = { properties: properties }
    return this.withAuth().post(url, postBody)
  }
}
