import { AxiosRequestConfig } from 'axios'
import BaseAPI from 'src/api/BaseAPI'

export default class GenericExternalAPI extends BaseAPI {
  /**
   * Performs a generic file upload to an external endpoint. Used for uploading files to signed S3 upload URLs.
   *
   * @param file
   * @param url
   * @param onUploadProgress
   */
  async upload(
    file: File,
    url: string,
    onUploadProgress?: (progressEvent: ProgressEvent) => void,
  ) {
    const config: AxiosRequestConfig = {
      timeout: 0,
      baseURL: '',
      headers: { 'Content-Type': file.type },
      onUploadProgress,
    }
    const data = await file.arrayBuffer()
    return this.withoutAuth().put(url, data, config)
  }
}
