import { Stub, Config, upgrade } from '../../../../';

export default (stub: Stub, config: Config): Stub => {
  // 3 is the Latest version so recursive update only
  if (config.recursive) {
    if (stub.theme) {
        stub.theme = upgrade(stub.theme, config);
    }
    if (stub.legacyStyles) {
      stub.legacyStyles = upgrade(stub.legacyStyles, config);
    }
    if (stub.storyBarStyles) {
      stub.storyBarStyles = upgrade(stub.storyBarStyles, config);
    }    
  }

  return stub;
};
