/**
 * Ported to Typescript because CoreUI hasn't created type definitions yet.
 * --------------------------------------------------------------------------
 * Licensed under MIT (https://coreui.io/license)
 * @returns {string} css custom property name
 * --------------------------------------------------------------------------
 */

export namespace StyleUtil {
  // control to switch to overlaid sidenavbar
  export const viewportOverrideWidth = 821

  export const getCssCustomProperties = () => {
    const cssCustomProperties: { [key: string]: string } = {}
    const sheets = document.styleSheets
    let cssText = ''
    for (let i = sheets.length - 1; i > -1; i--) {
      const rules = sheets[i].cssRules
      for (let j = rules.length - 1; j > -1; j--) {
        if ((rules[j] as any).selectorText === '.ie-custom-properties') {
          // eslint-disable-next-line prefer-destructuring
          cssText = rules[j].cssText
          break
        }
      }

      if (cssText) {
        break
      }
    }

    cssText = cssText.substring(
      cssText.lastIndexOf('{') + 1,
      cssText.lastIndexOf('}'),
    )

    cssText.split(';').forEach((property) => {
      if (property) {
        const name = property.split(': ')[0]
        const value = property.split(': ')[1]
        if (name && value) {
          cssCustomProperties[`--${name.trim()}`] = value.trim()
        }
      }
    })
    return cssCustomProperties
  }

  export const getStyle = (property: string, element = document.body) => {
    const minIEVersion = 10
    const isIE1x = () =>
      Boolean((document as any).documentMode) &&
      (document as any).documentMode >= minIEVersion
    const isCustomProperty = (property: string) => property.match(/^--.*/i)

    let style

    if (isCustomProperty(property) && isIE1x()) {
      const cssCustomProperties = getCssCustomProperties()
      style = cssCustomProperties[property]
    } else {
      style = window
        .getComputedStyle(element, null)
        .getPropertyValue(property)
        .replace(/^\s/, '')
    }

    return style
  }

  export const hexToRgba = (color: string, opacity = 100) => {
    if (typeof color === 'undefined') {
      throw new TypeError('Hex color is not defined')
    }

    const hex = color.match(/^#(?:[0-9a-f]{3}){1,2}$/i)

    if (!hex) {
      throw new Error(`${color} is not a valid hex color`)
    }

    let r
    let g
    let b

    if (color.length === 7) {
      r = parseInt(color.slice(1, 3), 16)
      g = parseInt(color.slice(3, 5), 16)
      b = parseInt(color.slice(5, 7), 16)
    } else {
      r = parseInt(color.slice(1, 2), 16)
      g = parseInt(color.slice(2, 3), 16)
      b = parseInt(color.slice(3, 5), 16)
    }

    return `rgba(${r}, ${g}, ${b}, ${opacity / 100})`
  }

  export const getColorShade = (color: string, increment: number) => {
    // get shades of a provided color by provided increments
    return `#${color
      .replace(/^#/, '')
      .replace(/../g, (color) =>
        (
          '0' +
          Math.min(255, Math.max(0, parseInt(color, 16) + increment)).toString(
            16,
          )
        ).substr(-2),
      )}`
  }
}
