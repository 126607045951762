import { APIAPIKey } from 'src/model/api/automation/APIAPIKey'
import { APIGetAutomateResponse } from 'src/model/api/automation/APIGetAutomateResponse'
import { GetAutomateResponse } from 'src/model/automation/GetAutomateResponse'
import { APIKey } from './APIKey'

export namespace AutomationModel {
  /**
   * Returns client-side data model representing an API Key sent to us from the Public API. The naming here is very
   * Abbott and Costello, but in order to be consistent with our naming, this is what this function does:
   *
   * Take an API Model returned from the API server called "APIAPIKey", and convert it to a UI Model (used client-side)
   * called "APIKey" -- the model in question representing an API key.
   *
   * @param apiApiKey
   */
  export const apiKeyFromAPIModel = (apiApiKey: APIAPIKey) => {
    const apiKey: APIKey = {
      uuid: apiApiKey.uuid,
      key: apiApiKey.key,
      name: apiApiKey.name,
      createdAt: apiApiKey.created_at,
    }
    return apiKey
  }

  export const getAutomateResponseFromAPIModel = (
    apiGetAutomateResponse: APIGetAutomateResponse,
  ) => {
    const getAutomateResponse: GetAutomateResponse = {
      wasSuccessful: apiGetAutomateResponse.ok,
      presentation: {
        shareAlias: apiGetAutomateResponse.presentation.share_alias,
        tags: apiGetAutomateResponse.presentation.tags,
        versionCreatedAt:
          apiGetAutomateResponse.presentation.version_created_at,
        versionName: apiGetAutomateResponse.presentation.version_name,
        versionUpdatedAt:
          apiGetAutomateResponse.presentation.version_updated_at,
      },
      responseMetadata: apiGetAutomateResponse.response_metadata,
    }
    return getAutomateResponse
  }
}
