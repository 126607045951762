export function defaultPopupTheme() {
  return {
    type: 'popupTheme',
    version: 1,
    cornerRadius: 8,
    shadow: { offsetX: 0, offsetY: 2, blur: 20, opacity: 0.3 },
    showScrim: true,
    closeButton: true,
    closeButtonColor: 'ffffff',
    closeButtonBackground: { style: "blur", blurStyle: "dark" }
  }
}