import BaseAPI from './BaseAPI'

export default class ExternalAuthAPI extends BaseAPI {
  async validateGafContractor(
    api_key: string,
    encoded_email: string,
    organization_uuid: string,
  ) {
    const config = {
      headers: {
        APIKEY: api_key,
      },
    }
    return this.V2().withPublicAPIKey(api_key).post(
      '/pubapi/gaf/get_external_contractor/',
      {
        organization_uuid: organization_uuid,
        encoded_email: encoded_email,
      },
      config,
    )
  }
}
