import { Stub, Config } from '../../../../';
import getDefaults from '../defaults/v2'

export default (stub: Stub, config: Config): Stub => {
    // Upgrades from 1 -> 2
    stub.version = 2;
    const defaults = getDefaults()

    if (!stub.textStyles) {
      stub.textStyles = defaults.textStyles
    }

    return stub;
};
