import { makeAutoObservable } from 'mobx'
import { RootStore } from './RootStore'
import { BaseStore } from './BaseStore'

export class SettingsStore implements BaseStore {
  rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this, { rootStore: false })
    // makePersistable(this, {
    //   name: 'TeamsSettings',
    //   properties: [],
    //   storage: window.localStorage,
    // }).then((r) => {
    //   console.debug('Persisting data for SettingsStore')
    // })
  }
}
