import { Stub, Config, upgrade } from '../../../../';

export default (stub: Stub, config: Config): Stub => {
    stub.version = 4;
    stub.isEditable = true;

    if (config.recursive) {
        stub.headingText = upgrade(stub.headingText, config);
        stub.scrollmotion = upgrade(stub.scrollmotion, config);
        stub.buttonOverlay = upgrade(stub.buttonOverlay, config);
        stub.buttonTextOverlay = upgrade(stub.buttonTextOverlay, config);
        stub.bodyText = upgrade(stub.bodyText, config);
    }
    return stub;
};
