import { Stub, Config, upgrade } from '../../../..';

export default (stub: Stub, config: Config): Stub => {
    stub.version = 2

    if (config.recursive) {
        const upgradedItems = [];
        for (let i = 0; i < stub.items.length; i++) {
            upgradedItems.push(upgrade(stub.items[i], config));
        }
        stub.items = upgradedItems;
    }
    return stub;
};
