import { Stub, Config, upgrade } from '../../../../';

export default (stub: Stub, config: Config): Stub => {
    stub.version = 2;
    stub.theme = {
        mode: 'light',
        baseColor: 'transparent',
    };

    if (stub.dynamicBox.blurStyle == 'dark') {
        stub.theme.mode = 'dark';
    }

    if (config.recursive) {
        stub.subheadingText = upgrade(stub.subheadingText, config);
        stub.dynamicBox = upgrade(stub.dynamicBox, config);
        stub.backgroundMedia = upgrade(stub.backgroundMedia, config);
        stub.buttonOverlay = upgrade(stub.buttonOverlay, config);
        stub.bodyText = upgrade(stub.bodyText, config);
    }

    return stub;
};
