export default function () {
  return {
    "type": "legacy-styles",
    "version": 2,
    "backgroundColor": "#ffffff",
    "buttonTintColor": "#36a2e5",
    "heading": {
      "type": "legacy-font-definition",
      "version": 1,
      "color": "#000000",
      "font": "benton-sans",
      "style": "title",
      "weight": "bold",
      "size": "medium"
    },
    "subheading": {
      "type": "legacy-font-definition",
      "version": 1,
      "color": "#2a292f",
      "font": "benton-sans",
      "style": "title",
      "weight": "bold",
      "size": "small"
    },
    "title": {
      "type": "legacy-font-definition",
      "version": 1,
      "color": "#2a292f",
      "font": "benton-sans",
      "style": "header",
      "weight": "bold",
      "size": "small"
    },
    "body": {
      "type": "legacy-font-definition",
      "version": 1,
      "color": "#000000",
      "font": "benton-sans",
      "style": "body",
      "weight": "regular",
      "size": "medium"
    },
    "impactHeading": {
      "type": "legacy-font-definition",
      "version": 1,
      "color": "#2a292f",
      "font": "benton-sans",
      "style": "title",
      "weight": "bold",
      "size": "medium"
    },
    "impactBody": {
      "type": "legacy-font-definition",
      "version": 1,
      "color": "#000000",
      "font": "benton-sans",
      "style": "body",
      "weight": "regular",
      "size": "medium"
    },
    "modalTitle": {
      "type": "legacy-font-definition",
      "version": 1,
      "color": "#000000",
      "font": "benton-sans",
      "style": "title",
      "weight": "bold",
      "size": "medium"
    },
    "modalBody": {
      "type": "legacy-font-definition",
      "version": 1,
      "color": "#000000",
      "font": "benton-sans",
      "style": "body",
      "weight": "regular",
      "size": "small"
    },
    "modalBackgroundColor": "#ffffff"
  }
}