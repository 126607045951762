import { Stub, Config } from '../../../..';

export default (stub: Stub, config: Config): Stub => {
  stub.version = 4;

  if (stub.playbackOptions) {
    stub.playbackOptions.shouldLoop = stub.playbackOptions.shouldAutoPlay ?? true
  }

  return stub;
};