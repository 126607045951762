import { CSpinner } from '@coreui/react-pro'
import * as Sentry from '@sentry/react'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ExternalAnalyticsEvent } from 'src/model/external-analytics/ExternalAnalyticsEvent'
import { useTeamsStore, useUserStore } from 'src/store'
import { ExternalAnalyticsUtil } from 'src/util/analytics/ExternalAnalyticsUtil'
import { UUID } from 'src/util/types'
import { UserUtil } from 'src/util/UserUtil'

type AutoLoginFromClientProps = {
  accessToken: string | null
  userUUID: UUID | null
  companyUUID: UUID | null
  platform: string | null
}

const AutoLoginFromClient = (props: AutoLoginFromClientProps) => {
  const { accessToken, userUUID, companyUUID, platform } = props

  const navigate = useNavigate()
  const userStore = useUserStore()
  const teamsStore = useTeamsStore()

  const didFirstCompanyFetch = useRef(false)

  const [wasPostLoginSuccessful, setWasPostLoginSuccessful] = useState(false)
  const [isLoggingIn, setIsLoggingIn] = useState(true)
  const [isFetchingCompanies, setIsFetchingCompanies] = useState(false)

  const revokeLogin = useCallback(async () => {
    await userStore.logout()
    navigate('/auth')
  }, [navigate, userStore])

  const getPostLoginData = useCallback(async () => {
    const userDetails = await userStore.getUserByUUID(userUUID!)
    if (!userDetails) {
      revokeLogin()
      return console.error(`Unable to find user with UUID ${userUUID}.`)
    }
    setIsFetchingCompanies(true)
    await teamsStore.getCompanies()
    await teamsStore.getAllTeams()
    didFirstCompanyFetch.current = true
    setIsFetchingCompanies(false)

    const companyUser = teamsStore.companyUsersMap[companyUUID!].find(
      (u) => u.user.id === userUUID,
    )
    if (!companyUser) {
      revokeLogin()
      return console.error(
        `Unable to associate with UUID ${userUUID} with a Company User in company ${companyUUID}.`,
      )
    }

    userStore.setProfile({
      email: userDetails.email,
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      fullName: userDetails.lastName,
      id: userDetails.id,
      isCompanyAdmin: companyUser.role.isAdmin,
      isCompanyAnalyst: companyUser.role.canViewTeamAnalytics,
      isTeamAdmin: companyUser.role.canImportUsers,
      isInternal: userDetails.isInternal,
      isPaid: userDetails.isPaid,
      url: userDetails.url,
      eulaAgreedAt: userDetails.eulaAgreedAt,
      features: userDetails.features,
    })
    setWasPostLoginSuccessful(true)
  }, [companyUUID, revokeLogin, teamsStore, userStore, userUUID])

  const loginUsingAccessToken = useCallback(async () => {
    userStore.setAccessToken(accessToken!)
    userStore.setAuthCheckTimestamp(UserUtil.getDefaultAuthCheckTimestamp())
    userStore.setLoginPlatform(platform ?? 'unknown')
    userStore.setLoginCompanyUUID(companyUUID ?? '')
    if (userStore.profile) {
      Sentry.setUser({ id: userStore.profile.id })
    }

    // TODO: Move to using a GetCompanyUserByCompanyUserUUID endpoint so we don't have auth-related tasks depending on a broad search.
    // Even though users should only have 1 entry, do a cursory search to match on user UUID to make absolutely sure
    // we're not falsely matching on someone who put the user's UUID in another searchable field.
    await teamsStore.getCompanyUsers(companyUUID!, { includeTeams: false })

    await getPostLoginData()
    // if no valid path is provided App.tsx will route the user to the Dashboard

    setIsLoggingIn(false)

    // set this last to reload the route after all of the data is loaded
    userStore.setIsConsoleAuthenticated(true)
    userStore.setIsAuthenticated(true)
  }, [
    accessToken,
    companyUUID,
    getPostLoginData,
    platform,
    teamsStore,
    userStore,
  ])

  useEffect(() => {
    console.debug('Automatically logging in with given URL parameters.')
    loginUsingAccessToken()
  }, [loginUsingAccessToken])

  useEffect(() => {
    if (wasPostLoginSuccessful) {
      if (userStore.profile?.id) {
        ExternalAnalyticsUtil.trackEvent(
          ExternalAnalyticsEvent.LOGIN,
          userStore.profile,
          {
            method: 'remote',
            platform,
          },
        )
      }
    }
  }, [wasPostLoginSuccessful, platform, userStore.profile])

  if (!accessToken || !userUUID || !companyUUID || !platform) return <></>

  return (
    <React.Fragment>
      {(isLoggingIn || isFetchingCompanies) && (
        <div className="c-app c-default-layout c-dark-theme">
          <div className="c-wrapper">
            <div className="c-body">
              <div className="w-100 vh-100 d-flex align-items-center justify-content-center flex-column">
                <CSpinner className="mb-3" />
                {isLoggingIn && <span>Logging in…</span>}
                {isFetchingCompanies && (
                  <span>Getting company information…</span>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}
export default observer(AutoLoginFromClient)
