import { Stub, Config, upgrade } from '../../../../';

export default (stub: Stub, config: Config): Stub => {
    stub.version = 4;

    if (stub.pageName === undefined) {
        stub.pageName = null;
    }

    if (stub.buttonTextOverlay == null) {
        stub.buttonTextOverlay = {
            type: 'overlay-component',
            version: 2,
            items: [],
        };
    }

    if (config.recursive) {
        stub.headingText = upgrade(stub.headingText, config);
        stub.gallery = upgrade(stub.gallery, config);
        stub.bodyText = upgrade(stub.bodyText, config);
        stub.buttonTextOverlay = upgrade(stub.buttonTextOverlay, config);
    }

    return stub;
};
