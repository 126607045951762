import { AxiosRequestConfig } from 'axios'

import { UUID } from 'src/util/types'
import { RootStore } from 'src/store/RootStore'
import { DynamicKeysModel } from 'src/model/dynamickeys'
import { CustomFieldsModel } from 'src/model/customfields'
import { DynamicKeyUpdateProps } from 'src/model/dynamickeys/DynamicKeyUpdateProps'
import { DynamicKeyCreateProps } from 'src/model/dynamickeys/DynamicKeyCreateProps'
import { CustomFieldUpdateProps } from 'src/model/customfields/CustomFieldUpdateProps'
import { CustomFieldCreateProps } from 'src/model/customfields/CustomFieldCreateProps'
import { CustomFieldValueCreateProps } from 'src/model/customfields/CustomFieldValueCreateProps'

export class DynamicContentStore {
  rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  async createCustomField(
    companyUUID: UUID,
    customFieldCreateProps: CustomFieldCreateProps,
  ) {
    const apiCustomFieldCreateProps =
      CustomFieldsModel.apiCustomFieldCreatePropsFromModel(
        customFieldCreateProps,
      )
    const response = await this.rootStore
      .getAPIClient()
      .dynamicContentAPI.createCustomField(
        companyUUID,
        apiCustomFieldCreateProps,
      )
    return CustomFieldsModel.customFieldFromAPIModel(response.data)
  }

  async updateCustomField(
    companyUUID: UUID,
    customFieldUUID: UUID,
    customFieldUpdateProps: CustomFieldUpdateProps,
    options?: AxiosRequestConfig,
  ) {
    const apiCustomFieldUpdateProps =
      CustomFieldsModel.apiCustomFieldUpdatePropsFromModel(
        customFieldUpdateProps,
      )
    const response = await this.rootStore
      .getAPIClient()
      .dynamicContentAPI.updateCustomField(
        companyUUID,
        customFieldUUID,
        apiCustomFieldUpdateProps,
        options,
      )
    return CustomFieldsModel.customFieldFromAPIModel(response.data)
  }

  async deleteCustomField(
    companyUUID: UUID,
    customFieldUUID: UUID,
    options?: AxiosRequestConfig,
  ) {
    await this.rootStore
      .getAPIClient()
      .dynamicContentAPI.deleteCustomField(
        companyUUID,
        customFieldUUID,
        options,
      )
  }

  async createCustomFieldValue(
    customFieldValueCreateProps: CustomFieldValueCreateProps,
    options?: AxiosRequestConfig,
  ) {
    const apiCustomFieldValueCreateProps =
      CustomFieldsModel.apiCustomFieldValueCreatePropsFromModel(
        customFieldValueCreateProps,
      )
    const response = await this.rootStore
      .getAPIClient()
      .dynamicContentAPI.createCustomFieldValue(
        apiCustomFieldValueCreateProps,
        options,
      )
    return CustomFieldsModel.customFieldValueFromAPIModel(response.data)
  }

  async createCustomFieldImageValue(
    file: File,
    customFieldValueCreateProps: CustomFieldValueCreateProps,
    options?: AxiosRequestConfig,
  ) {
    const apiCustomFieldValueCreateProps =
      CustomFieldsModel.apiCustomFieldValueCreatePropsFromModel(
        customFieldValueCreateProps,
      )
    const response = await this.rootStore
      .getAPIClient()
      .dynamicContentAPI.createCustomFieldImageValue(
        apiCustomFieldValueCreateProps,
        options,
      )
    await fetch(response.data.upload_url, { method: 'PUT', body: file })
    return CustomFieldsModel.customFieldValueFromAPIModel(response.data)
  }

  async updateCustomFieldValue(
    customFieldValueUUID: UUID,
    value: string,
    options?: AxiosRequestConfig,
  ) {
    const reponse = await this.rootStore
      .getAPIClient()
      .dynamicContentAPI.updateCustomFieldValue(
        customFieldValueUUID,
        value,
        options,
      )
    return CustomFieldsModel.customFieldValueFromAPIModel(reponse.data)
  }

  async getCustomFieldValue(
    customFieldValueUUID: UUID,
    options?: AxiosRequestConfig,
  ) {
    const response = await this.rootStore
      .getAPIClient()
      .dynamicContentAPI.getCustomFieldValue(customFieldValueUUID, options)
    return CustomFieldsModel.customFieldValueFromAPIModel(response.data)
  }

  async deleteCustomFieldValue(
    customFieldValueUUID: UUID,
    options?: AxiosRequestConfig,
  ) {
    await this.rootStore
      .getAPIClient()
      .dynamicContentAPI.deleteCustomFieldValue(customFieldValueUUID, options)
  }

  async createDynamicKey(
    companyUUID: UUID,
    dynamicKeyCreateProps: DynamicKeyCreateProps,
  ) {
    const apiDynamicKeyCreateProps =
      DynamicKeysModel.apiDynamicKeyCreatePropsFromModel(dynamicKeyCreateProps)
    const response = await this.rootStore
      .getAPIClient()
      .dynamicContentAPI.createDynamicKey(companyUUID, apiDynamicKeyCreateProps)
    return DynamicKeysModel.dynamicKeyFromAPIModel(response.data)
  }

  async updateDynamicKey(
    companyUUID: UUID,
    dynamicKeyUUID: UUID,
    dynamicKeyUpdateProps: DynamicKeyUpdateProps,
    options?: AxiosRequestConfig,
  ) {
    const apiDynamicKeyUpdateProps =
      DynamicKeysModel.apiDynamicKeyUpdatePropsFromModel(dynamicKeyUpdateProps)
    const response = await this.rootStore
      .getAPIClient()
      .dynamicContentAPI.updateDynamicKey(
        companyUUID,
        dynamicKeyUUID,
        apiDynamicKeyUpdateProps,
        options,
      )
    return DynamicKeysModel.dynamicKeyFromAPIModel(response.data)
  }

  async deleteDynamicKey(
    companyUUID: UUID,
    dynamicKeyUUID: UUID,
    options?: AxiosRequestConfig,
  ) {
    await this.rootStore
      .getAPIClient()
      .dynamicContentAPI.deleteDynamicKey(companyUUID, dynamicKeyUUID, options)
  }
}
