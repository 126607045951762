import { makeAutoObservable } from 'mobx'

import { BaseStore } from 'src/store/BaseStore'
import { RootStore } from 'src/store/RootStore'

type ModalProps = {
  type: 'install-app' | 'user-profile'
}

export class AppModalStore implements BaseStore {
  rootStore: RootStore

  modal: ModalProps | null = null

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this, { rootStore: false })
  }

  openModal(modal: ModalProps | null) {
    this.modal = modal
  }

  closeModal() {
    this.modal = null
  }

  clearData() {
    this.modal = null
  }
}
