import { Stub, Config, upgrade } from '../../../../';

export default (stub: Stub, config: Config): Stub => {
    stub.version = 5;

    if (config.recursive) {
        stub.subheadingText = upgrade(stub.subheadingText, config);
        stub.dynamicBox = upgrade(stub.dynamicBox, config);
        stub.backgroundMedia = upgrade(stub.backgroundMedia, config);
        stub.buttonOverlay = upgrade(stub.buttonOverlay, config);
        stub.bodyText = upgrade(stub.bodyText, config);
        stub.buttonTextOverlay = upgrade(stub.buttonTextOverlay, config);
    }
    return stub;
};
