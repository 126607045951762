import { AxiosRequestConfig } from 'axios'
import { makeAutoObservable } from 'mobx'
import { makePersistable } from 'mobx-persist-store'
import { APIPresentation } from 'src/model/api/presentation/APIPresentation'
import { APIPresentationVersion } from 'src/model/api/presentation/APIPresentationVersion'
import { APIPresentationBatchAssetResponse } from 'src/model/api/presentation/APIPresentationBatchAssetResponse'
import { APIUser } from 'src/model/api/user/APIUser'
import { PresentationsModel } from 'src/model/presentation/PresentationsModel'
import { Presentation } from 'src/model/presentation/Presentation'
import { PresentationPermissionField } from 'src/model/presentation/PresentationPermissionField'
import { ThumbnailCache } from 'src/model/presentation/ThumbnailCache'
import { ThumbnailCacheItem } from 'src/model/presentation/ThumbnailCacheItem'
import { UserModel } from 'src/model/user/UserModel'
import { RootStore } from 'src/store/RootStore'
import { ShareType, UUID } from 'src/util/types'
import { PresentationWithCurrentVersion } from 'src/model/presentation/PresentationWithCurrentVersion'
import { TeamsUtil } from 'src/util/TeamsUtil'
import { BaseStore } from './BaseStore'

export class PresentationStore implements BaseStore {
  static PRESENTATIONS_STORE_STORAGE_KEY = 'TeamsPresentations'
  rootStore: RootStore
  thumbnailCache: ThumbnailCache

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.thumbnailCache = {}
    makeAutoObservable(this, { rootStore: false })
    makePersistable(this, {
      name: PresentationStore.PRESENTATIONS_STORE_STORAGE_KEY,
      properties: ['thumbnailCache'],
      storage: window.localStorage,
    }).then((r) => {
      console.debug(
        `Persisting data for ${PresentationStore.PRESENTATIONS_STORE_STORAGE_KEY}`,
      )
    })
  }

  /**
   * Composite method to get all presentations (all pages if the responses are paginated), and fetch their current
   * versions. Eventually this will become deprecated one paginated API requests support more reobust sorting.
   *
   * @param companyUUID
   * @param shouldShowArchived
   */
  async getPresentationsAndCurrentVersion(
    companyUUID: UUID,
    shouldShowArchived: boolean,
  ) {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .presentationsAPI.getPresentations(companyUUID, { shouldShowArchived })
      if (res) {
        const presentations = res.map((apiPresentation: APIPresentation) =>
          PresentationsModel.presentationFromAPIModel(apiPresentation),
        )

        // ensure all presentations have a current_version
        const validAPIPresentations = presentations.filter(
          (p) => p.currentVersion,
        )
        return validAPIPresentations
      }
    } catch (e) {
      console.error(
        `Unable to fetch presentations for company ${companyUUID}`,
        e,
      )
    }
    return []
  }

  /**
   * Composite method to get a page of presentations and fetch their current versions.
   * Eventually this will become deprecated one paginated API requests support more robust sorting.
   *
   * @param companyUUID
   * @param page
   * @param pageSize
   * @param config
   */
  async getPaginatedPresentationsWithCurrentVersionForCompany(
    companyUUID: UUID,
    page: number,
    pageSize: number,
    config: AxiosRequestConfig,
  ): Promise<{
    totalCount: number
    presentations: PresentationWithCurrentVersion[]
  }> {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .presentationsAPI.getPresentationsPageForCompany(
          companyUUID,
          page,
          pageSize,
          config,
        )

      if (res) {
        const presentations = res.data.map((apiPresentation: APIPresentation) =>
          PresentationsModel.presentationFromAPIModel(apiPresentation),
        )

        // ensure all presentations have a currentVersion
        const validPresentations = presentations.filter(
          (p): p is PresentationWithCurrentVersion => p.currentVersion != null,
        )

        return {
          totalCount: res.headers['total-count']
            ? parseInt(res.headers['total-count'])
            : 0,
          presentations: validPresentations,
        }
      }
    } catch (e) {
      console.error(
        `Unable to fetch presentations for company ${companyUUID}`,
        e,
      )
    }

    return {
      totalCount: 0,
      presentations: [],
    }
  }

  /**
   * Composite method to get a page of presentations and fetch their current versions.
   * Eventually this will become deprecated one paginated API requests support more robust sorting.
   *
   * @param companyUUID
   * @param teamUUID
   * @param page
   * @param pageSize
   * @param config
   */
  async getPaginatedPresentationsWithCurrentVersionForTeam(
    companyUUID: UUID,
    teamUUID: UUID,
    page: number,
    pageSize: number,
    config: AxiosRequestConfig,
  ): Promise<{
    totalCount: number
    presentations: PresentationWithCurrentVersion[]
  }> {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .presentationsAPI.getPresentationsPageForTeam(
          companyUUID,
          teamUUID,
          page,
          pageSize,
          config,
        )

      if (res) {
        const presentations = res.data.map((apiPresentation: APIPresentation) =>
          PresentationsModel.presentationFromAPIModel(apiPresentation),
        )

        // ensure all presentations have a currentVersion
        const validPresentations = presentations.filter(
          (p): p is PresentationWithCurrentVersion => p.currentVersion != null,
        )

        return {
          totalCount: res.headers['total-count']
            ? parseInt(res.headers['total-count'])
            : 0,
          presentations: validPresentations,
        }
      }
    } catch (e) {
      console.error(
        `Unable to fetch presentations for company ${companyUUID} and team ${teamUUID}`,
        e,
      )
    }

    return {
      totalCount: 0,
      presentations: [],
    }
  }

  /**
   * Returns all presentations (all pages if the responses are paginated). Eventually this will become deprecated one
   * paginated API requests support more robust sorting.
   *
   * @param companyUUID
   */
  async getPresentations(companyUUID: UUID): Promise<Presentation[]> {
    try {
      const apiPresentations = await this.rootStore
        .getAPIClient()
        .presentationsAPI.getPresentations(companyUUID)
      return apiPresentations.map((apiPresentation) =>
        PresentationsModel.presentationFromAPIModel(apiPresentation),
      )
    } catch (e) {
      console.error(
        `Unable to fetch presentations for company ${companyUUID}`,
        e,
      )
    }
    return []
  }

  /**
   * Returns details for a presentation under a company.
   *
   * @param companyUUID
   * @param presentationUUID
   * @param teamUUID
   */
  async getCompanyPresentation(
    companyUUID: UUID,
    presentationUUID: UUID,
    shouldIncludeVersions = true,
    isArchived = false,
    config?: AxiosRequestConfig,
  ): Promise<Presentation | undefined> {
    try {
      const presentationRes = await this.rootStore
        .getAPIClient()
        .presentationsAPI.getCompanyPresentation(
          companyUUID,
          presentationUUID,
          shouldIncludeVersions,
          isArchived,
        )
      if (presentationRes.data) {
        return PresentationsModel.presentationFromAPIModel(presentationRes.data)
      }
    } catch (e) {
      if (!config?.signal?.aborted) {
        console.error(
          `Unable to fetch details for presentation ${presentationUUID}`,
          e,
        )
      }
    }
    return undefined
  }

  /**
   * Returns details for a presentation under a team.
   *
   * @param companyUUID
   * @param teamUUID
   * @param presentationUUID
   */
  async getTeamPresentation(
    companyUUID: UUID,
    teamUUID: UUID,
    presentationUUID: UUID,
    shouldIncludeVersions = true,
    isArchived = false,
    config?: AxiosRequestConfig,
  ): Promise<Presentation | undefined> {
    try {
      const presentationRes = await this.rootStore
        .getAPIClient()
        .presentationsAPI.getTeamPresentation(
          companyUUID,
          teamUUID,
          presentationUUID,
          shouldIncludeVersions,
          isArchived,
        )
      if (presentationRes.data) {
        return PresentationsModel.presentationFromAPIModel(presentationRes.data)
      }
    } catch (e) {
      if (!config?.signal?.aborted) {
        console.error(
          `Unable to fetch details for presentation ${presentationUUID}`,
          e,
        )
      }
    }
    return undefined
  }

  /**
   * Retrieves details for a single presentation by its UUID alone, without specifying a Company. Useful for when user
   * roles--like Creator--don't have access to endpoints like /team/api/company/:company_uuid/contents/:content_uuid/,
   * but we know that the user likely has access to the presentation, like in instances where they install Marketplace
   * content.
   *
   * @param presentationUUID
   * @param shouldIncludeVersions
   * @param isArchived
   */
  async getPresentationByUUID(
    presentationUUID: UUID,
    shouldIncludeVersions = true,
    isArchived = false,
    config?: AxiosRequestConfig,
  ): Promise<Presentation | undefined> {
    try {
      const presentationRes = await this.rootStore
        .getAPIClient()
        .presentationsAPI.getPresentationByUUID(
          presentationUUID,
          shouldIncludeVersions,
          isArchived,
          config,
        )
      if (presentationRes && presentationRes.data) {
        return PresentationsModel.presentationFromAPIModel(presentationRes.data)
      }
    } catch (e) {
      console.error(
        `Unable to fetch presentations for company ${presentationUUID}`,
        e,
      )
    }
    return undefined
  }

  /**
   * Returns a list of presentations with draft versions for the given company
   *
   * Uses the v2 API endpoint that returns a presentation with published and draft versions.
   *
   * @param companyUUID
   * @param page
   * @param pageSize
   * @param config
   */
  async getPaginatedPresentationsWithDraftVersionForCompany(
    companyUUID: UUID,
    page: number,
    pageSize: number,
    config: AxiosRequestConfig,
  ) {
    const response = await this.rootStore
      .getAPIClient()
      .presentationsAPI.getPaginatedPresentationsWithDraftVersionForCompany(
        companyUUID,
        page,
        pageSize,
        config,
      )

    return response.data.map((apiPresentation) =>
      PresentationsModel.presentationWithDraftVersionFromAPIModel(
        apiPresentation,
      ),
    )
  }

  /**
   * Returns a list of presentations with draft versions for the given team
   *
   * Uses the v2 API endpoint that returns a presentation with published and draft versions.
   *
   * @param companyUUID
   * @param teamUUID
   * @param page
   * @param pageSize
   * @param config
   * @returns
   */
  async getPaginatedPresentationsWithDraftVersionForTeam(
    companyUUID: UUID,
    teamUUID: UUID,
    page: number,
    pageSize: number,
    config: AxiosRequestConfig,
  ) {
    const response = await this.rootStore
      .getAPIClient()
      .presentationsAPI.getPaginatedPresentationsWithDraftVersionForTeam(
        companyUUID,
        teamUUID,
        page,
        pageSize,
        config,
      )

    return response.data.map((apiPresentation) =>
      PresentationsModel.presentationWithDraftVersionFromAPIModel(
        apiPresentation,
      ),
    )
  }

  /**
   * Returns details for a presentation under a given presentation UUID.
   *
   * Uses the v2 API endpoint that returns a presentation with published and draft versions.
   *
   * @param presentationUUID
   * @param shouldIncludeVersions
   * @param config
   */
  async getPresentationWithDraftVersionDetail(
    presentationUUID: UUID,
    shouldIncludeVersions = true,
    config?: AxiosRequestConfig,
  ) {
    try {
      const response = await this.rootStore
        .getAPIClient()
        .presentationsAPI.getPresentationWithDraftVersionDetail(
          presentationUUID,
          shouldIncludeVersions,
          config,
        )

      return PresentationsModel.presentationWithDraftVersionFromAPIModel(
        response.data,
      )
    } catch (e) {
      console.error(
        `Unable to fetch presentation detail for ${presentationUUID}`,
        e,
      )
    }
    return undefined
  }

  async getPaginatedPresentationVersions(
    presentationUUID: UUID,
    page: number,
    pageSize: number,
    config?: AxiosRequestConfig,
  ) {
    const response = await this.rootStore
      .getAPIClient()
      .presentationsAPI.getPaginatedPresentationVersions(
        presentationUUID,
        page,
        pageSize,
        config,
      )

    return {
      totalCount: response.headers['total-count']
        ? parseInt(response.headers['total-count'])
        : 0,
      versions: response.data.map((apiPresentationVersion) =>
        PresentationsModel.presentationVersionFromAPIModel(
          apiPresentationVersion,
        ),
      ),
    }
  }

  /**
   * Returns details for a presentation under a given share alias
   * .
   * @param shareAlias
   */
  async getPresentationInfoByShareAlias(shareAlias: string) {
    try {
      const infoRes = await this.rootStore
        .getAPIClient()
        .presentationsAPI.getPresentationInfoByShareAlias(shareAlias)
      if (infoRes && infoRes.data) {
        return infoRes.data
      }
    } catch (e) {
      console.error(
        `Unable to fetch presentations info for share alias: ${shareAlias}`,
        e,
      )
    }
    return null
  }

  /**
   * Returns a presentation from a URL returned by the server (usually as part of a version detail request, returned as
   * version.parent)
   *
   * @param presentationURL
   */
  async getPresentationFromURL(presentationURL: string) {
    const presentationRes = await this.rootStore
      .getAPIClient()
      .presentationsAPI.withAuth()
      .get<APIPresentation>(presentationURL)
    if (presentationRes.data) {
      return PresentationsModel.presentationFromAPIModel(presentationRes.data)
    }
    return null
  }

  /**
   * Returns the current version of a presentation using an API URL provided by the API.
   *
   * @param currentVersionURL Provided by the API in a previous response for getting a presentation.
   * @param shouldShowArchived
   */
  async getCurrentVersionFromURL(
    currentVersionURL: string,
    shouldShowArchived?: boolean,
  ) {
    const apiOptions = {
      is_archived: shouldShowArchived ? 'True' : undefined,
    }
    try {
      const versionResponse = await this.rootStore
        .getAPIClient()
        .presentationsAPI.withAuth()
        .get<APIPresentationVersion>(currentVersionURL, { params: apiOptions })
      if (versionResponse.data) {
        return PresentationsModel.presentationVersionFromAPIModel(
          versionResponse.data,
        )
      }
    } catch (e) {
      console.error(
        `Unable to fetch current version with URL: ${currentVersionURL}`,
        e,
      )
    }
    return undefined
  }

  async getVersionDetail(versionUUID: UUID, config?: AxiosRequestConfig) {
    const versionResponse = await this.rootStore
      .getAPIClient()
      .presentationsAPI.fetchVersion(versionUUID, config)

    return PresentationsModel.presentationVersionFromAPIModel(
      versionResponse.data,
    )
  }

  async getAssetCloudFrontDownloadURL(
    assetDownloadURL: string,
  ): Promise<string | undefined> {
    const response = await this.rootStore
      .getAPIClient()
      .presentationsAPI.getAssetCloudFrontDownloadURL(assetDownloadURL)
    if (response && response.data) {
      return response.request.responseURL as string
    }
  }

  /**
   * Returns a thumbnail, starting with a request to an authenticated endpoint, and following a redirect to a signed URL.
   *
   * @param url Provided by the API in a previous response for getting a presentation.
   */
  async getThumbnail(url: string, config?: AxiosRequestConfig) {
    const thumbnailResponse = await this.rootStore
      .getAPIClient()
      .presentationsAPI.getThumbnailImage(url, config)
    if (thumbnailResponse && thumbnailResponse.data) {
      return thumbnailResponse.request.responseURL
    }
  }

  /**
   * Returns a list of thumbnail objects with cloudfront download urls
   *
   * @param companyUUID
   * @param versionUUID
   */
  async getPresentationVersionThumbnails(
    companyUUID: UUID,
    versionUUID: UUID,
    config?: AxiosRequestConfig,
  ) {
    const currentTimestamp = Math.floor(Date.now() / 1000)
    if (
      this.thumbnailCache.hasOwnProperty(companyUUID) &&
      this.thumbnailCache[companyUUID].hasOwnProperty(versionUUID)
    ) {
      const versionThumbnailsExpiry =
        this.thumbnailCache[companyUUID][versionUUID].expiresOn
      if (currentTimestamp < versionThumbnailsExpiry) {
        return this.thumbnailCache[companyUUID][versionUUID].thumbnails
      }
    }

    if (!this.thumbnailCache.hasOwnProperty(companyUUID)) {
      this.thumbnailCache[companyUUID] = {}
    }

    try {
      const res = await this.rootStore
        .getAPIClient()
        .presentationsAPI.getPresentationVersionThumbnails(versionUUID, config)
      if (res && res.data) {
        const coverThumbnail = res.data.find(
          (d: APIPresentationBatchAssetResponse) => d.uuid === 'cover',
        )
        const thumbnailsExpiresArray = res.data.map(
          (d: APIPresentationBatchAssetResponse) =>
            parseInt(d.download_url.split('Expires=')[1].split('&')[0]),
        )

        this.setThumbnailCache(companyUUID, versionUUID, {
          coverURL: coverThumbnail ? coverThumbnail.download_url : '',
          expiresOn: Math.min(...thumbnailsExpiresArray),
          thumbnails: res.data,
        })

        return res.data
      }
    } catch (e) {
      console.error('Unable to get thumbnails', e)
      return []
    }
  }

  /**
   * Returns user information from a pre-supplied user endpoint URL. This URL comes in responses like the content
   * listing, through values like modified_by, created_by, deleted_by, etc. rather than supplying just the user's UUID.
   *
   * @param url Provided by the API in a previous response for getting a presentation.
   */
  async getUserFromURL(url: string, config?: AxiosRequestConfig) {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .presentationsAPI.withAuth()
        .get<APIUser>(url, config)
      if (res && res.data) {
        return UserModel.userFromAPIModel(res.data)
      }
    } catch (e) {
      console.error(`Unable to get user from URL: ${url}`)
    }
  }

  /**
   * Returns a list of teams that a presentation is currently shared to.
   *
   * @param companyUUID
   * @param presentationUUID
   * @param shouldShowArchived true if wishing to show a list of teams for an archived presentation.
   */
  async getTeamsPresentationSharedTo(
    companyUUID: UUID,
    presentationUUID: UUID,
    shouldShowArchived = false,
    config?: AxiosRequestConfig,
  ) {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .presentationsAPI.getTeamsPresentationSharedTo(
          companyUUID,
          presentationUUID,
          shouldShowArchived,
          config,
        )
      if (res.data) {
        return res.data
          .map((apiTeam) =>
            PresentationsModel.presentationTeamFromAPIModel(apiTeam),
          )
          .filter(TeamsUtil.filterHiddenTeams(this.rootStore.userStore.profile))
          .sort((a, b) => a.name.localeCompare(b.name))
      }
    } catch (e) {
      if (!config?.signal?.aborted) {
        console.error(
          `Unable to get teams shared with presentation ${presentationUUID}`,
          e,
        )
      }
    }
    return []
  }

  /**
   * Make Presentation Available
   *
   * Toggles on availability state to be used by clients
   * to show presentations after automation is complete
   *
   * @param presentationUUID Presentation UUID
   * @returns empty response
   */
  async makePresentationAvailable(presentationUUID: UUID) {
    try {
      await this.rootStore
        .getAPIClient()
        .presentationsAPI.makePresentationAvailable(presentationUUID)
    } catch (e) {
      console.error(
        `Toggling presentation availability failed for ${presentationUUID}`,
        e,
      )
    }
  }

  /**
   * Duplicates a presentation.
   *
   * @param presentationUUID
   * @param versionUUID
   */
  async duplicatePresentation(presentationUUID: UUID, versionUUID: UUID) {
    let version
    try {
      const duplicateRes = await this.rootStore
        .getAPIClient()
        .presentationsAPI.duplicateVersion(versionUUID)
      if (!duplicateRes || duplicateRes.status !== 201) {
        console.error(
          `Unable to duplicate presentation (${presentationUUID}) version: ${versionUUID}. HTTP status was not 201`,
        )
      }
      version = PresentationsModel.presentationVersionFromAPIModel(
        duplicateRes.data,
      )
    } catch (e) {
      console.error(
        `Unable to duplicate presentation (${presentationUUID}) version: ${versionUUID}`,
      )
    }
    return version
  }

  /**
   * Publishes a version.
   *
   * @param versionUUID
   */
  async publishPresentation(versionUUID: UUID) {
    try {
      const publishRes = await this.rootStore
        .getAPIClient()
        .presentationsAPI.publishVersion(versionUUID)
      if (!publishRes || publishRes.status !== 200) {
        return console.error(
          `Unable to publish presentation version: ${versionUUID}. HTTP status was not 200`,
        )
      }
    } catch (e) {
      return console.error(
        `Unable to publish presentation version: ${versionUUID}`,
      )
    }
  }

  /**
   * Toggles whether presentation sections or pages are visible.
   *
   * @param presentationUUID
   * @param sections
   * @param pages
   */
  async toggleVisibility(
    presentationUUID: UUID,
    sections?: Record<UUID, 'hide' | 'show'>,
    pages?: Record<UUID, 'hide' | 'show'>,
  ) {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .presentationsAPI.toggleVisibility(presentationUUID, sections, pages)
      if (!res || res.status !== 200) {
        return console.error(
          `Unable to toggle visibility for presentation ${presentationUUID}. HTTP status was not 200`,
        )
      }
      return res
    } catch (e) {
      return console.error(
        `Unable to toggle visibility for presentation ${presentationUUID}.`,
      )
    }
  }

  /**
   * Turns on/off web sharing for a presentation.
   *
   * @param presentationUUID
   * @param versionUUID
   * @param shareType 'public' to share it to anyone with the web link; 'owner' to keep them private.
   */
  async toggleWebSharingForPresentation(
    presentationUUID: UUID,
    versionUUID: UUID,
    shareType: ShareType,
  ) {
    try {
      // Only duplicate if we're sharing to the public
      if (shareType === ShareType.PUBLIC) {
        const duplicateRes = await this.rootStore
          .getAPIClient()
          .presentationsAPI.duplicateVersion(versionUUID)
        if (!duplicateRes || duplicateRes.status !== 201) {
          return console.error(
            `Unable to duplicate presentation (${presentationUUID}) version: ${versionUUID}. HTTP status was not 201`,
          )
        }

        const version = PresentationsModel.presentationVersionFromAPIModel(
          duplicateRes.data,
        )
        const publishRes = await this.rootStore
          .getAPIClient()
          .presentationsAPI.publishVersion(version.id)
        if (!publishRes || publishRes.status !== 200) {
          return console.error(
            `Unable to publish presentation (${presentationUUID}) version: ${version.id}. HTTP status was not 200`,
          )
        }
      }

      // Toggle the sharing value
      const shareRes = await this.rootStore
        .getAPIClient()
        .presentationsAPI.sharePresentation(presentationUUID, shareType)
      if (!shareRes || shareRes.status !== 200) {
        return console.error(
          `Unable to share presentation (${presentationUUID}) with share type ${shareType}. HTTP status was not 200`,
        )
      }
      return PresentationsModel.shareResponseFromAPIModel(shareRes.data)
    } catch (e) {
      return console.error(
        `Unable to duplicate presentation (${presentationUUID}) version: ${versionUUID}`,
      )
    }
  }

  /**
   * Returns the current version for a presentation.
   *
   * @param presentationUUID
   */
  async getCurrentVersion(presentationUUID: UUID, config?: AxiosRequestConfig) {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .presentationsAPI.getCurrentVersion(presentationUUID, config)
      if (res && res.data) {
        return PresentationsModel.presentationVersionFromAPIModel(res.data)
      }
    } catch (e) {
      console.error(
        `Unable to get current version for presentation ${presentationUUID}`,
        e,
      )
    }
    return undefined
  }

  /**
   * Clones a presentation from one company to another company and, optionally, that other company's teams as well.
   *
   * @param presentationUUID
   * @param companyUUID Destination company ID.
   * @param teamUUIDS Destination team UUIDs.
   */
  async cloneToCompanyAndTeams(
    presentationUUID: UUID,
    companyUUID: UUID,
    teamUUIDS: UUID[],
  ) {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .presentationsAPI.cloneToCompanyAndTeams(
          presentationUUID,
          companyUUID,
          teamUUIDS,
        )
      return res.status >= 200 && res.status <= 400
    } catch (e) {
      console.error(
        `Unable to clone presentation (${presentationUUID}) to company (${companyUUID}) teams`,
        e,
      )
    }
    return false
  }

  /**
   * Toggles whether or not a presentation is shared with a particular team. There is no control over turning it on/off
   * explicitly, it just toggles it.
   *
   * @param companyUUID
   * @param presentationUUID
   * @param teamUUID
   */
  async toggleTeamShare(
    companyUUID: UUID,
    presentationUUID: UUID,
    teamUUID: UUID,
  ) {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .presentationsAPI.toggleTeamShare(
          companyUUID,
          presentationUUID,
          teamUUID,
        )
      return res.status === 204
    } catch (e) {
      console.error(
        `Unable to toggle team share for presentation (${presentationUUID}) on company (${companyUUID}) for team ${teamUUID}`,
        e,
      )
    }
    return false
  }

  /**
   * Archives a presentation.
   *
   * @param companyUUID
   * @param presentationUUID
   * @param teamUUID
   */
  async archivePresentation(
    companyUUID: UUID,
    presentationUUID: UUID,
    teamUUID?: UUID,
  ) {
    await this.rootStore
      .getAPIClient()
      .presentationsAPI.archivePresentation(
        companyUUID,
        presentationUUID,
        teamUUID,
      )
  }

  /**
   * Restores an archived presentation to active status.
   *
   * @param companyUUID
   * @param presentationUUID
   */
  async restoreArchivedPresentation(companyUUID: UUID, presentationUUID: UUID) {
    return await this.rootStore
      .getAPIClient()
      .presentationsAPI.restorePresentation(companyUUID, presentationUUID)
  }

  /**
   * Permanently deletes a presentation.
   *
   * @param companyUUID
   * @param presentationUUID
   */
  async deleteArchivedPresentation(companyUUID: UUID, presentationUUID: UUID) {
    return await this.rootStore
      .getAPIClient()
      .presentationsAPI.deletePresentation(companyUUID, presentationUUID)
  }

  /**
   * Toggles a permission on a presentation.
   *
   * @param companyUUID
   * @param presentationUUID
   * @param field The field to toggle the permission on.
   */
  async togglePermission(
    companyUUID: UUID,
    presentation: Presentation,
    field: PresentationPermissionField,
  ) {
    const presentationFrozen = { ...presentation }
    try {
      const res = await this.rootStore
        .getAPIClient()
        .presentationsAPI.togglePermission(
          companyUUID,
          presentationFrozen.id,
          field,
        )
      if (res.status === 204) {
        // If permissions were adjusted to is_publicly_shareable, we should publish and share to the web or un-share it
        // based on what the new value is going to be
        if (field === PresentationPermissionField.IS_PUBLICLY_SHAREABLE) {
          const newShareableValue = !presentationFrozen.isPubliclyShareable
          await this.toggleWebSharingForPresentation(
            presentationFrozen.id,
            presentationFrozen.currentVersion!.id,
            newShareableValue ? ShareType.PUBLIC : ShareType.OWNER,
          )
        }

        // If we ENABLED `is_guest_editable` and `is_editable` was previously DISABLED, enable it as well
        if (field === PresentationPermissionField.IS_GUEST_EDITABLE) {
          const newIsGuestEditableValue = !presentationFrozen?.isGuestEditable
          if (newIsGuestEditableValue && !presentationFrozen.isEditable) {
            await this.togglePermission(
              companyUUID,
              presentationFrozen,
              PresentationPermissionField.IS_EDITABLE,
            )
          }
        }

        // If we DISABLED `is_editable` and `is_guest_editable` was previously ENABLED, disable it as well
        if (field === PresentationPermissionField.IS_EDITABLE) {
          const newIsEditableValue = !presentationFrozen?.isEditable
          if (!newIsEditableValue && presentationFrozen?.isGuestEditable) {
            await this.togglePermission(
              companyUUID,
              presentationFrozen,
              PresentationPermissionField.IS_GUEST_EDITABLE,
            )
          }
        }

        return true
      }
    } catch (e) {
      console.error(
        `Unable to toggle permission ${field} on presentation ${presentationFrozen.id} on company ${companyUUID}`,
        e,
      )
    }
    return false
  }

  setThumbnailCache(
    companyUUID: UUID,
    versionUUID: UUID,
    thumbnailCacheValue: ThumbnailCacheItem,
  ) {
    this.thumbnailCache = {
      ...this.thumbnailCache,
      [companyUUID]: {
        ...this.thumbnailCache[companyUUID],
        [versionUUID]: thumbnailCacheValue,
      },
    }
  }

  clearData() {
    this.thumbnailCache = {}
  }
}
