import { FontWeightProps } from '../../types/FontWeightProps'
import { defaultLegacyFontRules } from '../../rules/legacy'
import type {
  FontWeightToPropsMap,
  FamilyAndWeightToPropsMap
} from '../../rules/freestyle'
import type {
  LegacyFontFamilies,
  LegacyFontRules,
  LegacyFontStyles,
  LegacyFontSizes
} from '../../rules/legacy'

const LegacyCustomFontSizes = {
  'extra-small': 15,
  'small': 20,
  'medium': 25,
  'large': 30,
  'extra-large': 40,
  'extra-extra-large': 50,
  'extra-extra-extra-large': 60
}

export const defaultLegacyFontFamilies: LegacyFontFamilies = Object.entries(defaultLegacyFontRules).map(([key, value]) => ({
  key,
  full: value.font.name
}))

/**
 * Build a legacy font family list from available font family names.
 *
 * @param customFontFamilies list of available font family names
 */
export const buildCustomLegacyFontFamilies = (customFontFamilies: string[]): LegacyFontFamilies => {
  return customFontFamilies.map(fontFamily => ({
    key: fontFamily,
    full: fontFamily
  }))
}

/**
 * Based on Freestyle's family and weight to props map, create a Legacy
 * Font Rules map so that legacy fonts has access to custom fonts.
 *
 * @param customFamilyAndWeightToPropsMap Freestyle family and weight to props map
 */
export const buildCustomLegacyFontRules = (customFamilyAndWeightToPropsMap: FamilyAndWeightToPropsMap): LegacyFontRules => {
  return Object.entries(customFamilyAndWeightToPropsMap).reduce((legacyFontRules, [fontFamily, fontWeightToPropsMap]) => {
    legacyFontRules[fontFamily] = {
      font: {
        key: fontFamily,
        name: fontFamily,
        styles: buildCustomLegacyFontStyles(fontFamily, fontWeightToPropsMap)
      }
    }
    return legacyFontRules
  }, {} as LegacyFontRules)
}

/**
 * Build Legacy Font Styles for a custom font.
 *
 * Assign the `regular` variation to all custom font styles.
 *
 * @param fontFamily font family name
 * @param fontWeightToPropsMap Freestyle weight to props map
 */
const buildCustomLegacyFontStyles = (fontFamily: string, fontWeightToPropsMap: FontWeightToPropsMap): LegacyFontStyles => {
  return Object.entries(fontWeightToPropsMap).reduce((legacyFontStyles, [fontWeight, fontProps]) => {
    legacyFontStyles[fontWeight] = {
      variations: {
        // assign 'regular' to all custom fonts.
        regular: {
          name: fontWeight,
          sizes: buildCustomLegacyFontSizes(fontFamily, fontProps)
        }
      }
    }
    return legacyFontStyles
  }, {} as LegacyFontStyles)
}

/**
 * Build Legacy Font Sizes for a custom font.
 *
 * Base the sizes on a list of preset font sizes.
 *
 * @param fontFamily font family name for custom font
 * @param fontProps font props for custom font
 */
const buildCustomLegacyFontSizes = (fontFamily: string, { fontWeight }: FontWeightProps): LegacyFontSizes => {
  return Object.entries(LegacyCustomFontSizes).reduce((fontSizes, [name, fontSize]) => {
    fontSizes[name] = {
      'font-family': `"${fontFamily}"`,
      'font-weight': fontWeight,
      'font-size': fontSize,
      'uppercase': false,
      'italic': false
    }
    return fontSizes
  }, {} as LegacyFontSizes)
}
