import { useAppContext } from 'src/AppContext'

/**
 * Contains React hooks for the MobX store.
 */
export const useRootStore = () => {
  return useAppContext().store
}

export const useAnalyticsStore = () => {
  const { analyticsStore } = useAppContext().store
  return analyticsStore
}

export const useAppConfigStore = () => {
  const { appConfigStore } = useAppContext().store
  return appConfigStore
}

export const useIntegrationStore = () => {
  const { integrationStore } = useAppContext().store
  return integrationStore
}

export const useMarketplaceStore = () => {
  const { marketplaceStore } = useAppContext().store
  return marketplaceStore
}

export const usePresentationStore = () => {
  const { presentationStore } = useAppContext().store
  return presentationStore
}

export const useDynamicContentStore = () => {
  const { dynamicContentStore } = useAppContext().store
  return dynamicContentStore
}

export const useAssetsStore = () => {
  const { assetStore } = useAppContext().store
  return assetStore
}

export const useSettingsStore = () => {
  const { settingsStore } = useAppContext().store
  return settingsStore
}

export const useCompanyStore = () => {
  const { companyStore } = useAppContext().store
  return companyStore
}

export const useTeamsStore = () => {
  const { teamsStore } = useAppContext().store
  return teamsStore
}

export const useUIStore = () => {
  const { uiStore } = useAppContext().store
  return uiStore
}

export const useUserStore = () => {
  const { userStore } = useAppContext().store
  return userStore
}

export const useAutomationStore = () => {
  const { automationStore } = useAppContext().store
  return automationStore
}

export const useThemeStore = () => {
  const { themeStore } = useAppContext().store
  return themeStore
}

export const useFontStore = () => {
  const { fontStore } = useAppContext().store
  return fontStore
}

export const useAppModalStore = () => {
  const { appModalStore } = useAppContext().store
  return appModalStore
}

export const useAPI = () => {
  return useAppContext().store.getAPIClient()
}
