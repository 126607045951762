import Arvo from './Arvo'
import BentonSans from './Benton-Sans'
import Bodoni06pt from './Bodoni-06pt'
import Bodoni36pt from './Bodoni-36pt'
import Calibre from './Calibre'
import Didot from './Didot'
import DomaineDisplay from './Domaine-Display'
import Eczar from './Eczar'
import GillSans from './GillSans'
import GravitasOne from './GravitasOne'
import Gruppo from './Gruppo'
import HelveticaNeue from './HelveticaNeue'
import Montserrat from './Montserrat'
import OpenSans from './OpenSans'
import Oswald from './Oswald'
import Pacifico from './Pacifico'
import PTSerif from './PTSerif'
import Rajdhani from './Rajdhani'
import Rubik from './Rubik'

export * from './types'

export const defaultFreestyleFontRules = [
  Arvo,
  BentonSans,
  Bodoni06pt,
  Bodoni36pt,
  Calibre,
  Didot,
  DomaineDisplay,
  Eczar,
  GillSans,
  GravitasOne,
  Gruppo,
  HelveticaNeue,
  Montserrat,
  OpenSans,
  Oswald,
  Pacifico,
  PTSerif,
  Rajdhani,
  Rubik
]