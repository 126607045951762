import { AxiosRequestConfig } from 'axios'

import { RootStore } from 'src/store/RootStore'
import { CompanyModel } from 'src/model/company'
import { Company } from 'src/model/company/Company'

export class CompanyStore {
  rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  async getCompany(
    companyUUID: string,
    options?: AxiosRequestConfig,
  ): Promise<Company> {
    const response = await this.rootStore
      .getAPIClient()
      .companyAPI.getCompany(companyUUID, options)
    return CompanyModel.companyFromAPIModel(response.data)
  }
}
