import { Stub, Config } from '../../../..';

export default (stub: Stub, config: Config): Stub => {
  stub.version = 3;

  if (stub.isPlaceholder == null) {
    stub.isPlaceholder = false;
  }

  return stub;
};