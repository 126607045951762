import { Stub, Config } from '../../../..';
import { defaultThemeColors } from '../defaults/v3';
import { TinyColor } from '@ctrl/tinycolor';

export default (stub: Stub, config: Config): Stub => {
  stub.version = 4;

  if (stub.textStyles.length < 5) {
    const defaultButtonText = {
      name: 'Button',
      textFormat: {
        textColor: 'named-color-2',
        fontFamily: 'Montserrat',
        fontWeight: 'SemiBold',
        fontSize: 16
      }
    }

    stub.textStyles.push(defaultButtonText)
  }

  if (!stub.buttonTheme) {
    stub.buttonTheme = {
      iconButtonBackground: {
        blurStyle: 'light',
        style: 'blur'
      },
      iconButtonForegroundColor: 'named-color-1',
      textButtonBackground: {
        identifier: 'named-color-1',
        style: 'named'
      },
      textButtonCornerRadius: 6,
      type: 'buttonTheme',
      version: 1
    }
  }

  return stub;
};
