import { AxiosRequestConfig } from 'axios'

import { UUID } from 'src/util/types'
import BaseAPI from 'src/api/BaseAPI'
import { PlaybookTask } from 'src/model/playbook/PlaybookTask'
import { PlaybookStatus } from 'src/model/playbook/PlaybookStatus'
import { APIPlaybookAction } from 'src/model/api/playbook/APIPlaybookAction'

export default class PlaybookAPI extends BaseAPI {
  updatePlaybookTaskOwnerStatus = async (
    playbookTaskUUID: UUID,
    status: PlaybookStatus,
    config?: AxiosRequestConfig,
  ) => {
    return this.V1()
      .withAuth()
      .patch<PlaybookTask>(
        `/playbooktaskowners/${playbookTaskUUID}/`,
        { status },
        config,
      )
  }

  fetchPlaybookActions = async (config?: AxiosRequestConfig) => {
    return this.V1()
      .withAuth()
      .get<APIPlaybookAction[]>('/playbookactions/', config)
  }
}
