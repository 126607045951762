import { AxiosRequestConfig } from 'axios'

import BaseAPI from 'src/api/BaseAPI'
import { PresentationInfo } from '../model/presentation/PresentationInfo'
import { ANALYTICS_THUMBNAIL_SIZE, ShareType, UUID } from 'src/util/types'
import { APILockResponse } from 'src/model/api/presentation/APILockResponse'
import { APIPresentation } from 'src/model/api/presentation/APIPresentation'
import { APIShareResponse } from 'src/model/api/presentation/APIShareResponse'
import { APIPresentationTeam } from 'src/model/api/presentation/APIPresentationTeam'
import { APIPresentationVersion } from 'src/model/api/presentation/APIPresentationVersion'
import { PresentationListOptions } from 'src/model/presentation/PresentationListOptions'
import { PresentationPermissionField } from 'src/model/presentation/PresentationPermissionField'
import { APIPresentationWithDraftVersion } from 'src/model/api/presentation/APIPresentationWithDraftVersion'

/**
 * Client for presentations-related calls to the API.
 */
export default class PresentationAPI extends BaseAPI {
  /**
   * Returns a list of companies associated with a user.
   */
  async getPresentations(companyUUID: UUID, options?: PresentationListOptions) {
    const apiOptions = {
      is_archived: options?.shouldShowArchived ? 'True' : undefined,
      search: options?.search?.trim() ?? '',
    }

    const url = `/team/api/company/${companyUUID}/contents/`
    return await this.withAuth().getAllPages<APIPresentation>(url, {
      params: apiOptions,
    })
  }

  /**
   * Fetch a single page of company presentations
   */
  async getPresentationsPageForCompany(
    companyUUID: UUID,
    page: number,
    pageSize: number,
    config: AxiosRequestConfig,
  ) {
    const url = `/team/api/company/${companyUUID}/contents/`
    return this.withAuth().getPage<APIPresentation[]>(
      url,
      page,
      pageSize,
      config,
    )
  }

  /**
   * Fetch a single page of team presentations
   */
  async getPresentationsPageForTeam(
    companyUUID: UUID,
    teamUUID: UUID,
    page: number,
    pageSize: number,
    config: AxiosRequestConfig,
  ) {
    const url = `/team/api/company/${companyUUID}/teams/${teamUUID}/contents/`
    return this.withAuth().getPage<APIPresentation[]>(
      url,
      page,
      pageSize,
      config,
    )
  }

  /**
   * Retrieves details for a single presentation under a company.
   *
   * @param companyUUID
   * @param presentationUUID
   * @param teamUUID
   */
  async getCompanyPresentation(
    companyUUID: UUID,
    presentationUUID: UUID,
    shouldIncludeVersions = true,
    isArchived = false,
    config?: AxiosRequestConfig,
  ) {
    const apiOptions = {
      versions: shouldIncludeVersions,
      is_archived: isArchived ? 'True' : undefined,
    }
    const url = `/team/api/company/${companyUUID}/contents/${presentationUUID}/`
    return await this.withAuth().get<APIPresentation>(url, {
      ...config,
      params: apiOptions,
    })
  }

  /**
   * Retrieves details for a single presentation under a team.
   *
   * @param companyUUID
   * @param teamUUID
   * @param presentationUUID
   */
  async getTeamPresentation(
    companyUUID: UUID,
    teamUUID: UUID,
    presentationUUID: UUID,
    shouldIncludeVersions = true,
    isArchived = false,
    config?: AxiosRequestConfig,
  ) {
    const apiOptions = {
      versions: shouldIncludeVersions,
      is_archived: isArchived ? 'True' : undefined,
    }
    const url = `/team/api/company/${companyUUID}/teams/${teamUUID}/contents/${presentationUUID}/`
    return await this.withAuth().get<APIPresentation>(url, {
      ...config,
      params: apiOptions,
    })
  }

  /**
   * Retrieves details for a single presentation by its UUID alone, without specifying a Company. Useful for when user
   * roles--like Creator--don't have access to endpoints like /team/api/company/:company_uuid/contents/:content_uuid/,
   * but we know that the user likely has access to the presentation, like in instances where they install Marketplace
   * content.
   *
   * @param presentationUUID
   * @param shouldIncludeVersions
   * @param isArchived
   */
  async getPresentationByUUID(
    presentationUUID: UUID,
    shouldIncludeVersions = true,
    isArchived = false,
    config?: AxiosRequestConfig,
  ) {
    const apiOptions = {
      versions: shouldIncludeVersions,
      is_archived: isArchived ? 'True' : undefined,
    }
    const url = `/contents/${presentationUUID}/`
    return await this.withAuth().get<APIPresentation>(url, {
      ...config,
      params: { ...config?.params, apiOptions },
    })
  }

  /**
   * Returns a list of presentations with draft versions for the given company
   *
   * Uses the v2 API endpoint that returns a presentation with published and draft versions.
   *
   * @param companyUUID
   * @param page
   * @param pageSize
   * @param config
   */
  async getPaginatedPresentationsWithDraftVersionForCompany(
    companyUUID: UUID,
    page: number,
    pageSize: number,
    config: AxiosRequestConfig,
  ) {
    return this.V2()
      .withAuth()
      .getPage<APIPresentationWithDraftVersion[]>(
        `/company/${companyUUID}/contents/`,
        page,
        pageSize,
        config,
      )
  }

  /**
   * Returns a list of presentations with draft versions for the given team
   *
   * Uses the v2 API endpoint that returns a presentation with published and draft versions.
   *
   * @param companyUUID
   * @param teamUUID
   * @param page
   * @param pageSize
   * @param config
   * @returns
   */
  async getPaginatedPresentationsWithDraftVersionForTeam(
    companyUUID: UUID,
    teamUUID: UUID,
    page: number,
    pageSize: number,
    config: AxiosRequestConfig,
  ) {
    return this.V2()
      .withAuth()
      .getPage<APIPresentationWithDraftVersion[]>(
        `/company/${companyUUID}/teams/${teamUUID}/contents/`,
        page,
        pageSize,
        config,
      )
  }

  /**
   * Returns details for a presentation under a given presentation UUID.
   *
   * Uses the v2 API endpoint that returns a presentation with published and draft versions.
   *
   * @param presentationUUID
   * @param shouldIncludeVersions
   * @param config
   */
  async getPresentationWithDraftVersionDetail(
    presentationUUID: UUID,
    shouldIncludeVersions = true,
    config?: AxiosRequestConfig,
  ) {
    return await this.V2()
      .withAuth()
      .get<APIPresentationWithDraftVersion>(`/contents/${presentationUUID}/`, {
        ...config,
        params: {
          versions: shouldIncludeVersions,
          ...config?.params,
        },
      })
  }

  async getPaginatedPresentationVersions(
    presentationUUID: UUID,
    page: number,
    pageSize: number,
    config?: AxiosRequestConfig,
  ) {
    return await this.V1()
      .withAuth()
      .getPage<APIPresentationVersion[]>(
        `/contents/${presentationUUID}/versions/`,
        page,
        pageSize,
        config,
      )
  }

  async getAssetCloudFrontDownloadURL(
    assetDownloadURL: string,
    config?: AxiosRequestConfig,
  ) {
    return this.withAuth().get(assetDownloadURL, {
      responseType: 'arraybuffer',
      ...config,
    })
  }

  /**
   * Retrieves presentation information based on its share URL.
   *
   * @param shareAlias
   */
  async getPresentationInfoByShareAlias(shareAlias: string) {
    const url = `/contents/info/${shareAlias}/`
    return await this.withAuth().get<PresentationInfo>(url)
  }

  /**
   * Gets a thumbnail image, which ultimately HTTP redirects to a signed URL
   *
   * @param url thumbnail URL (requires auth) that will re-route to the signed URL.
   */
  async getThumbnailImage(url: string, config?: AxiosRequestConfig) {
    return await this.withAuth().get(url, {
      responseType: 'arraybuffer',
      ...config,
    })
  }

  /**
   * Gets all thumbnails for a presentation version
   *
   * @param versionUUID
   */
  async getPresentationVersionThumbnails(
    versionUUID: UUID,
    config?: AxiosRequestConfig,
  ) {
    const url = `/versions/${versionUUID}/batch_asset_download/?only_thumbnails=True&size=${ANALYTICS_THUMBNAIL_SIZE}`
    return await this.withAuth().get(url, { ...config })
  }

  /**
   * Gets a list of teams a presentation is shared to.
   *
   * @param companyUUID
   * @param presentationUUID
   * @param shouldShowArchived
   */
  async getTeamsPresentationSharedTo(
    companyUUID: UUID,
    presentationUUID: UUID,
    shouldShowArchived: boolean,
    config?: AxiosRequestConfig,
  ) {
    const apiOptions = {
      is_archived: shouldShowArchived ? 'True' : undefined,
    }
    const url = `/team/api/company/${companyUUID}/contents/${presentationUUID}/get_teams/`
    return await this.withAuth().get<APIPresentationTeam[]>(url, {
      ...config,
      params: apiOptions,
    })
  }

  /**
   * Sets whether a presentation is shared to the public
   *
   * @param presentationUUID
   * @param shareType 'public' if shared to the web, 'owner' if set to private
   */
  async sharePresentation(presentationUUID: UUID, shareType: ShareType) {
    const url = `/contents/${presentationUUID}/share/`
    const formData = new FormData()
    formData.append('share_type', shareType)
    return await this.withAuth().post<APIShareResponse>(url, formData)
  }

  async fetchVersion(versionUUID: UUID, config?: AxiosRequestConfig) {
    return await this.withAuth().get<APIPresentationVersion>(
      `/versions/${versionUUID}/`,
      config,
    )
  }

  /**
   * Make Presentation Available
   *
   * Toggles on availability state to be used by clients
   * to show presentations after automation is complete
   *
   * @param presentationUUID Presentation UUID
   * @returns empty response
   */
  async makePresentationAvailable(presentationUUID: UUID) {
    return this.V1()
      .withAuth()
      .patch<void>(`/contents/${presentationUUID}/make_available/`)
  }

  /**
   * Creates a duplicate of a given version.
   *
   * @param versionUUID
   */
  async duplicateVersion(versionUUID: UUID) {
    const url = `/versions/${versionUUID}/duplicate/`
    return await this.withAuth().post<APIPresentationVersion>(url)
  }

  /**
   * Publishes a version, marking it as the active version.
   *
   * @param versionUUID
   */
  async publishVersion(versionUUID: UUID) {
    const url = `/versions/${versionUUID}/publish/`
    return await this.withAuth().post(url)
  }

  /**
   * Toggles whether presentation sections or pages are visible.
   *
   * @param presentationUUID
   * @param sections
   * @param pages
   */
  async toggleVisibility(
    presentationUUID: UUID,
    sections?: Record<UUID, 'hide' | 'show'>,
    pages?: Record<UUID, 'hide' | 'show'>,
  ) {
    const url = `/contents/${presentationUUID}/toggle_component_visibility/`
    const postData = {
      sections,
      pages,
    }
    return await this.withAuth().post(url, postData)
  }

  /**
   * Retrieves the current version of a presentation.
   *
   * @param presentationUUID
   */
  async getCurrentVersion(presentationUUID: UUID, config?: AxiosRequestConfig) {
    const url = `/contents/${presentationUUID}/current_version/`
    return await this.withAuth().get<APIPresentationVersion>(url, config)
  }

  /**
   * Clones a company's presentation to another company, and optionally, teams in the other company.
   *
   * @param presentationUUID
   * @param companyUUID
   * @param teamUUIDS
   */
  async cloneToCompanyAndTeams(
    presentationUUID: UUID,
    companyUUID: UUID,
    teamUUIDS: UUID[],
  ) {
    const url = `/contents/${presentationUUID}/clone/`
    const postData = {
      companyid: companyUUID,
      team_ids: teamUUIDS,
    }
    return await this.withAuth().post(url, postData)
  }

  /**
   * Toggles whether a presentation is shared with a given team. Returns HTTP 204 if successful, no body.
   *
   * @param companyUUID
   * @param presentationUUID
   * @param teamUUID
   */
  async toggleTeamShare(
    companyUUID: UUID,
    presentationUUID: UUID,
    teamUUID: UUID,
  ) {
    const url = `/team/api/company/${companyUUID}/contents/${presentationUUID}/toggle_team_share/`
    const postData = {
      teamID: teamUUID,
    }
    return await this.withAuth().post(url, postData)
  }

  async archivePresentation(
    companyUUID: UUID,
    presentationUUID: UUID,
    teamUUID?: UUID,
  ) {
    let url

    if (teamUUID) {
      url = `/team/api/company/${companyUUID}/teams/${teamUUID}/contents/${presentationUUID}/`
    } else {
      url = `/team/api/company/${companyUUID}/contents/${presentationUUID}/`
    }

    return await this.V1().withAuth().delete<void>(url)
  }

  /**
   * Restores an archived presentation to active status.
   *
   * @param companyUUID
   * @param presentationUUID
   */
  async restorePresentation(companyUUID: UUID, presentationUUID: UUID) {
    const url = `/team/api/company/${companyUUID}/contents/${presentationUUID}/restore/?is_archived=True`
    return await this.withAuth().patch(url)
  }

  /**
   * Deletes an archived presentation permanently.
   *
   * @param companyUUID
   * @param presentationUUID
   */
  async deletePresentation(companyUUID: UUID, presentationUUID: UUID) {
    const url = `/team/api/company/${companyUUID}/contents/${presentationUUID}/?is_archived=True&permanent=True`
    return await this.withAuth().delete(url)
  }

  /**
   * Toggles a permission field on a presentation.
   *
   * @param companyUUID
   * @param presentationUUID
   * @param field the field to be toggled, e.g. is_editable, is_shareable, etc.
   */
  async togglePermission(
    companyUUID: UUID,
    presentationUUID: UUID,
    field: PresentationPermissionField,
  ) {
    const url = `/team/api/company/${companyUUID}/contents/${presentationUUID}/toggle_permission/`
    const postData = {
      contentID: presentationUUID,
      field,
    }
    return await this.withAuth().post(url, postData)
  }

  /**
   * Obtains a lock on a presentation to ensure that no one else can edit it.
   *
   * @param presentationUUID
   */
  async obtainLock(presentationUUID: UUID) {
    const url = `/contents/${presentationUUID}/obtain_lock/`
    return await this.withAuth().post<APILockResponse>(url)
  }

  /**
   * Releases a lock on a presentation so that other clients or processes can edit it.
   *
   * @param presentationUUID
   */
  async releaseLock(presentationUUID: UUID) {
    const url = `/contents/${presentationUUID}/release_lock/`
    return await this.withAuth().post<APILockResponse>(url)
  }
}
