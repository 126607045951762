import { AxiosRequestConfig } from 'axios'

import { UUID } from 'src/util/types'
import BaseAPI from 'src/api/BaseAPI'
import { APIDynamicKey } from 'src/model/api/dynamickeys/APIDynamicKey'
import { APICustomField } from 'src/model/api/customfields/APICustomField'
import { APICustomFieldValue } from 'src/model/api/customfields/APICustomFieldValue'
import { APIDynamicKeyCreateProps } from 'src/model/api/dynamickeys/APIDynamicKeyCreateProps'
import { APICustomFieldImageValue } from 'src/model/api/customfields/APICustomFieldImageValue'
import { APICustomFieldUpdateProps } from 'src/model/api/customfields/APICustomFieldUpdateProps'
import { APICustomFieldCreateProps } from 'src/model/api/customfields/APICustomFieldCreateProps'
import { APICustomFieldValueCreateProps } from 'src/model/api/customfields/APICustomFieldValueCreateProps'

export default class DynamicContentAPI extends BaseAPI {
  createCustomField(
    companyUUID: UUID,
    apiCustomFieldCreateProps: APICustomFieldCreateProps,
    options?: AxiosRequestConfig,
  ) {
    return this.V2()
      .withAuth()
      .post<APICustomField>(
        `/company/${companyUUID}/customfields/`,
        apiCustomFieldCreateProps,
        options,
      )
  }

  updateCustomField(
    companyUUID: UUID,
    customFieldUUID: UUID,
    apiCustomFieldUpdateProps: APICustomFieldUpdateProps,
    options?: AxiosRequestConfig,
  ) {
    return this.V2()
      .withAuth()
      .patch<APICustomField>(
        `/company/${companyUUID}/customfields/${customFieldUUID}/`,
        apiCustomFieldUpdateProps,
        options,
      )
  }

  deleteCustomField(
    companyUUID: UUID,
    customFieldUUID: UUID,
    options?: AxiosRequestConfig,
  ) {
    return this.V2()
      .withAuth()
      .delete<void>(
        `/company/${companyUUID}/customfields/${customFieldUUID}/`,
        options,
      )
  }

  createCustomFieldValue(
    apiCustomFieldValueCreateProps: APICustomFieldValueCreateProps,
    options?: AxiosRequestConfig,
  ) {
    return this.V2()
      .withAuth()
      .post<APICustomFieldValue>(
        `/customfieldvalues/`,
        apiCustomFieldValueCreateProps,
        options,
      )
  }

  createCustomFieldImageValue(
    apiCustomFieldValueCreateProps: APICustomFieldValueCreateProps,
    options?: AxiosRequestConfig,
  ) {
    return this.V2()
      .withAuth()
      .post<APICustomFieldImageValue>(
        `/customfieldvalues/`,
        apiCustomFieldValueCreateProps,
        options,
      )
  }

  updateCustomFieldValue(
    customFieldValueUUID: UUID,
    value: string,
    options?: AxiosRequestConfig,
  ) {
    return this.V2()
      .withAuth()
      .patch<APICustomFieldValue>(
        `/customfieldvalues/${customFieldValueUUID}/`,
        { value },
        options,
      )
  }

  deleteCustomFieldValue(
    customFieldValueUUID: UUID,
    options?: AxiosRequestConfig,
  ) {
    return this.V2()
      .withAuth()
      .delete<void>(`/customfieldvalues/${customFieldValueUUID}/`, options)
  }

  getCustomFieldValue(
    customFieldValueUUID: UUID,
    options?: AxiosRequestConfig,
  ) {
    return this.V2()
      .withAuth()
      .get<APICustomFieldValue>(`/customfieldvalues/${customFieldValueUUID}/`, options)
  }

  createDynamicKey(
    companyUUID: UUID,
    apiDynamicKeyCreateProps: APIDynamicKeyCreateProps,
    options?: AxiosRequestConfig,
  ) {
    return this.V2()
      .withAuth()
      .post<APIDynamicKey>(
        `/company/${companyUUID}/dynamickeys/`,
        apiDynamicKeyCreateProps,
        options,
      )
  }

  updateDynamicKey(
    companyUUID: UUID,
    dynamicKeyUUID: UUID,
    apiDynamicKeyUpdateProps: APICustomFieldUpdateProps,
    options?: AxiosRequestConfig,
  ) {
    return this.V2()
      .withAuth()
      .patch<APIDynamicKey>(
        `/company/${companyUUID}/dynamickeys/${dynamicKeyUUID}/`,
        apiDynamicKeyUpdateProps,
        options,
      )
  }

  deleteDynamicKey(
    companyUUID: UUID,
    dynamicKeyUUID: UUID,
    options?: AxiosRequestConfig,
  ) {
    return this.V2()
      .withAuth()
      .delete<void>(
        `/company/${companyUUID}/dynamickeys/${dynamicKeyUUID}/`,
        options,
      )
  }
}
