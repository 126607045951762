import { Stub, Config, upgrade, getUpgraderVersion } from '../../../../';

import { v4 as uuidv4 } from 'uuid';

export default (stub: Stub, config: Config): Stub => {

    // Recursively walk the stub to look for any nodes that contain the word
    // `Color`, then grab it's value and return and return a color.  This way
    // an upgraded stub will provide the user with a list of colors that exist
    // in the current document so that they don't have to manually add them.
    function extractColors (stub: Stub): any[] {
        let result: any[] = []
        for (let key in stub) {
            if (typeof stub[key] === 'object') {
                result = result.concat(extractColors(stub[key]))
            } else {
                var regEx = /Color/gi;
                if (key.search(regEx)) {
                    const value = stub[key]
                    if (typeof value === 'string' && value.charAt(0) === '#' && value !== "#000000" && value.toLowerCase() !== "#ffffff") {
                        // Strip the # off the color as template does not expect one
                        result.push(stub[key].substr(1))
                    }
                }
            }
        }
        return result
    }

    stub.version = 6;

    // The fix for ticket https://ingage-hq.atlassian.net/browse/DESKTOP-6739
    // incorrectly changed the default Story type from `story` to `stub`.  This
    // resulted in Desktop builds having the wrong story type and as a result
    // broke the stubs upgrader.  The lines below fix this.
    if (stub.type === 'stubs') {
        stub.type = 'story'
    }

    if (stub.template == null) {

        // Pull existing colors from the stub
        const distinctColors = new Set(extractColors(stub))
        const extractedColors = [...distinctColors].map(c => ({"style": "solid", "color": c}))

        let colors: any[] = [
            // We always want black and white in the palette
            ...[
                {"style": "solid", "color": "000000"},
                {"style": "solid", "color": "ffffff"}
            ],

            // Existing colors from the stub
            ...extractedColors,

            // Even though we don't support gradients, we will when we implement Freestyle
            // so let's just add the standard gradient defaults now.
            ...[
                {"style": "gradient", "direction": "topBottom", "colors": ["ffffff", "e6e6e6"]},
                {"style": "gradient", "direction": "topBottom", "colors": ["1f1f1f", "4f4E5c"]},
                {"style": "gradient", "direction": "topBottom", "colors": ["33a9e4", "227096"]},
                {"style": "gradient", "direction": "topBottom", "colors": ["e43333", "e433e4"]},
                {"style": "gradient", "direction": "topBottom", "colors": ["e4c733", "e43333"]}
            ]
        ]

        stub.template = {
            "version":1,
            "id": uuidv4(),
            "type":"template",
            "name":"Untitled Template",
            "masterPages":[],
            "theme":{
                "version":1,
                "type":"theme",
                "themeBackgrounds": [...colors],
                "userBackgrounds":[]
            }};
    }

    if (config.recursive) {
        if (stub.cover) {
            stub.cover = upgrade(stub.cover, config);
        }

        for (let i = 0; i < stub.sections.length; i++) {
            const section = stub.sections[i];

            for (let i = 0; i < section.pages.length; i++) {
                const page = section.pages[i];
                section.pages[i] = upgrade(page, config);
            }
            stub.sections[i] = section;
        }
    }

    stub.upgraderVersion = getUpgraderVersion()

    return stub;
};
