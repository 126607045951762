import { Company } from '../../model/company/Company'
import { Profile } from '../../model/user/Profile'
import { Feature } from './Feature'

export namespace FeaturesUtil {
  export const hasCompanyFeature = (company: Company, feature: Feature) => {
    return company.features.includes(feature)
  }

  export const hasUserFeature = (
    profile: Profile | undefined,
    feature: Feature,
  ) => {
    if (!profile || !profile.features) return false
    return profile.features.includes(feature)
  }
}
