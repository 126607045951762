export default function () {
  return {
    "type": "story-bar-styles",
    "version": 1,
    "backgroundColor": "#FFFFFF",
    "backgroundStyle": "lightBlur",
    "labelTextColor": "#000000",
    "selectedSectionColor": "#C4C4C7",
    "selectedSectionTextColor": "#1B1B20",
    "buttonTintColor": "#000000"
  }
}