import { createContext, useContext } from 'react'
import { RootStore } from 'src/store/RootStore'

interface AppContextType {
  store: RootStore
}

export const AppContext = createContext<AppContextType | undefined>(undefined)

export const useAppContext = () => {
  const context = useContext(AppContext)
  if (context === undefined) {
    throw new Error('useRootStore must be used within RootStoreProvider')
  }

  return context
}
