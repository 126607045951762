import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCardText,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from '@coreui/react-pro'

import { useTeamsStore } from 'src/store'
import { Message } from 'src/model/company/Message'

const MessageModal = () => {
  const teamsStore = useTeamsStore()

  const [isLoading, setIsLoading] = useState(true)
  const [messages, setMessages] = useState<Message[]>([])
  const [isVisibleMessageModal, setIsVisibleMessageModal] = useState(true)

  const handleGetMessages = useCallback(async () => {
    const messageResponse = await teamsStore.getMessages()
    setMessages(messageResponse ?? [])
    setIsLoading(false)
  }, [teamsStore])

  useEffect(() => {
    handleGetMessages()
  }, [handleGetMessages])

  const handleDismissMessages = (messageID: number) => {
    teamsStore.setMessagesDismissed([
      ...teamsStore.dismissedMessages,
      messageID,
    ])
  }

  const filteredMessages = useMemo(() => {
    return messages.filter(
      (message) => !teamsStore.dismissedMessages.includes(message.id),
    )
  }, [messages, teamsStore.dismissedMessages])

  return (
    <div>
      {!isLoading && filteredMessages.length > 0 && (
        <CModal backdrop="static" visible={isVisibleMessageModal}>
          <CModalHeader closeButton={false}>
            <CModalTitle>Messages</CModalTitle>
          </CModalHeader>
          <CModalBody style={{ maxHeight: 480, overflowY: 'auto' }}>
            {filteredMessages.map((message, idx) => {
              return (
                <CCard key={idx} className="mb-4">
                  <CCardHeader className="d-flex justify-content-between text-primary">
                    <span className="mt-1">{message.title}</span>
                    <CButton
                      type="button"
                      color="danger"
                      size="sm"
                      onClick={() => handleDismissMessages(message.id)}
                    >
                      Dismiss
                    </CButton>
                  </CCardHeader>
                  <CCardBody>
                    <CCardText>{message.details}</CCardText>
                  </CCardBody>
                </CCard>
              )
            })}
          </CModalBody>
          <CModalFooter>
            <CButton
              type="button"
              color="secondary"
              onClick={() => setIsVisibleMessageModal(false)}
            >
              Close
            </CButton>
          </CModalFooter>
        </CModal>
      )}
    </div>
  )
}

export default observer(MessageModal)
