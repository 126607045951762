import { AxiosRequestConfig } from 'axios'
import { APIFont } from 'ingage-fonts/types/APIFont'

import BaseAPI from 'src/api/BaseAPI'
import { UUID } from 'src/util/types'

/**
 * Client for fonts-related calls to the API.
 */
export default class FontAPI extends BaseAPI {
  /**
   * Returns a list of all fonts
   */
  async getAllFonts(companyUUID: UUID, config?: AxiosRequestConfig) {
    return this.withAuth().getAllPages<APIFont>(
      `/team/api/company/${companyUUID}/fonts/`,
      config,
    )
  }

  /**
   * Import font file
   */
  async importFontFile(companyUUID: UUID, fontFile: File) {
    const url = `/team/api/company/${companyUUID}/fonts/`
    const formData = new FormData()
    formData.append('file', fontFile, fontFile.name)
    return this.withoutContentHeaders().withAuth().post(url, formData)
  }

  async downloadFont(fontUUID: UUID) {
    // TODO: Configure axios to expect Blob as the content type, and then make the GET request
  }

  /**
   * Delete font
   */
  async deleteFont(companyUUID: UUID, fontUUID: UUID) {
    const url = `/team/api/company/${companyUUID}/fonts/${fontUUID}/`
    return this.withAuth().delete(url)
  }
}
