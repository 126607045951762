import { AxiosRequestConfig } from 'axios'

import { Story } from 'stubs/src/story'

import { UUID } from 'src/util/types'
import BaseAPI from 'src/api/BaseAPI'
import { APIPresentationAsset } from 'src/model/api/presentation/APIPresentationAsset'
import { APIPresentationAssetS3PresignedUrl } from 'src/model/api/presentation/APIPresentationAssetS3PresignedUrl'

/**
 * Client for presentation asset-related calls to the API.
 */
export default class AssetAPI extends BaseAPI {
  /**
   * Fetch assets from the version object assets URL
   *
   * @param assetsURL asset list URL from version object
   * @param config axios request configuration
   * @returns list of version assets
   */
  async fetchAssetsFromAssetsURL(
    assetsURL: string,
    config?: AxiosRequestConfig,
  ) {
    return this.withAuth().getAllPages<APIPresentationAsset>(assetsURL, config)
  }

  /**
   * Fetch all assets belonging to the given version
   *
   * @param versionUUID parent version UUID
   * @param config axios request configuration
   * @returns list of version assets
   */
  async fetchAssetsForVersion(versionUUID: UUID, config?: AxiosRequestConfig) {
    return this.withAuth().getAllPages<APIPresentationAsset>(
      `/versions/${versionUUID}/assets/`,
      config,
    )
  }

  /**
   * Fetch all assets belonging to the given version, but with CloudFront URLs
   * @param versionUUID parent version UUID
   * @param config axios request configuration
   * @returns list of version assets
   */
  async fetchAssetsForVersionRequestingDirectURL(
    versionUUID: UUID,
    config?: AxiosRequestConfig,
  ) {
    return this.withAuth().getAllPages<APIPresentationAsset>(
      `/versions/${versionUUID}/assets/?direct_url=true`,
      config,
    )
  }

  /**
   * Get the list of video frame URLs, assuming asset is a scrollmotion and frames have been generated
   *
   * @param assetURL asset url found in the asset model
   * @param config axios request configuration
   * @returns list of video frame URLs
   */
  async fetchFramesForVideoAssetURL(
    assetURL: string,
    config?: AxiosRequestConfig,
  ) {
    const response = await this.withAuth().get<string[]>(
      `${assetURL}?sm=true`,
      config,
    )
    return response.data
  }

  /**
   * Force the generation of frames for a scrollmotion video asset
   *
   * @param assetURL asset url found in the asset model
   * @param config axios request configuration
   * @returns list of video frame URLs
   */
  async forceFrameGenerationForVideoAssetURL(
    assetURL: string,
    config?: AxiosRequestConfig,
  ) {
    const response = await this.withAuth().post(
      `${assetURL}force_sm/`,
      undefined,
      config,
    )
    return response.data
  }

  /**
   * Download a stubs.json file from the given URL as an array buffer
   *
   * Decode and parse the array buffer response into JSON
   *
   * @param downloadURL stub's asset download URL
   * @param config axios request configuration
   * @returns version stub
   */
  async downloadStubFromURL(downloadURL: string, config?: AxiosRequestConfig) {
    const response = await this.withAuth().get<ArrayBuffer>(downloadURL, {
      ...config,
      responseType: 'arraybuffer',
    })
    return JSON.parse(new TextDecoder('utf-8').decode(response.data)) as Story
  }

  /**
   * Convert a version's content_spec.json to stubs.json
   *
   * @param versionUUID parent version UUID
   * @param config axios request configuration
   * @returns version stub
   */
  async convertContentSpecToStubsForVersion(
    versionUUID: UUID,
    config?: AxiosRequestConfig,
  ) {
    const response = await this.withAuth().get<Story>(
      `/versions/${versionUUID}/stub/`,
      config,
    )
    return response.data
  }

  /**
   * Searches for all asset tied to a specific version by their path
   *
   * @param versionUUID parent version UUID
   * @param path asset path
   * @param config axios request configuration
   */
  async findAssetsByPathFromVersion(
    versionUUID: UUID,
    path: string,
    config?: AxiosRequestConfig,
  ) {
    return this.withAuth().getAllPages<APIPresentationAsset>(
      `/versions/${versionUUID}/assets/?path=${path}`,
      config,
    )
  }

  /**
   * Create a new asset record on server, responds with S3 presigned URL for blob upload
   *
   * @param path asset path
   * @param versionUUID parent version UUID
   * @returns S3 Presigned URL
   */
  async uploadPreamble(path: string, versionUUID: UUID) {
    const uploadPreambleResponse =
      await this.withAuth().post<APIPresentationAssetS3PresignedUrl>(
        `/versions/${versionUUID}/assets/`,
        { path },
      )
    return uploadPreambleResponse.data
  }

  /**
   * Upload asset blob to S3 presigned URL provided by server
   *
   * @param s3PresignedUrl S3 Presigned URL provided by server for upload
   * @param blob asset blob to be uploaded
   */
  async uploadToS3(
    s3PresignedUrl: APIPresentationAssetS3PresignedUrl,
    blob: Blob,
  ) {
    const formData = new FormData()

    formData.set('key', s3PresignedUrl.key)
    formData.set('acl', s3PresignedUrl.acl)
    formData.set('policy', s3PresignedUrl.policy)
    formData.set('signature', s3PresignedUrl.signature)
    formData.set('Content-Type', s3PresignedUrl.content_type)
    formData.set('Cache-Control', s3PresignedUrl.cache_control)
    formData.set('AWSAccessKeyId', s3PresignedUrl.aws_access_key_id)
    formData.set(
      'success_action_redirect',
      s3PresignedUrl.success_action_redirect,
    )
    formData.set(
      'x-amz-server-side-encryption',
      s3PresignedUrl['x-amz-server-side-encryption'],
    )

    formData.append('file', blob)

    await fetch(s3PresignedUrl.post_action, {
      method: 'POST',
      mode: 'no-cors',
      body: formData,
    })
  }
}
