import { MixpanelClient } from 'src/util/analytics/MixpanelClient'
import { ExternalAnalyticsEvent } from 'src/model/external-analytics/ExternalAnalyticsEvent'
import { ExternalAnalyticsPage } from 'src/model/external-analytics/ExternalAnalyticsPage'
import { ExternalAnalyticsPayload } from 'src/model/external-analytics/ExternalAnalyticsPayload'
import { Profile } from 'src/model/user/Profile'
import { EnvConfig } from 'src/util/EnvConfig'
import { UUID } from 'src/util/types'

export namespace ExternalAnalyticsUtil {
  const mixpanelClient = new MixpanelClient(
    EnvConfig.getExternalMixpanelToken(),
  )

  let shouldDisableTracking = false

  export const setShouldDisableTracking = (isDisabled: boolean) => {
    shouldDisableTracking = isDisabled
  }

  /**
   * Generic event tracking that can go to multiple analytics services at once.
   *
   * @param userProfile
   * @param eventName
   * @param payload
   */
  export const trackEvent = (
    eventName: ExternalAnalyticsEvent,
    userProfile?: Profile,
    payload?: ExternalAnalyticsPayload,
  ) => {
    if (shouldDisableTracking) {
      return
    }
    trackMixpanelEvent(eventName, userProfile?.id, payload)
  }

  /**
   * Tracks a Page View event. All page views are standardized by funneling them through as a `page-view` event name
   * and the `page` is the unique name of the page being viewed.
   *
   * @param pageName
   * @param userProfile
   * @param payload
   */
  export const trackPageView = (
    pageName: ExternalAnalyticsPage,
    userProfile?: Profile,
    payload?: ExternalAnalyticsPayload,
  ) => {
    if (shouldDisableTracking) {
      return
    }
    trackMixpanelEvent(ExternalAnalyticsEvent.PAGE_VIEW, userProfile?.id, {
      page: pageName,
      ...payload,
    })
  }

  /**
   * Generic add person/entity operation for multiple analytics services.
   *
   * @param userUUID
   * @param payload
   */
  export const addPerson = (
    userUUID: UUID,
    payload: ExternalAnalyticsPayload,
  ) => {
    if (shouldDisableTracking) {
      return
    }
    addMixpanelPerson(userUUID, payload)
  }

  /**
   * Starts a timer for a Mixpanel event.
   * Think of this as the first of a set of bookends.
   * The track event for the same eventName is the last bookend.
   * @param eventName
   */
  export const startTimedMixpanelEvent = (
    eventName: ExternalAnalyticsEvent,
  ) => {
    if (shouldDisableTracking) {
      return
    }
    mixpanelClient.timeEvent(eventName)
  }

  /**
   * Tracks a Mixpanel event.
   *
   * @param eventName
   * @param userUUID optional user UUID
   * @param payload optional payload
   */
  export const trackMixpanelEvent = (
    eventName: ExternalAnalyticsEvent,
    userUUID?: UUID,
    payload?: ExternalAnalyticsPayload,
  ) => {
    if (shouldDisableTracking) {
      return
    }
    let mixPanelEventObj = {}
    if (payload) {
      mixPanelEventObj = { ...mixPanelEventObj, ...payload }
    }

    if (userUUID) {
      mixpanelClient.identify(userUUID)
    }
    mixpanelClient.track(eventName, mixPanelEventObj)
  }

  export const addMixpanelPerson = (
    userUUID: UUID,
    payload: ExternalAnalyticsPayload,
  ) => {
    if (shouldDisableTracking) {
      return
    }
    mixpanelClient.identify(userUUID)
    mixpanelClient.setPeople(payload)
  }

  export const setMixpanelSuperProperties = (
    payload: ExternalAnalyticsPayload,
  ) => {
    if (shouldDisableTracking) {
      return
    }
    mixpanelClient.register(payload)
  }
}
