import { Stub, Config } from '../../../../';
import { defaultThemeColors } from '../defaults/v3';
import { TinyColor } from '@ctrl/tinycolor';

export default (stub: Stub, config: Config): Stub => {
  stub.version = 3;

  if (config.template?.legacyStyles?.buttonTintColor) {
    const buttonTintColor = new TinyColor(config.template.legacyStyles.buttonTintColor)

    const primaryColor = buttonTintColor.toHex()
    const accentColor = buttonTintColor.toHex()

    let primaryPairing: string
    let accentPairing: string

    if (buttonTintColor.isDark()) {
      primaryPairing = accentPairing = 'FFFFFF'
    } else {
      primaryPairing = accentPairing = '000000'
    }

    stub.themeColors = [
      {
        color: primaryColor,
        identifier: "named-color-1",
        userFriendlyName: "Primary Color"
      },
      {
        color: primaryPairing,
        identifier: "named-color-2",
        userFriendlyName: "Primary Pairing"
      },
      {
        color: accentColor,
        identifier: "named-color-3",
        userFriendlyName: "Accent Color"
      },
      {
        color: accentPairing,
        identifier: "named-color-4",
        userFriendlyName: "Accent Pairing"
      }
    ]
  } else {
    stub.themeColors = defaultThemeColors()
  }

  return stub;
};
