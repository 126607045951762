import { Stub, Config, upgrade } from '../../../../';

export default (stub: Stub, config: Config): Stub => {
    stub.version = 5;

    if (stub.color == null) {
        stub.color = '#36a2e5';
    }

    if (stub.icon == null) {
        const keys = Object.keys(stub.buttonType);

        for (const key of keys) {
            if (typeof stub.buttonType[key] === 'object') {
                switch (key) {
                    case 'media':
                        stub.icon = 'media';
                        break;
                    case 'web':
                        stub.icon = 'web';
                        break;
                    case 'location':
                        stub.icon = 'map';
                        break;
                    case 'email':
                        stub.icon = 'email';
                        break;
                    case 'emailcapture':
                        stub.icon = 'emailCapture';
                        break;
                    case 'readme':
                        stub.icon = 'readme';
                        break;
                    case 'audio':
                        stub.icon = 'audio';
                        break;
                    case 'carousel':
                        stub.icon = 'carousel';
                        break;
                    case 'navigation':
                        stub.icon = 'navigation';
                        break;
                }
            }
        }
    }

    if (config.recursive) {
        const keys = Object.keys(stub.buttonType);

        for (const key of keys) {
            if (typeof stub.buttonType[key] === 'object') {
                stub.buttonType[key] = upgrade(stub.buttonType[key], config);
            } else {
                delete stub.buttonType[key];
            }
        }
    }

    return stub;
};
