import React from 'react'
import { Team } from 'src/model/company/Team'

/**
 * Makes it easier to know we're dealing with a UUID and not another string-based pattern when
 * dealing with IDs
 */
export type UUID = string

/**
 * Shorthand for a very verbose event
 */
export type HTMLInputEvent = React.ChangeEvent<HTMLInputElement>

/**
 * At the time of this writing, useLocation() has a property `state` as type `undefined` which is causing all sorts
 * of TypeScript compile time type safety errors. So we use this superficial type whenever dealing with Location.state
 */
export type LocationState = {
  from?: {
    pathname?: string
    query?: string // Optional querystring
    search?: string
  }
}

export type ConfirmationModalData = {
  title?: string
  message?: string | React.ReactNode
  okButtonLabel?: string
  okButtonId?: string
  cancelButtonLabel?: string
  cancelButtonId?: string
  isModalVisible?: boolean
  onOK?: () => void
  isLoading?: boolean
  shouldHideFooterButtons?: boolean
  hasCancelButton?: boolean
}

export type AlertMessage = {
  type:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'dark'
    | 'light'
    | string
  message: string
}

export type AlertToastProps = {
  type:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'dark'
    | 'light'
    | string
  headerTextPrimary?: string
  headerTextSecondary?: string
  bodyText?: JSX.Element | string
}

/**
 * Represents the Company.company_type field as returned from the backend, but associated with a human-readable identifier.
 */
export enum CompanyType {
  CUSTOMER = '0',
  DEMO = '1',
  TEST = '2',
  TRIAL = '3', // a variation of Customer requiring an expiration date
}

/**
 * Represents the CompanyUser.status field as returned from the backend, but associated with a human-readable identifier.
 */
export enum UserStatus {
  INACTIVE = 0,
  ACTIVE = 1,
  INVITED = 2,
}

/**
 * Unverified -- user has not activated their account
 * Valid -- user has activated their account
 * Invalid -- activation email bounced when sent to the user's email address
 */
export enum EmailStatus {
  UNVERIFIED = 0,
  VALID = 1,
  INVALID = 2,
}

/**
 * Represents various analytics campaign IDs with human-readable enum names.
 * @TODO: Possibly move this elsewhere as we flesh out analytics more
 */
export enum AnalyticsReportID {
  PRESENTATION_VIEWS_REPORT_ID = '1',
  PAGE_VIEWS_REPORT_ID = '5',
  SESSION_REPORT_ID = '7',
  SESSION_DETAIL_REPORT_ID = '8',
  USER_DETAIL_REPORT_ID = '9',
}

/**
 * Used for configuring CSV exports
 */
export type CSVExportOptions = {
  data: Record<string, unknown>[]
  filename?: string
  columns?: string[]
  separator?: string
  wrapper?: string
}

export type CSVImportOptions = {
  team?: Team
  shouldSendInviteEmails: boolean
  roleUUID: UUID
}

export type AddTeamMemberOptions = {
  role: number | UUID
  team: number | UUID
  user: number | UUID
}

/**
 * Used to denote the share type when sharing/unsharing web links for a presentation.
 */
export enum ShareType {
  PUBLIC = 'public',
  OWNER = 'owner',
}

export type PasswordStrengthResults = {
  doesMeetCriteria: boolean
  failures: string[]
}

/**
 * For modals that are intended to be re-used for CRUD operations
 */
export enum ModalMode {
  ADD = 'add',
  EDIT = 'edit',
  VIEW = 'view',
}

/**
 * list of Advanced Analytics aggregators which should support formatDuration
 */
export const validFormatAggregators = ['Sum', 'Average']

/**
 * list of pagination default page sizes
 */
export const defaultPaginationPageSizes = [8, 16, 32, 48]

/**
 * Represents hidden teams inside a company. These enum values are case sensitive.
 */
export enum HiddenTeam {
  COMPANY_TEMPLATES = '.Company Templates',
}

/**
 * Provides recursive Partial<T> support for objects. Useful for providing slices of a larger object tree for patch
 * operations while keeping them strongly typed.
 */
export type DeepPartial<T> = {
  [K in keyof T]?: T[K] extends object ? DeepPartial<T[K]> : T[K]
}

export type Option<VALUE_TYPE, LABEL_TYPE> = {
  value: VALUE_TYPE
  label: LABEL_TYPE
}

export type Nullable<T> = T | null

/**
 * Props for partner landing page signup forms
 */
export type LandingFormProps = {
  onSuccess?: (
    contactID: string,
    importCSVString: string,
    setupURL: string,
    message: string,
  ) => void
  onError?: (message: string) => void
  isVisible?: boolean
}

// region Files and File Types
export type FileType = 'image' | 'video' | 'file'

export type AllowedFileTypes = FileType | FileType[]

export const MIME_TYPES: Record<string, string[]> = {
  image: ['image/jpeg', 'image/png'],
  video: [
    'video/quicktime',
    'video/webm',
    'video/mp4',
    'video/x-m4v',
    'video/x-matroska',
  ],
}
// endregion Files and File Types

export const ANALYTICS_THUMBNAIL_SIZE = 256

export enum Brand {
  INGAGE = 'ingage',
  SONANCE = 'sonance',
  REBATH = 'rebath',
}

export type Diff<T> = {
  add: T[]
  remove: T[]
  update: T[]
}
