import { AxiosRequestConfig } from 'axios'

import BaseAPI from 'src/api/BaseAPI'
import { UUID } from 'src/util/types'
import { APISavedReport } from 'src/model/api/analytics/APISavedReport'
import { APISavedReportOptions } from 'src/model/api/analytics/APISavedReportOptions'
import { APISavedReportWithLabels } from 'src/model/api/analytics/APISavedReportWithLabels'
import {
  APIAnalyticsReport,
  APIAnalyticsEvent,
  APIAnalyticsSavedReport,
  APIAnalyticsSavedReportOptions,
} from 'src/model/api/analytics/APIAnalytics'

interface postObj {
  [key: string]: unknown
}

export type GetAnalyticsEventsOptions = {
  reportID: number | UUID
  startDate: string
  endDate: string
  sessionUUID?: UUID
  userUUID?: UUID
}

/**
 * Client for analytics-related calls to the API.
 */
export default class AnalyticsAPI extends BaseAPI {
  /**
   * Returns a list of globally used analytics reports
   */
  async getAnalyticsReports() {
    return this.withAuth().get<APIAnalyticsReport[]>(
      '/team/api/analyticsreports/',
    )
  }

  /**
   * Returns a list of analytics events
   * @param companyUUID
   * @param reportID
   * @param startDate
   * @param endDate
   * @param sessionUUID
   */
  async getAnalyticsEvents(
    companyUUID: UUID,
    options: GetAnalyticsEventsOptions,
    config?: AxiosRequestConfig,
  ) {
    const postObj: postObj = {
      start_date: options.startDate,
      end_date: options.endDate,
      company_uuid: companyUUID,
    }
    if (options.sessionUUID) {
      postObj['session_id'] = options.sessionUUID
    } else {
      postObj['user_id'] = options.userUUID ? options.userUUID : ''
    }
    const postData = JSON.stringify(postObj)
    return this.withAuth().post<APIAnalyticsEvent[]>(
      `/team/api/company/${companyUUID}/mixpanel/${options.reportID}/get_events/`,
      postData,
      config,
    )
  }

  /**
   * Returns a list of analytics saved reports
   * @param companyUUID
   */
  async getAnalyticsSavedReports(companyUUID: UUID) {
    // TODO(joel): update this to use the getAll wrapper when available
    return this.withAuth().getAllPages<APIAnalyticsSavedReport>(
      `/team/api/company/${companyUUID}/analytics-saved-reports/`,
    )
  }

  /**
   * Saves a new saved report
   * @param companyUUID
   * @param options
   */
  async saveNewAnalyticsSavedReport(
    companyUUID: UUID,
    options: APIAnalyticsSavedReportOptions,
  ) {
    const postData = JSON.stringify({
      name: options.name,
      description: options.description,
      url_params: options.urlParams,
      params: options.params,
      is_extract: options.isExtract,
      export_frequency: options.exportFrequency,
      export_recipients: options.exportRecipients,
    })
    return this.withAuth().post<APIAnalyticsSavedReport>(
      `/team/api/company/${companyUUID}/analytics-saved-reports/`,
      postData,
    )
  }

  /**
   * Updates an existing saved report
   * @param companyUUID
   * @param savedReportUUID
   * @param options
   */
  async updateAnalyticsSavedReport(
    companyUUID: UUID,
    savedReportUUID: UUID,
    options: APIAnalyticsSavedReportOptions,
  ) {
    const patchData = JSON.stringify({
      name: options.name,
      description: options.description,
      url_params: options.urlParams,
      params: options.params,
      is_extract: options.isExtract,
      export_frequency: options.exportFrequency,
      export_recipients: options.exportRecipients,
    })
    return this.withAuth().patch<APIAnalyticsSavedReport>(
      `/team/api/company/${companyUUID}/analytics-saved-reports/${savedReportUUID}/`,
      patchData,
    )
  }

  /**
   * Delete an existing saved report
   * @param companyUUID
   * @param savedReportUUID
   */
  async deleteAnalyticsSavedReport(companyUUID: UUID, savedReportUUID: UUID) {
    return this.withAuth().delete(
      `/team/api/company/${companyUUID}/analytics-saved-reports/${savedReportUUID}/`,
    )
  }

  /**
   * Returns a list of saved report events
   * @param companyUUID
   * @param savedReportUUID
   * @param startDate
   * @param endDate
   * @param limit
   */
  async getAnalyticsSavedReportsEvents(
    companyUUID: UUID,
    savedReportUUID: UUID,
    startDate: string,
    endDate: string,
    limit: string,
  ) {
    const postObj: postObj = {
      start_date: startDate,
      end_date: endDate,
      limit: limit,
    }
    const postData = JSON.stringify(postObj)
    return this.withAuth().post<APIAnalyticsEvent[]>(
      `/team/api/company/${companyUUID}/analytics-saved-reports/${savedReportUUID}/get_events/`,
      postData,
    )
  }

  /**
   * Returns a list of saved report events
   * @param companyUUID
   * @param options
   * @param startDate
   * @param endDate
   */
  async getAnalyticsSavedReportByOptions(
    companyUUID: UUID,
    options: { [key: string]: unknown },
    startDate: string,
    endDate: string,
    config?: AxiosRequestConfig,
  ) {
    const postObj: postObj = {
      options: options.params,
      start_date: startDate,
      end_date: endDate,
    }
    const postData = JSON.stringify(postObj)
    return this.withAuth().post<APIAnalyticsEvent[]>(
      `/team/api/company/${companyUUID}/analytics/get_events/`,
      postData,
      config,
    )
  }

  /**
   * Returns a single page from the list of saved report events for given company
   *
   * @param companyUUID company UUID
   * @param page page number
   * @param pageSize page size
   * @param config axios configuration
   * @returns a list of saved reports
   */
  async getPaginatedSavedReports(
    companyUUID: UUID,
    page: number,
    pageSize: number,
    config?: AxiosRequestConfig,
  ) {
    return this.V1()
      .withAuth()
      .getPage<APISavedReport[]>(
        `/company/${companyUUID}/analytics/saved-reports/`,
        page,
        pageSize,
        { ...config },
      )
  }

  /**
   * Returns a single saved report by UUID for given company
   *
   * @param companyUUID company UUID
   * @param savedReportUUID report UUID
   * @param config axios configuration
   * @returns details of the saved report
   */
  async getSavedReport(
    companyUUID: UUID,
    savedReportUUID: UUID,
    config?: AxiosRequestConfig,
  ) {
    return this.V1()
      .withAuth()
      .get<APISavedReportWithLabels>(
        `/company/${companyUUID}/analytics/saved-reports/${savedReportUUID}/`,
        config,
      )
  }

  /**
   * Creates a new saved report for given company with provided options
   *
   * @param companyUUID company UUID
   * @param options configuration for the saved report
   * @returns details of the saved report
   */
  async createSavedReport(companyUUID: UUID, options: APISavedReportOptions) {
    return this.V1()
      .withAuth()
      .post<APISavedReportWithLabels>(
        `/company/${companyUUID}/analytics/saved-reports/`,
        JSON.stringify(options),
      )
  }

  /**
   * Updates an existing saved report for given company with provided options
   *
   * @param companyUUID company UUID
   * @param savedReportUUID report UUID
   * @param options configuration for the saved report
   * @returns details of the saved report
   */
  async updateSavedReport(
    companyUUID: UUID,
    savedReportUUID: UUID,
    options: APISavedReportOptions,
  ) {
    return this.V1()
      .withAuth()
      .patch<APISavedReport>(
        `/company/${companyUUID}/analytics/saved-reports/${savedReportUUID}/`,
        JSON.stringify(options),
      )
  }

  /**
   * Deletes an existing saved report by UUID for given company
   *
   * @param companyUUID company UUID
   * @param savedReportUUID report UUID
   * @returns void
   */
  async deleteSavedReport(companyUUID: UUID, savedReportUUID: UUID) {
    return this.V1()
      .withAuth()
      .delete<void>(
        `/company/${companyUUID}/analytics/saved-reports/${savedReportUUID}/`,
      )
  }
}
