import { Stub, Config, upgrade } from '../../../../';

export default (stub: Stub, config: Config): Stub => {
    stub.version = 2;

    for (let i = 0; i < stub.items.length; i++) {
        const item = stub.items[i];

        if (!item.type) {
            item.type = 'button-component';
        }
        if (!item.version) {
            item.version = 1;
        }
    }

    if (config.recursive) {
        for (let i = 0; i < stub.items.length; i++) {
            const item = stub.items[i];
            stub.items[i] = upgrade(item, config);
        }
    }

    return stub;
};
