export namespace FontHelper {

  /**
   * These mappings are based on the following specifications:
   * - https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight
   * - https://github.com/jonathantneal/postcss-font-weights
   *
   * The order of these mappings also searches for "extra"/"ultra" fonts first.
   */
  export const fontWeightMap = [
    { pattern: 'extralight', weight: 200 },
    { pattern: 'ultralight', weight: 200 },
    { pattern: 'extrabold', weight: 800 },
    { pattern: 'ultrabold', weight: 800 },
    { pattern: 'extrablack', weight: 950 },
    { pattern: 'ultrablack', weight: 950 },
    { pattern: 'ultra', weight: 950 },
    { pattern: 'semibold', weight: 600 },
    { pattern: 'demi', weight: 600 },
    { pattern: 'demibold', weight: 600 },
    { pattern: 'thin', weight: 100 },
    { pattern: 'hairline', weight: 100 },
    { pattern: 'light', weight: 300 },
    { pattern: 'regular', weight: 400 },
    { pattern: 'italic', weight: 400 }, // For instances where they don't do "book italic", "italic" always assumes default weight
    { pattern: 'italique', weight: 400 },
    { pattern: 'book', weight: 400 },
    { pattern: 'normal', weight: 400 },
    { pattern: 'medium', weight: 500 },
    { pattern: 'roman', weight: 400 },
    { pattern: 'bold', weight: 700 },
    { pattern: 'black', weight: 900 },
    { pattern: 'fatface', weight: 900 }, // Thanks, Bodoni*
    { pattern: 'heavy', weight: 900 },
    { pattern: 'gras', weight: 900 },
  ]

  /**
   * Returns given string with first character transformed to upper case
   *
   * @param s string to capitalize
   */
  export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1)

  /**
   * Attempts to determine the CSS font weight, style and stretch of the given font subfamily name.
   * No consistent font metadata exists where we can get these values from the font files themselves.
   *
   * @param subfamilyName e.g. 'bold' or 'Extra Light'
   * @param defaultFontWeight Default weight to return, e.g. 400
   */
  export const guessFontPropsForSubfamilyName = (subfamilyName: string = '', defaultFontWeight: number = 400) => {
    // Convert the style to lowercase and remove any spaces or dashes. This way we don't have to check several
    // permutations of a style, e.g. "extralight" vs "Extra Light" vs "ExtraLight"
    const trimmedSubfamilyName = subfamilyName.trim()
    const normalizedSubfamilyName = trimmedSubfamilyName.toLowerCase().replace(/\s|-|\d/g, '')

    const fontWeight = FontHelper.guessFontWeightForSubfamilyName(trimmedSubfamilyName, normalizedSubfamilyName, defaultFontWeight)
    const fontStyle = FontHelper.guessFontStyleForSubfamilyName(normalizedSubfamilyName)
    const fontStretch = FontHelper.guessFontStretchForSubfamilyName(normalizedSubfamilyName)

    return {
      fontWeight,
      fontStyle,
      fontStretch
    }
  }

  /**
   * Attempts to determine the font weight of the font based on the subfamily name given.
   *
   * @param trimmedSubfamilyName trimmed font subfamily name
   * @param normalizedSubfamilyName normalized font subfamily name
   * @param defaultValue Default weight to return, e.g. 400
   */
  export const guessFontWeightForSubfamilyName = (trimmedSubfamilyName: string, normalizedSubfamilyName: string, defaultValue: number = 400): number => {
    let weight = defaultValue

    // Some fonts styles represent the actual numeric
    // weight of the font, in which case, we must use it (DESKTOP-9381)
    const numericWeight = parseInt(trimmedSubfamilyName)

    // Handle weird naming convention for Alex's Avenir font (DESKTOP-9108)
    const doubleDigitRegex = /^(\d\d)\s/
    const doubleDigitResult = doubleDigitRegex.exec(trimmedSubfamilyName)

    if (!isNaN(numericWeight)) {
      weight = numericWeight
    }

    else if (doubleDigitResult && doubleDigitResult[0]) {
      weight = +doubleDigitResult[0] * 10
    }

    // We always want to match on the starting word, because we don't want to misidentify a style like "Bold Italic" to
    // return a weight of `400` because it matched `italic` before matching `bold` (a font style of `italic` alone
    // indicates a normal 400 font weight).
    else {
      weight = FontHelper.fontWeightMap
      .find(item => normalizedSubfamilyName.match(new RegExp(`^${item.pattern}`, 'g')))?.weight
      ?? defaultValue
    }

    return weight
  }

  /**
   * Attempts to determine the font style of the font based on the subfamily name given.
   *
   * @param normalizedSubfamilyName normalized font subfamily name
   */
  export const guessFontStyleForSubfamilyName = (normalizedSubfamilyName: string) => {
    if (normalizedSubfamilyName.match(/italic|oblique|italique/ig)) {
      return 'italic'
    }

    return 'normal'
  }

  /**
   * Attempts to determine the font stretch of the font based on the subfamily name given.
   *
   * @param normalizedSubfamilyName normalized font subfamily name
   */
  export const guessFontStretchForSubfamilyName = (normalizedSubfamilyName: string) => {
    if (normalizedSubfamilyName.match(/extracompressed/ig)) {
      return 'ultra-condensed'
    }

    if (normalizedSubfamilyName.match(/compressed/ig)) {
      return 'extra-condensed'
    }

    if (normalizedSubfamilyName.match(/cond|condensed|condensé/ig)) {
      return 'condensed'
    }

    if (normalizedSubfamilyName.match(/expanded|expansé/ig)) {
      return 'expanded'
    }

    return 'normal'
  }

  /**
   * Helper function to sort font subfamily names
   */
  export const compareFontSubFamilyNames = (subfamilyNameA: string, subfamilyNameB: string) => {
    const numericWeightA = getNumericWeightForSubfamilyName(subfamilyNameA)
    const numericWeightB = getNumericWeightForSubfamilyName(subfamilyNameB)

    return numericWeightA - numericWeightB
  }

  /**
   * Returns the sort order for a particular font style given a FontItem. Used when sorting fonts in the Font Manager.
   */
  export const getNumericWeightForSubfamilyName = (subfamilyName: string): number => {
    const { fontWeight, fontStyle, fontStretch } = FontHelper.guessFontPropsForSubfamilyName(subfamilyName, 9999)

    // use font weight as the base numeric weight
    let numericWeight = fontWeight

    // slight bump to italicized fonts
    if (fontStyle === 'italic') {
      numericWeight += 0.5
    }

    //
    switch (fontStretch) {
      case 'expanded':
        numericWeight += 5
        break
      case 'condensed':
        numericWeight += 4
        break
      case 'extra-condensed':
        numericWeight += 3
        break
      case 'ultra-condensed':
        numericWeight += 2
        break
    }

    return numericWeight
  }
}
