import { Stub, Config, Context, upgrade } from '../../../../';

export default (stub: Stub, config: Config): Stub => {
    stub.version = 2;
    stub.theme = {
        baseColor: stub.cosmeticCellColorA,
        mode: 'light',
    };

    delete stub.cosmeticCellColorA;
    if (config.recursive) {
        stub.headingText = upgrade(stub.headingText, config);
        stub.bodyText = upgrade(stub.bodyText, config);
        stub.media = upgrade(stub.media, config, getSize(stub));
    }
    return stub;
};

const getSize = (page: Stub): Context => {
    const mapTranslateX: { [key: string]: number } = {
        splitAuto: 768.0,
        nineBySixteenTextRight: 625,
        threeByFourTextRight: 816,
        oneByOneTextRight: 752,
        fourByThreeTextBottom: 672,
        sixteenByNineTextBottom: 768.0,
        fullTextCenter: 768.0,
        fullTextBottomLeft: 768.0,
    };
    const mapTranslateY: { [key: string]: number } = {
        splitAuto: 713,
        nineBySixteenTextRight: 630,
        threeByFourTextRight: 544,
        oneByOneTextRight: 630,
        fourByThreeTextBottom: 744,
        sixteenByNineTextBottom: 632,
        fullTextCenter: 513.0,
        fullTextBottomLeft: 513.0,
    };
    return {
        width: mapTranslateX[page.layout],
        height: mapTranslateY[page.layout],
    };
};
