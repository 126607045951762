import { APIFont } from '../types/APIFont'
import { Font } from '../types/Font'

export namespace FontModel {
  export const fontFromAPIModel = (apiFont: APIFont) => {
    const font: Font = {
      uuid: apiFont.uuid,
      updatedAt: apiFont.updated_at,
      md5: apiFont.md5,
      displayName: apiFont.display_name,
      familyName: apiFont.family_name,
      subfamilyName: apiFont.subfamily_name,
      contentType: apiFont.content_type,
      downloadURL: apiFont.download,
      glyphData: apiFont.font_meta.glyphData,
      isReadOnly: apiFont.is_readonly
    }
    return font
  }
}
