import { AxiosRequestConfig } from 'axios'
import BaseAPI from 'src/api/BaseAPI'
import { APIPutAutomateResponse } from 'src/model/api/automation/APIPutAutomateResponse'
import { APIAutomationItem } from 'src/model/api/automation/automate/APIAutomationItem'
import { APIMarketplaceCategory } from 'src/model/api/marketplace/APIMarketplaceCategory'
import { APIMarketplaceCreateListingPayload } from 'src/model/api/marketplace/APIMarketplaceCreateListingPayload'
import { APIMarketplaceCreateListingReponse } from 'src/model/api/marketplace/APIMarketplaceCreateListingResponse'
import { APIMarketplaceDistributedItem } from 'src/model/api/marketplace/APIMarketplaceDistributedItem'
import { APIMarketplaceHatchSpeedToLeadEmailPayload } from 'src/model/api/marketplace/APIMarketplaceHatchSpeedToLeadEmailPayload'
import { APIMarketplaceItem } from 'src/model/api/marketplace/APIMarketplaceItem'
import { APIMarketplaceListing } from 'src/model/api/marketplace/APIMarketplaceListing'
import { APIMarketplaceListingAsset } from 'src/model/api/marketplace/APIMarketplaceListingAsset'
import { APIMarketplaceListingPurchaseStatus } from 'src/model/api/marketplace/APIMarketplaceListingPurchaseStatus'
import { APIMarketplacePublisher } from 'src/model/api/marketplace/APIMarketplacePublisher'
import { APIMarketplaceS3UploadPresignedUrl } from 'src/model/api/marketplace/APIMarketplaceS3UploadPresignedUrl'
import { APIPromoCode } from 'src/model/api/marketplace/APIPromoCode'
import { APIPromoCodeRedeemResponse } from 'src/model/api/marketplace/APIPromoCodeRedeemResponse'
import { MarketplaceItem } from 'src/model/marketplace/MarketplaceItem'
import { CategoryListFilter } from 'src/util/filter/marketplace/CategoryListFilter'
import { ListingItemListFilter } from 'src/util/filter/marketplace/ListingItemListFilter'
import { ListingListFilter } from 'src/util/filter/marketplace/ListingListFilter'
import { Nullable, UUID } from 'src/util/types'
import { APIMarketplaceItemSearchResult } from '../model/api/marketplace/APIMarketplaceItemSearchResult'
import { APISendEmailPayload } from 'src/model/api/marketplace/APISendEmailPayload'

/**
 * Client for marketplace-related calls to the API.
 */
export default class MarketplaceAPI extends BaseAPI {
  /**
   * Fetch a list of Marketplace Listings (products for sale on the Marketplace)
   */
  async getAllMarketplaceListings(filter?: ListingListFilter) {
    return this.withAuth().getAllPages<APIMarketplaceListing>(
      '/marketplace/listings/',
      { params: filter?.getParams() },
    )
  }

  /**
   * Fetch a single page off all Marketplace Listings
   */
  async getMarketplaceListings(
    page: number,
    pageSize: number,
    filter?: ListingListFilter,
    config?: AxiosRequestConfig,
  ) {
    return this.withAuth().getPage<APIMarketplaceListing[]>(
      '/marketplace/listings/',
      page,
      pageSize,
      {
        ...config,
        params: filter?.getParams(),
      },
    )
  }

  /**
   * Fetch a specific Marketplace Listing
   */
  async getMarketplaceListing(listingUUID: UUID) {
    return this.withAuth().get<APIMarketplaceListing>(
      `/marketplace/listings/${listingUUID}/`,
    )
  }

  /**
   * Fetch a specific Marketplace Listing by its slug
   */
  async getMarketplaceListingBySlug(slug: string) {
    return this.withAuth().get<APIMarketplaceListing>(
      `/marketplace/listings/${slug}/`,
    )
  }

  /**
   * Fetch all items included with a Marketplace listing
   */
  async getMarketplaceListingItemList(
    listingUUID: UUID,
    filter?: ListingItemListFilter,
  ) {
    return this.withAuth().getAllPages<APIMarketplaceItem>(
      `/marketplace/listings/${listingUUID}/items/`,
      {
        params: filter?.getParams(),
      },
    )
  }

  /**
   * Fetch all assets associated with a Marketplace listing (these are supporting media such as preview thumbnails)
   */
  async getMarketplaceListingAssetList(
    listingUUID: UUID,
    config?: AxiosRequestConfig,
  ) {
    return this.withAuth().getAllPages<APIMarketplaceListingAsset>(
      `/marketplace/listings/${listingUUID}/assets/`,
      config,
    )
  }

  /**
   * Fetch all categories associated with a Marketplace listing
   */
  async getMarketplaceListingCategoryList(listingUUID: UUID) {
    return this.withAuth().getAllPages<APIMarketplaceCategory>(
      `/marketplace/listings/${listingUUID}/categories/`,
    )
  }

  /**
   * Fetch all categories used in the Marketplace
   */
  async getMarketplaceCategoryList(config?: AxiosRequestConfig) {
    return this.withAuth().getAllPages<APIMarketplaceCategory>(
      `/marketplace/categories/`,
      config,
    )
  }

  /**
   * Fetch a specific category used in the Marketplace
   */
  async getMarketplaceCategory(
    categoryUUID: UUID,
    config?: AxiosRequestConfig,
  ) {
    return this.withAuth().get<APIMarketplaceCategory>(
      `/marketplace/categories/${categoryUUID}/`,
      config,
    )
  }

  /**
   * Fetch a specific category used in the Marketplace by its slug
   */
  async getMarketplaceCategoryBySlug(
    slug: string,
    config?: AxiosRequestConfig,
  ) {
    return this.withAuth().get<APIMarketplaceCategory>(
      `/marketplace/categories/${slug}/`,
      config,
    )
  }

  /**
   * Fetch all listings associated with a category
   */
  async getAllMarketplaceListingsUnderCategory(
    categoryUUID: UUID,
    filter?: CategoryListFilter,
  ) {
    return this.withAuth().getAllPages<APIMarketplaceListing>(
      `/marketplace/categories/${categoryUUID}/listings/`,
      {
        params: filter?.getParams(),
      },
    )
  }

  /**
   * Fetch a single page off listings associated with a category
   */
  async getMarketplaceListingsUnderCategory(
    categoryUUID: UUID,
    page: number,
    pageSize: number,
    config?: AxiosRequestConfig,
  ) {
    return this.withAuth().getPage<APIMarketplaceListing[]>(
      `/marketplace/categories/${categoryUUID}/listings/`,
      page,
      pageSize,
      config,
    )
  }

  /**
   * Asks the API to install the marketplace listing to the specified company--and subsequent team(s), if applicable.
   *
   * @param companyUUID
   * @param listingUUID
   * @param teamUUIDs An optional array of team UUIDs to install listing items to (when applicable)
   */
  async distributeListing(
    companyUUID: UUID,
    listingUUID: UUID,
    teamUUIDs: UUID[] = [],
    makeItemsUnavailable: UUID[] = [],
  ) {
    const postData = {
      company: companyUUID,
      teams: teamUUIDs,
      make_items_unavailable: makeItemsUnavailable,
    }
    return this.withAuth().post<APIMarketplaceDistributedItem[]>(
      `/marketplace/listings/${listingUUID}/distribute/`,
      postData,
    )
  }

  /**
   * Asks the API to generate n promo codes for a particular Marketplace listing.
   *
   * @param listingUUID
   * @param numCodes The number of codes to generate
   */
  async createPromoCodeBatch(
    listingUUID: UUID,
    numCodes: number = 1,
    isSingleUse = false,
    expiry: Nullable<Date> = null,
  ) {
    const data = {
      num_codes: numCodes,
      data: {
        listing: listingUUID,
        is_single_use: isSingleUse,
        expires_at: expiry ? expiry.toISOString() : null,
      },
    }
    return this.withAuth().post<APIPromoCode[]>(
      `/marketplace/promocodes/batch_create/`,
      data,
    )
  }

  /**
   * Fetch detail for a promo code
   */
  async getPromoCodeDetail(code: string) {
    return this.withAuth().get<Pick<APIPromoCode, 'listing'>>(
      `/marketplace/promocodes/${code}/`,
    )
  }

  /**
   * Fetches whether or not a listing UUID been purchased by the requesting user.
   */
  async getListingPurchaseStatus(listingUUID: UUID) {
    return this.withAuth().get<APIMarketplaceListingPurchaseStatus>(
      `/marketplace/promocodes/${listingUUID}/purchase_status/`,
    )
  }

  /**
   * Attempts to redeem a promo code
   */
  async redeemPromoCode(code: string) {
    return this.withAuth().post<APIPromoCodeRedeemResponse>(
      `/marketplace/promocodes/${code}/redeem/`,
    )
  }

  /**
   * Perform automation on a presentation with the given share alias
   *
   * @param shareAlias Share alias for presentation
   * @param payload Automation Payload
   * @param clone Clone the presentation before performing automation. This is not necessary for Marketplace QuickStart
   *              templates because the distribute endpoint will already clone it.
   * @param companyUUID Company UUID to associate the automated content if we're attempting to automate outside the
   *                    Marketplace distribute method (e.g. by the Automation Tester).
   * @returns
   */
  async performAutomation(
    shareAlias: string,
    payload: APIAutomationItem[],
    clone: boolean = false,
    companyUUID?: UUID,
  ) {
    const params = new URLSearchParams()
    if (clone) {
      params.set('clone_first', 'true')
    }
    if (companyUUID) {
      params.set('company_uuid', companyUUID)
    }
    return this.withAuth().put<APIPutAutomateResponse>(
      `/marketplace/automate/${shareAlias}/?${params}`,
      payload,
    )
  }

  /**
   * Sends an email to the user with the automated hatch speed to lead presentation urls
   *
   * @param apiMarketplaceHatchSpeedToLeadEmailPayload
   * @returns
   */
  async sendHatchSpeedToLeadEmail(
    apiMarketplaceHatchSpeedToLeadEmailPayload: APIMarketplaceHatchSpeedToLeadEmailPayload,
  ) {
    return this.withAuth().post<APIPutAutomateResponse>(
      '/marketplace/email/hatch_speed_to_lead/send/',
      apiMarketplaceHatchSpeedToLeadEmailPayload,
    )
  }

  /**
   * Sends an email
   *
   * @param apiKey
   * @param payload Content of the email
   * @returns
   */
  async sendEmail(apiKey: string, payload: APISendEmailPayload) {
    const payloadWithDefaults: APISendEmailPayload = {
      fail_silently: true,
      ...payload,
    }
    return this.withPublicAPIKey(apiKey).post(
      '/pubapi/email/send/',
      payloadWithDefaults,
    )
  }

  /**
   * Internal use only: Create a new Marketplace Listing. Requires feature flag: `marketplace-admin`.
   *
   * @param apiCreateListingPayload
   * @returns newly created marketplace listing plus S3 upload presigned urls
   */
  async createMarketplaceListingOld(
    apiCreateListingPayload: APIMarketplaceCreateListingPayload,
  ) {
    return this.withAuth()
      .post<APIMarketplaceCreateListingReponse>(
        '/marketplace/listings/create_listing/',
        apiCreateListingPayload,
      )
      .then((res) => res.data)
  }

  /**
   * Upload a Marketplace Listing Asset to S3. Requires feature flag: `marketplace-admin`.
   *
   * @param file dropped File object
   * @param s3UploadPresignedUrl presigned URL from S3
   * @returns Marketplace Listing Asset
   */
  async uploadMarketplaceAsset(
    file: File,
    s3UploadPresignedUrl: APIMarketplaceS3UploadPresignedUrl,
  ) {
    const formData = new FormData()

    formData.set('key', s3UploadPresignedUrl.key)
    formData.set('acl', s3UploadPresignedUrl.acl)
    formData.set('policy', s3UploadPresignedUrl.policy)
    formData.set('signature', s3UploadPresignedUrl.signature)
    formData.set('Content-Type', s3UploadPresignedUrl['Content-Type'])
    formData.set('Cache-Control', s3UploadPresignedUrl['cache_control'])
    formData.set('AWSAccessKeyId', s3UploadPresignedUrl.AWSAccessKeyId)
    formData.set(
      'success_action_redirect',
      s3UploadPresignedUrl.success_action_redirect,
    )
    formData.set(
      'x-amz-server-side-encryption',
      s3UploadPresignedUrl['x-amz-server-side-encryption'],
    )

    formData.append('file', file)

    await fetch(s3UploadPresignedUrl.post_action, {
      method: 'POST',
      mode: 'no-cors',
      body: formData,
    })
  }

  /**
   * Searches for items to add to a Marketplace Listing. Requires feature flag: `marketplace-admin`.
   * @param contentType 'version' | 'theme' | 'font'
   * @param query
   */
  async searchItems(contentType: string, query: string) {
    return this.withAuth().get<APIMarketplaceItemSearchResult[]>(
      `/marketplace/internal/search_items/?content_type=${contentType}&q=${query}`,
    )
  }

  /**
   * For a given Version for a piece of Content, check for a newer published Version for that Content.
   * @param versionUUID The version UUID currently being used for a Marketplace Item.
   */
  async getLatestVersion(versionUUID: string) {
    return this.withAuth().get<APIMarketplaceItemSearchResult>(
      `/marketplace/internal/newer_published_version/?uuid=${versionUUID}`,
    )
  }

  /**
   * Checks if a Marketplace Listing's name already exists (technically, the search engine friendly slug of the listing).
   * Returns HTTP 404 if the name does not exist, HTTP 200 if the name is found.
   * @param name
   */
  async checkName(name: string) {
    return this.withAuth().get(`/marketplace/internal/check_name/?name=${name}`)
  }

  async createItem(listingUUID: string, item: Partial<APIMarketplaceItem>) {
    return this.withAuth().post<APIMarketplaceItem>(
      `/marketplace/listings/${listingUUID}/items/`,
      item,
    )
  }

  async createAsset(
    listingUUID: string,
    asset: Partial<APIMarketplaceListingAsset>,
  ) {
    return this.withAuth().post<APIMarketplaceListingAsset>(
      `/marketplace/listings/${listingUUID}/assets/`,
      asset,
    )
  }

  async createCategory(category: Partial<APIMarketplaceCategory>) {
    return this.withAuth().post<APIMarketplaceCategory>(
      `/marketplace/categories/`,
      category,
    )
  }

  async updateListing(uuid: string, listing: Partial<APIMarketplaceListing>) {
    return this.withAuth().patch<APIMarketplaceListing>(
      `/marketplace/listings/${uuid}/`,
      listing,
    )
  }

  async updateItem(
    listingUUID: string,
    itemUUID: string,
    item: Partial<APIMarketplaceItem>,
  ) {
    return this.withAuth().patch<MarketplaceItem>(
      `/marketplace/listings/${listingUUID}/items/${itemUUID}/`,
      item,
    )
  }

  async updateAsset(
    listingUUID: string,
    assetUUID: string,
    asset: Partial<APIMarketplaceListingAsset>,
  ) {
    return this.withAuth().patch<APIMarketplaceListingAsset>(
      `/marketplace/listings/${listingUUID}/assets/${assetUUID}/`,
      asset,
    )
  }

  async updateCategory(
    uuid: string,
    category: Partial<APIMarketplaceCategory>,
  ) {
    return this.withAuth().patch<APIMarketplaceCategory>(
      `/marketplace/categories/${uuid}/`,
      category,
    )
  }

  async removeItem(listingUUID: string, itemUUID: string) {
    return this.withAuth().delete(
      `/marketplace/listings/${listingUUID}/items/${itemUUID}/`,
    )
  }

  async removeAsset(listingUUID: string, assetUUID: string) {
    return this.withAuth().delete(
      `/marketplace/listings/${listingUUID}/assets/${assetUUID}/`,
    )
  }

  async getMarketplacePublisher(
    publisherSlug: string,
    config?: AxiosRequestConfig,
  ) {
    return this.withAuth().get<APIMarketplacePublisher>(
      `/marketplace/publishers/${publisherSlug}/`,
      config,
    )
  }

  async getMarketplacePublisherListings(
    publisherSlug: string,
    page: number,
    pageSize: number,
    config?: AxiosRequestConfig,
  ) {
    return this.withAuth().getPage<APIMarketplaceListing[]>(
      `/marketplace/publishers/${publisherSlug}/listings/`,
      page,
      pageSize,
      config,
    )
  }
}
