/**
 * Ensures that every "filter" has a consistent params property and getParams() method declared
 */
import { isBoolean, isNumber } from 'lodash'

export class Filter {
  protected params: Record<string, string> = {}

  getParams() {
    return this.params
  }

  protected addParam(key: string, value: string | number | boolean) {
    if (isNumber(value) || isBoolean(value)) {
      this.params[key] = `${value}`
    } else {
      this.params[key] = value
    }
  }
}
