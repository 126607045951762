import { Stub, Config, upgrade } from '../../../../';

export default (stub: Stub, config: Config): Stub => {
    stub.version = 2;

    if (stub.legacyStyles === undefined) {
        stub.legacyStyles = null;
    }

    return stub;
};
