import PropTypes from 'prop-types'
import React, { ReactNode } from 'react'
import { AppContext } from './AppContext'
import { RootStore } from './store/RootStore'

let store: RootStore | undefined
const AppStoreProvider = ({
  children,
  injectedRootStore,
}: {
  children: ReactNode
  injectedRootStore?: RootStore
}) => {
  const rootStore = store ?? injectedRootStore ?? RootStore.getInstance()
  return (
    <AppContext.Provider value={{ store: rootStore }}>
      {children}
    </AppContext.Provider>
  )
}

AppStoreProvider.propTypes = {
  children: PropTypes.any,
}

export default AppStoreProvider
