import { Stub, Config, upgrade } from '../../../../';

export default (stub: Stub, config: Config): Stub => {
    if (config.recursive) {
        for (let i = 0; i < stub.items.length; i++) {
            const item = stub.items[i];
            item.media = upgrade(item.media, config);
            item.buttonOverlay = upgrade(item.buttonOverlay, config);
        }
    }
    return stub;
};
