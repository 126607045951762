import { Stub, Config } from '../../../..';
import { defaultPopupTheme } from '../defaults/v4';

export default (stub: Stub, config: Config): Stub => {
  stub.version = 5;

  if (!stub.popupTheme) {
    stub.popupTheme = defaultPopupTheme();
  }

  return stub;
};
