import { makeAutoObservable } from 'mobx'
import { makePersistable } from 'mobx-persist-store'
import { UUID } from 'src/util/types'
import { RootStore } from './RootStore'
import { BaseStore } from './BaseStore'

export class IntegrationStore implements BaseStore {
  static INTEGRATION_STORE_STORAGE_KEY = 'TeamsIntegrations'
  rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this, { rootStore: false })
    makePersistable(this, {
      name: IntegrationStore.INTEGRATION_STORE_STORAGE_KEY,
      properties: [],
      storage: window.localStorage,
    }).then((r) => {
      console.debug(
        `Persisting data for ${IntegrationStore.INTEGRATION_STORE_STORAGE_KEY}`,
      )
    })
  }

  async getHubspotCompanyProperties(companyUUID: UUID) {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .integrationAPI.getHubspotCompanyProperties(companyUUID)
      if (res.data) {
        return res.data.results
      }
    } catch (e) {
      console.error('Unable to fetch HubSpot company properties', e)
    }
  }

  async getHubspotCompanies(companyUUID: UUID, properties: string) {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .integrationAPI.getHubspotCompanies(companyUUID, properties)
      if (res.data) {
        return res.data
      }
    } catch (e) {
      console.error('Unable to fetch HubSpot companies', e)
    }
  }

  async getHubspotCompany(companyUUID: UUID, properties: string) {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .integrationAPI.getHubspotCompany(companyUUID, properties)
      if (res.data) {
        return res.data
      }
    } catch (e) {
      console.error('Unable to fetch HubSpot company', e)
    }
  }
}
