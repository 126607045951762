import { makeAutoObservable } from 'mobx'
import { API, APIConfig } from 'src/api/API'
import { AutomationStore } from 'src/store/AutomationStore'
import { FontStore } from 'src/store/FontStore'
import { MarketplaceStore } from 'src/store/MarketplaceStore'
import { PresentationStore } from 'src/store/PresentationStore'
import { DynamicContentStore } from './DynamicContentStore'
import { CompanyStore } from 'src/store/CompanyStore'
import { TeamsStore } from 'src/store/TeamsStore'
import { ThemeStore } from 'src/store/ThemeStore'
import { AnalyticsStore } from './AnalyticsStore'
import { AppConfigStore } from './AppConfigStore'
import { AssetStore } from './AssetStore'
import { IntegrationStore } from './IntegrationStore'
import { SettingsStore } from './SettingsStore'
import { UIStore } from './UIStore'
import { UserStore } from './UserStore'
import { AppModalStore } from './AppModalStore'

export class RootStore {
  private static instance: RootStore
  userStore: UserStore
  settingsStore: SettingsStore
  analyticsStore: AnalyticsStore
  appConfigStore: AppConfigStore
  integrationStore: IntegrationStore
  companyStore: CompanyStore
  teamsStore: TeamsStore
  uiStore: UIStore
  marketplaceStore: MarketplaceStore
  presentationStore: PresentationStore
  dynamicContentStore: DynamicContentStore
  assetStore: AssetStore
  automationStore: AutomationStore
  themeStore: ThemeStore
  fontStore: FontStore
  appModalStore: AppModalStore

  static getInstance() {
    if (!RootStore.instance) {
      RootStore.instance = new RootStore()
    }
    return RootStore.instance
  }

  private constructor() {
    this.userStore = new UserStore(this)
    this.settingsStore = new SettingsStore(this)
    this.analyticsStore = new AnalyticsStore(this)
    this.appConfigStore = new AppConfigStore(this)
    this.integrationStore = new IntegrationStore(this)
    this.companyStore = new CompanyStore(this)
    this.teamsStore = new TeamsStore(this)
    this.uiStore = new UIStore(this)
    this.marketplaceStore = new MarketplaceStore(this)
    this.presentationStore = new PresentationStore(this)
    this.dynamicContentStore = new DynamicContentStore(this)
    this.assetStore = new AssetStore(this)
    this.automationStore = new AutomationStore(this)
    this.themeStore = new ThemeStore(this)
    this.fontStore = new FontStore(this)
    this.appModalStore = new AppModalStore(this)
    makeAutoObservable(this)
  }

  getAPIClient() {
    const config: APIConfig = {
      responseInterceptors: [
        {
          // On HTTP 403s, check to see if the user has since been removed from a company since we last had a list.
          // Attempt to re-route the user to the first-available company if possible, or send them to the site root
          // if no available companies exist anymore, and let AuthRoute pick up the fact that they are orphaned and will
          // automatically log them out.
          code: 403,
          callback: async (code, url, errorMessage) => {
            const location = window.location.href
            const companies = (await this.teamsStore.getCompanies()) ?? []

            if (companies.length > 0) {
              // @TODO: remove logging once TEAMS-1878 is resolved
              console.info(
                'Encounted 403 error at location',
                location,
                'redirecting to first company',
              )

              window.location.href = `/company/${companies[0].uuid}/dashboard`
            } else {
              // @TODO: remove logging once TEAMS-1878 is resolved
              console.info(
                'Encounted 403 error at location',
                location,
                'redirecting to root',
              )

              window.location.href = '/'
            }
          },
          shouldCancelRequest: true,
        },
      ],
    }
    return API.getInstance(config)
  }

  async clearData() {
    await this.analyticsStore.clearData()
    await this.appConfigStore.clearData()
    await this.userStore.clearData()
    await this.uiStore.clearData()
    await this.teamsStore.clearData()
    await this.presentationStore.clearData()
    await this.themeStore.clearData()
    await this.fontStore.clearData()
    await this.appModalStore.clearData()
  }
}
