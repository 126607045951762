import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Auth0Provider } from '@auth0/auth0-react'
import App from './App'
import reportWebVitals from './reportWebVitals'
import AppStoreProvider from './AppStoreProvider'
import { EnvConfig } from './util/EnvConfig'

const container = document.getElementById('root')
const root = createRoot(container!)

// hacky solution for SSO authorize redirect
if (!EnvConfig.getAuth0BrandedDomain()) {
  window.location.reload()
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: EnvConfig.getCacheTTL(),
      staleTime: EnvConfig.getStalenessTTL(),
    },
  },
})

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AppStoreProvider>
        <Auth0Provider
          domain={EnvConfig.getAuth0BrandedDomain()}
          clientId={EnvConfig.getAuth0ClientID()}
          redirectUri={`${window.location.origin}/auth/sso`}
          cacheLocation="memory"
          useRefreshTokens={true}
          scope="openid profile email offline_access"
          audience={`https://${EnvConfig.getAuth0Domain()}/api/v2/`}
          prompt="login"
        >
          <App />
        </Auth0Provider>
      </AppStoreProvider>
    </QueryClientProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
