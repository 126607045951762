import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import duration from 'dayjs/plugin/duration'
import timezone from 'dayjs/plugin/timezone'
import { startOfDay, startOfWeek } from 'date-fns'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(advancedFormat)
dayjs.extend(customParseFormat)
dayjs.extend(duration)
dayjs.extend(timezone)
dayjs.extend(utc)

export enum DateRangePreset {
  THIS_WEEK = 'this_week',
  LAST_WEEK = 'last_week',
  THIS_MONTH = 'this_month',
  LAST_MONTH = 'last_month',
  CUSTOM_RANGE = 'custom_range',
}

export interface AnalyticsDateRange {
  startDate: Date
  endDate: Date
  preset: DateRangePreset
}

export const getDefaultDateRange = (): AnalyticsDateRange => {
  return {
    startDate: startOfWeek(new Date()),
    endDate: startOfDay(new Date()),
    preset: DateRangePreset.THIS_WEEK,
  }
}

export const formatDuration = (seconds: number) => {
  // round seconds to nearest whole number
  const roundedSeconds = Math.round(seconds)

  const duration = dayjs.duration(roundedSeconds, 'seconds')

  // if duration is under a minute, just show seconds
  if (roundedSeconds < 60) {
    return duration.format('s[s]')
  }

  // if duration is under an hour, show minutes and seconds
  else if (roundedSeconds < 3600) {
    return duration.format('m[m] s[s]')
  }

  // otherwise, show hours and minutes
  return duration.format('H[h] m[m]')
}

export const parseDateByFormat = (dateStr: string, format: string) => {
  return dayjs(dateStr, format).toDate()
}
