import { PlaybookTask } from 'src/model/playbook/PlaybookTask'
import { PlaybookAction } from 'src/model/playbook/PlabyookAction'
import { APIPlaybookTask } from 'src/model/api/playbook/APIPlaybookTask'
import { APIPlaybookAction } from 'src/model/api/playbook/APIPlaybookAction'
import { PlaybookTaskWithSlug } from 'src/model/playbook/PlaybookTaskWithSlug'

export namespace PlaybookModel {
  export const playbookTaskFromAPIModel = (
    apiPlaybookTask: APIPlaybookTask,
  ): PlaybookTask => {
    return {
      actionLink: apiPlaybookTask.action_link,
      actionUUID: apiPlaybookTask.action_uuid,
      completedAt: apiPlaybookTask.completed_at,
      createdAt: apiPlaybookTask.created_at,
      description: apiPlaybookTask.description,
      gracePeriod: apiPlaybookTask.grace_period,
      isRequired: apiPlaybookTask.is_required,
      name: apiPlaybookTask.name,
      playbookTaskUUID: apiPlaybookTask.playbook_task_uuid,
      reminderTimer: apiPlaybookTask.reminder_timer,
      status: apiPlaybookTask.status,
      updatedAt: apiPlaybookTask.updated_at,
      uuid: apiPlaybookTask.uuid,
    }
  }

  export const playbookActionFromAPIModel = (
    apiPlaybookAction: APIPlaybookAction,
  ): PlaybookAction => {
    return {
      createdAt: apiPlaybookAction.created_at,
      description: apiPlaybookAction.description,
      name: apiPlaybookAction.name,
      slug: apiPlaybookAction.slug,
      updatedAt: apiPlaybookAction.updated_at,
      uuid: apiPlaybookAction.uuid,
    }
  }

  export const playbookTasksWithSlugFromTasksAndActions = (
    playbookTasks: PlaybookTask[],
    playbookActions: PlaybookAction[],
  ): PlaybookTaskWithSlug[] => {
    return playbookTasks
      .map(({ actionUUID, ...playbookTask }) => {
        const action = playbookActions.find(
          (playbookAction) => playbookAction.uuid === actionUUID,
        )

        return {
          ...playbookTask,
          actionSlug: action ? action.slug : null,
        }
      })
      .filter(
        (playbookTask): playbookTask is PlaybookTaskWithSlug =>
          playbookTask.actionSlug !== null,
      )
  }
}
