/**
 * Represents content type model names in Django. We cannot use the numeric values because these are PKs that change
 * based on the order they're inserted into the DB
 */
export enum IngageContentType {
  CONTENT = 'content',
  VERSION = 'version',
  THEME = 'theme',
  FONT = 'font',
}
