import { AxiosRequestConfig } from 'axios'
import { APICompanyInternal } from 'src/model/api/company/APICompanyInternal'
import { Team } from 'src/model/company/Team'
import { APIResendInviteEmailResponse } from '../model/api/company/APIResendInviteEmailResponse'
import { LicenseQuote } from '../model/company/LicenseQuote'
import { PurchaseLicensesResponse } from '../model/company/PurchaseLicensesResponse'
import BaseAPI from './BaseAPI'
import { APICompany } from 'src/model/api/company/APICompany'
import {
  AddTeamMemberOptions,
  AnalyticsReportID,
  CSVImportOptions,
  UUID,
} from 'src/util/types'
import dayjs from 'dayjs'
import { APIMessage } from 'src/model/api/company/APIMessage'
import { APIActivityLogItem } from 'src/model/api/company/APIActivityLogItem'
import { APICompanyUser } from 'src/model/api/company/APICompanyUser'
import { APICompanyRole } from 'src/model/api/company/APICompanyRole'
import { APITeam } from 'src/model/api/company/APITeam'
import { APITeamMember } from 'src/model/api/company/APITeamMember'
import { APIPresentation } from 'src/model/api/presentation/APIPresentation'
import { NewUser } from 'src/model/user/NewUser'
import { NewTeam } from 'src/model/company/NewTeam'
import { UserModel } from 'src/model/user/UserModel'
import { APINewUserResponse } from 'src/model/api/company/APINewUserResponse'
import { UpdateCompanyUserOptions } from 'src/model/company/UpdateCompanyUserOptions'
import { UserAnalyticsOptions } from 'src/model/company/UserAnalyticsOptions'
import { UpdateTeamUserOptions } from 'src/model/company/UpdateTeamUserOptions'
import { APIUserDetailAnalyticsEvent } from 'src/model/api/company/APIUserDetailAnalyticsEvent'

export type GetCompanyUsersOptions = {
  query?: string
  sortDirection?: 'asc' | 'desc'
  sortSelection?: string[]
  filterEmailStatus?: number[]
  filterUserStatus?: number[]
  filterUserRoles?: string[]
  userFilterQuerystring?: string
  page?: number
  pageSize?: number
  includeTeams?: boolean
  excludeTeam?: string
  email?: string
}

export type GetCompanyUserOption = {
  includeTeams?: boolean
}

/**
 * Client for teams-related calls to the API.
 */
export default class TeamsAPI extends BaseAPI {
  /**
   * Returns messages for teams
   * This is global and not tied to a specific company
   */
  async getMessages() {
    return this.withAuth().get<APIMessage[]>('/messages/?platform=teams')
  }

  /**
   * Returns a list of companies associated with a user.
   */
  async getCompanies() {
    return this.withAuth().getAllPages<APICompany>('/team/api/company/')
  }

  /**
   * DEPRECATED: use getCompaniesInternalPage paginated method instead
   * Returns a list of all companies, whether or not they're associated with a user. INTERNAL USERS ONLY.
   */
  async getAllCompaniesInternal() {
    return this.withAuth().getAllPages<APICompanyInternal>(
      '/team/api/company_internal/',
    )
  }

  /**
   * Fetch a single page of companies
   */
  async getCompaniesInternalPage(
    page: number,
    pageSize: number,
    config?: AxiosRequestConfig,
  ) {
    return this.withAuth().getPage<APICompanyInternal[]>(
      `/team/api/company_internal/`,
      page,
      pageSize,
      config,
    )
  }

  /**
   * Returns a company.
   */
  async getCompany(companyUUID: UUID) {
    return this.withAuth().get<APICompany>(`/team/api/company/${companyUUID}/`)
  }

  /**
   * Creates a company
   * @param options
   * @param endDate string 'YYYY-MM-DD'
   */
  async createCompany(options: Partial<APICompany>, endDate?: string) {
    // written this way to keep the types simpler
    let patchData
    if (endDate) {
      patchData = { ...options, end_date: endDate }
    } else {
      patchData = options
    }
    return this.withAuth().post<APICompany>(
      '/team/api/company/',
      JSON.stringify(patchData),
    )
  }

  /**
   * Converts a company from Demo to Customer
   */
  async convertCompany(companyUUID: UUID) {
    return this.withAuth().patch<APICompany>(
      `/team/api/company/${companyUUID}/convert/`,
    )
  }

  /**
   * Returns a list of recent activities by users for a specific company
   * @param companyUUID
   * @param startDate
   * @param endDate
   * @param search
   * @param pageSize
   */
  async getCompanyActivityLog(
    companyUUID: UUID,
    startDate?: string,
    endDate?: string,
    search?: string,
    pageSize: number = 100,
  ) {
    startDate = startDate ?? dayjs().subtract(6, 'months').format('YYYY-MM-DD')
    endDate = endDate ?? dayjs().format('YYYY-MM-DD')
    pageSize = pageSize ?? 100
    const searchQueryString = search ? `&search=${encodeURI(search)}` : ''

    const url = `/team/api/company/${companyUUID}/events/?page_size=${pageSize}${searchQueryString}&start_date=${startDate}&end_date=${endDate}`
    return this.withAuth().get<APIActivityLogItem[]>(url)
  }

  /**
   * Fetch a single page of recent activities
   */
  async getCompanyActivityLogPage(
    companyUUID: UUID,
    page: number,
    pageSize: number,
    config?: AxiosRequestConfig,
  ) {
    return this.withAuth().getPage<APIActivityLogItem[]>(
      `/team/api/company/${companyUUID}/events/`,
      page,
      pageSize,
      config,
    )
  }

  /**
   * Get all users associated with a company, with additional options provided.
   *
   * @param companyUUID
   * @param options
   */
  async getCompanyUsers(
    companyUUID: UUID,
    options: GetCompanyUsersOptions,
    config?: AxiosRequestConfig,
  ) {
    options.pageSize = options.pageSize ?? 100
    options.page = options.page ?? 1
    options.sortDirection = options.sortDirection ?? 'asc'
    options.sortSelection = options.sortSelection ?? ['last_name']
    options.filterEmailStatus = options.filterEmailStatus ?? []
    options.filterUserStatus = options.filterUserStatus ?? [1, 2]
    options.filterUserRoles = options.filterUserRoles ?? []
    options.userFilterQuerystring = options.userFilterQuerystring ?? ''
    options.includeTeams = options.includeTeams ?? false
    options.excludeTeam = options.excludeTeam ?? ''
    options.email = encodeURIComponent(options.email ?? '')
    options.query = options.query ?? ''

    if (options.sortDirection === 'desc') {
      options.sortSelection = options.sortSelection.map(
        (sortField) => `-${sortField}`,
      )
    }

    const url = `/team/api/company/${companyUUID}/users/?include_teams=${
      options.includeTeams
    }&order_by=${options.sortSelection.join(',')}&page=${
      options.page
    }&page_size=${options.pageSize}&search=${options.query}&exclude_team=${
      options.excludeTeam
    }&user__email_status=${options.filterEmailStatus.join(
      ',',
    )}&user_status=${options.filterUserStatus.join(
      ',',
    )}&user_role=${options.filterUserRoles.join(',')}&${
      options.userFilterQuerystring
    }&email=${options.email}`
    return this.withAuth().getAllPages<APICompanyUser>(url, config)
  }

  async getCompanyUser(
    companyUUID: UUID,
    userUUID: UUID,
    options: GetCompanyUserOption = {},
    config?: AxiosRequestConfig,
  ) {
    const includeTeams = options.includeTeams ?? false
    return this.V1()
      .withAuth()
      .get<APICompanyUser>(
        `/team/api/company/${companyUUID}/users/${userUUID}/?include_teams=${includeTeams}`,
        config,
      )
  }

  /**
   * Fetch a single page of users
   */
  async getCompanyUsersPage(
    companyUUID: UUID,
    options: GetCompanyUsersOptions,
    config?: AxiosRequestConfig,
  ) {
    options.pageSize = options.pageSize ?? 100
    options.page = options.page ?? 1
    options.sortDirection = options.sortDirection ?? 'asc'
    options.sortSelection = options.sortSelection ?? ['last_name']
    options.filterEmailStatus = options.filterEmailStatus ?? []
    options.filterUserStatus = options.filterUserStatus ?? [1, 2]
    options.filterUserRoles = options.filterUserRoles ?? []
    options.userFilterQuerystring = options.userFilterQuerystring ?? ''
    options.includeTeams = options.includeTeams ?? false
    options.excludeTeam = options.excludeTeam ?? ''
    options.email = encodeURIComponent(options.email ?? '')
    options.query = options.query ?? ''

    if (options.sortDirection === 'desc') {
      options.sortSelection = options.sortSelection.map(
        (sortField) => `-${sortField}`,
      )
    }

    const url = `/team/api/company/${companyUUID}/users/?include_teams=${
      options.includeTeams
    }&order_by=${options.sortSelection.join(',')}&page=${
      options.page
    }&page_size=${options.pageSize}&search=${options.query}&exclude_team=${
      options.excludeTeam
    }&user__email_status=${options.filterEmailStatus.join(
      ',',
    )}&user_status=${options.filterUserStatus.join(
      ',',
    )}&user_role=${options.filterUserRoles.join(',')}&${
      options.userFilterQuerystring
    }&email=${options.email}`
    return this.withAuth().getPage<APICompanyUser[]>(
      url,
      options.page,
      options.pageSize,
      config,
    )
  }

  /**
   * Retrieves a list of roles associated with a company.
   *
   * @param companyUUID
   */
  async getCompanyRoles(companyUUID: UUID, config?: AxiosRequestConfig) {
    const url = `/team/api/company/${companyUUID}/roles/`
    return this.withAuth().get<APICompanyRole[]>(url, config)
  }

  /**
   * Retrieves a list of teams associated with a company.
   *
   * @param companyUUID
   */
  async getCompanyTeams(companyUUID: UUID) {
    const url = `/team/api/company/${companyUUID}/teams/`
    return this.withAuth().getAllPages<APITeam>(url)
  }

  /**
   * Retrieves a list of teams associated with a company.
   *
   * @param companyUUID
   */
  async getCompanyAllTeams(companyUUID: UUID, config?: AxiosRequestConfig) {
    const url = `/team/api/company/${companyUUID}/teams/`
    return this.withAuth().getAllPages<APITeam>(url, config)
  }

  /**
   * Fetch a single page of teams
   */
  async getCompanyTeamsPage(
    companyUUID: UUID,
    page: number,
    pageSize: number,
    config?: AxiosRequestConfig,
  ) {
    return this.withAuth().getPage<APITeam[]>(
      `/team/api/company/${companyUUID}/teams/`,
      page,
      pageSize,
      config,
    )
  }

  /**
   * Fetch a single Team
   * @param companyUUID
   * @param teamUUID
   */
  async getCompanyTeam(
    companyUUID: UUID,
    teamUUID: UUID,
    config?: AxiosRequestConfig,
  ) {
    return this.withAuth().get<APITeam>(
      `/team/api/company/${companyUUID}/teams/${teamUUID}/`,
      config,
    )
  }

  /**
   * Returns a list of teams associated with a user inside a company.
   *
   * @param companyUUID
   * @param userUUID
   */
  async getCompanyUserTeams(companyUUID: UUID, userUUID: UUID) {
    const url = `/team/api/company/${companyUUID}/users/${userUUID}/teams/`
    return this.withAuth().getAllPages<APITeam>(url)
  }

  /**
   * Saves a new user to a company.
   *
   * @param companyUUID
   * @param newUser
   * @param shouldSendInviteEmail
   */
  async saveNewUser(
    companyUUID: UUID,
    newUser: NewUser,
    shouldSendInviteEmail: boolean,
  ) {
    const apiModel = UserModel.newUserToAPIModel(newUser)
    const url = `/team/api/company/${companyUUID}/invite/?send_mail=${shouldSendInviteEmail}`
    const data = JSON.stringify(apiModel)
    return this.withAuth().post<APINewUserResponse>(url, data)
  }

  async addUserToTeam() {
    // TODO: Fill this in
  }

  async updateCompanyUser(
    companyUUID: UUID,
    userUUID: UUID,
    options: UpdateCompanyUserOptions,
  ) {
    const url = `/team/api/company/${companyUUID}/users/${userUUID}/`
    const postData = JSON.stringify({
      role_id: options.roleUUID,
      first_name: options.firstName,
      last_name: options.lastName,
      email: options.email,
      phone: options.phone,
      is_paid: options.isPaid,
      update_all_team_roles: options.updateAllTeamRoles,
    })
    return this.withAuth().patch(url, postData)
  }

  async deleteCompanyUser(companyUUID: UUID, userUUID: UUID) {
    const url = `/team/api/company/${companyUUID}/users/${userUUID}/`
    return this.withAuth().delete(url)
  }

  async getUserAnalytics(
    companyUUID: UUID,
    userUUID: UUID,
    reportID: AnalyticsReportID,
    options: UserAnalyticsOptions,
  ) {
    const url = `/team/api/company/${companyUUID}/mixpanel/${reportID}/get_events/`
    const postData = JSON.stringify({
      start_date:
        options.startDate ?? dayjs().subtract(5, 'months').format('YYYY-MM-DD'),
      end_date: options.endDate ?? dayjs().format('YYYY-MM-DD'),
      company_uuid: companyUUID,
      user_id: userUUID,
    })
    return this.withAuth().post<APIUserDetailAnalyticsEvent[]>(url, postData)
  }

  async importUsersFromCSV(
    companyUUID: UUID,
    csvFile: File,
    options: CSVImportOptions,
  ) {
    const url = options.team
      ? `/team/api/company/${companyUUID}/teams/${options.team.uuid}/members/import_users/?send_mail=${options.shouldSendInviteEmails}`
      : `/team/api/company/${companyUUID}/users/import_users/?send_mail=${options.shouldSendInviteEmails}`

    const formData = new FormData()
    formData.append('userCSV', csvFile, csvFile.name)
    formData.append('roleID', options.roleUUID)
    return this.withoutContentHeaders().withAuth().post(url, formData)
  }

  async resendInviteEmailForCompanyUsers(
    companyUUID: UUID,
    userUUIDs: UUID[],
    shouldForce: boolean = false,
  ) {
    const payload = {
      uuids: userUUIDs,
      force: shouldForce,
    }
    const url = `/team/api/company/${companyUUID}/resend_email/users/`
    return this.withAuth().post<APIResendInviteEmailResponse>(url, payload)
  }

  async resendInviteEmailForCompany(companyUUID: UUID) {
    const url = `/team/api/company/${companyUUID}/resend_email/`
    return this.withAuth().post<APIResendInviteEmailResponse>(url)
  }

  async resendInviteEmailForTeam(companyUUID: UUID, teamUUID: UUID) {
    const url = `/team/api/company/${companyUUID}/teams/${teamUUID}/resend_email/`
    return this.withAuth().post<APIResendInviteEmailResponse>(url)
  }

  /**
   * Saves a new team to a company.
   *
   * @param companyUUID
   * @param newTeam
   */
  async saveNewTeam(companyUUID: UUID, newTeam: NewTeam) {
    const url = `/team/api/company/${companyUUID}/teams/`
    const postData = JSON.stringify({
      name: newTeam.name,
    })
    return this.withAuth().post<APITeam>(url, postData)
  }

  /**
   * Updates an existing saved report
   * @param companyUUID
   * @param team
   */
  async updateTeam(companyUUID: UUID, team: Team) {
    const url = `/team/api/company/${companyUUID}/teams/${team.uuid}/`
    const patchData = JSON.stringify({
      name: team.name,
    })
    return this.withAuth().patch<APITeam>(url, patchData)
  }

  /**
   * Delete an existing team
   * @param companyUUID
   * @param teamUUID
   */
  async deleteTeam(companyUUID: UUID, teamUUID: UUID) {
    return this.withAuth().delete(
      `/team/api/company/${companyUUID}/teams/${teamUUID}/`,
    )
  }

  /**
   * Get an team members
   * @param companyUUID
   * @param teamUUID
   */
  async getTeamMembers(
    companyUUID: UUID,
    teamUUID: UUID,
    config?: AxiosRequestConfig,
  ) {
    return this.withAuth().getAllPages<APITeamMember>(
      `/team/api/company/${companyUUID}/teams/${teamUUID}/members/`,
      config,
    )
  }

  /**
   * Add a team member
   * @param companyUUID
   * @param teamUUID
   * @param options
   */
  async addTeamMember(
    companyUUID: UUID,
    teamUUID: UUID,
    options: AddTeamMemberOptions,
  ) {
    const url = `/team/api/company/${companyUUID}/teams/${teamUUID}/members/`
    const postData = JSON.stringify({
      team: teamUUID,
      user: options.user,
      role: options.role,
    })
    return this.withAuth().post<APITeamMember>(url, postData)
  }

  /**
   * Update a team member
   * @param companyUUID
   * @param teamUUID
   * @param options
   */
  async updateTeamMember(
    companyUUID: UUID,
    teamUUID: UUID,
    memberUUID: UUID,
    options: UpdateTeamUserOptions,
  ) {
    const url = `/team/api/company/${companyUUID}/teams/${teamUUID}/members/${memberUUID}/`
    const putData = JSON.stringify({
      id: options.id,
      role: options.role,
      role_uuid: options.roleUUID,
      team: options.team,
      team_uuid: options.teamUUID,
      user: options.user,
      uuid: options.uuid,
    })
    return this.withAuth().put<APITeamMember>(url, putData)
  }

  /**
   * Remove a team member
   * @param companyUUID
   * @param teamUUID
   * @param memberUUID - CompanyUser UUID
   */
  async removeTeamMember(companyUUID: UUID, teamUUID: UUID, memberUUID: UUID) {
    return this.withAuth().delete(
      `/team/api/company/${companyUUID}/teams/${teamUUID}/members/${memberUUID}/`,
    )
  }

  /**
   * Delete an existing team
   * @param companyUUID
   * @param teamUUID
   */
  async getTeamPresentations(
    companyUUID: UUID,
    teamUUID: UUID,
    config?: AxiosRequestConfig,
  ) {
    return this.withAuth().getAllPages<APIPresentation>(
      `/team/api/company/${companyUUID}/teams/${teamUUID}/contents/`,
      config,
    )
  }

  /**
   * Requests a quote for a purchase order of additional Ingage user licenses.
   * @param companyUUID
   * @param numLicenses
   * @param config
   */
  async requestLicenseQuote(
    companyUUID: UUID,
    numLicenses: number,
    config?: AxiosRequestConfig,
  ) {
    const postData = JSON.stringify({
      num_licenses: numLicenses,
    })
    return this.withAuth().post<LicenseQuote>(
      `/team/api/company/${companyUUID}/request_license_quote/`,
      postData,
      config,
    )
  }

  /**
   * Purchases additional Ingage user licenses on behalf of the requesting user.
   * @param companyUUID
   * @param numLicenses
   * @param config
   */
  async purchaseLicenses(
    companyUUID: UUID,
    numLicenses: number,
    config?: AxiosRequestConfig,
  ) {
    const postData = JSON.stringify({
      num_licenses: numLicenses,
    })
    return this.withAuth().post<PurchaseLicensesResponse>(
      `/team/api/company/${companyUUID}/purchase_licenses/`,
      postData,
      config,
    )
  }
}
