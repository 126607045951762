import { DynamicKey } from 'src/model/dynamickeys/DynamicKey'
import { APIDynamicKey } from 'src/model/api/dynamickeys/APIDynamicKey'
import { DynamicKeyUpdateProps } from 'src/model/dynamickeys/DynamicKeyUpdateProps'
import { DynamicKeyCreateProps } from 'src/model/dynamickeys/DynamicKeyCreateProps'
import { APIDynamicKeyUpdateProps } from 'src/model/api/dynamickeys/APIDynamicKeyUpdateProps'
import { APIDynamicKeyCreateProps } from 'src/model/api/dynamickeys/APIDynamicKeyCreateProps'

export namespace DynamicKeysModel {
  export const dynamicKeyFromAPIModel = (
    apiCustomField: APIDynamicKey,
  ): DynamicKey => {
    const dynamicKey: DynamicKey = {
      uuid: apiCustomField.uuid,
      name: apiCustomField.name,
      description: apiCustomField.description,
      slug: apiCustomField.slug,
      dataType: apiCustomField.data_type,
      defaultValue: apiCustomField.default_value,
      keyType: apiCustomField.key_type,
    }
    return dynamicKey
  }

  export const apiDynamicKeyCreatePropsFromModel = (
    dynamicKeyCreateProps: DynamicKeyCreateProps,
  ): APIDynamicKeyCreateProps => {
    const apiDynamicKeyCreateProps: APIDynamicKeyCreateProps = {
      name: dynamicKeyCreateProps.name,
      slug: dynamicKeyCreateProps.slug,
      description: dynamicKeyCreateProps.description,
      default_value: dynamicKeyCreateProps.defaultValue,
      key_type: dynamicKeyCreateProps.keyType,
      data_type: dynamicKeyCreateProps.dataType,
    }
    return apiDynamicKeyCreateProps
  }

  export const apiDynamicKeyUpdatePropsFromModel = (
    dynamicKey: DynamicKeyUpdateProps,
  ): APIDynamicKeyUpdateProps => {
    const apiDynamicKeyUpdateProps: APIDynamicKeyUpdateProps = {
      name: dynamicKey.name,
      description: dynamicKey.description,
      default_value: dynamicKey.defaultValue,
    }
    return apiDynamicKeyUpdateProps
  }
}
