import { makeAutoObservable } from 'mobx'
import { makePersistable } from 'mobx-persist-store'
import { RootStore } from './RootStore'
import { BaseStore } from './BaseStore'

export class UIStore implements BaseStore {
  isSidebarOpen = true
  isAsideOpen = false
  isSidebarUnfoldable = false
  isSidebarWidgetOpen = true
  activeSidebarCompanyUUID?: string
  rootStore: RootStore
  viewportWidth: number
  viewportHeight: number
  isMobile: boolean
  werePreviousViewportDimensionsMobile: boolean

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.viewportWidth = window.innerWidth
    this.viewportHeight = window.innerHeight
    this.isMobile = false
    this.werePreviousViewportDimensionsMobile = false
    makeAutoObservable(this, { rootStore: false })
    makePersistable(this, {
      name: 'TeamsUI',
      properties: [
        'isSidebarOpen',
        'isAsideOpen',
        'isSidebarUnfoldable',
        'isSidebarWidgetOpen',
        'activeSidebarCompanyUUID',
      ],
      storage: window.localStorage,
    }).then((r) => {
      console.debug('Persisting data for UIStore')
    })
  }

  setIsSidebarUnfoldable(isUnfoldable: boolean) {
    this.isSidebarUnfoldable = isUnfoldable
  }

  setIsSidebarOpen(isOpen: boolean) {
    this.isSidebarOpen = isOpen
  }

  setIsSidebarWidgetOpen(isOpen: boolean) {
    this.isSidebarWidgetOpen = isOpen
  }

  setIsAsideOpen(isOpen: boolean) {
    this.isAsideOpen = isOpen
  }

  setActiveSidebarCompanyUUID(uuid: string | undefined) {
    this.activeSidebarCompanyUUID = uuid
  }

  setViewportDimensions(width: number, height: number) {
    this.viewportWidth = width
    this.viewportHeight = height
    this.werePreviousViewportDimensionsMobile = this.isMobile
    this.isMobile = !window.matchMedia('(min-width: 640px)').matches
    // If we've moved in/out of mobile width from desktop width, reset the isSidebarOpen state to its default value for
    // the new viewport size. This prevents the sidebar from staying disappeared when upscaling from mobile dimensions
    // to larger dimensions, and also ensures that the hamburger menu state does not go out of sync.
    if (this.isMobile !== this.werePreviousViewportDimensionsMobile) {
      this.isSidebarOpen = !this.isMobile
    }
  }

  clearData() {
    this.activeSidebarCompanyUUID = undefined
    this.isSidebarOpen = true
    this.isSidebarWidgetOpen = true
  }
}
