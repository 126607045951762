import { MarketplaceListingAssetType } from 'src/model/marketplace/MarketplaceListingAssetType'
import { Filter } from 'src/util/filter/Filter'

interface ConstructorParams {
  type?: MarketplaceListingAssetType
  orderBy?: string[]
}

export class ListingAssetListFilter extends Filter {
  type?: MarketplaceListingAssetType
  orderBy?: string[]

  private constructor({ type, orderBy }: ConstructorParams) {
    super()
    this.type = type
    this.orderBy = orderBy
  }

  static create(constructorParams: ConstructorParams): ListingAssetListFilter {
    const filter = new ListingAssetListFilter(constructorParams)

    if (filter.type !== undefined) {
      filter.addParam('type', filter.type)
    }

    if (filter.orderBy !== undefined && filter.orderBy.length > 0) {
      filter.addParam('order_by', filter.orderBy.join(','))
    }

    return filter
  }
}
