import { useMemo } from 'react'

import { UUID } from 'src/util/types'
import { Profile } from 'src/model/user/Profile'
import { Feature } from 'src/util/features/Feature'
import { Company } from 'src/model/company/Company'
import { useTeamsStore, useUserStore } from 'src/store'
import { FeaturesUtil } from 'src/util/features/FeaturesUtil'

const companyAllowsViewFromTeams = (company?: Company) => {
  if (!company) return false

  return FeaturesUtil.hasCompanyFeature(company, Feature.ALLOW_VIEW_FROM_TEAMS)
}

export const userAllowsViewFromTeams = (profile?: Profile) => {
  if (!profile) return false

  return FeaturesUtil.hasUserFeature(profile, Feature.ALLOW_VIEW_FROM_TEAMS)
}

export const companyOrUserAllowsViewFromTeams = (
  company?: Company,
  profile?: Profile,
) => {
  if (!company || !profile) return false

  return companyAllowsViewFromTeams(company) || userAllowsViewFromTeams(profile)
}

export const useAllowViewFromTeams = (companyUUID?: UUID) => {
  const teamsStore = useTeamsStore()
  const userStore = useUserStore()

  const company = useMemo(() => {
    return teamsStore.companies.find((company) => company.uuid === companyUUID)
  }, [teamsStore.companies, companyUUID])

  return useMemo(() => {
    return companyOrUserAllowsViewFromTeams(company, userStore.profile)
  }, [company, userStore.profile])
}
