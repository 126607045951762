import { Stub, Config, Context, upgrade } from '../../../../';

export default (stub: Stub, config: Config): Stub => {
    stub.version = 2;

    if (stub.image) {
        stub.media = {
            type: 'media-component',
            version: 1,
            mediaType: {
                image: stub.image,
            },
        };
        delete stub.image;
    }

    if (!stub.buttonOverlay) {
        stub.buttonOverlay = {
            type: 'overlay-component',
            version: 1.0,
            items: [],
        };
    }

    stub.theme = {
        mode: 'light',
        baseColor: '#FFFFFF',
    };

    if (stub.cosmeticCellColorA == '#000000') {
        stub.theme.mode = 'dark';
    }

    delete stub.cosmeticCellColorA;
    delete stub.cosmeticCellColorB;
    delete stub.cosmeticCellColorC;

    if (config.recursive) {
        stub.title = upgrade(stub.title, config, { textMode: 'header' });
        stub.headline = upgrade(stub.headline, config);
        stub.subheading = upgrade(stub.subheading, config);
        stub.body = upgrade(stub.body, config);
        stub.media = upgrade(stub.media, config, getSize(stub));
        stub.buttonOverlay = upgrade(stub.buttonOverlay, config);
    }

    return stub;
};

const getSize = (page: Stub): Context => {
    const mapTranslateX: { [key: string]: number } = {
        'vertical-full-image': 1024,
        'vertical-small-image': 384,
        'horizontal-small-image': 384,
        'horizontal-full-image': 512,
    };
    const mapTranslateY: { [key: string]: number } = {
        'vertical-full-image': 296,
        'vertical-small-image': 288,
        'horizontal-small-image': 288,
        'horizontal-full-image': page.showTitle ? 704 : 768,
    };
    return {
        width: mapTranslateX[page.layout],
        height: mapTranslateY[page.layout],
    };
};
