/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'

import { API, APIConfig } from 'src/api/API'
import { EnvConfig } from 'src/util/EnvConfig'
import { LocalStorage } from 'src/store/LocalStorage'
import { TokenRefresher } from 'src/api/TokenRefresher'
import { UPGRADER_VERSION_NUMBER } from 'stubs-upgrader/src'
import brandManifest, { AppBrandKeys } from 'src/assets/brandManifest'

class APIClient {
  protected client: AxiosInstance
  protected apiConfig: APIConfig

  constructor(apiConfig: APIConfig, clientConfig?: AxiosRequestConfig) {
    this.apiConfig = apiConfig
    this.client = axios.create({
      baseURL: EnvConfig.getBaseURL(),
      timeout: 60 * 1000, // 60 seconds timeout,
      ...clientConfig,
      headers: {
        'X-CLIENT-ID': EnvConfig.getClientID(),
        'X-ORIGIN':
          brandManifest.xOriginHeader[
            process.env.REACT_APP_BRAND as AppBrandKeys
          ] ?? 'teams.ingage.ingage.io',
        'Accept-StubVersion': UPGRADER_VERSION_NUMBER,
        ...clientConfig?.headers,
      },
    })
  }

  protected attachResponseInterceptor() {
    this.client.interceptors.response.use(
      async (response) => {
        if (response.headers.hasOwnProperty('out-of-coffee')) {
          LocalStorage.getInstance().purgeUserStore()
          if (window.location.href !== `${window.location.origin}/auth`) {
            window.location.reload()
          }
        }
        return response
      },
      async (error) => {
        if (!error || !error.response || !error.response.status) {
          return
        }

        // Reject promise if non-auth error
        if (error.response.status !== 401) {
          const interceptors =
            this.apiConfig.responseInterceptors?.filter(
              (interceptor) => interceptor.code === error.response.status,
            ) ?? []
          for (const interceptor of interceptors) {
            interceptor.callback(interceptor.code, error.response.config.url)
            if (interceptor.shouldCancelRequest) {
              throw new axios.Cancel('Operation cancelled')
            }
          }
          return Promise.reject(error)
        } else {
          if (!/asset|download|oauth2\/token/.test(error.response.config.url)) {
            const refreshToken = LocalStorage.getInstance().getRefreshToken()

            if (refreshToken) {
              const newAccessToken = await TokenRefresher.instance.refresh(
                refreshToken,
              )
              error.response.config.headers[
                'Authorization'
              ] = `Bearer ${newAccessToken}`
              return axios(error.response.config)
            }
          }

          return Promise.reject(error)
        }
      },
    )
  }

  withAuth() {
    this.client.interceptors.request.use(
      async (config) => {
        const accessTokenExpiration =
          LocalStorage.getInstance().getAuthCheckTimestamp()
        const refreshToken = LocalStorage.getInstance().getRefreshToken()

        if (refreshToken && Date.now() > accessTokenExpiration) {
          const newAccessToken = await TokenRefresher.instance.refresh(
            refreshToken,
          )
          config.headers = Object.assign(
            { Authorization: `Bearer ${newAccessToken}` },
            config.headers,
          )
          return config
        } else {
          config.headers = Object.assign(
            {
              Authorization: `Bearer ${LocalStorage.getInstance().getAccessToken()}`,
            },
            config.headers,
          )
          return config
        }
      },
      (error) => Promise.reject(error),
    )
    this.attachResponseInterceptor()
    return this
  }

  withPublicAPIKey(apiKey: string) {
    this.client.interceptors.request.use(
      async (config) => {
        config.headers = {
          ...config.headers,
          APIKEY: apiKey,
        }
        return config
      },
      (error) => Promise.reject(error),
    )
    this.attachResponseInterceptor()
    return this
  }

  withoutAuth() {
    this.attachResponseInterceptor()
    return this
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getPage<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    page: number = 1,
    pageSize: number = 100,
    config?: AxiosRequestConfig<D>,
  ) {
    config = {
      ...config,
      params: {
        page: page,
        page_size: pageSize,
        ...config?.params,
      },
    }
    return this.get<T, AxiosResponse<T>, D>(url, config)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getAllPages<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    config?: AxiosRequestConfig<D>,
  ): Promise<T[]> {
    config = {
      ...config,
      params: {
        ...config?.params,
        page_size: config?.params?.page_size ?? API.DEFAULT_PAGE_SIZE,
        page: config?.params?.page ?? 1,
      },
    }

    const pageSize = config.params.page_size
    const page = parseInt(config.params.page)

    const allData: T[] = []

    const res = await this.get<T[], AxiosResponse<T[]>, D>(url, config)
    if (!res || res.status >= 400) {
      throw new Error(`Paginated request failed (page: ${page} of ${pageSize})`)
    }
    if (res.status >= 200) {
      allData.push(...res.data)
      const totalResults = res.headers['total-count']
        ? parseInt(res.headers['total-count'])
        : 0
      const numPages = Math.ceil(totalResults / pageSize)
      if (numPages <= page) {
        return allData
      }
      for (let i = page + 1; i <= numPages; i++) {
        config.params.page = i
        config.params.page_size = pageSize
        const pageRes = await this.getPage<T[], AxiosResponse<T[]>, D>(
          url,
          i,
          pageSize,
        )
        if (!pageRes || pageRes.status >= 400) {
          throw new Error(
            `Paginated request failed (page: ${i} of ${pageSize}).`,
          )
        }
        if (pageRes && pageRes.status < 400 && pageRes.status >= 200) {
          allData.push(...pageRes.data)
        }
      }
    }
    return allData
  }

  // region Axios client wrappers
  request<T = any, R = AxiosResponse<T>, D = any>(
    config: AxiosRequestConfig<D>,
  ): Promise<R> {
    return this.client.request(config)
  }

  get<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    config?: AxiosRequestConfig<D>,
  ): Promise<R> {
    return this.client.get(url, config)
  }

  delete<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    config?: AxiosRequestConfig<D>,
  ): Promise<R> {
    return this.client.delete(url, config)
  }

  head<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    config?: AxiosRequestConfig<D>,
  ): Promise<R> {
    return this.client.head(url, config)
  }

  options<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    config?: AxiosRequestConfig<D>,
  ): Promise<R> {
    return this.client.options(url, config)
  }

  post<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>,
  ): Promise<R> {
    return this.client.post(url, data, config)
  }

  put<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>,
  ): Promise<R> {
    return this.client.put(url, data, config)
  }

  patch<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>,
  ): Promise<R> {
    return this.client.patch(url, data, config)
  }
  // endregion Axios client wrappers
}

class APIV1 extends APIClient {
  constructor(apiConfig: APIConfig) {
    super(apiConfig, {
      headers: {
        Accept: 'application/json; version=1.1',
        'Content-Type': 'application/json; version=1.1',
      },
    })
  }
}

class APIV2 extends APIClient {
  constructor(apiConfig: APIConfig) {
    super(apiConfig, {
      headers: {
        Accept: 'application/json; version=2',
        'Content-Type': 'application/json; version=2',
      },
    })
  }
}

export default abstract class BaseAPI {
  protected apiConfig: APIConfig

  constructor(apiConfig: APIConfig) {
    this.apiConfig = apiConfig
  }

  /**
   * Make a request to the V1 API.
   *
   * @returns {APIV1}
   */
  V1(): APIV1 {
    return new APIV1(this.apiConfig)
  }

  /**
   * Make a request to the V2 API.
   *
   * @returns {APIV2}
   */
  V2(): APIV2 {
    return new APIV2(this.apiConfig)
  }

  /**
   * Make a request without content type headers.
   *
   * @returns {APIClient}
   */
  withoutContentHeaders(): APIClient {
    return new APIClient(this.apiConfig)
  }

  /**
   * Deprecated `withAuth` method. Use V1() instead.
   *
   * @deprecated
   * @returns {APIV1}
   */
  withAuth() {
    return new APIV1(this.apiConfig).withAuth()
  }

  /**
   * Deprecated `withPublicAPIKey` method. Use V1() instead.
   *
   * @deprecated
   * @returns {APIV1}
   */
  withPublicAPIKey(apiKey: string) {
    return new APIV1(this.apiConfig).withPublicAPIKey(apiKey)
  }

  /**
   * Deprecated `withoutAuth` method. Use V1() instead.
   *
   * @deprecated
   * @returns {APIV1}
   */
  withoutAuth() {
    return new APIV1(this.apiConfig).withoutAuth()
  }
}
