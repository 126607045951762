import { AxiosRequestConfig } from 'axios'
import BaseAPI from 'src/api/BaseAPI'
import { APICompanyUser } from 'src/model/api/company/APICompanyUser'
import { InternalCompany } from 'src/model/internal-company/InternalCompany'
import { UpdateMembershipResult } from 'src/model/internal-company/UpdateMembershipResult'
import { UpdateMembershipsData } from 'src/model/internal-company/UpdateMembershipsData'

export default class InternalCompanyAPI extends BaseAPI {
  /**
   * Returns all companies that the internal user is a member of.
   * @param config
   */
  async getCompaniesForInternalUser(config?: AxiosRequestConfig) {
    return this.withAuth().getAllPages<InternalCompany>(
      `/company/internal/`,
      config,
    )
  }

  /**
   * Fetch a single page of companies. Accepts one query parameter `q` which is a search string.
   */
  async searchCompanies(
    page: number,
    pageSize: number,
    config?: AxiosRequestConfig,
  ) {
    return this.withAuth().getPage<InternalCompany[]>(
      `/company/internal/search/`,
      page,
      pageSize,
      config,
    )
  }

  /**
   * Fetch a single page of company users. Accepts one query parameter `q` which is a search string.
   * @param page
   * @param pageSize
   * @param config
   */
  async searchCompanyUsers(
    page: number,
    pageSize: number,
    config?: AxiosRequestConfig,
  ) {
    return this.withAuth().getPage<APICompanyUser[]>(
      `/company/internal/users/search/`,
      page,
      pageSize,
      config,
    )
  }

  /**
   * Updates
   * @param patchData
   * @param config
   */
  async updateCompanyMemberships(
    patchData: UpdateMembershipsData,
    config?: AxiosRequestConfig,
  ) {
    return this.withAuth().patch<UpdateMembershipResult>(
      `/company/internal/update_memberships/`,
      patchData,
      config,
    )
  }
}
