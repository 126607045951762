import { Stub, Config, upgrade } from '../../../../';

export default (stub: Stub, config: Config): Stub => {
    stub.version = 2;
    stub.backgroundType = {
        hexColor: '#ffffff',
        style: 'blur',
        blurStyle: 'light',
        type: 'media-background-type',
        version: '1.0',
    };
    stub.foregroundBlurStyle = 'light';

    return stub;
};
