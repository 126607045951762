import arvo from './arvo'
import bentonSans from './benton-sans'
import bodoni from './bodoni'
import calibre from './calibre'
import didot from './didot'
import domaineDisplay from './domaine-display'
import eczar from './eczar'
import gillSans from './gill-sans'
import gravitasOne from './gravitas-one'
import gruppo from './gruppo'
import helveticaNeue from './helvetica-neue'
import montserrat from './montserrat'
import openSans from './open-sans'
import oswald from './oswald'
import pacifico from './pacifico'
import ptSerif from './pt-serif'
import rajdhani from './rajdhani'
import rubik from './rubik'

import type { LegacyFontRules } from './types'

export * from './types'

export const defaultLegacyFontRules: LegacyFontRules = {
  'arvo': arvo,
  'benton-sans': bentonSans,
  'bodoni': bodoni,
  'calibre': calibre,
  'didot': didot,
  'domaine-display': domaineDisplay,
  'eczar': eczar,
  'gill-sans': gillSans,
  'gravitas-one': gravitasOne,
  'gruppo': gruppo,
  'helvetica-neue': helveticaNeue,
  'montserrat': montserrat,
  'open-sans': openSans,
  'oswald': oswald,
  'pacifico': pacifico,
  'pt-serif': ptSerif,
  'rajdhani': rajdhani,
  'rubik': rubik
}