import { AxiosResponse } from 'axios'
import AutomationAPI from 'src/api/AutomationAPI'
import FontAPI from 'src/api/FontAPI'
import IntegrationAPI from 'src/api/IntegrationAPI'
import MarketplaceAPI from 'src/api/MarketplaceAPI'
import PlaybookAPI from 'src/api/PlaybookAPI'
import PresentationAPI from 'src/api/PresentationAPI'
import DynamicContentAPI from './DynamicContentAPI'
import ExternalAuthAPI from './ExternalAuthAPI'
import ThemeAPI from 'src/api/ThemeAPI'
import UserAPI from 'src/api/UserAPI'
import TeamsAPI from 'src/api/TeamsAPI'
import CompanyAPI from 'src/api/CompanyAPI'
import AnalyticsAPI from 'src/api/AnalyticsAPI'
import ExternalGenericAPI from './GenericExternalAPI'
import AssetAPI from './AssetAPI'
import InternalCompanyAPI from 'src/api/InternalCompanyAPI'

export type ResponseInterceptor = {
  code: number
  callback: (code: number, url: string, errorMessage?: string) => void
  shouldCancelRequest?: boolean
}

export type APIConfig = {
  responseInterceptors?: ResponseInterceptor[]
}

export class API {
  private static instance: API
  userAPI: UserAPI
  teamsAPI: TeamsAPI
  companyAPI: CompanyAPI
  playbookAPI: PlaybookAPI
  analyticsAPI: AnalyticsAPI
  marketplaceAPI: MarketplaceAPI
  presentationsAPI: PresentationAPI
  dynamicContentAPI: DynamicContentAPI
  externalAuthAPI: ExternalAuthAPI
  automationAPI: AutomationAPI
  themeAPI: ThemeAPI
  fontAPI: FontAPI
  assetAPI: AssetAPI
  externalGenericAPI: ExternalGenericAPI
  integrationAPI: IntegrationAPI
  internalCompanyAPI: InternalCompanyAPI

  static getInstance(apiConfig: APIConfig = {}) {
    if (!this.instance) {
      this.instance = new API(
        new UserAPI(apiConfig),
        new TeamsAPI(apiConfig),
        new CompanyAPI(apiConfig),
        new PlaybookAPI(apiConfig),
        new AnalyticsAPI(apiConfig),
        new MarketplaceAPI(apiConfig),
        new PresentationAPI(apiConfig),
        new DynamicContentAPI(apiConfig),
        new ExternalAuthAPI(apiConfig),
        new AutomationAPI(apiConfig),
        new ThemeAPI(apiConfig),
        new FontAPI(apiConfig),
        new AssetAPI(apiConfig),
        new ExternalGenericAPI(apiConfig),
        new IntegrationAPI(apiConfig),
        new InternalCompanyAPI(apiConfig),
      )
    }
    return this.instance
  }

  static DEFAULT_PAGE_SIZE = 100

  static getTotalCountFromResponse = (response: AxiosResponse) => {
    return response.headers['total-count']
      ? parseInt(response.headers['total-count']) || 0
      : 0
  }

  static getRedirectFromResponse = (response: AxiosResponse) => {
    return response.headers['location'] ?? undefined
  }

  private constructor(
    userAPI: UserAPI,
    teamsAPI: TeamsAPI,
    companyAPI: CompanyAPI,
    playbookAPI: PlaybookAPI,
    analyticsAPI: AnalyticsAPI,
    marketplaceAPI: MarketplaceAPI,
    presentationsAPI: PresentationAPI,
    dynamicContentAPI: DynamicContentAPI,
    externalAuthAPI: ExternalAuthAPI,
    automationAPI: AutomationAPI,
    themeAPI: ThemeAPI,
    fontAPI: FontAPI,
    assetAPI: AssetAPI,
    externalGenericAPI: ExternalGenericAPI,
    integrationAPI: IntegrationAPI,
    internalCompanyAPI: InternalCompanyAPI,
  ) {
    this.userAPI = userAPI
    this.teamsAPI = teamsAPI
    this.companyAPI = companyAPI
    this.playbookAPI = playbookAPI
    this.analyticsAPI = analyticsAPI
    this.marketplaceAPI = marketplaceAPI
    this.presentationsAPI = presentationsAPI
    this.dynamicContentAPI = dynamicContentAPI
    this.externalAuthAPI = externalAuthAPI
    this.automationAPI = automationAPI
    this.themeAPI = themeAPI
    this.fontAPI = fontAPI
    this.assetAPI = assetAPI
    this.externalGenericAPI = externalGenericAPI
    this.integrationAPI = integrationAPI
    this.internalCompanyAPI = internalCompanyAPI
  }
}
