import merge from 'lodash/merge'
import mergeWith from 'lodash/mergeWith'
import isArray from 'lodash/isArray'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import isString from 'lodash/isString'
import kebabCase from 'lodash/kebabCase'

import { FontHelper } from './FontHelper'
import {
  defaultFontFamilies,
  defaultFamilyToWeightsMap,
  defaultFamilyAndWeightToPropsMap,
  buildCustomFontFamilies,
  buildCustomFamilyToWeightsMap,
  buildCustomFamilyAndWeightToPropsMap
} from './freestyle'
import {
  defaultLegacyFontFamilies,
  buildCustomLegacyFontFamilies,
  buildCustomLegacyFontRules
} from './legacy'
import type {
  FamilyToWeightsMap,
  FamilyAndWeightToPropsMap
} from '../rules/freestyle'
import type {
  LegacyFontFamilies,
  LegacyFontRules,
  LegacyFontRule
} from '../rules/legacy'
import {
  defaultLegacyFontRules
} from '../rules/legacy'
import type { Font } from '../types/Font'
import type { FontWeightProps } from '../types/FontWeightProps'

export class FontUtils {
  private static readonly defaultFontFamilies: string[] = defaultFontFamilies
  private static readonly defaultFamilyToWeightsMap: FamilyToWeightsMap = defaultFamilyToWeightsMap
  private static readonly defaultFamilyAndWeightToPropsMap: FamilyAndWeightToPropsMap = defaultFamilyAndWeightToPropsMap

  private static customFontFamilies: string[] = []
  private static customFamilyToWeightsMap: FamilyToWeightsMap = {}
  private static customFamilyAndWeightToPropsMap: FamilyAndWeightToPropsMap = {}

  private static readonly defaultLegacyFontFamilies: LegacyFontFamilies = defaultLegacyFontFamilies
  private static readonly defaultLegacyFontRules: LegacyFontRules = defaultLegacyFontRules

  private static customLegacyFontFamilies: LegacyFontFamilies = []
  private static customLegacyFontRules: LegacyFontRules = {}

  public static get fontFamilies() {
    const fontFamilies = [
      ...this.defaultFontFamilies,
      ...this.customFontFamilies
    ].filter((value, index, self) => self.indexOf(value) === index)

    // sort alphabetically
    return fontFamilies.sort()
  }

  public static get fontFamilyOptions() {
    return FontUtils.fontFamilies.map(fontFamily => ({
      label: fontFamily,
      value: fontFamily
    }))
  }

  public static get familyToWeightsMap(): FamilyToWeightsMap {
    // This merge needs a customizer because we don't want arrays values to be overwritten.
    const consolidatedMap: FamilyToWeightsMap = mergeWith({}, this.defaultFamilyToWeightsMap, this.customFamilyToWeightsMap, (destination, source) => {
      if (isArray(destination)) {
        return uniq([...destination, ...source])
      }
    })
    for (const [key, styles] of Object.entries(consolidatedMap)) {
      consolidatedMap[key] = styles.sort((a, b) => FontHelper.compareFontSubFamilyNames(a, b))
    }
    return consolidatedMap
  }

  private static get familyAndWeightToPropsMap(): FamilyAndWeightToPropsMap {
    return merge(this.defaultFamilyAndWeightToPropsMap, this.customFamilyAndWeightToPropsMap)
  }

  public static get legacyFontFamilies() {
    const legacyFontFamilies = uniqBy([
      ...this.defaultLegacyFontFamilies,
      ...this.customLegacyFontFamilies
    ], 'full')

    // sort alphabetically
    return legacyFontFamilies.sort((a, b) => a.full > b.full ? 1 : -1)
  }

  public static get legacyFontRules(): LegacyFontRules {

    // We want to sanitize our legacy font rules so that we can correctly merge any custom-uploaded fonts that belong
    // to any existing font families shipped with the application (rules for the latter  provided by
    // `defaultLegacyFontRules`). While these are technically two separate entities (and should be treated as such
    // behind the scenes), it makes sense at the UI level to merge these so they display as a single cohesive entity.
    // Font rules shipped with the app are keyed in kebab-case, and so we just need to convert any custom fonts to
    // also use the same format.
    const sanitizedLegacyFontRules: { [key: string]: any } = {}
    Object.keys({...this.customLegacyFontRules}).forEach(key => {
      this.customLegacyFontRules[key].font.key = kebabCase(key)
      sanitizedLegacyFontRules[kebabCase(key)] = this.customLegacyFontRules[key]
    })

    // Additionally, this merge needs a customizer because we don't want arrays values to be overwritten.
    return mergeWith({}, this.defaultLegacyFontRules, sanitizedLegacyFontRules, (destination, source) => {
      if (isArray(destination)) {
        return [...destination, ...source]
      }
    })
  }

  public static getFontWeights(fontFamily: string) {
    return (FontUtils.familyToWeightsMap[fontFamily] ?? []).map(fontWeight => ({
      label: fontWeight,
      value: fontWeight
    }))
  }

  /**
   * Get the CSS font weight and style needed to render a font that
   * matches the correct font face declaraton for family and weight.
   *
   * @param fontFamily currently selected font family
   * @param fontWeight currently selected font weight
   */
  public static getFontWeightProps(fontFamily: string, fontWeight: string): FontWeightProps {
    const familyAndWeightToPropsMap = FontUtils.familyAndWeightToPropsMap

    const defaults = {
      fontWeight: 400,
      fontStyle: 'normal',
      lineHeight: 1.2
    }

    if (!isString(fontWeight)) {
      return defaults
    }

    if (!familyAndWeightToPropsMap[fontFamily]) {
      return defaults
    }

    return familyAndWeightToPropsMap[fontFamily][FontHelper.capitalize(fontWeight)] ?? defaults
  }

  /**
   * When switching between font families, calculate the closest font weight in
   * the new family that best matches the font weight in the currently selected one.
   *
   * @param currentFontFamily currently selected font family
   * @param currentFontWeight currently selected font family
   * @param newFontFamily new font family about to be selected
   */
  public static getNearestSupportedFontWeight(currentFontFamily: string, currentFontWeight: string, newFontFamily: string) {
    const familyAndWeightToPropsMap = FontUtils.familyAndWeightToPropsMap
    const currentFontWeightProps = FontUtils.getFontWeightProps(currentFontFamily, currentFontWeight)

    const fontWeightPropsArray = Object.entries(familyAndWeightToPropsMap[newFontFamily] ?? {})

    let closestNumericWeight!: number
    let previousDistance = Infinity

    for (const [, props] of fontWeightPropsArray) {
      const distance = Math.abs(props.fontWeight - currentFontWeightProps.fontWeight)

      if (distance < previousDistance) {
        closestNumericWeight = props.fontWeight
        previousDistance = distance
      }
    }

    if (closestNumericWeight) {
      // filter all font weights with the same numeric weight
      const closestFontWeights = fontWeightPropsArray.filter(([, props]) => {
        return props.fontWeight === closestNumericWeight
      })

      // find if one of those weights matches the current font style
      const closestFontWeight = closestFontWeights.find(([, props]) => {
        return props.fontStyle === currentFontWeightProps.fontStyle
      })

      // return the font weight name
      const [fontWeightName] = closestFontWeight ?? closestFontWeights[0]

      return fontWeightName
    }

    return currentFontWeight
  }

  public static getLegacyFontRules(key: string): LegacyFontRule {
    return FontUtils.legacyFontRules[kebabCase(key)]
  }

  /**
   * Create font maps for custom fonts.
   *
   * @param fonts Font array for current company.
   */
  public static setCustomFontMaps(fonts: Font[]) {
    const editorFonts = fonts.filter(item => !item.isReadOnly)
  
    // build font maps for freestyle
    this.customFontFamilies = buildCustomFontFamilies(editorFonts)
    this.customFamilyToWeightsMap = buildCustomFamilyToWeightsMap(editorFonts)
    this.customFamilyAndWeightToPropsMap = buildCustomFamilyAndWeightToPropsMap(fonts)

    // build legacy font maps based on the freestyle font maps
    this.customLegacyFontFamilies = buildCustomLegacyFontFamilies(this.customFontFamilies)
    this.customLegacyFontRules = buildCustomLegacyFontRules(this.customFamilyAndWeightToPropsMap)
  }

  /**
   * Clear custom font maps.
   *
   * We dont' want to display custom font options across companies.
   */
  public static clearCustomFontMaps() {
    this.customFontFamilies = []
    this.customFamilyToWeightsMap = {}
    this.customFamilyAndWeightToPropsMap = {}

    this.customLegacyFontFamilies = []
    this.customLegacyFontRules = {}
  }

  /**
   * Create, add and load a font face for every given font.
   *
   * @param fonts Font array for current company
   */
  public static addFontsToFontFaceSet(fonts: Font[]) {
    this.removeFontsFromFontFaceSet()

    for (const font of fonts) {
      const fontProps = this.getFontWeightProps(font.familyName, font.subfamilyName)
      const fontFace = new FontFace(font.familyName, `url(${font.downloadURL})`, {
        weight: `${fontProps.fontWeight}`,
        style: fontProps.fontStyle
      })

      document.fonts.add(fontFace)
    }
  }

  public static async loadFontFaceSet() {
    await Promise.allSettled(
      Array
        .from(document.fonts.values())
        .map(fontFace => fontFace.load())
    )
  }

  /**
   * Remove all manually added font faces.
   *
   * Preserves all font faces declared on CSS.
   */
  public static removeFontsFromFontFaceSet() {
    document.fonts.clear()
  }

  public static createFreestyleFontUtils() {
    const freestyleFontUtils = {
      get fontFamilyOptions() {
        return FontUtils.fontFamilyOptions
      },

      getFontWeights(fontFamily: string) {
        return FontUtils.getFontWeights(fontFamily)
      },

      getFontWeightProps(fontFamily: string, fontWeight: string) {
        return FontUtils.getFontWeightProps(fontFamily, fontWeight)
      },

      getNearestSupportedFontWeight(currentFontFamily: string, currentFontWeight: string, newFontFamily: string) {
        return FontUtils.getNearestSupportedFontWeight(currentFontFamily, currentFontWeight, newFontFamily)
      }
    }

    return freestyleFontUtils
  }

  public static createLegacyFontUtils() {
    const legacyFontUtils = {
      get names(): LegacyFontFamilies {
        return FontUtils.legacyFontFamilies
      },

      getRules(key: string): LegacyFontRule {
        return FontUtils.getLegacyFontRules(key)
      }
    }

    return legacyFontUtils
  }
}
