import { Stub, Config, upgrade } from '../../../../';

export default (stub: Stub, config: Config): Stub => {
    stub.version = 4;

    if (!stub.buttonStyle) {
        stub.buttonStyle = 'ingage';
    }

    if (!stub.width) {
        stub.width = 150;
    }

    if (!stub.height) {
        stub.height = 150;
    }

    if (config.recursive) {
        const keys = Object.keys(stub.buttonType);

        for (const key of keys) {
            if (typeof stub.buttonType[key] === 'object') {
                stub.buttonType[key] = upgrade(stub.buttonType[key], config);
            } else {
                delete stub.buttonType[key];
            }
        }
    }

    return stub;
};
