export default function () {
  return {
    "type": "theme",
    "version": 2,
    "themeBackgrounds": [
      {
        "style": "solid",
        "color": "FFFFFF"
      },
      {
        "style": "solid",
        "color": "C4C4C7"
      },
      {
        "style": "solid",
        "color": "8F8E94"
      },
      {
        "style": "solid",
        "color": "2a292f"
      },
      {
        "style": "solid",
        "color": "1B1B20"
      },
      {
        "style": "solid",
        "color": "000000"
      },
      {
        "style": "solid",
        "color": "E43333"
      },
      {
        "style": "solid",
        "color": "E4C733"
      },
      {
        "style": "solid",
        "color": "6EE433"
      },
      {
        "style": "solid",
        "color": "36a2e5"
      },
      {
        "style": "solid",
        "color": "5033E4"
      },
      {
        "style": "solid",
        "color": "E433E4"
      },
      {
        "style": "gradient",
        "direction": "topBottom",
        "colors": [
          "FFFFFF",
          "E6E6E6"
        ]
      },
      {
        "style": "gradient",
        "direction": "topBottom",
        "colors": [
          "1F1F1F",
          "4F4E5C"
        ]
      },
      {
        "style": "gradient",
        "direction": "topBottom",
        "colors": [
          "33A9E4",
          "227096"
        ]
      },
      {
        "style": "gradient",
        "direction": "topBottom",
        "colors": [
          "E43333",
          "E433E4"
        ]
      },
      {
        "style": "gradient",
        "direction": "topBottom",
        "colors": [
          "E4C733",
          "E43333"
        ]
      }
    ],
    "userBackgrounds": [] as any[],
    "textStyles": [
      {
        "name": "Title",
        "textFormat": {
          "textColor": "000000",
          "fontFamily": "Benton Sans",
          "fontWeight": "bold",
          "fontSize": 48
        }
      },
      {
        "name": "Heading",
        "textFormat": {
          "textColor": "000000",
          "fontFamily": "Benton Sans",
          "fontWeight": "bold",
          "fontSize": 24
        }
      },
      {
        "name": "Body",
        "textFormat": {
          "textColor": "000000",
          "fontFamily": "Benton Sans",
          "fontWeight": "regular",
          "fontSize": 18
        }
      },
      {
        "name": "Caption",
        "textFormat": {
          "textColor": "8F8E94",
          "fontFamily": "Benton Sans",
          "fontWeight": "bold",
          "fontSize": 12
        }
      }
    ]
  }
}