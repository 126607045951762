import dayjs from 'dayjs'
import { DateUtil } from 'src/util/DateUtil'
import { StringUtil } from 'src/util/StringUtil'
import { AnalyticsEvent } from 'src/model/analytics/Analytics'

export namespace ChartUtil {
  export const assignDefaultUserId = (
    event: AnalyticsEvent,
  ): AnalyticsEvent => {
    return event.user_id === ''
      ? { ...event, user_id: StringUtil.defaultUUID }
      : event
  }

  export const groupEventsBySessionId = (
    events: AnalyticsEvent[],
  ): Map<string, AnalyticsEvent[]> => {
    // group events by session id
    const eventsBySessionId = events.reduce(
      (
        eventsBySessionId: Map<string, AnalyticsEvent[]>,
        event: AnalyticsEvent,
      ) => {
        const sessionId = event.session_id
        const eventsForSession = eventsBySessionId.get(sessionId) || []

        return eventsBySessionId.set(sessionId, [...eventsForSession, event])
      },
      new Map(),
    )

    // filter out junk sessions that either have no 'view-page' events,
    // or are only composed of `view-page` events without a `view-story` end to the session
    for (const [sessionId, events] of eventsBySessionId) {
      if (
        !events.some((event) => event.event_name === 'view-story') ||
        !events.some((event) => event.event_name === 'view-page')
      ) {
        eventsBySessionId.delete(sessionId)
      }
    }

    return eventsBySessionId
  }

  export const groupEventsBySessionIdForDateRange = (
    events: AnalyticsEvent[],
    startDate: Date,
    endDate: Date,
  ): Map<string, AnalyticsEvent[]> => {
    // filter events by date range
    const eventsForDateRange = events.filter((event) => {
      const eventDate = dayjs(event.date.substring(0, 10)).toDate()

      return DateUtil.isDateWithinDateRange(startDate, endDate, eventDate)
    })

    // group events by session id filtering out junk sessions
    return groupEventsBySessionId(eventsForDateRange)
  }

  const groupEventsBySessionIdByDuration = (
    eventsBySessionId: Map<string, AnalyticsEvent[]>,
  ): Map<string, number> => {
    // use a map to group durations by session id
    // add durations for events with the same session id
    return Array.from(eventsBySessionId.entries()).reduce(
      (
        durationBySessionId: Map<string, number>,
        [sessionId, events]: [string, AnalyticsEvent[]],
      ) => {
        // add up the duration of all view-page events for the session
        const duration = events
          .filter((event) => event.event_name === 'view-page')
          .reduce(
            (duration: number, event: AnalyticsEvent) =>
              duration + event.duration_seconds,
            0,
          )

        // assign the duration to the session id
        return durationBySessionId.set(sessionId, duration)
      },
      new Map(),
    )
  }

  export const groupDurationsBySessionId = (
    events: AnalyticsEvent[],
  ): Map<string, number> => {
    // group events by session id
    const eventsBySessionId = groupEventsBySessionId(events)

    // use a map to group durations by session id
    return groupEventsBySessionIdByDuration(eventsBySessionId)
  }

  export const groupDurationsBySessionIdForDateRange = (
    events: AnalyticsEvent[],
    startDate: Date,
    endDate: Date,
  ): Map<string, number> => {
    // group events by session id for date range
    const eventsBySessionId = groupEventsBySessionIdForDateRange(
      events,
      startDate,
      endDate,
    )

    // use a map to group durations by session id
    return groupEventsBySessionIdByDuration(eventsBySessionId)
  }

  export const groupEventsByUserId = (
    events: AnalyticsEvent[],
  ): Map<string, AnalyticsEvent[]> => {
    return events.reduce(
      (
        eventsByUserId: Map<string, AnalyticsEvent[]>,
        event: AnalyticsEvent,
      ) => {
        const userId = event.user_id
        const eventsForUser = eventsByUserId.get(userId) || []

        return eventsByUserId.set(userId, [...eventsForUser, event])
      },
      new Map(),
    )
  }

  const groupEventsByUserIdForDateRange = (
    events: AnalyticsEvent[],
    startDate: Date,
    endDate: Date,
  ): Map<string, AnalyticsEvent[]> => {
    // filter events by date range
    const eventsForDateRange = events.filter((event) => {
      const eventDate = dayjs(event.date.substring(0, 10)).toDate()

      return DateUtil.isDateWithinDateRange(startDate, endDate, eventDate)
    })

    // group events by user id
    return groupEventsByUserId(eventsForDateRange)
  }

  export const groupSessionCountByUserIdForDateRange = (
    events: AnalyticsEvent[],
    startDate: Date,
    endDate: Date,
  ) => {
    const eventsByUserId = groupEventsByUserIdForDateRange(
      events,
      startDate,
      endDate,
    )

    return Array.from(eventsByUserId.entries()).reduce(
      (
        sessionCountByUserId: Map<string, number>,
        [userId, events]: [string, AnalyticsEvent[]],
      ) => {
        const userEventsBySessionId = groupEventsBySessionId(events)

        return sessionCountByUserId.set(userId, userEventsBySessionId.size)
      },
      new Map(),
    )
  }

  const groupEventsBySessionIdByPageViewCount = (
    eventsBySessionId: Map<string, AnalyticsEvent[]>,
  ): Map<string, number> => {
    return Array.from(eventsBySessionId.entries()).reduce(
      (
        pageViewCountBySessionId: Map<string, number>,
        [sessionId, events]: [string, AnalyticsEvent[]],
      ) => {
        const pageViewCount = events.filter(
          (event) => event.event_name === 'view-page',
        ).length

        return pageViewCountBySessionId.set(sessionId, pageViewCount)
      },
      new Map(),
    )
  }

  export const groupPageViewCountBySessionId = (
    events: AnalyticsEvent[],
  ): Map<string, number> => {
    const eventsBySessionId = groupEventsBySessionId(events)

    return groupEventsBySessionIdByPageViewCount(eventsBySessionId)
  }

  export const groupPageViewCountBySessionIdForDateRange = (
    events: AnalyticsEvent[],
    startDate: Date,
    endDate: Date,
  ) => {
    const eventsBySessionId = groupEventsBySessionIdForDateRange(
      events,
      startDate,
      endDate,
    )

    return groupEventsBySessionIdByPageViewCount(eventsBySessionId)
  }
}
