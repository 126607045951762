/**
 * Contains a list of all analytics events.
 */
export enum ExternalAnalyticsEvent {
  // Page View Event -- USE THIS FOR ALL PAGE VIEWS instead of using the old NAV_ events in the future.
  PAGE_VIEW = 'page-view',

  // Auth
  LOGIN = 'login-user-success',
  LOGIN_BUT_NOT_AUTHORIZED = 'login-user-success-but-not-authorized',
  EULA_AGREED = 'eula-agreed',
  EULA_DECLINED = 'eula-declined',
  PREVIOUSLY_AUTHORIZED_USER_NO_LONGER_AUTHORIZED = 'previously-authorized-user-no-longer-authorized',

  // Legacy pageview events - @deprecated
  NAV_DASHBOARD_HOME = 'nav-dashboard',
  NAV_TEAMS_HOME = 'nav-team',
  NAV_USERS_HOME = 'nav-users',
  NAV_PRESENTATIONS_HOME = 'nav-presentations',
  NAV_ANALYTICS_HOME = 'nav-analytics',
  NAV_ACCOUNT = 'nav-account',
  NAV_INTEGRATIONS_HOME = 'nav-integrations',

  // Sidebar
  SIDEBAR_PARENT_NODE_SELECTED = 'sidebar-parent-node-selected',
  SIDEBAR_PARENT_NODE_EXPANDED = 'sidebar-parent-node-expanded',
  SIDEBAR_PARENT_NODE_COLLAPSED = 'sidebar-parent-node-collapsed',
  SIDEBAR_CHILD_NODE_SELECTED = 'sidebar-child-node-selected',
  SIDEBAR_CHILD_NODE_EXPANDED = 'sidebar-child-node-expanded',
  SIDEBAR_CHILD_NODE_COLLAPSED = 'sidebar-child-node-collapsed',

  // Teams
  ADD_TEAM = 'add-new-team',
  UPDATE_TEAM = 'update-team',
  DELETE_TEAM = 'delete-team',

  // Users
  ADD_NEW_USER = 'add-new-user',
  REMOVE_TEAM_MEMBER = 'remove-team-member',

  // Analytics
  ANALYTICS_ADVANCED_OPTIONS_UPDATED = 'teams-analytics-advanced-options-updated',
  ANALYTICS_REPORT_SELECTED = 'teams-analytics-report-selected',
  ANALYTICS_REPORT_DOWNLOAD = 'teams-analytics-report-download',
  ANALYTICS_REPORT_PRINT = 'teams-analytics-report-print',
  ANALYTICS_SAVED_REPORT_CREATED = 'teams-analytics-saved-report-created',
  ANALYTICS_SAVED_REPORT_UPDATED = 'teams-analytics-saved-report-updated',
  ANALYTICS_SAVED_REPORT_DELETED = 'teams-analytics-saved-report-deleted',
  ANALYTICS_SAVED_REPORT_SELECTED = 'teams-analytics-saved-report-selected',
  ANALYTICS_SECTION_SELECTED = 'teams-analytics-section-selected',

  // Integrations
  PUBLIC_API_VIEW_KEY = 'teams-publicapi-view-key',
  PUBLIC_API_COPY_KEY = 'teams-publicapi-copy-key',
  PUBLIC_API_CREATE_KEY = 'teams-publicapi-create-key',
  PUBLIC_API_UPDATE_KEY = 'teams-publicapi-update-key',
  PUBLIC_API_REGENERATE_KEY = 'teams-publicapi-regenerate-key',
  PUBLIC_API_DELETE_KEY = 'teams-publicapi-delete-key',

  // Presentations
  PRESENTATION_MENU_CLICK_EDIT = 'presentation-menu-click-edit',

  // Playlists
  PRESENTATION_PLAYLIST_VIEWED = 'teams-presentation-playlist-viewed',
  PRESENTATION_PLAYLIST_SET = 'teams-presentation-playlist-set',
  PRESENTATION_PLAYLIST_CANCELED = 'teams-presentation-playlist-canceled',

  // Account
  ACTIVITY_LOG_VIEW_EVENTS = 'teams-activitylog-view-events',
  ACTIVITY_LOG_SEARCH_EVENTS = 'teams-activitylog-search-events',

  // Dashboard
  DASHBOARD_CLICK_CONVERT_DEMO = 'dashboard-click-convert-demo',
  DASHBOARD_CLICK_MARKETPLACE_BANNER = 'dashboard-click-marketplace-banner',
  DASHBOARD_CLICK_NEW_USER = 'dashboard-click-new-user',
  DASHBOARD_CLICK_NEW_TEAM = 'dashboard-click-new-team',
  DASHBOARD_CLICK_LEARN = 'dashboard-click-learn',
  DASHBOARD_CLICK_DOWNLOAD = 'dashboard-click-download',
  DASHBOARD_CLICK_ANALYTICS_TILE = 'dashboard-click-analytics-tile',
  DASHBOARD_CLICK_MARKETPLACE_TILE = 'dashboard-click-marketplace-tile',

  // Download Modal
  DOWNLOAD_MODAL_VIEW = 'download-modal-view',
  DOWNLOAD_MODAL_DISMISS = 'download-modal-dismiss',
  DOWNLOAD_MODAL_ACTION = 'download-modal-action',

  // Marketplace
  MARKETPLACE_HOME_CAROUSEL_ITEM_CLICKED = 'marketplace-home-carousel-item-clicked',
  MARKETPLACE_HOME_CATEGORY_CLICKED = 'marketplace-home-category-clicked',
  MARKETPLACE_HOME_SEARCH = 'marketplace-home-search',
  MARKETPLACE_INSTALL_BUTTON_CLICKED = 'marketplace-install-button-clicked',
  MARKETPLACE_INSTALL_START = 'marketplace-install-start',
  MARKETPLACE_INSTALL_CANCEL_BUTTON_CLICKED = 'marketplace-install-cancel-button-clicked',
  MARKETPLACE_INSTALL_DONE_BUTTON_CLICKED = 'marketplace-install-done-button-clicked',
  MARKETPLACE_INSTALL_EXIT_MODAL_ICON_CLICKED = 'marketplace-install-exit-modal-icon-clicked',
  MARKETPLACE_INSTALL_SKIP_BUTTON_CLICKED = 'marketplace-install-skip-button-clicked',
  MARKETPLACE_INSTALL_NEXT_BUTTON_CLICKED = 'marketplace-install-next-button-clicked',
  MARKETPLACE_INSTALL_MODAL_CLOSED = 'marketplace-install-modal-closed',
  MARKETPLACE_INSTALL_POST_INSTALL_ACTIONS = 'marketplace-install-post-install-actions',
  MARKETPLACE_INSTALL_FINISHED = 'marketplace-install-finished',
  MARKETPLACE_INSTALL_CANCELLED = 'marketplace-install-cancelled',
  MARKETPLACE_INSTALL_ERROR = 'marketplace-install-error',
  MARKETPLACE_INSTALL_STEP_THEME_SELECT = 'marketplace-install-step-theme-select',
  MARKETPLACE_INSTALL_STEP_TEAMS_SELECT = 'marketplace-install-step-teams-select',
  MARKETPLACE_INSTALL_STEP_CONFIRM_BEFORE_INSTALL = 'marketplace-install-step-confirm-before-install',
  MARKETPLACE_INSTALL_STEP_CUSTOMIZE = 'marketplace-install-step-customize',
  MARKETPLACE_INSTALL_UPSELL_REQUIRED = 'marketplace-install-upsell-required',
  MARKETPLACE_INSTALL_REQUEST_DEMO_CTA_CLICKED = 'marketplace-install-request-demo-clicked',
  MARKETPLACE_PUBLISHER_LINK_CLICKED = 'marketplace-publisher-link-clicked',
  MARKETPLACE_LOGIN_REQUIRED_REROUTE = 'marketplace-login-required-reroute',
  MARKETPLACE_PREVIEW_BUTTON_CLICK = 'marketplace-preview-button-click',

  // Settings
  SETTINGS_COMPANY_CREATED = 'settings-company-created',

  // Performance Monitoring
  PERFORMANCE_LOADING_ANALYTICS_PREBUILT_DASHBOARD = 'loading-analytics-prebuilt-dashboard',
  PERFORMANCE_LOADING_ANALYTICS_PREBUILT_SESSIONS_HOME = 'loading-analytics-prebuilt-sessions-home',
  PERFORMANCE_LOADING_ANALYTICS_PREBUILT_SESSIONS_DETAILS = 'loading-analytics-prebuilt-sessions-details',
  PERFORMANCE_LOADING_ANALYTICS_PREBUILT_USERS_HOME = 'loading-analytics-prebuilt-users-home',
  PERFORMANCE_LOADING_ANALYTICS_PREBUILT_USERS_DETAILS = 'loading-analytics-prebuilt-users-details',
  PERFORMANCE_LOADING_ANALYTICS_PREBUILT_COMPARE_HOME = 'loading-analytics-prebuilt-compare-home',
  PERFORMANCE_LOADING_ANALYTICS_PREBUILT_COMPARE_DETAILS = 'loading-analytics-prebuilt-compare-details',
  PERFORMANCE_LOADING_ANALYTICS_PREBUILT_CONTENT_HOME = 'loading-analytics-prebuilt-content-home',
  PERFORMANCE_LOADING_ANALYTICS_PREBUILT_CONTENT_DETAILS = 'loading-analytics-prebuilt-content-details',
}
