import { makeAutoObservable } from 'mobx'
import { ThemeDataPayload } from 'src/api/ThemeAPI'
import { PresentationsModel } from 'src/model/presentation/PresentationsModel'
import { Theme } from 'src/model/theme/Theme'
import { ThemeModel } from 'src/model/theme/ThemeModel'
import { RootStore } from 'src/store/RootStore'
import { UUID } from 'src/util/types'
import { BaseStore } from './BaseStore'

export class ThemeStore implements BaseStore {
  rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this, { rootStore: false })
  }

  /**
   * Gets a list of themes for a company.
   *
   * @param companyUUID
   */
  async getThemeList(companyUUID: UUID) {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .themeAPI.getThemeList(companyUUID)
      if (res) {
        return res.map((apiTheme) => ThemeModel.themeFromAPIModel(apiTheme))
      }
    } catch (e) {
      console.error(`Unable to fetch all themes for company ${companyUUID}`, e)
    }
    return []
  }

  /**
   * Returns a single theme for a specific company.
   *
   * @param companyUUID
   * @param themeUUID
   */
  async getTheme(companyUUID: UUID, themeUUID: UUID) {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .themeAPI.getTheme(companyUUID, themeUUID)
      if (res.data) {
        const theme = ThemeModel.themeFromAPIModel(res.data)
        return theme
      }
    } catch (e) {
      console.error(
        `Unable to get theme ${themeUUID} for company ${companyUUID}`,
        e,
      )
    }
    return null
  }

  /**
   * Creates a new theme under a specific company.
   *
   * @param companyUUID
   * @param newThemeData payload for the new theme, including company UUID, name, description, and theme object data
   */
  async createTheme(companyUUID: UUID, newThemeData: ThemeDataPayload) {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .themeAPI.createTheme(companyUUID, newThemeData)
      if (res.data) {
        return ThemeModel.themeFromAPIModel(res.data)
      }
    } catch (e) {
      console.error(`Unable to create new theme for company ${companyUUID}`, e)
    }
    return null
  }

  /**
   * Updates an existing theme under a specific company.
   *
   * @param companyUUID
   * @param themeUUID
   * @param changes payload of the changes, including name, description, and theme object data
   */
  async updateTheme(
    companyUUID: UUID,
    themeUUID: UUID,
    changes: ThemeDataPayload,
  ) {
    try {
      // Theme name has a 56 character limit. Truncate to prevent HTTP 400.
      changes.name = changes.name.slice(0, 56)
      const res = await this.rootStore
        .getAPIClient()
        .themeAPI.updateTheme(companyUUID, themeUUID, changes)
      if (res.data) {
        return ThemeModel.themeFromAPIModel(res.data)
      }
    } catch (e) {
      console.error(
        `Unable to update theme ${themeUUID} for company ${companyUUID}`,
        e,
      )
    }
    return null
  }

  /**
   * Removes an existing theme. This action permanently removes the theme itself, not just the relationship between
   * itself and the company.
   *
   * @param companyUUID
   * @param themeUUID
   */
  async removeTheme(companyUUID: UUID, themeUUID: UUID) {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .themeAPI.removeTheme(companyUUID, themeUUID)
      if (res.status < 400) {
        return true
      }
    } catch (e) {
      console.error(
        `Unable to update theme ${themeUUID} for company ${companyUUID}`,
        e,
      )
    }
    return false
  }

  /**
   * Applies a theme to a presentation. This process does not obtain a lock on a presentation, so it should only be used
   * when the presentation has yet to be made available to the end-user to edit (e.g. during the Marketplace Customize
   * process).
   *
   * @param companyUUID
   * @param presentationUUID
   * @param theme
   */
  async applyThemeToPresentation(
    companyUUID: UUID,
    presentationUUID: UUID,
    theme: Theme,
  ) {
    const currentVersion =
      await this.rootStore.presentationStore.getCurrentVersion(presentationUUID)
    if (currentVersion) {
      const duplicateRes = await this.rootStore
        .getAPIClient()
        .presentationsAPI.duplicateVersion(currentVersion.id)
      if (!duplicateRes || duplicateRes.status !== 201) {
        return console.error(
          `Unable to duplicate presentation (${presentationUUID}) version: ${currentVersion.id}. HTTP status was not 201`,
        )
      }
      PresentationsModel.presentationVersionFromAPIModel(duplicateRes.data)
    }
  }

  clearData() {
    // no-op
  }
}
