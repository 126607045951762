import { Stub, Config, upgrade } from '../../../../';

export default (stub: Stub, config: Config): Stub => {
    if (config.recursive) {
        if (stub.mediaType.image) {
            stub.mediaType.image = upgrade(stub.mediaType.image, config);
        }
        if (stub.mediaType.video) {
            stub.mediaType.video = upgrade(stub.mediaType.video, config);
        }
    }
    return stub;
};
