import { Stub, Config, upgrade } from '../../../../';
import freestyleStylesDefaults from '../../freestyle-styles/defaults/v2'
import legacyStylesDefaults from '../../legacy-styles/defaults/v2'
import storybarDefaults from '../../story-bar-styles/defaults/v1'

export default (stub: Stub, config: Config): Stub => {
  // Updates from version 2 -> 3

  stub.version = 3;

  if (!stub.theme) {
      stub.theme = freestyleStylesDefaults()
  }

  if (!stub.legacyStyles) {
    stub.legacyStyles = legacyStylesDefaults()
  }

  if (!stub.storyBarStyles){
    stub.storyBarStyles = storybarDefaults()
  }

  if (!stub.name || stub.name.length === 0) {
    stub.name = 'Ingage'
  }

  delete stub.masterPages

  if (config.recursive) {
    if (stub.theme) {
        stub.theme = upgrade(stub.theme, config);
    }
    if (stub.legacyStyles) {
      stub.legacyStyles = upgrade(stub.legacyStyles, config);
    }
    if (stub.storyBarStyles) {
      stub.storyBarStyles = upgrade(stub.storyBarStyles, config);
    }    
  }

  return stub;
};
