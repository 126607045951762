import { Stub, Config, Context, upgrade } from '../../../../';

export default (stub: Stub, config: Config): Stub => {
    stub.version = 5;
    stub.isEditable = true;

    const size = getSize(stub);

    for (let i = 0; i < stub.partitions.length; i++) {
        const partition = stub.partitions[i];

        if (partition.buttonTextOverlay == null) {
            partition.buttonTextOverlay = {
                type: 'overlay-component',
                version: 2,
                items: [],
            };
        }

        if (config.recursive) {
            partition.body = upgrade(partition.body, config);
            partition.media = upgrade(partition.media, config, size);
            partition.buttonOverlay = upgrade(partition.buttonOverlay, config);
            partition.buttonTextOverlay = upgrade(
                partition.buttonTextOverlay,
                config,
            );
            partition.subheading = upgrade(partition.subheading, config);
        }
    }

    if (config.recursive) {
        stub.title = upgrade(stub.title, config, { textMode: 'header' });
        stub.headline = upgrade(stub.headline, config);
    }

    return stub;
};

const getSize = (page: Stub): Context => {
    let mapTranslateX: { [key: number]: number };
    let mapTranslateY: { [key: number]: number };

    if (page.organization === 'columns') {
        mapTranslateX = {
            1: 384,
            2: 384,
            3: 277,
        };
        mapTranslateY = {
            1: 288,
            2: 288,
            3: 208,
        };
    } else {
        mapTranslateX = {
            1: 294,
            2: 294,
            3: 166,
        };
        mapTranslateY = {
            1: 220,
            2: 220,
            3: 125,
        };
    }

    return {
        width: mapTranslateX[page.partitionCount],
        height: mapTranslateY[page.partitionCount],
    };
};
