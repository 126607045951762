import { Profile } from 'src/model/user/Profile'

interface NamedTeam {
  name: string
}

export namespace TeamsUtil {
  /**
   * Display team only if it is not a hidden team (team name starts with a ".") or if the user is an internal user.
   *
   * @param team company team or user team
   * @param profile user profile to determine if it is an internal user
   * @returns true if the team should be displayed
   */
  export const shouldDisplaTeam = <T extends NamedTeam>(
    team: T,
    profile?: Profile,
  ) => {
    const isInternal = profile?.isInternal ?? false
    const isHiddenTeam = team.name?.startsWith('.') ?? false

    return isInternal || !isHiddenTeam
  }

  /**
   * Filter out hidden teams (teams that start with a ".") from the list of teams for all except internal users.
   *
   * @param profile user profile
   * @returns filter callback
   */
  export const filterHiddenTeams =
    (profile?: Profile) =>
    <T extends NamedTeam>(team: T) =>
      shouldDisplaTeam(team, profile)
}
