import { Stub, Config, upgrade } from '../../../../';

export default (stub: Stub, config: Config): Stub => {
    if (config.recursive) {
        for (const key in stub.content) {
            stub.content[key] = upgrade(stub.content[key], config);
        }
    }
    return stub;
};
