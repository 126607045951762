import { Stub, Config, upgrade } from '../../../../';

export default (stub: Stub, config: Config): Stub => {
    stub.version = 3.0;

    if (stub.buttonTextOverlay == null) {
        stub.buttonTextOverlay = {
            type: 'overlay-component',
            version: 1.0,
            items: [],
        };
    }

    if (config.recursive) {
        stub.headingText = upgrade(stub.headingText, config);
        stub.video = upgrade(stub.video, config);
        stub.buttonOverlay = upgrade(stub.buttonOverlay, config);
        stub.buttonTextOverlay = upgrade(stub.buttonTextOverlay, config);
        stub.bodyText = upgrade(stub.bodyText, config);
    }
    return stub;
};
