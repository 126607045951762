import { APITemplate } from 'src/model/api/theme/APITemplate'
import { APITheme } from 'src/model/api/theme/APITheme'
import { Template } from 'src/model/theme/template/Template'
import { Theme } from 'src/model/theme/Theme'

export namespace ThemeModel {
  /**
   * Translates the API data model to a view data model.
   * @param apiTheme
   */
  export const themeFromAPIModel = (apiTheme: APITheme): Theme => ({
    company: apiTheme.company,
    companyUUID: apiTheme.company_uuid,
    createdAt: apiTheme.created_at,
    createdBy: apiTheme.created_by,
    deletedAt: apiTheme.deleted_at,
    deletedBy: apiTheme.deleted_by,
    description: apiTheme.description,
    id: apiTheme.id,
    isArchived: apiTheme.is_archived,
    name: apiTheme.name,
    theme: templateFromAPIModel(apiTheme.theme),
    updatedAt: apiTheme.updated_at,
    updatedBy: apiTheme.updated_by,
    uuid: apiTheme.uuid,
    version: apiTheme.version,
  })

  /**
   * Translates the view data model to an API data model.
   * @param theme
   */
  export const apiModelFromTheme = (theme: Theme): APITheme => ({
    company: theme.company,
    company_uuid: theme.companyUUID,
    created_at: theme.createdAt,
    created_by: theme.createdBy,
    deleted_at: theme.deletedAt,
    deleted_by: theme.deletedBy,
    description: theme.description,
    id: theme.id,
    is_archived: theme.isArchived,
    name: theme.name,
    theme: apiTemplateFromModel(theme.theme),
    updated_at: theme.updatedAt,
    updated_by: theme.updatedBy,
    uuid: theme.uuid,
    version: theme.version,
  })

  export const templateFromAPIModel = (apiTemplate: APITemplate): Template => ({
    theme: apiTemplate.theme,
    id: apiTemplate.id,
    legacyStyles: apiTemplate.legacyStyles,
    name: apiTemplate.name,
    storyBarStyles: apiTemplate.storyBarStyles,
    type: apiTemplate.type,
    version: apiTemplate.version,
  })

  export const apiTemplateFromModel = (template: Template): APITemplate => ({
    theme: template.theme,
    id: template.id,
    legacyStyles: template.legacyStyles,
    name: template.name,
    storyBarStyles: template.storyBarStyles,
    type: template.type,
    version: template.version,
  })
}
