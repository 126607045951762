import { Stub, Config, upgrade } from '../../../../';

export default (stub: Stub, config: Config): Stub => {
    stub.version = 6;
    if (config.recursive) {
        stub.headingText = upgrade(stub.headingText, config);
        stub.compare = upgrade(stub.compare, config);
        stub.bodyText = upgrade(stub.bodyText, config);
        stub.buttonTextOverlay = upgrade(stub.buttonTextOverlay, config);
    }

    return stub;
};
