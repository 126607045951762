import { Stub, Config, upgrade } from '../../../../';

export default (stub: Stub, config: Config): Stub => {
    if (config.recursive) {
        stub.headingText = upgrade(stub.headingText, config);
        stub.video = upgrade(stub.video, config);
        stub.buttonOverlay = upgrade(stub.buttonOverlay, config);
        stub.buttonTextOverlay = upgrade(stub.buttonTextOverlay, config);
        stub.bodyText = upgrade(stub.bodyText, config);
    }
    return stub;
};
