export enum ItemCodeStatus {
  NEEDS_REDEEMING,
  IS_REDEEMING,
  IS_CHECKING_STATUS,
  HAS_ERRORS,
  REDEEMED,
  ALREADY_REDEEMED_BY_SELF,
}

export type ItemCodeRedemption = {
  status: ItemCodeStatus
  listingUUID: string
}
