const gafDynamicContentMap = {
  certifications: {
    level0: `${process.env.REACT_APP_BRAND_ASSET_PATH}/certification-assets/not_certified.png`,
    level1: `${process.env.REACT_APP_BRAND_ASSET_PATH}/certification-assets/certified.png`,
    level2: `${process.env.REACT_APP_BRAND_ASSET_PATH}/certification-assets/certified_plus.png`,
    level3: `${process.env.REACT_APP_BRAND_ASSET_PATH}/certification-assets/master_elite.png`,
  },
  warranties: {
    level0: `${process.env.REACT_APP_BRAND_ASSET_PATH}/certification-assets/not_certified_warranty.png`,
    level1: `${process.env.REACT_APP_BRAND_ASSET_PATH}/certification-assets/certified_warranty.png`,
    level2: `${process.env.REACT_APP_BRAND_ASSET_PATH}/certification-assets/certified_plus_warranty.png`,
    level3: `${process.env.REACT_APP_BRAND_ASSET_PATH}/certification-assets/master_elite_warranty.png`,
  },
  presidentsClub: {
    level1: `${process.env.REACT_APP_BRAND_ASSET_PATH}/certification-assets/presidents_club_1.png`,
    level2: `${process.env.REACT_APP_BRAND_ASSET_PATH}/certification-assets/presidents_club_2.png`,
    level3: `${process.env.REACT_APP_BRAND_ASSET_PATH}/certification-assets/presidents_club_3.png`,
  },
}

export type LevelKeys = 'level0' | 'level1' | 'level2' | 'level3'

export default gafDynamicContentMap
