/**
 * Provides methods to ingest client-side data models from API data for Presentations.
 */
import { APIPresentation } from 'src/model/api/presentation/APIPresentation'
import { APIPresentationAsset } from 'src/model/api/presentation/APIPresentationAsset'
import { APIPresentationTeam } from 'src/model/api/presentation/APIPresentationTeam'
import { APIPresentationVersion } from 'src/model/api/presentation/APIPresentationVersion'
import { APIPresentationWithDraftVersion } from '../api/presentation/APIPresentationWithDraftVersion'
import { PresentationWithDraftVersion } from './PresentationWithDraftVersion'
import { APIShareResponse } from 'src/model/api/presentation/APIShareResponse'
import { CompanyModel } from 'src/model/company'
import { Presentation } from 'src/model/presentation/Presentation'
import { PresentationAsset } from 'src/model/presentation/PresentationAsset'
import { PresentationTeam } from 'src/model/presentation/PresentationTeam'
import { PresentationVersion } from 'src/model/presentation/PresentationVersion'
import { ShareResponse } from 'src/model/presentation/ShareResponse'

export namespace PresentationsModel {
  export const presentationVersionFromAPIModel = (
    apiPresentationVersion: APIPresentationVersion,
  ) => {
    const presentationVersion: PresentationVersion = {
      assets: apiPresentationVersion.assets,
      covers: apiPresentationVersion.covers,
      createdAt: apiPresentationVersion.created_at,
      createdBy: apiPresentationVersion.created_by,
      id: apiPresentationVersion.id,
      isPublished: apiPresentationVersion.is_published,
      name: apiPresentationVersion.name,
      pages: apiPresentationVersion.pages,
      parent: apiPresentationVersion.parent,
      size: apiPresentationVersion.size,
      subtitle: apiPresentationVersion.subtitle,
      thumbnail: apiPresentationVersion.thumbnail,
      thumbnailComposite: apiPresentationVersion.thumbnail_composite,
      updatedAt: apiPresentationVersion.updated_at,
      updatedBy: apiPresentationVersion.updated_by,
      url: apiPresentationVersion.url,
    }
    return presentationVersion
  }

  export const presentationFromAPIModel = (
    apiPresentation: APIPresentation,
  ) => {
    const presentation: Presentation = {
      automationTemplate: apiPresentation.automation_template,
      contentExtra: apiPresentation.content_extra,
      createdAt: apiPresentation.created_at,
      createdBy: apiPresentation.created_by,
      currentVersionURL: apiPresentation.current_version,
      currentVersion:
        apiPresentation.current_version_obj &&
        presentationVersionFromAPIModel(apiPresentation.current_version_obj),
      cvUpdatedAt: apiPresentation.cv_updated_at,
      deletedAt: apiPresentation.deleted_at,
      deletedBy: apiPresentation.deleted_by,
      favorite: apiPresentation.favorite,
      id: apiPresentation.id,
      isArchived: apiPresentation.is_archived,
      isDeletable: apiPresentation.is_deletable,
      isDuplicatable: apiPresentation.is_duplicatable,
      isEditable: apiPresentation.is_editable,
      isGuestEditable: apiPresentation.is_guest_editable,
      isInternallyShareable: apiPresentation.is_internally_shareable,
      isLocked: apiPresentation.is_locked,
      isLockedBy: apiPresentation.is_locked_by,
      isPubliclyShareable: apiPresentation.is_publicly_shareable,
      canCopyToClipboard: apiPresentation.can_copy_to_clipboard,
      listing: apiPresentation.listing,
      lockedBy: apiPresentation.locked_by,
      lockedPages: apiPresentation.locked_pages,
      navTag: apiPresentation.nav_tag,
      owner: apiPresentation.owner,
      shareAlias: apiPresentation.share_alias,
      shareType: apiPresentation.share_type,
      shareUrl: apiPresentation.share_url,
      type: apiPresentation.type,
      updatedAt: apiPresentation.updated_at,
      updatedBy: apiPresentation.updated_by,
      url: apiPresentation.url,
      clonedFromVersionId: apiPresentation.cloned_from_version_id,
      versions: apiPresentation.versions.map((apiVersion) =>
        presentationVersionFromAPIModel(apiVersion),
      ),
    }
    return presentation
  }

  export const presentationWithDraftVersionFromAPIModel = (
    apiPresentation: APIPresentationWithDraftVersion,
  ) => {
    const presentationWithDraftVersion: PresentationWithDraftVersion = {
      url: apiPresentation.url,
      id: apiPresentation.id,
      type: apiPresentation.type,
      owner: apiPresentation.owner,
      createdAt: apiPresentation.created_at,
      createdBy: apiPresentation.created_by,
      updatedAt: apiPresentation.updated_at,
      updatedBy: apiPresentation.updated_by,
      versions: apiPresentation.versions.map((apiVersion) =>
        presentationVersionFromAPIModel(apiVersion),
      ),
      shareAlias: apiPresentation.share_alias,
      isArchived: apiPresentation.is_archived,
      deletedAt: apiPresentation.deleted_at,
      deletedBy: apiPresentation.deleted_by,
      shareType: apiPresentation.share_type,
      isWebAccessible: apiPresentation.is_web_accessible,
      shareUrl: apiPresentation.share_url,
      contentExtra: apiPresentation.content_extra,
      isEditable: apiPresentation.is_editable,
      isDuplicatable: apiPresentation.is_duplicatable,
      isDeletable: apiPresentation.is_deletable,
      isInternallyShareable: apiPresentation.is_internally_shareable,
      isPubliclyShareable: apiPresentation.is_publicly_shareable,
      isGuestEditable: apiPresentation.is_guest_editable,
      changePermission: apiPresentation.change_permission,
      canCopyToClipboard: apiPresentation.can_copy_to_clipboard,
      automationTemplate: apiPresentation.automation_template,
      navTag: apiPresentation.nav_tag,
      lockedPages: apiPresentation.locked_pages,
      isLockedBy: apiPresentation.is_locked_by,
      lockedBy: apiPresentation.locked_by,
      isLocked: apiPresentation.is_locked,
      lockId: apiPresentation.lock_id,
      clonedFromContentId: apiPresentation.cloned_from_content_id,
      clonedFromVersionId: apiPresentation.cloned_from_version_id,
      isMarketplace: apiPresentation.is_marketplace,
      isReadOnly: apiPresentation.is_read_only,
      isAvailable: apiPresentation.is_available,
      companyUUIDs: apiPresentation.company_uuids,
      teamUUIDs: apiPresentation.team_uuids,
      draftVersion:
        apiPresentation.draft_version &&
        presentationVersionFromAPIModel(apiPresentation.draft_version),
      publishedVersion:
        apiPresentation.published_version &&
        presentationVersionFromAPIModel(apiPresentation.published_version),
    }
    return presentationWithDraftVersion
  }

  export const presentationAssetFromAPIModel = (
    apiPresentationAsset: APIPresentationAsset,
  ): PresentationAsset => ({
    url: apiPresentationAsset.url,
    id: apiPresentationAsset.id,
    path: apiPresentationAsset.path,
    download: apiPresentationAsset.download,
    size: apiPresentationAsset.size,
    contentType: apiPresentationAsset.content_type,
    md5: apiPresentationAsset.md5,
    createdAt: apiPresentationAsset.created_at,
    createdBy: apiPresentationAsset.created_by,
    updatedAt: apiPresentationAsset.updated_at,
    updatedBy: apiPresentationAsset.updated_by,
    status: apiPresentationAsset.status,
    error: apiPresentationAsset.error,
    isSm: apiPresentationAsset.is_sm,
    isSmComplete: apiPresentationAsset.is_sm_complete,
    smStatus: apiPresentationAsset.sm_status,
    downloadCF: apiPresentationAsset.download_cf,
    downloadCFTTL: apiPresentationAsset.download_cf_ttl,
  })

  export const presentationTeamFromAPIModel = (
    apiPresentationTeam: APIPresentationTeam,
  ) => {
    const presentationTeam: PresentationTeam = {
      company: apiPresentationTeam.company,
      companyUUID: apiPresentationTeam.company_uuid,
      contentsURL: apiPresentationTeam.contents_url,
      id: apiPresentationTeam.id,
      membersURL: apiPresentationTeam.members_url,
      name: apiPresentationTeam.name,
      role: CompanyModel.teamRoleFromAPIModel(apiPresentationTeam.role),
      thumbnail: apiPresentationTeam.thumbnail,
      uuid: apiPresentationTeam.uuid,
    }
    return presentationTeam
  }

  export const shareResponseFromAPIModel = (
    apiShareResponse: APIShareResponse,
  ) => {
    const shareResponse: ShareResponse = {
      content: apiShareResponse.content,
      id: apiShareResponse.id,
      name: apiShareResponse.name,
      shareType: apiShareResponse.share_type,
      shareUrl: apiShareResponse.share_url,
      type: apiShareResponse.type,
      url: apiShareResponse.url,
      users: apiShareResponse.users,
    }
    return shareResponse
  }
}
