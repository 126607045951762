import gafDynamicContentMap from './brand/dynamicContent/gafDynamicContentMap'
import gafGettingStartedGuide from './brand/gettingStartedGuide/gafGettingStartedGuide'
import ingageGettingStartedGuide from './brand/gettingStartedGuide/ingageGettingStartedGuide'

const brandManifest = {
  assets: {
    appIcon: `${process.env.REACT_APP_BRAND_ASSET_PATH}/app-icon`,
    brandedIPad: `${process.env.REACT_APP_BRAND_ASSET_PATH}/branded-ipad`,
    dashboardBanner: `${process.env.REACT_APP_BRAND_ASSET_PATH}/dashboard-banner`,
    downloadApp: `${process.env.REACT_APP_BRAND_ASSET_PATH}/download-app`,
    favicon: `${process.env.REACT_APP_BRAND_ASSET_PATH}/favicon`,
    listingAssetPlaceholder: `${process.env.REACT_APP_BRAND_ASSET_PATH}/listing-asset-placeholder`,
    listingAssetThumbnailPlaceholder: `${process.env.REACT_APP_BRAND_ASSET_PATH}/listing-asset-thumbnail-placeholder`,
    loginBackground: `${process.env.REACT_APP_BRAND_ASSET_PATH}/login-background`,
    logo: `${process.env.REACT_APP_BRAND_ASSET_PATH}/logo`,
    thumbnail: `${process.env.REACT_APP_BRAND_ASSET_PATH}/thumbnail`,
    thumbailPlaceholder: `${process.env.REACT_APP_BRAND_ASSET_PATH}/thumbnail-placeholder`,
    dynamicContent: {
      gaf: gafDynamicContentMap,
      ingage: undefined,
    },
  },
  appName: {
    gaf: 'GAF Present',
    ingage: 'Ingage',
  },
  dashboardBannerNav: {
    gaf: 'presentations',
    ingage: 'marketplace',
  },
  logoSize: {
    gaf: 24,
    ingage: 30,
  },
  showSSOOption: {
    gaf: false,
    ingage: true,
  },
  urlScheme: {
    gaf: 'gafpresent',
    ingage: 'ingage',
  },
  xOriginHeader: {
    gaf: 'teams.present.gaf.com',
    ingage: 'teams.ingage.ingage.io',
  },
  canEdit: {
    gaf: false,
    ingage: true,
  },
  iTunesURL: {
    gaf: 'https://itunes.apple.com/app/apple-store/id6670276084',
    ingage: 'https://itunes.apple.com/app/apple-store/id1100355870',
  },
  macDownloadURL: {
    gaf: 'https://download.ingage.io/gaf/desktop/mac/latest/GAF%20Present.zip',
    ingage:
      'https://download.ingage.io/apps/desktop/mac/latest/Presentations.zip',
  },
  windowsDownloadURL: {
    gaf: 'https://download.ingage.io/gaf/desktop/windows/latest/GAF%20Present.zip',
    ingage:
      'https://download.ingage.io/apps/desktop/windows/latest/Presentations.zip',
  },
  macInstallVideoURL: {
    gaf: 'https://s3.amazonaws.com/teams-assets.ingage.io/gaf/onboarding-videos/gaf-onboarding-install-mac.mp4',
    ingage: 'https://www.youtube.com/embed/-2pA-8EFNRQ?si=duz5P8v-Pnbc_-eg',
  },
  windowsInstallVideoURL: {
    gaf: 'https://s3.amazonaws.com/teams-assets.ingage.io/gaf/onboarding-videos/gaf-onboarding-install-windows.mp4',
    ingage: 'https://www.youtube.com/embed/yLh4ZkUh-KE?si=q7Ponj6s9LLDh4cm',
  },
  gettingStartedGuide: {
    gaf: gafGettingStartedGuide,
    ingage: ingageGettingStartedGuide,
  },
}

export type AppBrandKeys = 'gaf' | 'ingage'

export default brandManifest
