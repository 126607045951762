import { makeAutoObservable } from 'mobx'
import { AxiosRequestConfig } from 'axios'
import { makePersistable } from 'mobx-persist-store'
import { UUID } from 'src/util/types'
import { RootStore } from './RootStore'
import {
  AnalyticsReport,
  AnalyticsSavedReport,
} from 'src/model/analytics/Analytics'
import {
  APIAnalyticsReport,
  APIAnalyticsEvent,
  APIAnalyticsSavedReport,
  APIAnalyticsSavedReportOptions,
} from 'src/model/api/analytics/APIAnalytics'
import { AnalyticsModel } from 'src/model/analytics'
import { SavedReport } from 'src/model/analytics/SavedReport'
import { GetAnalyticsEventsOptions } from 'src/api/AnalyticsAPI'
import { ChartUtil } from 'src/views/company/analytics/utils/ChartUtil'
import { APISavedReport } from 'src/model/api/analytics/APISavedReport'
import { SavedReportOptions } from 'src/model/analytics/SavedReportOptions'
import { BaseStore } from './BaseStore'

export class AnalyticsStore implements BaseStore {
  static ANALYTICS_STORE_STORAGE_KEY = 'TeamsAnalytics'
  rootStore: RootStore
  reports: AnalyticsReport[]
  savedReports: AnalyticsSavedReport[]
  initialAnalyticsParams: { [key: string]: string }

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.reports = []
    this.savedReports = []
    this.initialAnalyticsParams = {}
    makeAutoObservable(this, { rootStore: false })
    makePersistable(this, {
      name: AnalyticsStore.ANALYTICS_STORE_STORAGE_KEY,
      properties: ['reports', 'initialAnalyticsParams'],
      storage: window.localStorage,
    }).then((r) => {
      console.debug(
        `Persisting data for ${AnalyticsStore.ANALYTICS_STORE_STORAGE_KEY}`,
      )
    })
  }

  async getAnalyticsReports() {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .analyticsAPI.getAnalyticsReports()
      if (res.data) {
        this.setReports(
          res.data.map((apiAnalyticsReport: APIAnalyticsReport) =>
            AnalyticsModel.analyticsReportFromAPIModel(apiAnalyticsReport),
          ),
        )
      }
    } catch (e) {
      console.error('Unable to fetch analytics reports', e)
      this.setReports([])
    }
  }

  async getAnalyticsEvents(
    companyUUID: UUID,
    options: GetAnalyticsEventsOptions,
    config?: AxiosRequestConfig,
  ) {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .analyticsAPI.getAnalyticsEvents(companyUUID, options, config)
      if (res.data) {
        return res.data.map((apiAnalyticsEvent: APIAnalyticsEvent) =>
          AnalyticsModel.analyticsEventFromAPIModel(apiAnalyticsEvent),
        )
      }
    } catch (e) {
      if (!config?.signal?.aborted) {
        console.error(
          `Unable to fetch analytics events for ${companyUUID} using report: ${options.reportID}`,
          e,
        )
      }
    }
  }

  async getAnalyticsSavedReports(companyUUID: UUID) {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .analyticsAPI.getAnalyticsSavedReports(companyUUID)
      if (res) {
        this.setSavedReports(
          res.map((apiAnalyticsSavedReport: APIAnalyticsSavedReport) =>
            AnalyticsModel.analyticsSavedReportFromAPIModel(
              apiAnalyticsSavedReport,
            ),
          ),
        )
      }
    } catch (e) {
      console.error('Unable to fetch analytics saved reports', e)
      this.setSavedReports([])
    }
  }

  async saveNewAnalyticsSavedReport(
    companyUUID: UUID,
    options: APIAnalyticsSavedReportOptions,
  ) {
    try {
      await this.rootStore
        .getAPIClient()
        .analyticsAPI.saveNewAnalyticsSavedReport(companyUUID, options)
    } catch (e) {
      console.error('Unable to save new analytics saved report', e)
    }
  }

  async updateAnalyticsSavedReport(
    companyUUID: UUID,
    savedReportUUID: UUID,
    options: APIAnalyticsSavedReportOptions,
  ) {
    try {
      await this.rootStore
        .getAPIClient()
        .analyticsAPI.updateAnalyticsSavedReport(
          companyUUID,
          savedReportUUID,
          options,
        )
    } catch (e) {
      console.error('Unable to update analytics saved report', e)
    }
  }

  async deleteAnalyticsSavedReport(companyUUID: UUID, savedReportUUID: UUID) {
    try {
      await this.rootStore
        .getAPIClient()
        .analyticsAPI.deleteAnalyticsSavedReport(companyUUID, savedReportUUID)
    } catch (e) {
      console.error('Unable to delete analytics saved report', e)
    }
  }

  async getAnalyticsSavedReportsEvents(
    companyUUID: UUID,
    savedReportUUID: UUID,
    startDate: string,
    endDate: string,
    limit: string,
  ) {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .analyticsAPI.getAnalyticsSavedReportsEvents(
          companyUUID,
          savedReportUUID,
          startDate,
          endDate,
          limit,
        )
      if (res.data) {
        return res.data.map((apiAnalyticsEvent: APIAnalyticsEvent) =>
          AnalyticsModel.analyticsEventFromAPIModel(apiAnalyticsEvent),
        )
      }
    } catch (e) {
      console.error(`Unable to fetch saved report events for ${companyUUID}`, e)
    }
  }

  async getAnalyticsSavedReportByOptions(
    companyUUID: UUID,
    options: { [key: string]: unknown },
    startDate: string,
    endDate: string,
    config?: AxiosRequestConfig,
  ) {
    try {
      const res = await this.rootStore
        .getAPIClient()
        .analyticsAPI.getAnalyticsSavedReportByOptions(
          companyUUID,
          options,
          startDate,
          endDate,
          config,
        )
      if (res.data) {
        return res.data.map((apiAnalyticsEvent: APIAnalyticsEvent) => {
          const analyticsEvent =
            AnalyticsModel.analyticsEventFromAPIModel(apiAnalyticsEvent)

          // assign a default user id to identify anonymous users
          return ChartUtil.assignDefaultUserId(analyticsEvent)
        })
      }
    } catch (e) {
      if (!config?.signal?.aborted) {
        console.error(
          `Unable to fetch saved report events for ${companyUUID}`,
          e,
        )
      }
    }
  }

  async getPaginatedSavedReports(
    companyUUID: UUID,
    page: number,
    pageSize: number,
    config?: AxiosRequestConfig,
  ): Promise<{ totalCount: number; reports: SavedReport[] }> {
    const response = await this.rootStore
      .getAPIClient()
      .analyticsAPI.getPaginatedSavedReports(
        companyUUID,
        page,
        pageSize,
        config,
      )

    return {
      totalCount: response.headers['total-count']
        ? parseInt(response.headers['total-count'])
        : 0,
      reports: response.data.map((apiSavedReport: APISavedReport) =>
        AnalyticsModel.savedReportFromAPIModel(apiSavedReport),
      ),
    }
  }

  async getSavedReport(
    companyUUID: UUID,
    savedReportUUID: UUID,
    config?: AxiosRequestConfig,
  ) {
    const response = await this.rootStore
      .getAPIClient()
      .analyticsAPI.getSavedReport(companyUUID, savedReportUUID, config)

    return AnalyticsModel.savedReportWithLabelsFromAPIModel(response.data)
  }

  async createSavedReport(companyUUID: UUID, options: SavedReportOptions) {
    const APIOptions = AnalyticsModel.APISavedReportOptionsFromModel(options)

    const response = await this.rootStore
      .getAPIClient()
      .analyticsAPI.createSavedReport(companyUUID, APIOptions)

    return AnalyticsModel.savedReportWithLabelsFromAPIModel(response.data)
  }

  async updateSavedReport(
    companyUUID: UUID,
    savedReportUUID: UUID,
    options: SavedReportOptions,
  ) {
    const APIOptions = AnalyticsModel.APISavedReportOptionsFromModel(options)

    const response = await this.rootStore
      .getAPIClient()
      .analyticsAPI.updateSavedReport(companyUUID, savedReportUUID, APIOptions)

    return AnalyticsModel.savedReportFromAPIModel(response.data)
  }

  async deleteSavedReport(companyUUID: UUID, savedReportUUID: UUID) {
    await this.rootStore
      .getAPIClient()
      .analyticsAPI.deleteSavedReport(companyUUID, savedReportUUID)
  }

  getSavedReportsSortedMostRecent() {
    const sortedSavedReports = this.savedReports.sort((a, b) => {
      return a.updatedAt < b.updatedAt ? 1 : a.updatedAt > b.updatedAt ? -1 : 0
    })
    return sortedSavedReports
  }

  // region Getters and setters
  setReports(reports: AnalyticsReport[]) {
    this.reports = reports
  }

  setSavedReports(savedReports: AnalyticsSavedReport[]) {
    this.savedReports = savedReports
  }

  setInitialAnalyticsParams(params: { [key: string]: string }) {
    this.initialAnalyticsParams = params
  }

  clearData() {
    this.initialAnalyticsParams = {}
  }
}
