import { cloneDeep } from 'lodash'

// pages
import * as deck from './stubs/pages/deck';
import * as grid from './stubs/pages/grid';
import * as impact from './stubs/pages/impact';
import * as video from './stubs/pages/video';
import * as comparePage from './stubs/pages/compare';
import * as coverPage from './stubs/pages/cover';
import * as scrollmotionPage from './stubs/pages/scrollmotion';
import * as galleryPage from './stubs/pages/gallery';
import * as freeformPage from './stubs/pages/freeform';
import * as htmlPage from './stubs/pages/html';

// components
import * as audio from './stubs/components/audio';
import * as button from './stubs/components/button';
import * as compare from './stubs/components/compare';
import * as dynamicBox from './stubs/components/dynamic-box';
import * as email from './stubs/components/email';
import * as image from './stubs/components/image';
import * as location from './stubs/components/location';
import * as mediaComponent from './stubs/components/media';
import * as mediaModal from './stubs/components/media-modal';
import * as readme from './stubs/components/readme';
import * as overlay from './stubs/components/overlay';
import * as text from './stubs/components/text';
import * as videoComponent from './stubs/components/video';
import * as freeformImageComponent from './stubs/components/freeform-image';
import * as freeformVideoComponent from './stubs/components/freeform-video';
import * as web from './stubs/components/web';
import * as galleryComponent from './stubs/components/gallery'
import * as freeformComponent from './stubs/components/freeform-overlay';
import * as carouselButtonComponent from './stubs/components/carousel-button-component';
import * as richTextComponent from './stubs/components/rich-text-component';

// story
import * as story from './stubs/meta/story';
import * as template from './stubs/meta/template';
import * as theme from './stubs/meta/freestyle-styles';
import * as legacyStyles from './stubs/meta/legacy-styles'
import * as legacyFontDefinition from './stubs/meta/legacy-font-definition'
import * as storyBarStyles from './stubs/meta/story-bar-styles'

// This number needs to be incremented with each change to the 
// upgrader.
export const UPGRADER_VERSION_NUMBER = 8

interface Upgrader {
    versions: { [key: number]: Function };
}

const upgraders: { [key: string]: Upgrader } = {
    // pages
    'deck-page': deck,
    'rows-and-columns-page': grid,
    'impact-page': impact,
    'video-page': video,
    'compare-page': comparePage,
    'cover-page': coverPage,
    'scrollmotion-page': scrollmotionPage,
    'gallery-page': galleryPage,
    'freeform-page': freeformPage,
    'html-page': htmlPage,

    //components
    'audio-button-component': audio,
    'button-component': button,
    'compare-component': compare,
    'dynamic-box-component': dynamicBox,
    'email-button-component': email,
    'image-component': image,
    'location-button-component': location,
    'media-modal-component': mediaModal,
    'media-component': mediaComponent,
    'readme-modal-component': readme,
    'overlay-component': overlay,
    'text-component': text,
    'video-component': videoComponent,
    'freeform-image-component': freeformImageComponent,
    'freeform-video-component': freeformVideoComponent,
    'web-component': web,
    'gallery-component': galleryComponent,
    'freeform-overlay-component': freeformComponent,
    'carousel-button-component': carouselButtonComponent,
    'rich-text-component': richTextComponent,

    // story
    story: story,
    template: template,
    theme: theme,
    'legacy-styles': legacyStyles,
    'legacy-font-definition': legacyFontDefinition,
    'story-bar-styles': storyBarStyles
};

export type StubType =
    // pages
    | 'deck-page'
    | 'rows-and-columns-page'
    | 'impact-page'
    | 'video-page'
    | 'compare-page'
    | 'cover-page'
    | 'scrollmotion-page'
    | 'gallery-page'
    | 'freeform-page'
    | 'html-page'

    //components
    | 'audio-button-component'
    | 'button-component'
    | 'compare-component'
    | 'dynamic-box-component'
    | 'email-button-component'
    | 'image-component'
    | 'location-button-component'
    | 'media-modal-component'
    | 'media-component'
    | 'readme-modal-component'
    | 'overlay-component'
    | 'text-component'
    | 'video-component'
    | 'freeform-image-component'
    | 'freeform-video-component'
    | 'web-component'
    | 'scrollmotion-component'
    | 'emailcapture-button-component'
    | 'gallery-component'
    | 'freeform-overlay-component'
    | 'carousel-button-component'
    | 'rich-text-component'

    // story
    | 'stubs'
    | 'story'
    | 'template'
    | 'theme'
    | 'legacy-styles'
    | 'legacy-font-definition'
    | 'story-bar-styles'

export interface Stub {
    type: StubType;
    version: number;
    [x: string]: any;
}

interface StubError {
    type: 'error';
    version: number;
    code?: number;
    message?: string;
}

export interface Config {
    recursive?: boolean;
    supports: { [Type in StubType]?: number };

    // Scale factor is the ratio of the iPad screen width relative to the
    // default with of 1024. This is required for converting from relative
    // to absolute coordinates.
    scaleFactor?: number;
    template?: Stub;
}

export interface Context {
    width?: number;
    height?: number;
    textMode?: string;
}

const ERROR_VERSION = 1;

export const upgrade = (
    stub: Stub,
    originalConfig: Config,
    context?: Context,
): Stub | StubError => {
    if (stub == null) {
        return {
            type: 'error',
            version: ERROR_VERSION,
            message: 'Expected stub, got undefined',
        };
    }

    
    if (originalConfig == null) {
      return {
        type: 'error',
        version: ERROR_VERSION,
        message: 'Expected config, got undefined',
      };
    }

    // Don't mutate the original config
    const config = cloneDeep(originalConfig)

    if (!('type' in stub)) {
        return {
            type: 'error',
            version: ERROR_VERSION,
            message: 'Expected stub to have property `type`, got undefined',
        };
    }

    if (!('version' in stub)) {
        return {
            type: 'error',
            version: ERROR_VERSION,
            message: 'Expected stub to have property \`version\`, got undefined',
        };
    }

    if (!('supports' in config)) {
        return {
            type: 'error',
            version: ERROR_VERSION,
            message:
                'Expected config to have property `supports`, got undefined',
        };
    }

    // The fix for ticket https://ingage-hq.atlassian.net/browse/DESKTOP-6739
    // incorrectly changed the default Story type from `story` to `stub`.
    // This resulted in Desktop builds having the wrong story type and as a result
    // broke the stubs upgrader.  The lines below fix this by setting the stub type
    // to `story` if the upgrader encounters a stub that has a type of `stubs`.
    // V6 of the story upgrade will permanently fix this.
    if (stub.type === 'stubs') {
        stub.type = 'story'
    }

    if (!(stub.type in config.supports!)) {
        return stub;
    }

    const maxVersion = config.supports[stub.type]

    if (maxVersion && stub.version && stub.version > maxVersion) {
        return stub;
    }

    if (!('recursive' in config)) {
        config.recursive = true;
    }

    if (!('template' in config)) {
        if (stub.type === 'story' && stub.template != null) {
            config.template = cloneDeep(stub.template)
        }

        else if (stub.type === 'template') {
            config.template = cloneDeep(stub)
        }
    }

    const version = stub.version;

    let result: Stub | StubError;

    if (stub.type in upgraders) {
        const upgrader = upgraders[stub.type].versions[stub.version - 1];

        if (upgrader) {
            result = upgrader(stub, config, context);
        } else {
            return stub;
        }
    } else {
        return stub;
    }

    //@ts-ignore ('any' type)
    const maxResultVersion = config.supports[result.type]

    if (
        result.type !== 'error' &&
        maxResultVersion &&
        result.version < maxResultVersion &&
        result.version != version
    ) {
        return upgrade(result, config, context);
    }

    return result;
};

export const getUpgraderVersion = () => {
  return UPGRADER_VERSION_NUMBER
}

export default upgrade;