import { useMemo } from 'react'

import { UUID } from 'src/util/types'
import { Profile } from 'src/model/user/Profile'
import { Feature } from 'src/util/features/Feature'
import { Company } from 'src/model/company/Company'
import { useTeamsStore, useUserStore } from 'src/store'
import { FeaturesUtil } from 'src/util/features/FeaturesUtil'

export const companyOrUserAllowsStreamlinedExperience = (
  company?: Company,
  profile?: Profile,
) => {
  if (!company || !profile) return false

  return (
    FeaturesUtil.hasCompanyFeature(
      company,
      Feature.ALLOW_STREAMLINED_EXPERIENCE,
    ) ||
    FeaturesUtil.hasUserFeature(profile, Feature.ALLOW_STREAMLINED_EXPERIENCE)
  )
}

/**
 * Checks if the `allow-streamlined-experience` feature is enabled for the company or user.
 */
export const useAllowStreamlinedExperience = (companyUUID?: UUID) => {
  const teamsStore = useTeamsStore()
  const userStore = useUserStore()

  const company = useMemo(() => {
    return teamsStore.companies.find((company) => company.uuid === companyUUID)
  }, [teamsStore.companies, companyUUID])

  return useMemo(() => {
    return companyOrUserAllowsStreamlinedExperience(company, userStore.profile)
  }, [company, userStore.profile])
}
