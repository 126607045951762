export enum Feature {
  LEGACY_ANALYTICS = 'analytics-legacy',
  PREBUILT_ANALYTICS = 'analytics-prebuilt',
  LICENSE_MANAGEMENT = 'license-management',
  MARKETPLACE_ADMIN = 'marketplace-admin',
  VERSION_HISTORY = 'version-history',
  ALLOW_VIEW_FROM_TEAMS = 'allow-view-from-teams',
  ALLOW_STREAMLINED_EXPERIENCE = 'allow-streamlined-experience',
  ALLOW_SAVED_REPORTS = 'allow-saved-reports',
  ALLOW_DYNAMIC_CONTENT = 'allow-dynamic-content',
  ALLOW_DYNAMIC_CONTENT_PARAM_MANAGEMENT = 'allow-dynamic-content-param-management',
  GAF_CONTRACTOR_VALIDATION = 'gaf-contractor-validation',
  SIGNUP_PAGE = 'signup-page',
}
